import { canEditPublicFormsOnFestivalString, canViewAccreditationsOnFestivalString } from '@/variables/permissions';

export const publicFormTabs = (
  admin = false,
  hasAccreditations = false,
  permissions: string[] = [],
  smallScreen = false
) => {
  const tabs = [];

  tabs.push({ key: 'responses', title: 'Responses' });

  if (hasAccreditations && (admin || permissions.includes(canViewAccreditationsOnFestivalString))) {
    tabs.push({ key: 'accreditations', title: 'Accreditations' });
  }
  if (admin || permissions.includes(canEditPublicFormsOnFestivalString)) {
    if (!smallScreen) {
      tabs.push({ key: 'info-sections', title: 'Info Sections' });
      tabs.push({ key: 'settings', title: 'Settings' });
    }
  }
  return tabs;
};
