import VDialog from '@/components/Modals/VDialog.vue';
import { useModal } from 'vue-final-modal';
import { useEventListener } from '@vueuse/core';

export const useCertaintyModal = () => {
  const assertCertain = async (
    title: string,
    content: string,
    certainText = 'Yes',
    options = { titleHighlight: '' },
    cancelText = 'Cancel',
    id: string | undefined = undefined,
    submitOnEnter = true
  ) => {
    return new Promise<boolean>((resolve) => {
      let resolveResponse = false;
      const modelInstance = useModal({
        defaultModelValue: true,
        component: VDialog,
        attrs: {
          title,
          content,
          id,
          titleHighlight: options?.titleHighlight,
          buttons: [
            {
              class: 'btn btn-outline-text btn-pending',
              style: 'width:90px!important',
              title: cancelText,
              emit: 'cancel',
            },
            {
              class: 'btn-success',
              style: 'width:120px!important',
              title: certainText,
              emit: 'sure',
            },
          ],
          async onCancel() {
            resolveResponse = false;

            modelInstance.close();
          },
          async onSure() {
            resolveResponse = true;
            modelInstance.close();
          },
          onClosed() {
            resolve(resolveResponse);
            modelInstance.destroy();
          },
        },
      });
      if (submitOnEnter) {
        useEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            resolveResponse = true;
            modelInstance.close();
          }
        });
      }
    });
  };

  return { assertCertain };
};
