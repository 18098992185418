<script setup lang="ts">
import { watch, ref } from 'vue';

interface Props {
  localStorageString?: string | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  value: string | Array<never> | boolean | never | number | null | Object;
  timeout?: number;
}
const props = withDefaults(defineProps<Props>(), {
  localStorageString: null,
  value: null,
  timeout: 0,
});

const emits = defineEmits(['newValue']);
const storeIt = ref(false);

setTimeout(() => {
  const storedState = JSON.parse(localStorage.getItem(props.localStorageString));
  if (storedState !== null) {
    emits('newValue', storedState);
  }
  storeIt.value = true;
}, props.timeout);

watch(
  () => props.value,
  () => {
    if (!storeIt.value || !props.localStorageString) return;
    if (!props || props.value === null || props.value === 'null') {
      localStorage.removeItem(props.localStorageString);
    } else {
      localStorage.setItem(props.localStorageString, JSON.stringify(props.value));
    }
  }
);
</script>
<template />
