<script setup lang="ts">
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { getItemFromArrayBasedOnId } from '@/util/globals.ts';
import { downloadFile } from '@/helpers/downloadFileFunctions';

type Props = {
  metaData: unknown;
  showTimes: unknown[];
};

defineProps<Props>();

const toast = useToast();

const working = ref(false);
const modalOpen = ref(false);
const downloadableMetaData = ref([]);

const closeDownloadMetaDataReportModal = () => {
  modalOpen.value = false;
};

const downloadReport = async () => {
  if (working.value) return;
  working.value = true;

  const { data } = await axios
    .post('/api/meta-data/event-report', {
      downloadableMetaData: downloadableMetaData.value,
    })
    .catch((error) => {
      console.error(error);
      working.value = false;
      toast.warning('Something went wrong, please try again later');
    });
  await downloadFile(data.url, data.name);
  working.value = false;
  closeDownloadMetaDataReportModal();
};
</script>

<template>
  <ReportModal
    :with-button="false"
    :can-download="downloadableMetaData.length > 0"
    title="Download Meta Data Report"
    :working="working"
    @download="downloadReport">
    <VMultiselect
      v-model="downloadableMetaData"
      class="col-span-2"
      :close-on-select="false"
      option-label="title"
      label-key="id"
      set-focus
      :options="
        metaData.map(function (m) {
          let title = m.title;
          if (m.show_time_id) {
            title += ' - ' + getItemFromArrayBasedOnId(m.show_time_id, showTimes, { title: 'N/A' }).title;
          }
          return { id: m.id, title: title };
        })
      "
      label="Meta Data to include in report" />
  </ReportModal>
</template>
