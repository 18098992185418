export const groupContactListTabs = () => [
  {
    key: 'contact_lists',
    title: 'contact',
  },
  {
    key: 'companies',
    title: 'Companies',
  },
];
