import { KeyContactResource } from '@/types/event';

export const postContactInvite = async (
  id: number,
  data: {
    role: string | null;
    name: string | null;
    phone: string | null;
    email: string | null;
    write: boolean;
  }
) => {
  const url = `/api/contacts/${id}/invite`;
  return axios.post<[string, KeyContactResource]>(url, data);
};
