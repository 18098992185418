<script lang="ts" setup>
import Datepicker from '@vuepic/vue-datepicker';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { formatStampAsDate, highlightDatesForKeys } from '@/util/timeFunctions';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { focusOnField, slotEmpty } from '@/util/globals.js';
import '@vuepic/vue-datepicker/dist/main.css';
import { startKey } from '@/provide/keys';

type PropType = {
  modelValue?:
    | string
    | number
    | string[]
    | Date
    | Date[]
    | number[]
    | {
        hours: string | number;
        minutes: string | number;
        seconds?: string | number | undefined;
      };
  canEdit?: boolean;
  earliestDate?: string | Date | undefined;
  label?: string;
  labelTitle?: string;
  disabledLabelTitle?: string;
  labelPlacement?: string;
  required?: boolean;
  isHidden?: boolean;
  inputClass?: string;
  inline?: boolean;
  monthPicker?: boolean;
  flow?: ('month' | 'year' | 'calendar' | 'time' | 'minutes' | 'hours' | 'seconds')[];
  multiDates?: boolean;
  range?: boolean;
  maxDate?: string | Date | undefined;
  startDate?: string | Date | undefined;
  partialRange?: boolean;
  setFocus?: boolean;
  withIcon?: boolean;
  alwaysShowClear?: boolean;
  highlightToday?: boolean;
  disabledDates?: string[];
  highlightDays?: string[];
};

const props = withDefaults(defineProps<PropType>(), {
  modelValue: undefined,
  canEdit: true,
  earliestDate: undefined,
  label: '',
  labelTitle: null,
  disabledLabelTitle: null,
  labelPlacement: 'top',
  required: false,
  isHidden: false,
  inputClass: '',
  inline: false,
  monthPicker: false,
  multiDates: false,
  range: false,
  alwaysShowClear: true,
  maxDate: undefined,
  startDate: undefined,
  partialRange: true,
  withIcon: true,
  setFocus: false,
  highlightToday: false,
  disabledDates: () => [],
  highlightDays: () => [],
  flow: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', val: string[] | string): void;
  (event: 'viewing:month', val: string): void;
  (event: 'viewing:year', val: string): void;
  (event: 'clear'): void;
  (event: 'closed'): void;
}>();
const inFocus = ref(false);

const onUpdate = (e: string | (string | null | undefined)[] | null | undefined) => {
  inFocus.value = false;
  if (props.multiDates) {
    if (!e) {
      emit('update:modelValue', []);
      return;
    }

    const t = Array.isArray(e) ? e.map((date: string | null | undefined) => formatStampAsDate(date)) : '';
    emit('update:modelValue', t);
  } else if (props.range) {
    if (!Array.isArray(e)) return;
    emit('update:modelValue', [formatStampAsDate(e[0]), formatStampAsDate(e[1])]);
  } else {
    if (Array.isArray(e)) return;
    emit('update:modelValue', formatStampAsDate(e));
  }
};
const justToMakeItImport = Datepicker;

const pos = computed(() => {
  switch (props.labelPlacement.toLowerCase()) {
    case 'left': {
      return '';
    }
    default: {
      return 'flex-col';
    }
  }
});

const datepicker = ref(null);
const wrapper = ref<HTMLElement | null>(null);

watch(
  () => props.modelValue,
  () =>
    handleMonthYear({
      month: new Date(String(props.modelValue)).getMonth(),
      year: new Date(String(props.modelValue)).getFullYear(),
    })
);

const viewMonth = ref<string | number>(new Date(String(props.modelValue)).getMonth());
const viewYear = ref<string | number>(new Date(String(props.modelValue)).getFullYear());

// For multiCalendars, instance will be the index of the calendar where the value is changed
const handleMonthYear: (arg0: { month: string | number; year: string | number }) => void = ({ month, year }) => {
  viewMonth.value = month;
  viewYear.value = year;
  emit('viewing:year', year);
  emit('viewing:month', month);
};

const overflowing = computed(() => {
  if (!wrapper.value) return false;
  const box = wrapper.value.getBoundingClientRect();
  return box.right + 150 > window.innerWidth;
});

onMounted(async () => {
  if (props.setFocus) {
    focusOnField(datepicker.value, 'openMenu');
  }
});
const clearDatePicker = () => {
  if (props.required) return;
  emit('clear');
  emit('update:modelValue', null);
};

const startFromKey = inject(startKey, null);
const getStartDate = computed(() => {
  if (props.startDate) return props.startDate;
  return startFromKey;
});

const customPosition = (el: HTMLElement) => {
  const box = el.getBoundingClientRect();
  let left: number;
  let top: number;

  if (box.left + 300 > window.innerWidth) {
    const diff = box.left + box.width + 300 - window.innerWidth;
    left = window.innerWidth - diff - 100;
  } else {
    left = box.left + box.width / 2 - 150;
  }

  if (box.top + box.height + 300 > window.innerHeight) {
    top = box.top - 300;
  } else {
    top = box.bottom + 10;
  }

  return {
    top,
    left,
  };
};
</script>

<template>
  <div
    ref="wrapper"
    class="flex"
    :class="[pos, { 'w-[311px]': inline }]">
    <InputLabel
      :label="label"
      :title="canEdit || !disabledLabelTitle ? labelTitle : disabledLabelTitle"
      :mandatory-text="required ? `${label} is required` : null" />
    <Datepicker
      v-bind="$attrs"
      ref="datepicker"
      class="datepicker h-full rounded"
      :class="{
        'datepicker-hidden hover:!ring-1 [&_*]:!ring-0 ': isHidden,
        ' [&_*]:!ring-borderColor [&_input]:cursor-not-allowed ': !canEdit,
        ' bg-inputs-background ': !isHidden,
        ' ring-1 !ring-highlight ': inFocus,
      }"
      :model-value="modelValue"
      :auto-apply="true"
      :teleport="true"
      :enable-time-picker="false"
      :month-change-on-scroll="false"
      :alt-position="overflowing ? customPosition : null"
      :readonly="!canEdit"
      :disabled="!canEdit"
      :min-date="earliestDate"
      :required="required"
      :flow="flow"
      :clearable="!required && (inFocus || alwaysShowClear)"
      :hide-input-icon="!withIcon"
      :inline="inline"
      :multi-dates="multiDates"
      :month-picker="monthPicker"
      month-name-format="long"
      :day-names="['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']"
      :format="formatStampAsDate"
      :input-class-name="inputClass + ' input-field'"
      :menu-class-name="`menu-class ${
        highlightToday && viewMonth === new Date().getMonth() && viewYear === new Date().getFullYear()
          ? 'highlight-today'
          : ''
      } `"
      :highlight="highlightDays.length > 0 ? highlightDays : highlightDatesForKeys()"
      calendar-cell-class-name="dp-custom-cell"
      :hide-navigation="['month', 'year']"
      :range="range"
      :auto-position="true"
      :max-date="maxDate"
      :start-date="getStartDate"
      :disabled-dates="disabledDates"
      :partial-range="partialRange"
      @focus="inFocus = true"
      @blur="inFocus = false"
      @cleared="clearDatePicker"
      @closed="[emit('closed'), (inFocus = false)]"
      @update-month-year="handleMonthYear"
      @update:model-value="onUpdate">
      <template #input-icon>
        <div
          :class="inFocus ? ' text-textColor ' : ' text-textColor-soft '"
          class="flex w-[35px] items-center justify-center">
          <i class="fa fa-fw fa-calendar fa-regular"></i>
        </div>
      </template>
      <template
        v-if="inFocus || alwaysShowClear"
        #clear-icon>
        <div class="flex w-[35px] items-center justify-center text-textColor-soft hover:text-textColor">
          <i
            class="fa fa-fw fa-times"
            @click.stop="clearDatePicker"></i>
        </div>
      </template>
      <template #calendar-header="{ index, day }">
        <span :class="index === new Date().getDay() - 1 ? 'dp-custom-today-header' : ''">{{ day }}</span>
      </template>

      <!-- LEGACY -->
      <template
        v-if="!slotEmpty($slots, 'trigger')"
        #trigger>
        <slot name="trigger" />
      </template>
    </Datepicker>
  </div>
</template>

<style>
.dp__theme_light {
  --dp-background-color: rgb(var(--color-background));
  --dp-text-color: rgb(var(--color-text));
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #1976d2;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-menu-border-color: theme('colors.borderColor.DEFAULT');
  --dp-disabled-color: transparent;
  --dp-scroll-bar-background: theme('colors.backgroundColor.DEFAULT');
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
}

.input-field {
  height: 40px;
  border: none;

  @apply ring-1 ring-borderColor hover:ring-textColor-soft;
}

.input-field.dp__input_focus {
  @apply ring-highlight;
}

.datepicker-hidden {
  --dp-border-color: none;
}

.menu-class {
  @apply rounded-xl p-4;
}

.dp-custom-cell {
  @apply rounded-md border-none text-sm;
}

.highlight-today .dp-custom-today-header,
.dp__today {
  @apply text-highlight hover:!text-highlight;
}

.dp__input {
  @apply bg-transparent text-base;
}

.dp__cell_offset {
  @apply text-borderColor;
}

.dp__date_hover_end:hover,
.dp__date_hover_start:hover,
.dp__overlay_cell:hover,
.dp__date_hover:hover,
.dp__inner_nav:hover {
  @apply bg-backgroundColor-dark text-textColor ring-1;
}

.dp__range_end,
.dp__range_start,
.dp__active_date {
  @apply bg-highlight text-base text-textColor-inverted;
}

.dp__range_between {
  @apply bg-borderColor;
}

.dp__month_year_row {
  @apply h-[unset] p-0 pt-3;
}

.dp__month_year_wrap {
  @apply mx-auto max-w-fit gap-4;
}

.dp__inner_nav svg {
  @apply aspect-1 w-5;
}

.dp__month_year_select {
  @apply h-[unset] max-w-fit rounded-none border-b border-b-transparent !pb-0 uppercase;
}

.dp__month_year_select:hover {
  @apply border-b-borderColor bg-transparent text-highlight;
}

.dp__calendar {
  @apply grid gap-2;
}

.dp__calendar_header_separator {
  display: none;
}

.dp__calendar_header {
  @apply mb-0 mt-2 gap-2 pb-0;
}

.dp__calendar_header .dp__calendar_header_item {
  @apply h-auto font-body text-sm;
  font-weight: normal;
}

.dp__calendar_row {
  @apply m-0 gap-2;
}

.dp__overlay_cell_active {
  @apply bg-highlight text-textColor-inverted;
}

.dp__cell_disabled {
  color: rgba(var(--color-disabled)) !important;
}

.dp__input_icon {
  color: rgba(var(--color-soft-text)) !important;
}
</style>
