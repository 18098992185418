<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';

type Props = {
  loading?: boolean;
  totalPages?: number;
  currentPage: number;
  perPage?: number;
  totalCount?: number;
  small?: boolean;
  columnWidth?: number;
};

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  totalPages: 1,
  currentPage: 1,
  perPage: 0,
  totalCount: 0,
  small: false,
  columnWidth: 150,
});

const emit = defineEmits<{
  (event: 'changePage', page: number): void;
}>();

const shownPage = ref(2);

const nextPage = () => {
  if (props.loading) {
    return;
  }
  if (props.currentPage < props.totalPages) {
    emit('changePage', props.currentPage + 1);
  }
};

const previousPage = () => {
  if (props.loading) {
    return;
  }
  if (props.currentPage !== 1) {
    emit('changePage', props.currentPage - 1);
  }
};

const setPage = (page: number) => {
  if (props.loading) return;
  emit('changePage', page);
};

const isOnFirstPage = () => props.currentPage === 1;

const hasMorePages = () => props.currentPage !== props.totalPages;

const showThisPage = (page: number) => {
  if (props.currentPage - shownPage.value / 2 < page && props.currentPage + shownPage.value / 2 > page) {
    return true;
  }

  if (page > props.totalPages - shownPage.value) {
    return true;
  }

  return page < shownPage.value;
};

const getPaginationButtonClasses = () => {
  let classes = [
    // Base
    'bg-backgroundColor-content',
    'border',
    'font-medium',
    'h-full',
    'items-center',
    'relative',
    'text-center',
    'text-sm',

    // hover
    'enabled:cursor-pointer',
    'enabled:hover:bg-highlight',
    'enabled:hover:text-black',

    // Focus
    'focus-visible:ring-2',
    'focus-visible:outline-none',
    'focus-visible:ring-highlight',
    'focus-visible:z-10',
  ];

  if (props.small) {
    classes = [...classes, 'px-3', 'py-1'];
  } else {
    classes = [...classes, 'px-4', 'py-2', 'inline-flex'];
  }

  return classes;
};

const paginator = ref<HTMLElement | null>(null);

const getElemCounter = () => {
  const elem = paginator.value;
  if (elem) {
    let elemWidth = elem.offsetWidth;
    if (elemWidth > 400) {
      elemWidth -= 100;
      shownPage.value = elemWidth / (props.small ? 150 : 250);
    }
  } else {
    let pageWidth = document.body.offsetWidth;
    // Remove the sidebar
    pageWidth -= 200;
    // Remove other content
    pageWidth -= 300;
    shownPage.value = pageWidth / (props.small ? 120 : 180);
  }
};

onMounted(() => {
  getElemCounter();
  window.addEventListener('resize', getElemCounter);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', getElemCounter);
});
</script>

<template>
  <div
    v-if="totalPages >= 1"
    ref="paginator"
    :class="small ? 'p-1' : 'p-2'"
    class="grid items-center rounded bg-transparent"
    :style="`grid-template-columns: ${columnWidth}px auto ${columnWidth}px;`">
    <div>
      <slot name="before" />
    </div>

    <div class="mx-auto">
      <ul
        v-if="totalPages > 1"
        class="-space-x-px relative z-0 inline-flex rounded-md shadow-sm">
        <li>
          <button
            tabindex="0"
            :disabled="isOnFirstPage()"
            :class="[isOnFirstPage() ? ' disabled text-textColor-soft ' : ' ', getPaginationButtonClasses()]"
            class="rounded-tl rounded-bl"
            title="Go to Previous"
            @click.prevent="previousPage">
            <i
              v-if="loading"
              class="fa fa-fw fa-circle-o-notch fa-spin" />
            <i
              v-else
              class="fa fa-angle-double-left fa-sm" />
          </button>
        </li>

        <template
          v-for="page in totalPages"
          :key="page">
          <li>
            <button
              v-if="showThisPage(page)"
              tabindex="0"
              class="cursor-pointer"
              :class="[page === currentPage ? ' bg-highlight text-black ' : '', getPaginationButtonClasses()]"
              @click.prevent="setPage(page)">
              {{ page }}
            </button>
            <button
              v-if="page > 1 && !showThisPage(page) && showThisPage(page - 1)"
              disabled
              :class="['disabled', getPaginationButtonClasses()]"
              @click.prevent>
              ...
            </button>
          </li>
        </template>
        <li>
          <button
            tabindex="0"
            :disabled="!hasMorePages()"
            :class="[hasMorePages() ? ' disabled ' : ' ', getPaginationButtonClasses()]"
            title="Go to Next"
            class="rounded-br rounded-tr"
            @click.prevent="nextPage">
            <i
              v-if="loading"
              class="fa fa-fw fa-circle-o-notch fa-spin" />
            <i
              v-else
              class="fa fa-angle-double-right fa-sm" />
          </button>
        </li>
      </ul>
    </div>

    <div
      v-if="currentPage && perPage && totalCount"
      :class="small ? 'text-xs' : 'text-sm'"
      class="pr-4 text-center text-textColor-soft hover:text-textColor whitespace-nowrap">
      {{ (currentPage - 1) * perPage + 1 }}
      -
      {{ totalCount < currentPage * perPage ? totalCount : perPage * currentPage }}
      of {{ totalCount }}
    </div>
  </div>
</template>
