<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import { MaybeElementRef, onClickOutside, useEventListener } from '@vueuse/core';
import { ZIndexHoverBox } from '@/variables/z-indexes';

type Props = {
  xPos: number;
  yPos: number;
  teleport?: string;
  transition?: string;
  ignoreElements?: (MaybeElementRef | string)[];
  zIndex?: number;
  eventBounds?: DOMRect | null;
  closeOnScroll?: boolean;
  listIsInverted?: boolean | null;
  addY?: number;
  rerenderPositionIndicator?: number | null;
};

const props = withDefaults(defineProps<Props>(), {
  teleport: 'body',
  transition: 'fade',
  ignoreElements: () => [],
  closeOnScroll: false,
  listIsInverted: false,
  zIndex: ZIndexHoverBox,
  eventBounds: null,
  addY: 0,
  rerenderPositionIndicator: 0,
});

const emit = defineEmits<{
  (e: 'closed'): void;
  (event: 'update:listIsInverted', value: boolean): void;
}>();

const show = ref(false);
const wrapper = ref<HTMLElement | null>(null);

if (props.closeOnScroll) {
  useEventListener(
    'wheel',
    () => {
      emit('closed');
    },
    { passive: true }
  );
}

const left = ref(props.xPos);
const top = ref(props.yPos);

const calcPosition = () => {
  setTimeout(() => {
    if (wrapper.value) {
      if (props.eventBounds) {
        if (props.xPos + wrapper.value.getBoundingClientRect().width > window.innerWidth) {
          const maybeLeft = props.eventBounds?.left - wrapper.value.getBoundingClientRect().width;
          if (maybeLeft > 0) {
            left.value = maybeLeft;
          } else {
            left.value = 0;
          }
        }

        if (props.yPos + wrapper.value.getBoundingClientRect().height > window.innerHeight) {
          emit('update:listIsInverted', true);
          top.value = props.eventBounds?.top - (wrapper.value.getBoundingClientRect().height + 5) - props.addY;
          if (top.value < 50) {
            top.value = 100;
          }
        } else {
          top.value = props.yPos;
          emit('update:listIsInverted', false);
        }
      }
      show.value = true;
    }
  }, 50);
};

watch(
  () => props.eventBounds,
  () => {
    calcPosition();
  },
  { deep: true, immediate: true }
);
watch(
  () => props.rerenderPositionIndicator,
  () => {
    calcPosition();
  },
  { deep: true, immediate: true }
);

onMounted(async () => {
  onClickOutside(
    wrapper,
    () => {
      emit('closed');
    },
    {
      ignore: props.ignoreElements,
    }
  );

  await nextTick();
  calcPosition();
});
</script>

<template>
  <teleport :to="teleport">
    <div
      ref="wrapper"
      class="fixed"
      :class="{ 'invisible': !show, listIsInverted: ' bottom-0 [&>div]:bg-highlight ' }"
      :style="`left: ${left}px; top: ${top}px; z-index: ${zIndex}`">
      <slot />
    </div>
  </teleport>
</template>
