<script setup lang="ts">
import moment from 'moment';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { dateFormat } from '@/variables/date-format';
import { stampIsValid } from '@/util/timeFunctions';
import { ref } from 'vue';

type Props = {
  modelValue: string | null;
  canEdit: boolean;
};

defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', arg: string): void;
}>();

const edit = ref(false);
const focus = ref(false);

const onChange = (value: string) => {
  emit('update:modelValue', value);
  edit.value = false;
};

const formatDate = (date: string | null) => {
  if (date && stampIsValid(date)) {
    return moment(date).format(dateFormat);
  }
  return null;
};

// edit() {
//   if (this.edit) {
//     setTimeout(() => {
//       this.focus = true;
//     }, 50);
//   } else {
//     this.focus = false;
//   }
// },
</script>

<template>
  <div
    v-if="canEdit"
    class="h-full w-full [&_.rounded]:rounded-none [&_.datepicker:hover]:ring-textColor-soft">
    <VDatepicker
      :model-value="modelValue"
      :clearable="true"
      :can-edit="canEdit"
      :label="null"
      is-hidden
      @closed="edit = false"
      @update:model-value="onChange" />
  </div>
  <div
    v-else
    class="full-size">
    <p
      v-if="formatDate(modelValue)"
      class="my-0">
      {{ formatDate(modelValue) }}
    </p>
    <slot
      v-else
      name="emptyState" />
  </div>
</template>
