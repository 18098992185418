<template>
  <svg
    width="22mm"
    height="20mm"
    version="1.1"
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-130.01 -110.2)">
      <g transform="matrix(.35277 0 0 .35277 14.684 110.27)">
        <path
          class="cls-1"
          transform="translate(-203.56,-195.99)"
          d="m582.05 251.83h10.56l-26.52-55.42h-7.84l-27.54 55.42h10.77l3.4-6.88 16.83-36 17 36.06z" />
      </g>
    </g>
  </svg>
</template>
