<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import { useInfiniteScroll } from '@vueuse/core';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import {
  BoardAuditResource,
  BoardCellResource,
  BoardColumnResource,
  BoardResource,
  BoardRowResource,
} from '@/types/board';
import { usePage } from '@inertiajs/vue3';
import { getKey } from '@/util/globals';

type Props = {
  board: BoardResource;
  boardRow?: BoardRowResource | null;
  boardColumn?: BoardColumnResource | null;
  boardCell?: BoardCellResource | null;
};

const props = withDefaults(defineProps<Props>(), {
  boardRow: null,
  boardColumn: null,
  boardCell: null,
});

const toast = useToast();
const target = ref(null);

const loading = ref(false);
const open = ref(false);

const loadingSelectedAudit = ref(false);

const audits = ref<BoardAuditResource[]>([]);
const activeFields = ref([]);
const allowedFields = ref(['order', 'title', 'component', 'options', 'width', 'value']);
const page = ref(1);
const totalPages = ref(1);
const totalAudits = ref(null);
const selectedAudit = ref(null);

const auditEventTypes = ref([
  { name: 'Created', id: 'created' },
  { name: 'Updated', id: 'updated' },
  { name: 'Deleted', id: 'deleted' },
]);

const allAuditEventTypes = [
  { name: 'Created', id: 'created' },
  { name: 'Updated', id: 'updated' },
  { name: 'Deleted', id: 'deleted' },
];

const timeoutFunction = ref(null);
const fetching = ref(false);

const getTitleForAudits = () => {
  if (props.boardCell) return 'Audits for ' + props.boardRow.title + ' on ' + props.boardColumn.title;
  if (props.boardColumn) return 'Audits for ' + props.boardColumn.title;
  if (props.boardRow) return 'Audits for ' + props.boardRow.title;
  return 'Audits for ' + props.board.title;
};

const getUrlForAudits = () => {
  if (props.boardCell) return '/api/boards/' + props.board.id + '/board-cells/' + props.boardCell.id + '/audits';
  if (props.boardColumn) return '/api/boards/' + props.board.id + '/board-columns/' + props.boardColumn.id + '/audits';
  if (props.boardRow) return '/api/boards/' + props.board.id + '/board-rows/' + props.boardRow.id + '/audits';
  return '/api/boards/' + props.board.id + '/audits';
};

const loadAudits = async () => {
  if (loading.value) return;
  fetching.value = true;
  loading.value = true;
  const { data } = await axios
    .get(getUrlForAudits(), {
      params: {
        page: page.value,
        audit_events: auditEventTypes.value.map((type) => type.id),
      },
    })
    .catch((error) => {
      console.error(error);
    });
  if (data.meta.hasOwnProperty('total')) {
    totalAudits.value = data.meta.total;
  }
  if (data.meta.hasOwnProperty('last_page')) {
    totalPages.value = data.meta.last_page;
  } else if (data.data.length > 0) {
    totalPages.value = page.value + 1;
  }
  if (data.data.length) {
    audits.value = audits.value.concat(data.data);
  }

  loading.value = false;
  fetching.value = false;
};

const resetAndLoad = (delay = 1000) => {
  audits.value = [];
  page.value = 1;
  clearTimeout(timeoutFunction.value);
  timeoutFunction.value = null;
  fetching.value = true;
  timeoutFunction.value = setTimeout(() => {
    clearTimeout(timeoutFunction.value);
    loadAudits();
  }, delay);
};

const showSidebar = () => {
  open.value = false;
  nextTick(() => {
    open.value = true;
  });
  resetAndLoad(0);
};

const openAudit = async (audit: BoardAuditResource) => {
  selectedAudit.value = null;
  await nextTick();
  selectedAudit.value = audit;
  const possibleFields = audit.old_values
    ? Object.keys(audit.old_values).concat(Object.keys(audit.new_values))
    : Object.keys(audit.new_values);
  activeFields.value = allowedFields.value.filter((field) => possibleFields.includes(field));
};

watch(auditEventTypes, () => {
  resetAndLoad(0);
});

onMounted(() => {
  setTimeout(() => {
    showSidebar();
  }, 100);
});
useInfiniteScroll(
  target,
  async () => {
    // load more
    if (page.value < totalPages.value) {
      page.value += 1;
      await loadAudits();
    }
  },
  { distance: 10 }
);

const height = CSS.supports('height: 100dvh') ? '100dvh' : '100vh';
</script>

<template>
  <div>
    <BaseSlideout
      v-model="open"
      small
      within-same
      :base-z-index="1000"
      @closed="$emit('closed')">
      <template #header>
        <div class="flex flex-col border-b p-edge">
          <div class="flex gap-3">
            <IconWithLoading
              icon="fa-history"
              classes="text-3xl"
              :loading="loading"></IconWithLoading>
            <h1>{{ totalAudits }} Records</h1>
          </div>
          <div class="sub-title text-textColor-soft">
            {{ getTitleForAudits() }}
          </div>
        </div>
      </template>
      <!--      <div class="h-full">-->
      <!--        <div class="flex flex-col gap-5 [&>div]:p-5">-->
      <!--          &lt;!&ndash;          <VMultiselect&ndash;&gt;-->
      <!--          &lt;!&ndash;            v-if="canFilterAuditEvents"&ndash;&gt;-->
      <!--          &lt;!&ndash;            v-model="auditEventTypes"&ndash;&gt;-->
      <!--          &lt;!&ndash;            :close-on-select="false"&ndash;&gt;-->
      <!--          &lt;!&ndash;            placeholder="Select One or more types of audits"&ndash;&gt;-->
      <!--          &lt;!&ndash;            :options="allAuditEventTypes"&ndash;&gt;-->
      <!--          &lt;!&ndash;            object&ndash;&gt;-->
      <!--          &lt;!&ndash;            label="Audit Events" />&ndash;&gt;-->

      <!--          <div-->
      <!--            v-if="!audits.length"-->
      <!--            class="mt-50">-->
      <!--            <p>No audits found.</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          v-if="audits.length"-->
      <!--          ref="target"-->
      <!--          :style="`height: calc(${height} - var(&#45;&#45;navbar-height)- 20px)`"-->
      <!--          class="mb-5 mt-2 grid gap-4 overflow-auto [&>div]:px-edge">-->
      <!--          <div-->
      <!--            v-for="audit in audits"-->
      <!--            :key="audit.id"-->
      <!--            class="cursor-pointer [&_.sub-title:hover]:underline hover:bg-row-hover hover:border-l-highlight border-l-2 border-l-transparent group grid grid-cols-[30px_auto] items-center gap-3 py-2 transition-all"-->
      <!--            @click.prevent="openAudit(audit)">-->
      <!--            <div class="image pull-left align-content-vertically">-->
      <!--              <img-->
      <!--                v-if="audit.user && audit.user.avatar_url"-->
      <!--                :src="audit.user.avatar_url"-->
      <!--                class="img-responsive rounded-full" />-->
      <!--              <img-->
      <!--                v-else-->
      <!--                :src="usePage().props.asset_url + 'assets/images/default-avatar.png'"-->
      <!--                class="img-responsive rounded-full" />-->
      <!--            </div>-->
      <!--            <div class="overflow-hidden pr-2">-->
      <!--              <div class="sub-title truncate">-->
      <!--                {{ audit.user ? audit.user.name : 'Unknown user' }}-->
      <!--                {{ audit.title }}-->
      <!--              </div>-->
      <!--              <small-->
      <!--                class="mt-2 text-textColor-soft"-->
      <!--                :title="audit.created_at">-->
      <!--                {{ audit.created_at_human }}-->
      <!--              </small>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          &lt;!&ndash;          {{ audits }}&ndash;&gt;-->
      <!--          &lt;!&ndash;          <AuditsSidebarItem&ndash;&gt;-->
      <!--          &lt;!&ndash;            v-for="audit in audits"&ndash;&gt;-->
      <!--          &lt;!&ndash;            :audit="audit"&ndash;&gt;-->
      <!--          &lt;!&ndash;            can-open&ndash;&gt;-->
      <!--          &lt;!&ndash;            @click="openAudit(audit)" />&ndash;&gt;-->
      <!--          <div-->
      <!--            v-if="audits.length && page >= totalPages"-->
      <!--            class="p-5 text-center text-lg uppercase">-->
      <!--            All loaded-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="h-full">
        <div
          v-if="!audits.length"
          class="mt-10">
          <p>You don't have any notifications yet</p>
        </div>
        <div
          v-if="audits.length"
          ref="target"
          :style="`height: calc(${height} - var(--navbar-height) - 10px)`"
          class="flex flex-col overflow-auto [&>div]:px-edge [&>div]:py-3">
          <div
            v-for="audit in audits"
            :key="audit.id"
            class="cursor-pointer [&_.sub-title:hover]:underline hover:bg-row-hover hover:border-l-highlight border-l-2 border-l-transparent group grid grid-cols-[30px_auto] items-center gap-3 py-2 transition-all"
            @click.prevent="openAudit(audit)">
            <div class="image pull-left align-content-vertically">
              <img
                v-if="audit.user && audit.user.avatar_url"
                :src="audit.user.avatar_url"
                class="img-responsive rounded-full" />
              <img
                v-else
                :src="usePage().props.asset_url + 'assets/images/default-avatar.png'"
                class="img-responsive rounded-full" />
            </div>
            <div class="overflow-hidden pr-2">
              <div class="sub-title truncate">
                {{ audit.user ? audit.user.name : 'Unknown user' }}
                {{ audit.title }}
              </div>
              <small
                class="mt-2 text-textColor-soft"
                :title="audit.created_at">
                {{ audit.created_at_human }}
              </small>
            </div>
          </div>

          <!--          <div-->
          <!--            v-for="(notification, index) in notifications"-->
          <!--            :key="`${notification.id}_${index}`"-->
          <!--            :ref="'notification_' + notification.id"-->
          <!--            @click="notificationClick(notification, $event)">-->
          <!--            <p :class="'notification ' + (notification.action_url ? ' cursor-pointer hover:underline ' : '')">-->
          <!--              {{ notification.action_text }}-->
          <!--            </p>-->
          <!--            <div-->
          <!--              :title="notification.date"-->
          <!--              class="text-xs text-textColor-soft">-->
          <!--              {{ notification.friendly_date }}-->
          <!--            </div>-->
          <!--          </div>-->

          <div v-if="audits.length && page >= totalPages">
            <span
              class="mt-25"
              @click.prevent>
              All Loaded
            </span>
          </div>
        </div>

        <div v-if="loading">
          <div class="text-center">
            <h3>
              <i class="fa fa-fw fa-circle-o-notch fa-spin" />
            </h3>
          </div>
        </div>
      </div>
    </BaseSlideout>

    <CrudModal
      v-if="selectedAudit"
      title="Audit"
      only-close-button
      @closed="selectedAudit = null">
      <div
        v-if="!loadingSelectedAudit && selectedAudit"
        class="main-content">
        <div class="sub-title mb-6">
          {{ selectedAudit.user ? selectedAudit.user.name : 'Unknown user' }}
          {{ selectedAudit.title }}
        </div>
        {{}}
        <div
          v-if="activeFields.length > 0"
          class="border-t hover:border-t-highlight [&_th>*]:px-2">
          <VTable>
            <template #head>
              <VTableRow head>
                <VTableCell>Attribute</VTableCell>
                <VTableCell v-if="['updated', 'deleted'].includes(selectedAudit.event)"> Old</VTableCell>
                <VTableCell v-if="['created', 'updated'].includes(selectedAudit.event)"> New</VTableCell>
              </VTableRow>
            </template>
            <template #default>
              <VTableRow
                v-for="field in activeFields"
                :key="field.id">
                <VTableCell main-cell>
                  <span class="capitalize">{{ field }}</span>
                </VTableCell>
                <VTableCell v-if="['updated', 'deleted'].includes(selectedAudit.event)">
                  {{ getKey(selectedAudit.old_values, field) }}
                  <span
                    v-for="(t, idx) in getKey(selectedAudit.old_values, field, '')?.split('\n')"
                    :key="idx">
                    <DisplayRichText :content="t"></DisplayRichText>
                    <br />
                  </span>
                </VTableCell>
                <VTableCell v-if="['created', 'updated'].includes(selectedAudit.event)">
                  <span
                    v-for="(t, idx) in getKey(selectedAudit.new_values, field, '')?.split('\n')"
                    :key="idx">
                    <DisplayRichText :content="t"></DisplayRichText>
                    <br />
                  </span>
                </VTableCell>
              </VTableRow>
            </template>
          </VTable>
        </div>

        <div class="mt-6">
          <div class="text-xs text-textColor-soft">
            <i class="fa fa-clock mr-2" />
            <span class="mr-2 italic">when</span>
            <i class="fa fa-minus fa-sm mr-1" />
            <span class="text-sm italic">{{ selectedAudit.created_at }}</span>
          </div>
        </div>
      </div>
    </CrudModal>
  </div>
</template>
