import type { SideMenuTab } from '@/types/page-tab';

const getIcon = (name: string) => {
  switch (name) {
    case 'group': {
      return 'fa-group';
    }
    case 'contacts': {
      return 'fa-address-book';
    }
    case 'event_invites':
    case 'user_invites': {
      return 'fa-inbox';
    }
    case 'shift': {
      return 'fa-clock';
    }
  }
  return 'fa-bell';
};

type Notification = {
  group: any[];
  event: any[];
  event_invites: any[];
  contacts: any[];
  user_invites: any[];
  shift: any[];
};

export const useProfileSettingsTabs = (notifications: Notification) => {
  const sections: SideMenuTab[] = [];

  sections.push({
    key: 'profile',
    title: 'Profile',
    icon: 'fa-user',
    open: true,
    active: false,
    clickable: false,
  });

  sections.push({
    key: '',
    icon: 'fa-address-card',
    parentId: 'profile',
    title: 'My Details',
    description: 'Edit your profile.',
    open: false,
    active: false,
  });

  sections.push({
    key: 'affiliations',
    icon: 'fa-network-wired fa-regular',
    parentId: 'profile',
    title: 'Manage Affiliations',
    description: 'Manage your Affiliations.',
    open: false,
    active: false,
  });

  sections.push({
    key: 'email',
    icon: 'fa-envelope',
    parentId: 'profile',
    title: 'Email',
    description: 'Change your email.',
    open: false,
    active: false,
  });
  sections.push({
    key: 'password',
    icon: 'fa-key',
    parentId: 'profile',
    title: 'Password',
    description: 'Change your Password.',
    open: false,
    active: false,
  });

  sections.push({
    key: 'integrations',
    title: 'Integrations',
    open: true,
    active: false,
    icon: 'fa-link',
    clickable: false,
  });

  sections.push({
    key: 'calendar',
    icon: 'fa-calendar',
    parentId: 'integrations',
    title: 'Calendar',
    description: 'Get a link to your calendar.',
    open: false,
    active: false,
  });

  if (notifications) {
    sections.push({
      key: 'notifications',
      icon: 'fa-bell',
      title: 'Notifications',
      open: true,
      active: false,
      clickable: false,
    });
    Object.keys(notifications).forEach((value) => {
      sections.push({
        key: 'notification_' + value,
        icon: getIcon(value),
        parentId: 'notifications',
        title: value
          .split('_')
          .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
          .join(' '),
        open: false,
        active: false,
      });
    });
  }

  return sections;
};
