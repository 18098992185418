import { timeSlotsKey } from '@/provide/keys';
import { useTimeSlotsStore } from '@/store/time-slots-store';
import { computed, provide } from 'vue';
import { storeToRefs } from 'pinia';
import { formatModel } from '@/util/store-helpers';
import type { ModelType } from '@/types/global';

export const useTimeSlots = (model: ModelType, modelId: number, shouldProvide = true) => {
  const { list, loading } = storeToRefs(useTimeSlotsStore());
  const { fetchTimeSlots } = useTimeSlotsStore();

  const timeSlots = computed(() => {
    const items = list.value.get(formatModel(model, modelId));
    if (items) {
      return items;
    }
    return [];
  });

  const fetch = async (force = false) => {
    if (loading.value) return;

    if (force || !list.value.has(formatModel(model, modelId))) {
      await fetchTimeSlots(model, modelId);
    }
  };

  const flushForModel = () => {
    list.value.delete(formatModel(model, modelId));
  };

  // const addItem = (eventType: EventTypeResource) => {
  //   const key = formatModel(model, modelId);
  //   const current = list.value.get(key) || [];
  //   list.value.set(key, [...current, eventType]);
  // };

  // const removeItem = (eventTypeId: number) => {
  //   const key = formatModel(model, modelId);
  //   const current = list.value.get(key) || [];
  //   list.value.set(
  //     key,
  //     current.filter((item) => item.id !== eventTypeId)
  //   );
  // };

  // const updateItem = (eventType: EventTypeResource) => {
  //   const key = formatModel(model, modelId);
  //   const current = list.value.get(key) || [];
  //   list.value.set(
  //     key,
  //     current.map((item) => (item.id === eventType.id ? eventType : item))
  //   );
  // };

  if (shouldProvide) {
    provide(timeSlotsKey, {
      timeSlots,
      loading,
      fetch,
      flushForModel,
    });
  }

  return {
    timeSlots,
    loading,
    fetch,
    flushForModel,
    // addItem,
    // removeItem,
    // updateItem,
  };
};
