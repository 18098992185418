import { createUuId } from '@/util/globals';
import { getRoute } from '@/util/route';
import moment from 'moment';
import { App } from 'vue';

const createUser = (user) => {
  if (!user?.id) return {};
  return {
    id: user.id,
    url: getRoute('admin.users.show', user?.id),
  };
};

const sendError = (error: any) => {
  const err = { ...error };
  const url = import.meta.env.VITE_SLACK_WEBHOOK_URL;

  const { user, message, stack, axiosError } = err;
  delete err.user;
  delete err.message;
  delete err.stack;

  const blocks = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: `Error #${createUuId('error_')}`,
        emoji: true,
      },
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*USERID:* ${user?.id}`,
        },
      ],
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*ADMIN_URL:* ${user?.url}`,
        },
      ],
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Error:* ${message}`,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Device:* ${navigator.userAgent}`,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*URL:* ${window.location.href}`,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Time:* ${moment().toString()}`,
      },
    },
  ];
  if (stack) {
    // Virker som Slack API ikke liker '?' tegnet bruk denne i npm watch
    // const safeStack = stack.split('?').join('+');
    blocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Stack:* ${stack}`,
      },
    });
  }
  if (axiosError) {
    const fields = [
      {
        type: 'mrkdwn',
        text: `*STATUSCODE:* ${axiosError?.statusCode}`,
      },
      {
        type: 'mrkdwn',
        text: `*URL:* ${axiosError?.url}`,
      },
      {
        type: 'mrkdwn',
        text: `*METHOD:* ${axiosError?.method}`,
      },
      {
        type: 'mrkdwn',
        text: `*STACK:* ${axiosError?.stack}`,
      },
    ];
    if (axiosError?.data) {
      fields.push({
        type: 'mrkdwn',
        text: `*DATA:* ${axiosError?.data}`,
      });
    }
    blocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Axios error:* ${axiosError?.message}`,
      },
      fields,
    });
  }

  Object.entries(err).forEach(([key, value]) => {
    if (key === 'axiosError') return;
    blocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*${key}:* *${value}*`,
      },
    });
  });
  blocks.push({
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: `*NEXT:* *${'****************************'}*`,
    },
  });

  const data = {
    text: 'Error Front End',
    blocks,
  };

  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  xhr.send(JSON.stringify(data));
};

const getErrorFromStack = (error) => {
  const list = error.stack.split('\n');
  if (list.length > 1) {
    return list[1].trimStart();
  }
  return 'Error';
};

export const errorHandler = (vueApp: App) => {
  window.onerror = (msg, url, line, col, error) => {
    const elem = document.getElementById('app');
    const err = {
      message: msg,
      scriptUrl: url,
      line,
      col,
      stack: getErrorFromStack(error),
    };
    if (elem) {
      const data = elem.getAttribute('data-page');
      if (data) {
        const obj = JSON.parse(data);
        if (obj.props) {
          if (obj.props.auth?.user) {
            err.user = createUser(obj.props.auth.user);
            err.component = obj.component;
          }
        }
      }
    }

    sendError(err);
  };
  window.onunhandledrejection = (message) => {
    let data = message.reason.config?.data;
    if (data?.includes('password')) {
      data = 'Scrambled user data';
    }
    const elem = document.getElementById('app');
    const err = {
      message: JSON.stringify({
        message: message?.reason?.message,
        responseURL: message.reason?.response?.request?.responseURL,
        data,
      }),
    };
    if (elem) {
      const data = elem.getAttribute('data-page');
      if (data) {
        const obj = JSON.parse(data);
        if (obj.props) {
          if (obj.props.auth) {
            err.user = createUser(obj.props.auth.user);
            err.component = obj.component;
          }
        }
      }
    }
    sendError(err);
    return true;
  };
  vueApp.config.errorHandler = (err, vm, info) => {
    const newError: {
      user: object | null;
      type: string | null;
      message: string | null;
      axiosError: string | null;
      stack: string | null;
    } = {
      user: null,
      type: null,
      message: null,
      axiosError: null,
      stack: null,
    };
    if (vm?.$page.props.authenticated) {
      newError.user = createUser(vm?.$page?.props?.auth?.user);
    }
    newError.type = info;
    newError.message = JSON.stringify(err?.message);
    if (err?.isAxiosError) {
      const e = err?.toJSON();
      newError.axiosError = JSON.stringify({
        message: e.message,
        statusCode: e.status,
        method: e.config.method,
        url: e.config.url,
        data: e.config?.data?.includes('password') ? 'Scrambled user data' : e.config.data,
        stack: getErrorFromStack(e),
      });
    } else {
      newError.stack = getErrorFromStack(err);
    }
    sendError(newError);
  };
};

export const sendAnErrorToSlack = (
  message: string | null = null,
  object = null,
  slackWebHook: string | null = null
) => {
  let user = null;

  const elem = document.getElementById('app');
  if (elem) {
    const data = elem.getAttribute('data-page');
    if (data) {
      const obj = JSON.parse(data);
      if (obj.props) {
        if (obj.props.auth?.user) {
          user = createUser(obj.props.auth.user);
        }
      }
    }
  }

  const blocks = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: `Send A Message To Slack`,
        emoji: true,
      },
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*USERID:* ${user?.id}`,
        },
      ],
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*ADMIN_URL:* ${user?.url}`,
        },
      ],
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Message:* ${message}`,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Current URL:* ${window.location}`,
      },
    },
  ];
  if (object) {
    blocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Object:* ${object}`,
      },
    });
  }

  const data = {
    text: 'sendAnErrorToSlack',
    blocks,
  };
  let url = slackWebHook ? slackWebHook : import.meta.env.VITE_SLACK_WEBHOOK_URL;
  if (!window.location.href.includes('app.crescat.io')) {
    url = import.meta.env.VITE_SLACK_WEBHOOK_URL;
  }
  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  xhr.send(JSON.stringify(data));
};
