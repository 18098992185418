<script lang="ts" setup>
export type Mode = {
  name: string;
  value: number | string;
  hoverText?: string;
  icon?: string;
};

type Props = {
  greyDefault?: boolean;
  disabled?: boolean;
  modes: Mode[];
  classes?: string;
};

withDefaults(defineProps<Props>(), {
  greyDefault: false,
  disabled: false,
  classes: '',
});
const emit = defineEmits<{
  (event: 'update:modelValue', arg: string | number | null): void;
}>();

const model = defineModel<string | number | object | null>();
</script>

<template>
  <ul
    class="flex flex-wrap border-b"
    :class="classes + ' ' + (disabled ? 'opacity-50' : '')">
    <li
      v-for="mode in modes"
      :key="mode.value"
      :title="mode.hoverText"
      :class="{ 'cursor-pointer': !disabled, 'cursor-not-allowed': disabled }"
      @click.stop="disabled ? null : [(model = mode.value), emit('update:modelValue', mode.value)]">
      <a
        :class="[
          mode.value === model ? '!border-textColor' : 'border-transparent',
          { 'opacity-50 hover:opacity-100': greyDefault && mode.value !== model },
          disabled ? '' : 'hover:border-borderColor',
        ]"
        class="font-medium font-headers mr-6 block border-b-2 p-1 leading-tight !text-textColor no-underline">
        <i
          v-if="mode.icon"
          class="fa fa-fw mr-1"
          :class="mode.icon" />
        <span class="mode-name-selector">
          {{ mode.name }}
        </span>
      </a>
    </li>
  </ul>
</template>
