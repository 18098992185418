import { InvoiceBasesResource, InvoiceRowCategoryResource, InvoiceRowResource } from '@/types/invoice-row';

export const getInvoiceRows = async (groupId: number) => {
  const url = `/api/invoice-rows`;
  return axios.get<InvoiceRowResource[]>(url, {
    params: {
      model_type: 'App\\Group',
      model_id: groupId,
    },
  });
};

export const getInvoiceRowsCategories = async (groupId: number) => {
  const url = `/api/invoice-row-categories`;
  return axios.get<InvoiceRowCategoryResource[]>(url, {
    params: {
      model_type: 'App\\Group',
      model_id: groupId,
    },
  });
};
export const getInvoiceRowsCategory = async (categoryId: number) => {
  const url = `/api/invoice-row-categories/${categoryId}`;
  return axios.get<InvoiceRowCategoryResource>(url);
};

export const getInvoiceBases = async (model: 'Group' | 'Invite', modelId: number) => {
  const url = `/api/invoice-bases`;
  return axios.get<Omit<InvoiceBasesResource, 'users' | 'total' | 'rows'>[]>(url, {
    params: {
      model_type: 'App\\' + model,
      model_id: modelId,
    },
  });
};

export const getInvoiceBase = async (baseId: number) => {
  const url = `/api/invoice-bases/${baseId}`;
  return axios.get<InvoiceBasesResource>(url);
};

export const getInvoiceRow = async (rowId: number) => {
  const url = `/api/invoice-rows/${rowId}`;
  return axios.get<InvoiceRowResource>(url);
};

type NewInvoiceBasis = {
  title: string;
  with_vat: boolean;
  is_global: boolean;
  template_id: number | null;
};
export const postInvoiceBasis = async (model: string, modelId: number, data: NewInvoiceBasis) => {
  const url = `/api/invoice-bases`;
  return axios.post<InvoiceBasesResource>(url, {
    model_type: 'App\\' + model,
    model_id: modelId,
    ...data,
  });
};

export const patchInvoiceBasis = async (baseId: number, data: Partial<NewInvoiceBasis>) => {
  const url = `/api/invoice-bases/${baseId}`;
  return axios.patch<string>(url, data);
};

export const destroyInvoiceBasis = async (baseId: number) => {
  const url = `/api/invoice-bases/${baseId}`;
  return axios.delete<string>(url);
};

export const patchInvoiceRowCategory = async (categoryId: number, title: string) => {
  const url = `/api/invoice-row-categories/${categoryId}`;
  return axios.patch<string>(url, { title });
};

export const destroyInvoiceRowCategory = async (categoryId: number) => {
  const url = `/api/invoice-row-categories/${categoryId}`;
  return axios.delete<string>(url);
};

export const postInvoiceRowCategory = async (model: string, modelId: number, title: string) => {
  const url = `/api/invoice-row-categories`;
  return axios.post<InvoiceRowCategoryResource>(url, {
    model_type: 'App\\' + model,
    model_id: modelId,
    title,
  });
};
