import type { ModelType } from '@/types/global';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';
import { TaskTemplateListResource } from '@/types/tasks';

export const useTaskTemplateListsStore = defineStore('task-template-lists', () => {
  const list = ref(new Map());
  const loading = ref(false);

  const listenForBroadcast = (modelType: string, modelId: number) => {
    // Echo.join(`On.${model}.${modelId}`)
    //   .listen('.shiftType.created', () => {
    //     getData(model, modelId);
    //   })
    //   .listen('.shiftType.updated', () => {
    //     getData(model, modelId);
    //   })
    //   .listen('.shiftType.deleted', () => {
    //     getData(model, modelId);
    //   });
  };

  const getData = async (modelType: ModelType, modelId: number) => {
    if (loading.value) return;
    loading.value = true;
    const { data } = await axios.get('/api/task-template-lists', {
      params: {
        model_type: `App\\${modelType}`,
        model_id: modelId,
      },
    });
    list.value.set(formatModel(modelType, modelId), data);
    loading.value = false;
  };

  const fetchTaskTemplateLists = async (modelType: ModelType, modelId: number, force = false) => {
    if (force) {
      await getData(modelType, modelId);
    } else if (!list.value.has(formatModel(modelType, modelId))) {
      list.value.set(formatModel(modelType, modelId), []);
      await getData(modelType, modelId);
      listenForBroadcast(modelType, modelId);
    }
    return list.value.get(formatModel(modelType, modelId));
  };

  const flushForModel = (modelType: string, modelId: number) => {
    list.value.delete(formatModel(modelType, modelId));
  };

  const addOrUpdateTaskTemplateLists = async (
    modelType: ModelType,
    modelId: number,
    taskTemplateList: TaskTemplateListResource
  ) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await fetchTaskTemplateLists(modelType, modelId);
      return;
    }
    let data = list.value.get(formatModel(modelType, modelId));
    data = exchangeValuesOfObject(taskTemplateList, data);
    list.value.set(formatModel(modelType, modelId), data);
  };

  const removeTaskTemplateList = async (modelType: ModelType, modelId: number, taskTemplateListId: number) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await fetchTaskTemplateLists(modelType, modelId);
      return;
    }
    const data = list.value.get(formatModel(modelType, modelId));
    const index = getIndexFromArrayBasedOnId(taskTemplateListId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(formatModel(modelType, modelId), data);
    } else {
      await fetchTaskTemplateLists(modelType, modelId);
    }
  };

  return {
    list,
    loading,
    fetchTaskTemplateLists,
    addOrUpdateTaskTemplateLists,
    removeTaskTemplateList,
    flushForModel,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaskTemplateListsStore, import.meta.hot));
}
