<template>
  <ReportModal
    :with-button="false"
    :can-download="true"
    title="Download Check List Report"
    :working="working"
    @download="downloadReport">
    <VMultiselect
      v-model="downloadableCheckLists"
      :close-on-select="false"
      option-label="title"
      label-key="id"
      :options="checkLists"
      label="Check Lists to include" />
  </ReportModal>
</template>

<script>
import { useToast } from 'vue-toastification';
import ReportModal from '@/components/Modals/ReportModal.vue';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions.ts';

export default {
  components: {
    VMultiselect,
    ReportModal,
  },
  props: {
    checkLists: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      downloadableCheckLists: [],
      working: false,
      modalOpen: false,

      toast: useToast(),
    };
  },

  methods: {
    openDownloadCheckListReportModal() {
      this.modalOpen = false;
      this.$nextTick(() => {
        this.modalOpen = true;
      });
    },

    closeDownloadCheckListReportModal() {
      this.modalOpen = false;
    },

    async downloadReport() {
      if (this.working) return;
      this.working = true;
      const { data } = await axios
        .post('/api/check-lists/event-report', {
          downloadableCheckLists: this.downloadableCheckLists,
        })
        .catch((error) => {
          console.error(error);
          this.working = false;
          this.toast.warning('Something went wrong, please try again later');
        });
      await downloadFile(data.url, data.name);
      this.working = false;
      this.closeDownloadCheckListReportModal();
    },
  },
};
</script>
