import { TemplateContainer, TemplateObject } from '@/components/Models/TemplateGallery/TemplateGalleryModal.vue';
import { getKey } from '@/util/globals';
import { formatQueryString, updateQueryString } from '@/util/query-helpers';
import { useEmitStore } from '@/store/EmitStore';
import MetaData from '@/components/Models/MetaData/MetaData.vue';
import { GroupResource } from '@/types/group';
import { postMetaData } from '@/services/api-meta-data';

export const nameOfMetaDataType = 'metaData';
export const metaDataTemplateGalleryRootEmitKey = 'new-meta-data-from-gallery';

export const getAllMetaDataTemplates = function (group: GroupResource): TemplateContainer[] {
  return {
    title: 'Import Meta Data Templates',
    props: {
      canEditContent: false,
      canEditForm: false,
      isTemplateGallery: true,
      isTemplate: true,
      isDisplay: true,

      model: 'Group',
      modelId: group.id,
      group: group,
    },
    importFunction: async (template: TemplateObject) => {
      const { data } = await postMetaData(
        'Group',
        group.id,
        getKey(template.data, 'title'),
        getKey(template.data, 'fields')
      );
      updateQueryString(formatQueryString('MetaData', data.id), true);
      const { rootEmit } = useEmitStore();
      rootEmit('template-gallery-import', { key: metaDataTemplateGalleryRootEmitKey, data: data });
    },
    type: nameOfMetaDataType,
    dataPropName: 'meta-data',
    component: MetaData,
    templates: [
      {
        'uuid': '99a7a246-3375-48d0-9e7c-f60d0001981c',
        'title': 'Event Report',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Event Report',
          'fields': [
            {
              'title': 'About Organizer',
              'order': 100,
              'component': 'field-text',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'About Content',
              'order': 200,
              'component': 'field-text',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'About Audience',
              'order': 300,
              'component': 'field-text',
              'class': 'col-md-12',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Unwanted events',
              'order': 400,
              'component': 'field-text',
              'class': 'col-md-12',
              'options': null,
              'linebreak_after': false,
            },
          ],
        },
      },
      {
        'uuid': 'e4600c8c-f731-4413-aa04-8d422c2b9c81',
        'title': 'Visitors',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Visitors',
          'fields': [
            {
              'title': 'Capacity',
              'order': 100,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Sold',
              'order': 200,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Free',
              'order': 300,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'No Show',
              'order': 400,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
          ],
        },
      },
      {
        'uuid': '37c010df-10f7-40c8-9e07-8cb10ffb78b2',
        'title': 'Billing details',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Billing details',
          'fields': [
            {
              'title': 'Name',
              'order': 50,
              'component': 'field-text',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Company',
              'order': 100,
              'component': 'field-text',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Street',
              'order': 200,
              'component': 'field-text',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Postal code',
              'order': 300,
              'component': 'field-text',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'City',
              'order': 400,
              'component': 'field-text',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': true,
            },
            {
              'title': 'VAT',
              'order': 500,
              'component': 'field-number',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': false,
            },
          ],
        },
      },
      {
        'uuid': 'e95368b1-c6af-4553-991a-dadb806944f9',
        'title': 'Production information',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Production information',
          'fields': [
            {
              'title': 'Brief summary of needs',
              'order': 100,
              'component': 'field-text',
              'class': 'col-md-12',
              'options': null,
              'linebreak_after': true,
            },
            {
              'title': 'Projector',
              'order': 200,
              'component': 'field-toggle',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'LED display',
              'order': 300,
              'component': 'field-toggle',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Wireless Microphones',
              'order': 400,
              'component': 'field-toggle',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Phone/Tablet Connection',
              'order': 500,
              'component': 'field-toggle',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Notes',
              'order': 600,
              'component': 'field-rich-text',
              'class': 'col-md-12',
              'options': null,
              'linebreak_after': false,
            },
          ],
        },
      },
      {
        'uuid': '9fb06760-b5ec-4c7b-955b-df79ccdb7cc9',
        'title': 'Venue configuration',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Venue configuration',
          'fields': [
            {
              'title': 'Type of show',
              'order': 100,
              'component': 'field-list',
              'class': 'col-md-6',
              'options': ['Seated', 'Standing', 'Mixed'],
              'linebreak_after': false,
            },
            {
              'title': 'Capacity',
              'order': 200,
              'component': 'field-number',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': true,
            },
            {
              'title': 'Number of seats',
              'order': 300,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Number of tables',
              'order': 400,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Notes',
              'order': 500,
              'component': 'field-text',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': false,
            },
          ],
        },
      },
      {
        'uuid': '026af7e8-898b-4d42-b550-9c14c7e0c9a4',
        'title': 'Marketing details',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Marketing details',
          'fields': [
            {
              'title': 'Sosial media links',
              'order': 100,
              'component': 'field-text',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': true,
            },
            {
              'title': 'Bio Artist',
              'order': 200,
              'component': 'field-text',
              'class': 'col-md-6',
              'options': null,
              'linebreak_after': true,
            },
            {
              'title': 'Picture',
              'order': 300,
              'component': 'field-document',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
          ],
        },
      },
      {
        'uuid': '1a66eead-8ad1-4aa6-9755-6a6850712d1f',
        'title': 'Ticketing details',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Ticketing details',
          'fields': [
            {
              'title': 'Total Capacity',
              'order': 100,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Ticketmaster Allocation',
              'order': 200,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'See Tickets Allocation',
              'order': 300,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Dice Allocation',
              'order': 400,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Earlybird Ticket Allocation',
              'order': 500,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Earlybird Ticket Price',
              'order': 600,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': true,
            },
            {
              'title': 'Advance Ticket Allocation',
              'order': 700,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Advance Ticket Price',
              'order': 800,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': true,
            },
            {
              'title': 'Full Ticket Allocation',
              'order': 900,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': false,
            },
            {
              'title': 'Full Ticket Price',
              'order': 1000,
              'component': 'field-number',
              'class': 'col-md-3',
              'options': null,
              'linebreak_after': true,
            },
            {
              'title': 'Notes',
              'order': 1100,
              'component': 'field-rich-text',
              'class': 'col-md-12',
              'options': null,
              'linebreak_after': false,
            },
          ],
        },
      },
    ],
  };
};
