import { TaskResource } from '@/types/tasks';
import { getKey } from '@/util/globals';

export const sortTaskList = (
  tasks: TaskResource[] | null,
  sortBy: string | null,
  asc = true,
  newlyCreatedIds: number[]
) => {
  if (!Array.isArray(tasks)) return tasks;
  if (!sortBy) return tasks;

  const [prioritizedTasks, otherTasks] = tasks.reduce(
    (acc, task) => {
      if (newlyCreatedIds.includes(task.id)) {
        acc[0].push(task);
      } else {
        acc[1].push(task);
      }
      return acc;
    },
    [[], []] as [TaskResource[], TaskResource[]]
  );

  const sortedTasks = otherTasks.sort((a, b) => {
    let textA = null;
    let textB = null;
    if (sortBy === 'title') {
      textA = a.title;
      textB = b.title;
    } else if (sortBy === 'user') {
      textA = a.assigned_user_id && a.assigned_user ? getKey(a.assigned_user, 'name', null) : null;
      textB = b.assigned_user_id && b.assigned_user ? getKey(b.assigned_user, 'name', null) : null;
    } else if (sortBy === 'group') {
      const entityA = a.entity_task_pivots.find((pivot) => pivot.entity_type === 'App\\Group');
      if (entityA) {
        textA = getKey(entityA, 'entity', { name: null }).name;
      }
      const entityB = b.entity_task_pivots.find((pivot) => pivot.entity_type === 'App\\Group');
      if (entityB) {
        textB = getKey(entityB, 'entity', { name: null }).name;
      }
    } else if (sortBy === 'Due Date') {
      textA = getKey(a, 'due_date', null);
      textB = getKey(b, 'due_date', null);
    } else if (sortBy.includes('task_field_')) {
      const taskFieldId = Number(sortBy.split('task_field_')[1]);
      const entityA = a.task_task_field_pivots.find((pivot) => pivot.task_field_id === taskFieldId);
      if (entityA) {
        textA = getKey(entityA, 'value');
      }
      const entityB = b.task_task_field_pivots.find((pivot) => pivot.task_field_id === taskFieldId);
      if (entityB) {
        textB = getKey(entityB, 'value');
      }
    }
    if (!textA) return asc ? -1 : 1;
    if (!textB) return asc ? 1 : -1;
    if (textA < textB) {
      return asc ? -1 : 1;
    }
    if (textA > textB) {
      return asc ? 1 : -1;
    }
    return 1;
  });
  return [...prioritizedTasks, ...sortedTasks];
};
