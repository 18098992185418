<script setup lang="ts">
import { patchRoomBooking } from '@/services/api-room-booking';
import { useEmitStore } from '@/store/EmitStore';
import type { InviteResource } from '@/types/invite';
import type { RoomBookingResource } from '@/types/room-booking';
import moment from 'moment';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { dateFormat, timeFormat } from '@/variables/date-format';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import AuditsSidebar from '@/components/Audits/AuditsSidebar.vue';
import { auditableRoomBookingFields } from '@/helpers/auditHelper.js';
import CheckBox from '@/components/Icons/CheckBox.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { getKey } from '@/util/globals';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  invite: InviteResource;
  canEdit: boolean;
  isPast: boolean;
  isRecurring: boolean;
  bookRoomId?: number;
  allResources: any;
  roomBookings: RoomBookingResource[];
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'selected', booking: RoomBookingResource): void;
  (e: 'fetch'): void;
}>();

const { isSmallScreen } = useSmallScreen();

const toast = useToast();
const recurring = useRecurringModal();
const certain = useCertaintyModal();
const { rootEmit } = useEmitStore();

const auditsOpen = ref(false);

const formattedBookingDate = (booking: RoomBookingResource) => {
  let string = '';
  if (!moment(booking.start).isSame(props.invite.start, 'day')) {
    string = `${moment(booking.start).format(dateFormat)} at `;
  }
  const diff = Math.abs(moment(booking.start).diff(booking.end, 'hours'));
  if (diff < 20) {
    return `${string + moment(booking.start).format(timeFormat)} - ${moment(booking.end).format(timeFormat)}`;
  }
  return `${string + moment(booking.start).format(timeFormat)} - ${moment(booking.end).format(timeFormat)}(+${diff}H)`;
};

const canEditBooking = (booking: RoomBookingResource) => {
  if (!props.canEdit) return false;
  if (booking.show_time_id) {
    if (props.invite.festival) {
      return false;
    }
  }
  return true;
};

const editRoomBookingOnEvent = (booking: RoomBookingResource) => {
  if (!props.canEdit) return;
  if (!canEditBooking(booking)) {
    useToast().info('Cannot change Rooms booked for festival show from here. Go to scheduler.');
    return;
  }
  booking.title = booking.room_title;
  emit('selected', booking);
};

const confirmBooking = async (booking: RoomBookingResource) => {
  if (!props.canEdit) {
    return;
  }
  if (booking.confirmed) {
    const c = await certain.assertCertain(
      'Unconfirm booking',
      'Are you sure you want to unconfirm this booking? This will remove all assignments on the room, and remove it from all Runningorders.'
    );
    if (!c) return;
  }

  const addToAll = props.isRecurring
    ? await recurring.recurringModal(
        '',
        'Update Recurring Room Booking',
        'Would you like to update this booking on all recurrences of this event, or just this one?'
      )
    : false;

  if (addToAll === 'cancel') return;

  await patchRoomBooking(booking.id, {
    confirmed: !booking.confirmed,
    is_global: addToAll === 'all',
    start: booking.start,
    title: booking.room_title,
    end: booking.end,
  });

  toast.success('Booking updated');
  rootEmit('room-update-on-event');
  emit('fetch');
};

const actions = computed(() => {
  const array = [];

  if (!isSmallScreen.value) {
    array.push({
      title: 'Audits',
      icon: 'fa-history',
      action: () => {
        auditsOpen.value = false;
        nextTick(() => {
          auditsOpen.value = true;
        });
      },
    });
  }

  array.push({
    title: 'Add Room',
    icon: 'fa-plus',
    primary: true,
    maxHeightDropdown: '50vh',
    dropdown: [
      {
        title: 'Add Room',
        type: 'header',
      },
    ]
      .concat(
        props.allResources
          .filter((r) => r.model === 'room')
          .map((r) => {
            return {
              title: r.title,
              selected: props.roomBookings.map((b) => b.room_id).includes(r.model_id),
              action: (close: () => void) => {
                editRoomBookingOnEvent({
                  id: null,
                  event_id: props.invite.event_id,
                  room_name: r.title,
                  room_title: '',
                  invite_id: props.invite.id,
                  start: props.invite.start,
                  end: props.invite.end,
                  confirmed: true,
                  recurring_original_id: null,
                  room_id: r.model_id,
                });
                close();
              },
            };
          })
      )
      .concat(
        props.allResources
          .filter((v) => v.model === 'venue' && getKey(v, 'children', []).length > 0)
          .flatMap((venue) => {
            return [
              {
                title: venue.title,
                type: 'header',
              },
            ].concat(
              venue.children.map((r) => {
                return {
                  title: r.title,
                  selected: props.roomBookings.map((b) => b.room_id).includes(r.model_id),
                  action: (close: () => void) => {
                    editRoomBookingOnEvent({
                      id: null,
                      event_id: props.invite.event_id,
                      room_name: r.title,
                      room_title: '',
                      invite_id: props.invite.id,
                      start: props.invite.start,
                      end: props.invite.end,
                      confirmed: true,
                      recurring_original_id: null,
                      room_id: r.model_id,
                    });
                    close();
                  },
                };
              })
            );
          })
      ),
  });

  return array;
});

const getAllowedAuditSidebarFields = () => {
  return [{ model: 'App\\RoomBooking', fields: auditableRoomBookingFields() }];
};
</script>

<template>
  <div>
    <BoxContainer
      :actions="actions"
      header="Room Bookings">
      <div class="min-h-[150px]">
        <EmptyStateFullPage
          v-if="roomBookings.length === 0"
          size="medium"
          description="No Rooms Booked" />
        <VTable
          v-if="roomBookings.length > 0"
          row-size="small"
          header-hover
          edge-to-edge>
          <template #head>
            <VTableRow head>
              <VTableCell>Room</VTableCell>
              <VTableCell v-if="!isSmallScreen">Title</VTableCell>
              <VTableCell>When</VTableCell>
              <VTableCell>Confirmed</VTableCell>
              <VTableCell v-if="canEdit" />
            </VTableRow>
          </template>
          <VTableRow
            v-for="booking in roomBookings"
            :key="booking.id"
            :clickable="canEditBooking(booking)"
            @click.self="editRoomBookingOnEvent(booking)">
            <VTableCell
              main-cell
              @click="editRoomBookingOnEvent(booking)">
              {{ booking.room_name }}
              <div
                v-if="isSmallScreen"
                class="text-textColor-soft">
                {{ booking.room_title }}
              </div>
            </VTableCell>
            <VTableCell
              v-if="!isSmallScreen"
              @click="editRoomBookingOnEvent(booking)">
              {{ booking.room_title }}
            </VTableCell>
            <VTableCell
              :title="booking.start + ' - ' + booking.end"
              @click="editRoomBookingOnEvent(booking)">
              {{ formattedBookingDate(booking) }}
            </VTableCell>
            <VTableCell
              v-if="!isPast && canEdit"
              clickable
              @click.stop="confirmBooking(booking)">
              <CheckBox :model-value="booking.confirmed" />
            </VTableCell>
            <VTableCell v-else>
              {{ booking.confirmed ? 'Yes' : 'No' }}
            </VTableCell>
            <VTableCell
              v-if="canEdit"
              style="width: 35px"
              @click="editRoomBookingOnEvent(booking)">
              <VButton
                v-if="canEditBooking(booking)"
                type="inTable"
                icon="fa-pencil"
                @click="editRoomBookingOnEvent(booking)" />
            </VTableCell>
          </VTableRow>
        </VTable>
      </div>
    </BoxContainer>
    <AuditsSidebar
      v-if="canEdit && auditsOpen"
      key="Audits for Room Bookings"
      :can-filter-models="false"
      open-on-create
      :url="'/api/audits/invites/' + invite.id"
      :queryable-parameters="[{ name: 'RoomBooking', id: 'App\\RoomBooking' }]"
      :allowed-fields="getAllowedAuditSidebarFields()"
      title="Audits for Room Bookings" />
  </div>
</template>
