import { useShiftTypeStore } from '@/store/ShiftTypeStore';
import { ModelType } from '@/types/global';
import { ShiftTypeResource } from '@/types/shifts';
import { formatModel } from '@/util/store-helpers';
import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { shiftTypesKey } from '@/provide/keys';
import { useToast } from 'vue-toastification';

export const useShiftTypes = (model: ModelType, modelId: number, shouldProvide = true) => {
  const { list, loading } = storeToRefs(useShiftTypeStore());
  const { fetchShiftTypes } = useShiftTypeStore();

  const shiftTypes = computed(() => list.value.get(formatModel(model, modelId)) || []);

  const fetch = async (force = false) => {
    if (!model || !modelId) {
      useToast().error('No model or model ID provided to ShiftTypes');
      throw new Error('No model or model ID provided to ShiftTypes');
    }

    if (force || !list.value.has(formatModel(model, modelId))) {
      await fetchShiftTypes(model, modelId);
    }
  };

  const addItem = (item: ShiftTypeResource) => {};

  const removeItem = (itemId: number) => {};

  const updateItem = (item: ShiftTypeResource) => {};

  if (shouldProvide) {
    provide(shiftTypesKey, {
      shiftTypes,
      loading: readonly(loading),
      fetch,
      addItem,
      removeItem,
      updateItem,
    });
  }

  return {
    shiftTypes,
    loading,
    fetch,
    addItem,
    removeItem,
    updateItem,
  };
};
