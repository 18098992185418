<script lang="ts" setup>
import { getKey } from '@/util/globals';
import { computed } from 'vue';

type Props = {
  action: {
    class?: string;
    disabled?: boolean;
    type?: 'pending' | 'success';
    icon?: string;
    isDanger?: boolean;
    disabledTitle?: string;
    hoverText?: string;
    imgURL?: string;
    title: string;
    loading?: boolean;
    action?: (event: Event) => void;
  };
  primaryAction: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  primaryAction: false,
});

const classes = computed(() => {
  let classString = getKey(props.action, 'class', '');

  classString += 'font-headers';

  if (getKey(props.action, 'disabled', false)) {
    return ' !text-[#031d32] [&_*]:!text-[#031d32] ';
  }
  switch (getKey(props.action, 'type')) {
    case 'pending': {
      classString += 'bg-pending text-[#031d32] hover:bg-pending/90';
      break;
    }
    case 'success': {
      classString += 'bg-success text-[#031d32] hover:bg-success/90';
      break;
    }
    default: {
      break;
    }
  }
  if (props.action.icon === 'fa-trash' || getKey(props.action, 'isDanger', false)) {
    classString += ' hover:bg-warning [&>i]:text-warning hover:bg-warning/90 ';
  } else {
    classString +=
      ' bg-actionButton-background [&_i]:group-hover:!text-actionButton-hoverIcon hover:bg-actionButton-hoverBackground hover:!text-actionButton-hoverText text-actionButton-text  [&>i]:text-actionButton-icon   [&>i:hover]:text-actionButton-hoverIcon ';
  }

  return classString;
});
</script>

<template>
  <button
    class="btn-sm focus-visible:outline hover:shadow-md hover:border-borderColor-strong focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight action-button group flex items-center truncate focus:outline-none [&:hover>i]:text-[unset]"
    :title="
      getKey(action, 'disabled', false) && getKey(action, 'disabledTitle') !== null
        ? action.disabledTitle
        : action.hoverText
    "
    :class="classes"
    :disabled="action.hasOwnProperty('disabled') && action.disabled"
    @click.stop="action.hasOwnProperty('action') ? action.action($event) : null">
    <img
      v-if="action.imgURL"
      alt="img"
      :src="action.imgURL" />
    <i
      v-if="action && action.hasOwnProperty('loading') && action.loading"
      :class="action.title ? ' mr-2 ' : ''"
      class="fa fa-fw fa-circle-o-notch fa-spin" />
    <i
      v-else
      class="fa fa-fw"
      :class="action.icon + (action.title ? ' mr-2 mb-1 ' : '')" />
    {{ action.title }}
  </button>
</template>
