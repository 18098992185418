export const formatAppModel = (model: string) => {
  if (!model) return null;

  if (model.includes('App\\')) {
    return model;
  }
  if (model === 'FestivalSection') {
    return 'App\\Models\\Festivals\\FestivalSection';
  }
  return `App\\${model}`;
};
