import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useInvoiceProductsStore } from '@/store/invoices/InvoiceProductsStore';
import { InvoiceRowCategoryResource, InvoiceRowResource } from '@/types/invoice-row';

export const useInvoiceProducts = (groupId: number) => {
  const { list, loading } = storeToRefs(useInvoiceProductsStore());
  const {
    fetchInvoiceProducts: fetch,
    fetchProduct: fetchOneProduct,
    changeOutList: changeListValue,
    addOrUpdateInvoiceProducts: addOrUpdate,
    removeInvoiceProduct: remove,
  } = useInvoiceProductsStore();

  const products = computed(() => list.value.get(groupId) ?? []);
  const groupedProducts = computed(() => _.groupBy(list.value.get(groupId) ?? [], (product) => product.category_id));

  const fetchInvoiceProducts = async (force = false) => {
    if (force || !list.value.has(groupId)) {
      await fetch(groupId);
    }
  };

  const getGroupedProducts = (invoiceRowCategories: InvoiceRowCategoryResource[]) => {
    const existingCategoryIds = invoiceRowCategories.map((category) => category.id);
    return invoiceRowCategories
      .map((category) => {
        return {
          id: category.id,
          title: category.title,
          rows: products.value.filter((p) => p.category_id === category.id),
        };
      })
      .concat([
        {
          id: null,
          title: 'Without Category',
          rows: products.value.filter((p) => p.category_id === null || !existingCategoryIds.includes(p.category_id)),
        },
      ]);
  };

  const addOrUpdateInvoiceProducts = async (InvoiceProduct: InvoiceRowResource) => {
    await addOrUpdate(groupId, InvoiceProduct);
  };

  const removeInvoiceProduct = async (InvoiceProductId: number) => {
    await remove(groupId, InvoiceProductId);
  };
  const fetchProduct = async (InvoiceProductId: number) => {
    await fetchOneProduct(groupId, InvoiceProductId);
  };

  const changeOutList = (types: InvoiceRowResource[]) => {
    changeListValue(groupId, types);
  };

  return {
    products,
    groupedProducts,
    getGroupedProducts,
    changeOutList,
    fetchProduct,
    fetchInvoiceProducts,
    addOrUpdateInvoiceProducts,
    removeInvoiceProduct,
    loading,
  };
};
