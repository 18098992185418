import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';
import { InvoiceRowResource } from '@/types/invoice-row';
import { getInvoiceRow, getInvoiceRows } from '@/services/api-invoice-rows';

export const useInvoiceProductsStore = defineStore('invoice-products', () => {
  const list = ref<Map<number, InvoiceRowResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (groupId: number) => {
    Echo.channel(`private-Group.${groupId}`)
      .listen('.invoiceRow.created', async (e) => {
        await fetchProduct(groupId, e.id);
      })
      .listen('.invoiceRow.updated', async (e) => {
        await fetchProduct(groupId, e.id);
      })
      .listen('.invoiceRow.deleted', async (e) => {
        await removeInvoiceProduct(groupId, e.id);
      });
  };

  const fetchInvoiceProducts = async (groupId: number) => {
    loading.value = true;
    const { data } = await getInvoiceRows(groupId);
    if (!list.value.has(groupId)) {
      listenForBroadcast(groupId);
    }
    list.value.set(groupId, data);
    loading.value = false;
  };

  const fetchProduct = async (groupId: number, id: number) => {
    const { data } = await getInvoiceRow(id);
    let currentItems = list.value.get(groupId);
    currentItems = exchangeValuesOfObject(data, currentItems);
    list.value.set(groupId, currentItems);
  };

  const addOrUpdateInvoiceProducts = async (groupId: number, InvoiceProduct: InvoiceRowResource) => {
    if (!list.value.has(groupId)) {
      await fetchInvoiceProducts(groupId);
      return;
    }
    let data = list.value.get(groupId);
    data = exchangeValuesOfObject(InvoiceProduct, data);
    list.value.set(groupId, data);
  };

  const removeInvoiceProduct = async (groupId: number, InvoiceProductId: number) => {
    if (!list.value.has(groupId)) {
      await fetchInvoiceProducts(groupId);
      return;
    }
    const data = list.value.get(groupId);
    const index = getIndexFromArrayBasedOnId(InvoiceProductId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(groupId, data);
    } else {
      await fetchInvoiceProducts(groupId);
    }
  };

  const changeOutList = (groupId: number, types: InvoiceRowResource[]) => {
    list.value.set(groupId, types);
  };

  return {
    list,
    loading,
    changeOutList,
    fetchProduct,
    fetchInvoiceProducts,
    addOrUpdateInvoiceProducts,
    removeInvoiceProduct,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInvoiceProductsStore, import.meta.hot));
}
