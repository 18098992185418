<script setup lang="ts">
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { TextField } from '@/types/event-request';
import { ref } from 'vue';

type Props = {
  editMode: boolean;
  isTemplate: boolean;
  isNewRequest: boolean;
  content: TextField | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:content', arg: TextField): void;
}>();

const text = ref(props.content?.text ?? '');

const emitContent = () => {
  if (!props.isTemplate) {
    emit('update:content', { text: text.value });
  }
};
const blurText = () => {
  emitContent();
};

if (!props.isTemplate && !props.content) {
  emitContent();
}
</script>

<template>
  <h4
    v-if="isTemplate"
    class="text-textColor-soft">
    Text will come here.
  </h4>
  <div v-else>
    <TextareaInput
      v-model="text"
      :min-rows="3"
      :can-edit="editMode"
      class="form-control"
      @blur="blurText" />
  </div>
</template>
