<script lang="ts" setup>
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { destroyAssignment, patchAssignment, postAssignment } from '@/services/api-assignments';
import { getCrewIcon } from '@/util/event-timeline-functions';
import { dateTimeFormat } from '@/variables/date-format';
import moment from 'moment';
import { z } from 'zod';
import { EventCrewResource } from '@/services/api-event';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import ButtonGroup from '@/components/Inputs/Components/ButtonGroup.vue';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { isWithinIntervalOfObject } from '@/util/timeFunctions';
import { getKey } from '@/util/globals';

type Props = {
  eventId: number;
  initAssignment: {
    id: number | null;
    title: string;
    description: string;
    start: string;
    end: string;
    documentId: number;
    is_global: boolean;
    resourceIds: string[];
    recurring_original_id: number | null;
  } | null;
  eventCrew: { id: number | string; title: string; model: string; model_id: number; type: string }[];
  rooms: EventCrewResource[];
  simple: boolean;
  allowRoomsToBeConfigured?: boolean;
  write: boolean;
  allowedTimeSlots?: {
    id: string | undefined;
    timeSlots: { start: string; end: string }[];
  }[];
  isRecurring: boolean;
  withToast?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  allowRoomsToBeConfigured: false,
  withToast: true,
  allowedTimeSlots: () => [],
});

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'created', arg: any): void;
  (event: 'updated'): void;
  (event: 'deleted'): void;
  (event: 'goToEvent'): void;
}>();

const outsideBooking = ref<string[]>([]);
const outsideShift = ref<string[]>([]);

const canSave = computed(() => {
  const hasTitle = assignment.value.title.length >= 2;
  const hasAssignables = selectedCrew.value.length + selectedRooms.value.length > 0;
  const withinBookings = outsideBooking.value.length === 0;
  const withinShifts = outsideShift.value.length === 0;
  const hasStart = assignment.value?.start?.length > 0;
  return [withinBookings, withinShifts, hasTitle, hasAssignables, hasStart].every((v) => v);
});

const loading = ref(false);

const assignmentSchema = z.object({
  title: z.string().min(3),
  start: z.string(),
  end: z.string(),
  description: z.string(),
  is_global: z.boolean(),
});

const assignableSchema = z.object({
  type: z.string(),
  id: z.number(),
  context: z.string(),
});

type AssignableType = z.infer<typeof assignableSchema>;

type AssignmentSchema = z.infer<typeof assignmentSchema>;

const assignment = ref<AssignmentSchema>({
  title: props.initAssignment?.title ?? '',
  start: props.initAssignment?.start ?? '',
  end: props.initAssignment?.end ?? '',
  description: props.initAssignment?.description ?? '',
  is_global: props.initAssignment?.is_global ?? false,
});

const selectedCrew = ref<string[]>([]);
const selectedRooms = ref<string[]>([]);

if (getKey(props.initAssignment, 'resourceIds', []).length) {
  props.initAssignment.resourceIds.forEach((id) => {
    if (id.includes('room')) {
      selectedRooms.value.push(id);
    } else {
      if (props.rooms?.find((c) => c.id === id)) {
        selectedRooms.value.push(id);
      } else {
        selectedCrew.value.push(id);
      }
    }
  });
} else {
  selectedCrew.value.push('global');
}

watch(
  [selectedRooms, selectedCrew, () => assignment.value.start, () => assignment.value.end],
  () => {
    outsideBooking.value = [];
    outsideShift.value = [];

    selectedRooms.value.forEach((selected) => {
      const index = _.findIndex(props.allowedTimeSlots, (r) => r.id === selected);
      if (index > -1) {
        let outside = true;
        props.allowedTimeSlots[index].timeSlots.forEach((slot) => {
          if (isWithinIntervalOfObject(slot.start, slot.end, assignment.value.start, assignment.value.end, true)) {
            outside = false;
          }
        });
        if (outside) {
          const room = props.rooms?.find((c) => c.id === selected);
          if (!room) {
            useToast().error('Room not found');
            return;
          }
          outsideBooking.value.push(room.title);
        }
      }
    });

    selectedCrew.value.forEach((selected) => {
      const index = _.findIndex(props.allowedTimeSlots, (r) => r.id === selected);
      if (index > -1) {
        let outside = false;
        props.allowedTimeSlots[index].timeSlots.forEach((slot) => {
          if (
            moment(assignment.value.start, dateTimeFormat).isBefore(slot.start) ||
            moment(assignment.value.end, dateTimeFormat).isAfter(slot.end)
          ) {
            outside = true;
          }
        });
        if (outside) {
          const crew = props.eventCrew?.find((c) => c.id === selected);
          outsideShift.value.push(crew.title);
        }
      }
    });
  },
  { immediate: true }
);

const createAssignment = async (close: () => void, addAnother = false) => {
  if (!canSave.value) return;
  if (props.initAssignment?.id) return;
  loading.value = true;

  const assignables: AssignableType[] = [];

  selectedCrew.value.forEach((id: string) => {
    const crew = props.eventCrew?.find((c) => c.id === id);
    if (crew) {
      assignables.push({
        type: crew.model,
        id: crew.model_id,
        context: crew.id,
      });
    }
  });

  selectedRooms.value.forEach((id: string) => {
    const room = props.rooms?.find((c) => c.id === id);
    if (room) {
      assignables.push({
        type: room.model,
        id: room.model_id,
        context: room.id,
      });
    }
  });
  await new Promise((r) => setTimeout(r, 100));

  const data = {
    start: assignment.value.start,
    end: assignment.value.end,
    title: assignment.value.title,
    description: assignment.value.description,
    assignables,
    is_global: assignment.value.is_global,
  };

  const { data: newData } = await postAssignment(props.eventId, data);

  if (props.withToast) {
    useToast().success('Assignment created');
  }

  emit('created', newData);
  loading.value = false;
  if (!addAnother) {
    close();
  } else {
    assignment.value = {
      title: props.initAssignment?.title ?? '',
      start: props.initAssignment?.start ?? '',
      end: props.initAssignment?.end ?? '',
      description: props.initAssignment?.description ?? '',
    };
    if (props.initAssignment?.resourceIds.length) {
      selectedCrew.value = [...props.initAssignment.resourceIds];
    }
    selectedRooms.value = [];
  }
};

const deleteAssignment = async (close: () => void) => {
  if (!props.initAssignment?.id) return;

  const yes = await useCertaintyModal().assertCertain(
    'Delete Assignment',
    `Are you sure you want to delete this assignment?`
  );

  if (!yes) return;

  const result = props.initAssignment.recurring_original_id
    ? await useRecurringModal().recurringModal(
        '',
        'Delete All Recurrences of assignment',
        `Do you want to delete all recurrences of this assignment, or just this one?`
      )
    : false;
  if (result === 'cancel') return false;

  loading.value = true;

  await destroyAssignment(props.initAssignment.id, result === 'all');
  if (props.withToast) {
    useToast().success('Assignment deleted');
  }
  emit('deleted');
  close();
};

const roomsHasBeenChanged = ref(false);

const updateAssignment = async (close: () => void) => {
  if (!canSave.value) return;
  if (!props.initAssignment?.id) return;
  await new Promise((r) => setTimeout(r, 100));

  const assignables: AssignableType[] = [];

  selectedCrew.value.forEach((id: string) => {
    const crew = props.eventCrew?.find((c) => c.id === id);
    if (crew) {
      assignables.push({
        type: crew.model,
        id: crew.model_id,
        context: crew.id,
      });
    }
  });

  selectedRooms.value.forEach((id: string) => {
    const room = props.rooms?.find((c) => c.id === id);
    if (room) {
      assignables.push({
        type: room.model,
        id: room.model_id,
        context: room.id,
      });
    }
  });

  const data = {
    start: assignment.value.start,
    end: assignment.value.end,
    title: assignment.value.title,
    description: assignment.value.description,
    assignables,
    is_global: assignment.value.is_global,
  };

  if (props.simple) {
    data.should_update_assignables = false;
    if (props.allowRoomsToBeConfigured && roomsHasBeenChanged.value) {
      data.rooms_has_been_configured = true;
    }
  }
  await patchAssignment(props.initAssignment.id, props.eventId, data);
  if (props.withToast) {
    useToast().success('Assignment updated');
  }

  emit('updated');
  close();
};

const getEventCrew = () => {
  return props.eventCrew.map((crew) => ({
    ...crew,
    icon: getCrewIcon(crew.model, crew.type) + (getCrewIcon(crew.model, crew.type) === 'fa-user' ? ' ml-5 ' : ''),
  }));
};
</script>

<template>
  <CrudModal
    medium
    :loading="loading"
    :title="initAssignment?.id ? 'Edit Assignment' : 'New Assignment'"
    :update="initAssignment?.id > 0"
    :disabled="!canSave"
    @update="updateAssignment"
    @delete="deleteAssignment"
    @create="createAssignment($event, false)"
    @closed="$emit('closed')">
    <template #default="{ close }">
      <div class="form-layout">
        <TextInput
          v-model="assignment.title"
          label="Title"
          set-focus
          :min-length="3"
          required
          text-wrapper-class="col-span-2"
          @keydown.enter="initAssignment?.id ? updateAssignment(close) : createAssignment(close)" />

        <StartEndPicker
          v-model:start="assignment.start"
          v-model:end="assignment.end"
          class="col-span-2"
          with-time
          vertical
          required
          :duration-options="[15, 30, 45, 60, 75, 90, 120, 60 * 4, 60 * 6, 60 * 8, 60 * 10]"
          with-duration />

        <VMultiselect
          v-if="!simple"
          v-model="selectedCrew"
          wrapper-class="col-span-2"
          :have-max-width="false"
          :options="getEventCrew()"
          with-add-all
          :with-filter="true"
          option-label="title"
          label="Who" />

        <div
          v-if="!simple && outsideShift.length > 0"
          class="col-span-2 flex flex-col gap-2">
          <div class="col-s flex justify-end">
            <VButton
              title="Accept"
              type="pending"
              size="extra-small"
              @click="outsideShift = []" />
          </div>
          <VTable>
            <VTableRow
              v-for="outside in outsideShift"
              :key="outside">
              <VTableCell
                :title="
                  'You assignment is outside the period in which ' + outside + ' is available according to the shift.'
                "
                >Outside shift of {{ outside }}
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>
        <VMultiselect
          v-if="(!simple || allowRoomsToBeConfigured) && rooms.length > 0"
          v-model="selectedRooms"
          wrapper-class="col-span-2"
          :options="rooms"
          :have-max-width="false"
          with-add-all
          option-label="title"
          label="Where"
          @update:model-value="[(roomsHasBeenChanged = true)]" />

        <div
          v-if="(!simple || allowRoomsToBeConfigured) && outsideBooking.length > 0"
          class="col-span-2 flex flex-col gap-2">
          <div class="col-s flex justify-end">
            <VButton
              title="Accept"
              type="pending"
              size="extra-small"
              @click="outsideBooking = []" />
          </div>
          <VTable>
            <VTableRow
              v-for="outside in outsideBooking"
              :key="outside">
              <VTableCell
                :title="
                  'You assignment is outside the period in which ' +
                  outside +
                  ' is available according to your booking.'
                "
                >Outside booking period of {{ outside }}
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>

        <template v-if="isRecurring">
          <div
            v-if="!initAssignment?.id"
            class="col-span-2">
            <SettingCheck
              v-model="assignment.is_global"
              label="Assignment on All Recurring Events"
              title="For the recurrent Event, it will repeat the assignment on all events" />
          </div>

          <div
            v-else
            class="col-span-2">
            <SettingCheck
              v-model="assignment.is_global"
              label="Assignment on All Recurring Events"
              title="For the recurrent Event, it will repeat the assignment on all events" />
          </div>
        </template>

        <TextareaInput
          v-model="assignment.description"
          label="description"
          :min-rows="1"
          :min-height="40"
          wrapper-class="col-span-2" />

        <p
          v-if="simple"
          class="col-span-2 text-textColor-soft">
          This is a simplified assignment modal. For more options go to
          <span
            class="cursor-pointer text-highlight hover:underline"
            @click.prevent="$emit('goToEvent', $event)">
            Event
          </span>
        </p>
      </div>
    </template>

    <template
      v-if="!initAssignment?.id && simple"
      #footer="{ close }">
      <div class="flex justify-end">
        <ButtonGroup>
          <VButton
            title="Create & Add Another"
            :loading="loading"
            @click="createAssignment(close, true)" />
          <VButton
            type="success"
            :loading="loading"
            title="Create"
            @click="createAssignment(close, false)" />
        </ButtonGroup>
      </div>
    </template>
  </CrudModal>
</template>
