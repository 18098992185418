import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import { getInvoiceBases } from '@/services/api-invoice-rows';
import { InvoiceBasesResource } from '@/types/invoice-row';

export const invoiceStore = defineStore('invoice-store', () => {
  const list = ref<Map<string, Omit<InvoiceBasesResource, 'total' | 'users' | 'rows'>[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: 'Group' | 'Invite', modelId: number) => {
    Echo.channel(`private-${model}.${modelId}`)
      .listen(`.invoiceBasis.created`, async () => {
        await fetchInvoice(model, modelId, true);
      })
      .listen(`.invoiceBasis.updated`, async () => {
        await fetchInvoice(model, modelId, true);
      })
      .listen(`.invoiceBasis.deleted`, async () => {
        await fetchInvoice(model, modelId, true);
      });
  };

  const fetchInvoice = async (model: 'Group' | 'Invite', modelId: number, force: boolean = false) => {
    if (!force && list.value.has(formatModel(model, modelId))) return list.value.get(formatModel(model, modelId));

    loading.value = true;

    const { data } = await getInvoiceBases(model, modelId);

    if (!list.value.has(formatModel(model, modelId))) {
      listenForBroadcast(model, modelId);
    }

    list.value.set(formatModel(model, modelId), data);

    loading.value = false;

    return data;
  };

  return {
    list,
    loading,
    fetchInvoice,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(invoiceStore, import.meta.hot));
}
