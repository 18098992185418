<script lang="ts" setup>
import { ref } from 'vue';
import MobileMenu from '@/components/Navbar/MobileMenu.vue';
import { router } from '@inertiajs/vue3';

const currentPlace = ref('Personal');

const currentPage = ref('group');

router.on('navigate', (e) => {
  if (e.detail.page.component === 'Event/EventShow') {
    currentPlace.value = e.detail.page.props.event.name;
    currentPage.value = 'event';
  }
  if (e.detail.page.component === 'Group/GroupShow') {
    currentPlace.value = e.detail.page.props.group.name;
    currentPage.value = 'group';
  }

  if (e.detail.page.component === 'Performance/PerformanceShow') {
    currentPlace.value = e.detail.page.props.performance.name;
    currentPage.value = 'performance';
  }

  if (e.detail.page.component === 'Festival/FestivalSectionShow') {
    currentPlace.value = e.detail.page.props.festivalSection.name;
    currentPage.value = 'festival_section';
  }

  if (e.detail.page.component === 'PublicForm/PublicFormShow') {
    currentPlace.value = e.detail.page.props.publicForm.name;
    currentPage.value = 'public_form';
  }

  if (e.detail.page.component === 'Festival/FestivalShow') {
    currentPlace.value = e.detail.page.props.festival.name;
    currentPage.value = 'festival_show';
  }
});
</script>

<template>
  <nav class="py-4 flex gap-2 items-center border-b px-edge justify-between relative h-navbar">
    <h2 class="text-center capitalize truncate">{{ currentPlace }}</h2>
    <Suspense>
      <MobileMenu
        v-model:page="currentPage"
        v-model:place="currentPlace" />
      <template #fallback>
        <div>
          <i class="fa fa-fw fa-spinner fa-spin" />
        </div>
      </template>
    </Suspense>
  </nav>
</template>
