import { router } from '@inertiajs/vue3';

export const getRoute = (page: string, options: any = null): string => window.route(page, options);
export const openRoute = (route: string, event: MouseEvent | null = null, inNewTab = false): void => {
  if ((event && event.ctrlKey) || inNewTab) {
    window.open(route);
    return;
  }
  router.visit(route);
};
export const openExternalPage = (url: string, event: MouseEvent | null = null, inNewTab = false): void => {
  if ((event && event.ctrlKey) || inNewTab) {
    window.open(url);
    return;
  }
  router.visit(url);
};
export const goBack = (): void => {
  window.history.back();
};

export const reloadPage = (): void => {
  location.reload();
};
