<script lang="ts" setup>
import {
  addDocumentToFolder,
  destroyDocument,
  patchDocument,
  removeDocumentFromFolder,
} from '@/services/api-documents';
import { z } from 'zod';
import { DocumentFolderResource, DocumentModelType, DocumentResource } from '@/types/document';
import { useToast } from 'vue-toastification';
import { ref, computed } from 'vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  initDocument: DocumentResource | null;
  documentFolders?: DocumentFolderResource[];
  model?: DocumentModelType | null;
  modelId?: number | null;
  overrideModel?: DocumentModelType | null;
  overrideModelId?: number | null;
};

const props = withDefaults(defineProps<Props>(), {
  documentFolders: () => [],
  overrideModel: null,
  overrideModelId: null,
  model: null,
  modelId: null,
});

const emit = defineEmits<{
  (e: 'created', document: DocumentResource): void;
  (e: 'updated', document: DocumentResource): void;
  (e: 'deleted', documentId: number): void;
  (e: 'closed'): void;
}>();

const toast = useToast();

const DocumentSchema = z.object({
  filename: z.string().min(1).max(255),
  document_folder_id: z.number().nullable(),
});

type DocumentSchemaType = z.infer<typeof DocumentSchema>;

const document = ref<DocumentSchemaType>({
  filename: props.initDocument?.filename || '',
  document_folder_id: props.initDocument?.document_folder_id || null,
});

const canSave = computed(() => {
  return DocumentSchema.safeParse(document.value).success;
});

const updateDocument = async (close: () => void) => {
  if (!canSave.value || !props.initDocument?.id) return;

  const model = props.overrideModel ?? props.model;
  const modelId = props.overrideModelId ?? props.modelId;

  await patchDocument(model, modelId, props.initDocument.id, document.value.filename);

  if (document.value.document_folder_id !== props.initDocument.document_folder_id) {
    if (props.initDocument.document_folder_id) {
      await removeDocumentFromFolder(model, modelId, props.initDocument.document_folder_id, props.initDocument.id);
    }
    if (document.value.document_folder_id) {
      await addDocumentToFolder(model, modelId, document.value.document_folder_id, props.initDocument.id);
    }
  }
  toast.success('Updated');
  emit('updated', { ...props.initDocument, ...document.value });
  close();
};

const deleteDocument = async (close: () => void) => {
  if (!props.initDocument?.id) return;

  const modal = useDeleteObjectModal();
  const readyToDelete = await modal.assertReadyToDeleteModal(
    `Delete ${document.value.filename}`,
    `Are you sure you want to delete ${document.value.filename}?`
  );
  if (!readyToDelete) return;
  await destroyDocument(props.initDocument.id);

  toast.success(`${document.value.filename} deleted`);
  emit('deleted', props.initDocument.id);
  close();
};

const flattenedFolder = (folder: DocumentFolderResource, indent = 0) => {
  return [
    {
      id: folder.id,
      name: folder.name,
      indent,
    },
  ].concat(folder.children.flatMap((child) => flattenedFolder(child, indent + 1)));
};

const allFolders = computed(() => {
  return props.documentFolders.filter((f) => f.editable).flatMap((folder) => flattenedFolder(folder));
});
</script>
<template>
  <CrudModal
    :disabled="document.filename.length < 2"
    :update="true"
    title="Update Document"
    small
    @closed="$emit('closed')"
    @delete="deleteDocument"
    @update="updateDocument">
    <div class="form-layout">
      <TextInput
        v-model="document.filename"
        label="Name"
        placeholder="Name of Document" />

      <VSelect
        v-if="allFolders.length > 0"
        v-model="document.document_folder_id"
        option-value="name"
        :required="false"
        label="Document Folder"
        nullable
        nullable-display-text="No Folder"
        placeholder="Select a document Folder"
        :options="allFolders" />
    </div>
  </CrudModal>
</template>
