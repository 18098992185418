<script lang="ts" setup>
import { ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { ZIndexCrudModal } from '@/variables/z-indexes';

type Props = {
  width: string;
  clickToClose?: boolean;
  shouldTeleport?: boolean;
  bgOverlayBackdrop?: string | null;
  footerClasses?: string | null;
};

withDefaults(defineProps<Props>(), {
  width: '600px',
  clickToClose: true,
  shouldTeleport: true,
  bgOverlayBackdrop: 'bg-[--color-backdrop-modal]',
  footerClasses: '',
});
defineEmits<{
  (e: 'closed', arg: any): void;
  (e: 'clickOutside', arg: () => void, arg2: any): void;
  (e: 'beforeClose', arg: () => void, arg2: any): void;
}>();

const html = ref(null);

defineExpose({
  html,
});

const show = ref(true);

const closeModal = () => {
  show.value = false;
};

const setIndex = ({ index }: { index: number }) => {
  if (index === 0) return ZIndexCrudModal;
  return ZIndexCrudModal + index;
};
</script>

<template>
  <teleport
    to="body"
    :disabled="!shouldTeleport">
    <VueFinalModal
      ref="html"
      v-model="show"
      :z-index-fn="setIndex"
      :click-to-close="clickToClose"
      :content-transition="{
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': 'scale-0 opacity-0',
        'enter-to-class': '',
        'leave-active-class': 'transition duration-200 ease-in-out transform',
        'leave-to-class': 'opacity-0',
        'leave-from-class': '',
      }"
      :focus-trap="false"
      :overlay-class="bgOverlayBackdrop"
      class="flex items-center justify-center"
      :content-style="{ width: width }"
      content-class="relative flex flex-col bg-backgroundColor rounded border"
      @click-outside="$emit('clickOutside', closeModal, $event)"
      @before-close="$emit('beforeClose', closeModal, $event)"
      @closed="$emit('closed', $event)">
      <slot
        name="header"
        :close="closeModal" />

      <div
        v-if="$slots.title"
        class="px-8 pt-3 pb-5">
        <slot
          name="title"
          :close="closeModal" />
      </div>
      <slot
        :close="closeModal"
        name="contentWrapper">
        <div class="max-h-[70vh] overflow-auto px-8 py-3 md:max-h-[80vh]">
          <slot :close="closeModal" />
        </div>
      </slot>
      <div
        v-if="$slots.footer"
        :class="footerClasses"
        style="padding-bottom: 1.8rem"
        class="p-8 pt-5">
        <slot
          name="footer"
          :close="closeModal" />
      </div>
      <div
        v-else
        class="h-8" />
    </VueFinalModal>
  </teleport>
</template>
