import type { FestivalMinimalResource } from '@/types/festival';
import type { ContextSidebarTab } from '@/types/global';
import { getRoute } from '@/util/route';

// const createFestivalTabsForSmallScreen = (festival: FestivalMinimalResource, isAdmin: boolean): ContextSidebarTab[] => {
//   const array = [];
//
//   if (isAdmin || festival.permissions.includes('view_scheduler')) {
//     array.push({
//       hash: '',
//       parentKey: `festival_${festival.id}`,
//       title: 'Scheduler',
//       icon: 'fa-calendar',
//       route: getRoute('festivals.show', festival.slug),
//     });
//   }
//
//   if (isAdmin || festival.permissions.includes('view_performances') || festival.permissions.includes('update')) {
//     // festivalPerformanceTabs(festival, isAdmin, festival.permissions).forEach((t) => {
//     //   array.push({
//     //     hash: t.key,
//     //     key: t.key,
//     //     parentKey: `festival_${festival.id}_performances`,
//     //     title: t.title,
//     //     route: getRoute('festivals.show', festival.slug),
//     //   });
//     // });
//     array.push({
//       hash: 'performances',
//       parentKey: `festival_${festival.id}_performances`,
//       title: 'Performances',
//       icon: 'fa-music',
//       route: getRoute('festivals.show', festival.slug),
//     });
//   }
//   return array;
// };

export const createFestivalTabs = (
  festival: FestivalMinimalResource,
  superAdmin: boolean,
  isSmallScreen: boolean
): ContextSidebarTab[] => {
  const array: ContextSidebarTab[] = [];

  const isAdmin = festival.admin || (festival.permissions && festival.permissions.includes('update')) || superAdmin;

  // if (isSmallScreen) {
  //   return createFestivalTabsForSmallScreen(festival, isAdmin);
  // }
  if (isAdmin || festival.permissions.includes('view_scheduler')) {
    array.push({
      hash: 'scheduler',
      key: `festival_${festival.id}`,
      title: 'Scheduler',
      icon: 'fa-calendar-o',
      route: getRoute('festivals.show', festival.slug),
    });
  }
  if (isAdmin || festival.permissions.includes('view_performances') || festival.permissions.includes('update')) {
    array.push({
      hash: 'performances',
      key: `festival_${festival.id}_performances`,
      title: 'Performances',
      icon: 'fa-music fa-regular',
      route: getRoute('festivals.show', festival.slug),
    });
  }
  if (
    festival.festival_settings?.has_public_forms &&
    (isAdmin || festival.permissions.includes('update') || festival.permissions.includes('view_public_forms'))
  ) {
    array.push({
      hash: 'public_forms',
      key: `festival_${festival.id}_public_forms`,
      title: 'Public Forms',
      icon: 'fa-list-ul',
      route: getRoute('festivals.show', festival.slug),
    });
  }
  if (
    festival.festival_settings?.has_festival_sections &&
    (isAdmin || festival.permissions.includes('view_festival_sections'))
  ) {
    array.push({
      hash: 'festival_sections',
      key: `festival_${festival.id}_festival_sections`,
      title: 'Festival Sections',
      icon: 'fa-group',
      route: getRoute('festivals.show', festival.slug),
    });
  }
  if (
    festival.festival_settings?.has_accreditations &&
    (isAdmin || festival.permissions.includes('view_accreditations') || festival.permissions.includes('update'))
  ) {
    array.push({
      hash: 'accreditations',
      key: `festival_${festival.id}_accreditations`,
      title: 'Accreditations',
      icon: 'fa-id-badge',
      route: getRoute('festivals.show', festival.slug),
    });
  }
  if (
    isAdmin ||
    festival.permissions.includes('view_resources') ||
    festival.permissions.includes('view_scheduler') ||
    festival.permissions.includes('update')
  ) {
    array.push({
      hash: 'venues',
      key: `festival_${festival.id}_venues`,
      title: 'Venues',
      icon: 'fa-speakers fa-regular',
      route: getRoute('festivals.show', festival.slug),
    });
  }
  if (isAdmin && festival.festival_settings?.has_tasks) {
    array.push({
      hash: 'tasks',
      key: `festival_${festival.id}_tasks`,
      title: 'Tasks',
      icon: 'fa-tasks fa-regular',
      route: getRoute('festivals.show', festival.slug),
    });
  }
  if (isAdmin || festival.permissions.includes('view_contacts') || festival.permissions.includes('update')) {
    array.push({
      hash: 'contact_lists',
      key: `festival_${festival.id}_contact_lists`,
      title: 'Contact Lists',
      icon: 'fa-address-book fa-regular',
      route: getRoute('festivals.show', festival.slug),
    });
  }
  if (isAdmin || festival.permissions.includes('update')) {
    if (!isSmallScreen) {
      array.push({
        hash: '',
        key: `festival_${festival.id}_settings`,
        title: 'Settings',
        icon: 'fa-cog fa-regular',
        route: getRoute('festivals.administrator', festival.slug),
      });
    }
  }
  return array;
};
