<script setup lang="ts">
import moment from 'moment';
import { useToast } from 'vue-toastification';
import VSelect from '@/components/Inputs/VSelect.vue';
import { dateFormat } from '@/variables/date-format';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { computed, ref, watch } from 'vue';

type Props = {
  checkLists: any[];
};

defineProps<Props>();

const emit = defineEmits<{
  (event: 'downloading', arg: boolean): void;
}>();

const toast = useToast();

const start = ref(moment().startOf('month').format(dateFormat));
const end = ref(moment().endOf('month').format(dateFormat));
const check_list_id = ref(null);
const with_cancelled = ref(false);
const working = ref(false);
const modalOpen = ref(false);

watch(working, () => {
  emit('downloading', working.value);
});

const canSave = computed(() => {
  const hasValidDates = moment(start.value).isValid() && moment(end.value).isValid();
  const startEarlier = moment(end.value) > moment(start.value);
  const checkListId = !(check_list_id.value === null);
  return [hasValidDates, startEarlier, checkListId].every((v) => v);
});

const closeModal = () => {
  modalOpen.value = false;
};

const downloadReport = async () => {
  if (!canSave.value) {
    return;
  }
  if (working.value) {
    return;
  }
  working.value = true;
  const response = await axios
    .post(`/api/check-lists/${check_list_id.value}/pdf`, {
      start: start.value,
      end: end.value,
      with_cancelled: with_cancelled.value,
    })
    .catch((error) => {
      console.error(error);
      working.value = false;
      toast.warning('Something went wrong, please try again later');
    });
  if (response.status === 204) {
    toast.success('There are no result for the requested period');
    working.value = false;
    return;
  }
  await downloadFile(response.data.url, response.data.name);
  working.value = false;
  closeModal();
};
</script>

<template>
  <ReportModal
    v-model:start="start"
    v-model:end="end"
    v-model:with-cancelled="with_cancelled"
    :can-download="canSave"
    :with-button="false"
    title="Download Check List Report"
    :working="working"
    @download="downloadReport">
    <VSelect
      v-model="check_list_id"
      label="Check List"
      :options="checkLists"
      option-value="title" />
  </ReportModal>
</template>
