<script setup lang="ts">
import { getItemFromArrayBasedOnId } from '@/util/globals';
import { SharedMetaDataResource } from '@/types/meta-data';
import { computed, ref } from 'vue';
import { ShowTimeResource } from '@/types/show-time';
import FieldsList from '@/components/Fields/FieldsList.vue';
import { useToast } from 'vue-toastification';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  canEdit?: boolean | null;
  inviteId: number;
  metaData: SharedMetaDataResource[];
  showTimes: ShowTimeResource[];
};

const props = withDefaults(defineProps<Props>(), {
  group: null,
  canEdit: true,
});

const loading = ref(false);

const notTooManyFields = computed(() => {
  return (
    props.metaData.flatMap((m) => {
      return m.fields;
    }).length < 15
  );
});

const superHeader = (m) => {
  if (m.show_time_id && props.showTimes.length > 0) {
    return getItemFromArrayBasedOnId(m.show_time_id, props.showTimes, {
      title: 'N/A',
    }).title;
  }
  return null;
};

const assignValue = async (value, metaDataField) => {
  if (!metaDataField.write) return;
  if (metaDataField.value === value) return;
  await axios.patch(`/api/meta-data-fields/${metaDataField.id}/input`, {
    value,
  });
  useToast().success('Updated');
  metaDataField.value = value;
};
</script>
<template>
  <div
    v-if="!loading && metaData.length"
    class="px-edge flex flex-col gap-edge">
    <BoxContainer
      v-for="meta in metaData"
      :togglable="!notTooManyFields"
      :loading="loading"
      :default-open="notTooManyFields"
      :super-text="superHeader(meta)"
      :header="meta.title">
      <FieldsList
        :model-value="meta.fields"
        allow-fields-to-override-can-edit
        @assign-value="assignValue" />
    </BoxContainer>
  </div>
</template>
