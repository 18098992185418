import { BoardCellResource, BoardColumnResource, BoardRowResource } from '@/types/board';
import { createUuId, getItemFromArrayBasedOnId } from '@/util/globals';
import { computed, inject } from 'vue';
import { documentKey, eventMembersKey } from '@/provide/keys';

export const getBoardCell = (
  cells: BoardCellResource[],
  rowId: number,
  columnId: number,
  defaultReturn: null | object | never = null
) => {
  if (!rowId || !columnId) return defaultReturn;
  const cell = cells?.find((cell) => cell.board_row_id === rowId && cell.board_column_id === columnId);
  if (cell === undefined) return defaultReturn;
  return cell;
};

export const boardColumnTypes = [
  {
    title: 'Text',
    component: 'board-column-text',
    width: 'width-regular',
    class: 'fa fa-file-text-o',
  },
  {
    title: 'Date',
    component: 'board-column-date',
    width: 'width-regular',
    class: 'fa fa-calendar',
  },
  {
    title: 'Time',
    component: 'board-column-time',
    width: 'width-medium',
    class: 'fa fa-clock-o',
  },
  {
    title: 'User',
    component: 'board-column-user',
    width: 'width-regular',
    class: 'fa fa-user',
  },
  {
    title: 'Toggle',
    component: 'board-column-toggle',
    width: 'width-medium',
    class: 'fa fa-check-square',
  },
  {
    title: 'List',
    component: 'board-column-list',
    configurator: 'board-column-list-configurator',
    width: 'width-regular',
    class: 'fa fa-list',
  },
  {
    title: 'Document',
    component: 'board-column-document',
    width: 'width-medium',
    class: 'fa fa-file-o',
  },
] as const;

export const createExampleBoard = () => {
  const columnDefinitions: BoardColumnResource[] = [];
  const rows: BoardRowResource[] = [];
  const textId = createUuId('column_board-column-text_');
  const toggleId = createUuId('column_board-column-toggle_');

  columnDefinitions.push({
    component: 'board-column-text',
    options: null,
    title: 'Text',
    id: textId,
    value: null,
    width: 'width-regular',
  });
  columnDefinitions.push({
    component: 'board-column-toggle',
    options: null,
    title: 'Toggle',
    id: toggleId,
    value: null,
    width: 'width-regular',
  });

  rows.push({
    title: 'A general text field',
    columns: columnDefinitions,
  });
  rows.push({
    title: 'Example Row 2',
    columns: columnDefinitions,
  });
  rows.push({
    title: 'Example Row 3',
    columns: columnDefinitions,
  });

  return rows;
};

export const sortBoardRows = (
  rows: BoardRowResource[] | null,
  columns: BoardColumnResource[] | null,
  cells: BoardCellResource[],
  sortBy: string | null,
  asc = true
) => {
  if (!Array.isArray(rows)) return rows;
  if (!Array.isArray(columns)) return rows;
  if (!sortBy) return rows;
  const component = getItemFromArrayBasedOnId(sortBy, columns, { component: null })?.component;
  return rows.sort((a, b) => {
    let textA = null;
    let textB = null;
    if (sortBy === 'title') {
      textA = a.title;
      textB = b.title;
    } else {
      const column = getItemFromArrayBasedOnId(sortBy, columns, { id: null });
      textA = getBoardCell(cells, a.id, column.id, { value: null }).value;
      textB = getBoardCell(cells, b.id, column.id, { value: null }).value;
    }
    if (component?.includes('document')) {
      const { allDocuments } = inject(documentKey, {
        allDocuments: computed(() => []),
      });
      textA = textA ? getItemFromArrayBasedOnId(Number(textA), allDocuments.value, { filename: null }).filename : null;
      textB = textB ? getItemFromArrayBasedOnId(Number(textB), allDocuments.value, { filename: null }).filename : null;
    }
    if (component?.includes('user')) {
      const { allMembers } = inject(eventMembersKey, { allMembers: computed(() => []) });
      textA = textA ? getItemFromArrayBasedOnId(Number(textA), allMembers.value, { name: null }).name : null;
      textB = textB ? getItemFromArrayBasedOnId(Number(textB), allMembers.value, { name: null }).name : null;
    }
    if (!textA) return asc ? -1 : 1;
    if (!textB) return asc ? 1 : -1;
    if (textA < textB) {
      return asc ? -1 : 1;
    }
    if (textA > textB) {
      return asc ? 1 : -1;
    }
    return 1;
  });
};
