<script lang="ts" setup>
import { computed } from 'vue';
import VToggle from '@/components/Inputs/VToggle.vue';

const props = defineProps({
  modelValue: {
    type: [String, Boolean],
    required: false,
    default: false,
  },
  canEdit: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(['blur']);

const onChange = (value: boolean) => {
  if (!props.canEdit) return;
  emit('blur', value);
};

const value = computed(() => {
  switch (typeof props.modelValue) {
    case 'string':
      return props.modelValue === '1' || props.modelValue === 'true';
    case 'boolean':
      return props.modelValue;
    default:
      return false;
  }
});
</script>

<template>
  <div
    class="h-full w-full"
    @click="onChange(!value)">
    <VToggle
      :model-value="value"
      :can-edit="canEdit" />
  </div>
</template>
