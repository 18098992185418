<script setup>
import { watch } from 'vue';

const props = defineProps({
  sessionStorageString: {
    type: String,
    required: false,
    default: null,
  },
  value: {
    type: [Number, String, Array, null, Object],
    required: false,
    default: null,
  },
});
const emits = defineEmits(['newValue']);

const storedState = JSON.parse(sessionStorage.getItem(props.sessionStorageString));
if (storedState !== null) {
  emits('newValue', storedState);
}
watch(
  () => props.value,
  () => {
    if (!props.sessionStorageString) {
      return;
    }
    if (!props || props.value === null || props.value === 'null') {
      sessionStorage.removeItem(props.sessionStorageString);
    } else {
      sessionStorage.setItem(props.sessionStorageString, JSON.stringify(props.value));
    }
  }
);
</script>
<template />
