<script setup lang="ts">
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import { RunningOrderColumnResource, RunningorderResource } from '@/types/runningorder';
import { formatStampAsHumanReadableDate } from '@/util/timeFunctions';
import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  runningOrder: RunningorderResource;
  columnDefinitions: RunningOrderColumnResource[];
  hiddenColumns: RunningOrderColumnResource[];
};

const props = defineProps<Props>();

defineEmits<{
  (event: 'closed'): void;
}>();

const toast = useToast();

const downloadableColumns = ref(props.columnDefinitions);
const asCsv = ref(false);
const colorOnPrint = ref(false);
const downloadingRunningOrder = ref(false);
const runningOrderShowId = ref(null);

const downloadBoard = async () => {
  if (downloadingRunningOrder.value) return;

  downloadingRunningOrder.value = true;

  try {
    const { data } = await axios.post(`/api/runningorders/${props.runningOrder.id}/download`, {
      asCSV: asCsv.value,
      downloadableColumns: downloadableColumns.value,
      colorOnPrint: colorOnPrint.value,
      running_order_show_id: runningOrderShowId.value,
    });
    await downloadFile(data.url, data.name);
    downloadingRunningOrder.value = false;
  } catch (e) {
    console.error(e);
    downloadingRunningOrder.value = false;
    toast.warning('Something went wrong, please try again later');
  }
};
downloadableColumns.value = [];
props.columnDefinitions.forEach((columnDefinition, index) => {
  if (!props.hiddenColumns.includes(columnDefinition.id)) {
    if (
      columnDefinition.component === 'runningorder-column-document' ||
      columnDefinition.component === 'runningorder-column-image'
    ) {
    } else {
      downloadableColumns.value.push(columnDefinition.id);
    }
  }
});
</script>

<template>
  <ReportModal
    v-model:as-c-s-v="asCsv"
    can-download
    small
    title="Download Running Order"
    :working="downloadingRunningOrder"
    :with-button="false"
    @download="downloadBoard"
    @close="$emit('closed')">
    <VMultiselect
      v-if="columnDefinitions.length > 0"
      v-model="downloadableColumns"
      :options="columnDefinitions"
      with-add-all
      label="Columns to Include"
      can-edit
      option-label="title"
      label-key="id" />
    <SettingToggle
      v-if="!asCsv"
      v-model="colorOnPrint"
      label="Include Colors on Print"
      title="If enabled, the color definitions of this running order will be included on the print." />

    <VSelect
      v-if="runningOrder.running_order_shows.length > 0"
      v-model="runningOrderShowId"
      :options="
        runningOrder.running_order_shows.map((s) => {
          return {
            id: s.id,
            name:
              'Show on ' +
              formatStampAsHumanReadableDate(s.start) +
              ' (' +
              s.running_order_show_row_pivots.length +
              ' rows)',
          };
        })
      "
      label="Show To Use"
      nullable
      nullable-display-text="Use Default Values"
      can-edit
      label-key="id" />
  </ReportModal>
</template>
