import { useToast } from 'vue-toastification';
import { DocumentResource } from '@/types/document';
import { usePage } from '@inertiajs/vue3';

const sendBlockToSlack = (block: string, url: string) => {
  const data = {
    text: 'Download Document Error',
    blocks: [
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: block,
          },
        ],
      },
      {
        type: 'divider',
      },
    ],
  };
  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  xhr.send(JSON.stringify(data));
};

export const downloadFile = async (url: string, fileName: string) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    const href = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      link.parentNode.removeChild(link);
    }, 1000 * 10);
    return 'Done';
  } catch (error) {
    console.error(error);
    const toast = useToast();
    toast.warning('Something went wrong with the download');
    const urlSlack = import.meta.env.VITE_SLACK_WEBHOOK_URL;
    let block = '';

    block = `${block}*downloadCreatedDocument feil skjedd:*\n
          Den skjedde på domenet ${window.location.href}\n`;
    if (usePage().props.auth.user) {
      const { user } = usePage().props.auth;
      block = `${block}*Skjedde for ${user.name}:*\n Id: ${user.id}.
            Email: ${user.email}. Phone: ${user.phone}\n`;
    }
    if (error.response) {
      block = `${block}*Status:*${error.response.status}\n`;
      block = `${block}*Error:*${error.response.statusText}\n`;
      block = `${block}*URL:*${urlSlack}\n`;
      block = `${block}*FileName:*${fileName}\n`;
    }
    sendBlockToSlack(block, urlSlack);
    return new Error('Error');
  }
};

export const downloadDocument = async (doc: DocumentResource) => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    window.open(`/documents/${doc.id}/download`);
  } else {
    await downloadFile(doc.download_url, doc.filename);
  }
};
