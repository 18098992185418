import { BoardCellResource, BoardColumnResource, BoardModelType, BoardResource, BoardRowResource } from '@/types/board';

export const getBoards = async (modelType: BoardModelType, modelId: number) => {
  const url = '/api/boards';
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.get<BoardResource[]>(url, { params });
};

export const getBoard = async (id: number) => {
  const url = `/api/boards/${id}`;
  return axios.get<BoardResource>(url);
};

type BoardUpdateData = {
  title?: string;
  row_title?: string;
};

export const patchBoard = async (boardId: number, data: BoardUpdateData) => {
  const url = `/api/boards/${boardId}`;
  return axios.patch<string>(url, { ...data });
};

type BoardStoreData = {
  title: string;
  template_id?: number | null;
  is_global?: boolean;
  model_id: number;
  model: string;
  row_title?: string | null;
  board_rows?: [] | null;
  board_columns?: [] | null;
};

export const postBoard = async (data: BoardStoreData) => {
  const url = '/api/boards';
  return axios.post<BoardResource>(url, {
    model_type: `App\\${data.model}`,
    model_id: data.model_id,
    title: data.title,
    row_title: data.row_title,
    template_id: data.template_id,
    is_global: data.is_global,
    board_rows: data.board_rows,
    board_columns: data.board_columns,
  });
};

export const destroyBoard = async (boardId: number) => {
  const url = `/api/boards/${boardId}`;
  return axios.delete<string>(url);
};

export const reOrderBoardRows = async (
  boardId: number,
  sortedRowIds: Array<number | string>,
  movedRowId?: null | number | string
) => {
  const url = `/api/boards/${boardId}/update-row-orders`;
  return axios.post(url, {
    sorted_row_ids: sortedRowIds,
    moved_row_id: movedRowId,
  });
};

export const reOrderBoardColumns = async (
  boardId: number,
  sortedColumnIds: Array<number | string>,
  movedColumnId?: null | number | string
) => {
  const url = `/api/boards/${boardId}/update-column-orders`;
  return axios.post(url, {
    sorted_column_ids: sortedColumnIds,
    moved_column_id: movedColumnId,
  });
};

// TEMPLATE API

// export const getBoardTemplates = async (modelType: BoardModelType, modelId: number) => {
//   const url = '/api/board-templates';
//   const params = {
//     model_type: `App\\${modelType}`,
//     model_id: modelId,
//   };
//   return axios.get<BoardResource[]>(url, { params });
// };
//
// export const getBoardTemplate = async (id: number) => {
//   const url = `/api/board-templates/${id}`;
//   return axios.get<BoardResource>(url);
// };
//
// type BoardTemplateData = {
//   title: string;
// };
//
// export const postBoardTemplate = async (model: BoardModelType, modelId: number, data: BoardTemplateData) => {
//   const url = '/api/board-templates';
//   return axios.post<BoardResource>(url, {
//     model_type: `App\\${model}`,
//     model_id: modelId,
//     title: data.title,
//   });
// };
//
// export const patchBoardTemplate = async (boardId: number, title: string, rows: BoardRow[] = []) => {
//   const url = '/api/board-templates/' + boardId;
//   return axios.patch<string>(url, {
//     title: title,
//     rows: rows,
//   });
// };
//
// export const destroyBoardTemplate = async (boardId: number) => {
//   const url = `/api/board-templates/${boardId}/delete`;
//   return axios.post<string>(url);
// };

// Row API
export const getBoardRows = async (boardId: number) => {
  const url = '/api/boards/' + boardId + '/board-rows';
  return axios.get<BoardRowResource[]>(url);
};
export const getBoardRow = async (boardId: number, boardRowId: number) => {
  const url = '/api/boards/' + boardId + '/board-rows/' + boardRowId;
  return axios.get<BoardRowResource>(url);
};

type BoardRowData = {
  title: string;
  order: number | null;
};

export const createBoardRow = async (boardId: number, data: BoardRowData) => {
  const url = '/api/boards/' + boardId + '/board-rows';
  return axios.post<BoardRowResource>(url, {
    title: data.title,
    order: data.order,
  });
};

export const updateBoardRow = async (boardId: number, data: BoardRowResource) => {
  const url = '/api/boards/' + boardId + '/board-rows/' + data.id;
  return axios.patch<BoardRowResource>(url, {
    title: data.title,
  });
};
export const deleteBoardRow = async (boardId: number, data: BoardRowResource) => {
  const url = '/api/boards/' + boardId + '/board-rows/' + data.id;
  await axios.delete<BoardRowResource>(url);
  return true;
};

// Column API
export const getBoardColumns = async (boardId: number) => {
  const url = '/api/boards/' + boardId + '/board-columns';
  return axios.get<BoardColumnResource[]>(url);
};

export const getBoardColumn = async (boardId: number, boardColumnId: number) => {
  const url = '/api/boards/' + boardId + '/board-columns/' + boardColumnId;
  return axios.get<BoardColumnResource>(url);
};

type BoardColumnData = {
  title: string;
  component: string;
  width: string;
  options: string;
  order: number | null;
};

export const createBoardColumn = async (boardId: number, data: BoardColumnData) => {
  const url = '/api/boards/' + boardId + '/board-columns';
  return axios.post<BoardColumnResource>(url, {
    title: data.title,
    component: data.component,
    width: data.width,
    options: data.options,
    order: data.order,
  });
};

export const updateBoardColumn = async (
  boardId: number,
  data: { id: number; order?: number; title?: string; width?: string; options?: string[] }
) => {
  const url = '/api/boards/' + boardId + '/board-columns/' + data.id;
  return axios.patch<BoardColumnResource>(url, {
    title: data.title,
    width: data.width,
    options: data.options,
    order: data.order,
  });
};
export const deleteBoardColumn = async (boardId: number, data: BoardColumnResource) => {
  const url = '/api/boards/' + boardId + '/board-columns/' + data.id;
  await axios.delete<BoardColumnResource>(url);
  return true;
};

// Cell API
export const getBoardCells = async (boardId: number) => {
  const url = '/api/boards/' + boardId + '/board-cells';
  return axios.get<BoardCellResource[]>(url);
};

export const getBoardCell = async (boardId: number, boardCellId: number) => {
  const url = '/api/boards/' + boardId + '/board-cells/' + boardCellId;
  return axios.get<BoardCellResource>(url);
};

type BoardCellData = {
  value: string;
  id: number | null;
  board_row_id: number | null;
  board_column_id: number | null;
};

export const createBoardCell = async (boardId: number, data: BoardCellData) => {
  const url = '/api/boards/' + boardId + '/board-cells';
  return axios.post<BoardCellResource>(url, {
    board_row_id: data.board_row_id,
    board_column_id: data.board_column_id,
    value: data.value,
  });
};

export const updateBoardCell = async (boardId: number, data: BoardCellResource) => {
  const url = '/api/boards/' + boardId + '/board-cells/' + data.id;
  return axios.patch<BoardColumnResource>(url, {
    value: data.value,
  });
};
