import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useFestivalSectionsStore = defineStore('festival-sections', () => {
  const list = ref(new Map());
  const loading = ref(false);

  const fetchFestivalFestivalSections = async (festivalId: number) => {
    if (loading.value) return;
    loading.value = true;
    const { data } = await axios.get('/api/festival-sections/', {
      params: {
        festival_id: festivalId,
        with_users: true,
      },
    });
    list.value.set(festivalId, data);
    loading.value = false;
  };

  const getForFestival = (festivalId: number, force = false) => {
    if (force) {
      fetchFestivalFestivalSections(festivalId);
    } else if (!list.value.has(festivalId)) {
      list.value.set(festivalId, []);
      fetchFestivalFestivalSections(festivalId);
      // TODO: listen for broadcast
    }
    return list.value.get(festivalId);
  };

  const flushForFestival = (festivalId: number) => {
    list.value.delete(festivalId);
  };

  return {
    list,
    loading,
    getForFestival,
    flushForFestival,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFestivalSectionsStore, import.meta.hot));
}
