export const itemsForReminderExchange = (small = false) =>
  [
    small ? null : '{{$date}}',
    small ? null : '{{$start}}',
    small ? null : '{{$hours}}',
    '{{$name}}',
    '{{$firstName}}',
    '{{$lastName}}',
    small ? null : '{{$via}}',
    small ? null : '{{$place}}',
    small ? null : '{{$events}}',
  ].filter((item) => item !== null);
