import { GroupResource } from '@/types/group';
import { EventGroupInviteResource, EventInviteResource, FestivalSectionInviteResource } from '@/types/event';
import { UserMinimalResource } from '@/types/user';
import { TaskResource } from '@/types/tasks';
import _ from 'lodash';

export type TaskEntityResource = {
  id: string;
  model: string;
  model_id: number;
  name: string;
};
export type TaskViaResource = {
  id: string;
  model_type: string;
  model_id: number;
  name: string;
};
export type TaskAssignableUserResource = {
  id: number;
  name: string;
};

export type TaskAssignableListResource = {
  formattedModelId: string | null;
  users: TaskAssignableUserResource[];
};

export const formatModelAndIdToId = (model: string | null, id: number | null) => {
  if (!model || !id) return null;
  return model + '_' + id;
};

export const formatEntitiesForTaskListForGroupShow = (group: GroupResource): TaskEntityResource[] => {
  return [
    {
      id: formatModelAndIdToId('App\\Group', group.id),
      model: 'App\\Group',
      model_id: group.id,
      name: group.name,
    },
  ].concat(
    group.childGroups.map((g) => {
      return {
        id: formatModelAndIdToId('App\\Group', g.id),
        model: 'App\\Group',
        model_id: g.id,
        name: g.name,
      };
    })
  );
};

export type TaskAssignableResource = {
  id: string;
  model_type: string;
  model_id: number;
  name: string;
};

export const formatAssignableItemsForGroup = (group: GroupResource): TaskAssignableResource[] => {
  return [
    {
      via_id: formatModelAndIdToId('App\\Group', group.id),
      items: group.members.map((m) => {
        return {
          id: formatModelAndIdToId('App\\User', m.id),
          model_type: 'App\\User',
          model_id: m.id,
          name: m.name,
        };
      }),
    },
  ].concat([]);
  // group.childGroups.map((g) => {
  //   return {
  //     id: formatModelAndIdToId('App\\Group', g.id),
  //     model_type: 'App\\Group',
  //     model_id: g.id,
  //     name: g.name,
  //   };
  // })
  // );
};

export const formatEntitiesForTaskListForEvent = (invites: EventInviteResource): TaskEntityResource[] => {
  if (!invites) return [];
  return invites?.groups?.flatMap((groupInvite: EventGroupInviteResource) => {
    return [
      {
        id: formatModelAndIdToId('App\\Group', groupInvite.invitable.id),
        model: 'App\\Group',
        model_id: groupInvite.invitable.id,
        name: groupInvite.invitable.name,
      },
    ].concat(
      groupInvite.invitable.invited_subgroups.map((g) => {
        return {
          id: formatModelAndIdToId('App\\Group', g.id),
          model: 'App\\Group',
          model_id: g.id,
          name: g.name + ' (' + groupInvite.invitable.name + ')',
        };
      })
    );
  });
};

export const formatEventInvitesAsGroupsForEntityOnTask = (invites: EventInviteResource): TaskEntityResource[] => {
  if (!invites) return [];
  return invites?.groups
    ?.flatMap((groupInvite: EventGroupInviteResource) => {
      return [
        {
          id: groupInvite.invitable.id,
          type: 'App\\Group',
          name: groupInvite.invitable.name,
        },
      ].concat(
        groupInvite.invitable.invited_subgroups.map((g) => {
          return {
            id: g.id,
            type: 'App\\Group',
            name: g.name + ' (' + groupInvite.invitable.name + ')',
          };
        })
      );
    })
    .concat(
      invites?.festival_sections?.map((festivalSectionInvite: FestivalSectionInviteResource) => {
        return {
          id: festivalSectionInvite.invitable.id,
          type: festivalSectionInvite.invitable_type,
          name: festivalSectionInvite.invitable.name,
        };
      })
    );
};

export const formatViaForTaskListForEvent = (invites: EventInviteResource): TaskViaResource[] => {
  if (!invites) return [];
  return invites?.groups?.flatMap((groupInvite: EventGroupInviteResource) => {
    console.log(groupInvite);
    return [
      {
        id: formatModelAndIdToId('App\\Group', groupInvite.invitable.id),
        model_type: 'App\\Group',
        model_id: groupInvite.invitable.id,
        name: groupInvite.invitable.name,
      },
    ].concat(
      groupInvite.invitable.invited_subgroups.map((g) => {
        return {
          id: formatModelAndIdToId('App\\Group', g.id),
          model_type: 'App\\Group',
          model_id: g.id,
          name: g.name + ' (' + groupInvite.invitable.name + ')',
        };
      })
    );
  });
};

export const formatUserForTaskListForEvent = (
  invites: EventInviteResource,
  allMembers: UserMinimalResource[]
): TaskAssignableListResource[] => {
  if (!invites) return [];
  return [
    {
      formattedModelId: null,
      label: 'All',
      options: allMembers
        .map(function (m) {
          return {
            id: m.id,
            name: m.name,
          };
        })
        .concat(
          invites?.groups.flatMap((groupInvite: EventGroupInviteResource) => {
            const projectLeader =
              groupInvite.project_leader && groupInvite.project_leader.id
                ? [
                    {
                      id: groupInvite.project_leader.id,
                      name: groupInvite.project_leader.name,
                    },
                  ]
                : [];

            const subGroupProjectLeaders = groupInvite.invitable.invited_subgroups.flatMap((g) => {
              return g.project_leader && g.project_leader.id
                ? [
                    {
                      id: g.project_leader.id,
                      name: g.project_leader.name,
                    },
                  ]
                : [];
            });
            return projectLeader.concat(subGroupProjectLeaders);
          })
        ),
    },
  ]
    .concat(
      invites?.groups?.flatMap((groupInvite: EventGroupInviteResource) => {
        const groupUsers = groupInvite.invitable.invited_members
          .map(function (u) {
            return {
              id: u.id,
              name: u.name,
            };
          })
          .filter((u) => u.id !== groupInvite.project_leader_id);
        const projectLeader =
          groupInvite.project_leader && groupInvite.project_leader.id
            ? [
                {
                  id: groupInvite.project_leader.id,
                  name: groupInvite.project_leader.name,
                },
              ]
            : [];
        return [
          {
            formattedModelId: formatModelAndIdToId('App\\Group', groupInvite.invitable.id),
            label: groupInvite.invitable.name,
            options: projectLeader.concat(groupUsers).filter((i) => i !== null),
          },
        ].concat(
          groupInvite.invitable.invited_subgroups.map((g) => {
            const projectLeader =
              g.project_leader && g.project_leader.id
                ? [
                    {
                      id: g.project_leader.id,
                      name: g.project_leader.name,
                    },
                  ]
                : [];

            return {
              formattedModelId: formatModelAndIdToId('App\\Group', g.id),
              label: g.name,
              options: projectLeader
                .concat(
                  g.invited_members.map(function (u) {
                    return {
                      id: u.id,
                      name: u.name,
                    };
                  })
                )
                .filter((i) => i !== null),
            };
          })
        );
      })
    )
    .concat(
      invites?.festival_sections?.flatMap((festivalSectionInvite: FestivalSectionInviteResource) => {
        return [
          {
            formattedModelId: formatModelAndIdToId(
              festivalSectionInvite.invitable_type,
              festivalSectionInvite.invitable_id
            ),
            label: festivalSectionInvite.invitable.name,
            options: festivalSectionInvite.invitable.invited_members.map(function (u) {
              return {
                id: u.id,
                name: u.name,
              };
            }),
          },
        ];
      })
    );
};

export const getPossibleAssignableUsers = (task: TaskResource, assignableUsers: TaskAssignableListResource[]) => {
  const index = _.findIndex(
    assignableUsers,
    (i) => i.formattedModelId === formatModelAndIdToId(task.via_type, task.via_id)
  );
  if (index > -1) {
    return assignableUsers[index].users;
  }
  return [];
};
