<script setup lang="ts">
import { z } from 'zod';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import TextInput from '@/components/Inputs/TextInput.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import { createUuId, getKey } from '@/util/globals';
import VSelect from '@/components/Inputs/VSelect.vue';
import FieldListOptionEditor from '@/components/Fields/FieldListOptionEditor.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { fieldHasOptions } from '@/util/fields';
import { defaultColumnWidths } from '@/variables/mysc';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import { RunningOrderColumnResource } from '@/types/runningorder';
import { BoardColumnResource } from '@/types/board';

type IColumn = {
  id: string;
  component: string;
  title: string;
  value: string | null;
  options: string[] | null;
  width: string;
  restrict_edit?: boolean;
};

type Props = {
  initColumn: IColumn | null;
  withRestrictEdit?: boolean;
  columnTypes: Readonly<{ title: string; component: string; width: string; class: string }[]>;
  columns?: RunningOrderColumnResource[] | BoardColumnResource[] | object[];
  maxNumberColumns?: number | null;
  classes?: string;
  minWidth?: string;
  width?: string;
  noPadding?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  withRestrictEdit: false,
  classes: undefined,
  minWidth: '85px',
  width: '85px',
  maxNumberColumns: null,
  noPadding: false,
  columns: () => [],
});

const emit = defineEmits<{
  (event: 'created', arg: IColumn): void;
  (event: 'updated', arg: IColumn): void;
  (event: 'deleted', arg: string): void;
  (event: 'closed'): void;
}>();

const unsavedOption = ref(false);
const toast = useToast();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const loading = ref(false);

const ColumnSchema = z.object({
  title: z.string().min(2).max(255),
  options: z.array(z.string()).nullable(),
  width: z.string(),
  component: z.string(),
  restrict_edit: z.boolean(),
});

type Column = z.infer<typeof ColumnSchema>;

const column = ref<Column>({
  title: props.initColumn?.title ?? '',
  options: props.initColumn?.options ?? null,
  width: props.initColumn?.width ?? defaultColumnWidths[0].class,
  restrict_edit: getKey(props.initColumn, 'restrict_edit', true),
  component: props.initColumn?.component ?? props.columnTypes[0].component,
});

const showModal = ref(false);

const openModal = (col?: Column | null) => {
  if (!col) {
    if (props.maxNumberColumns && props.columns && props.columns.length > 0) {
      if (props.maxNumberColumns <= props.columns.length) {
        useToast().warning('Maximum number of columns is ' + props.maxNumberColumns);
        return;
      }
    }
  }
  column.value = {
    id: getKey(col, 'id'),
    title: getKey(col, 'title'),
    context: getKey(col, 'context'),
    options: getKey(col, 'options'),
    width: getKey(col, 'width', defaultColumnWidths[2].class),
    restrict_edit: getKey(col, 'restrict_edit', true),
    component: getKey(col, 'component', props.columnTypes[0].component),
  };
  showModal.value = false;
  nextTick(() => {
    showModal.value = true;
  });
};

watch(
  () => props.initColumn,
  () => {
    if (props.initColumn === null) return;

    showModal.value = false;
    openModal(props.initColumn);
  }
);

const canSave = computed(() => ColumnSchema.safeParse(column.value).success);

const createColumn = () => {
  if (props.initColumn?.id || !canSave.value) return;
  if (column.value.title.trim().length < 2) {
    toast.error('Please enter a title for the column');
    return;
  }

  emit('created', {
    id: createUuId('column_'),
    title: column.value.title,
    options: column.value.options,
    width: column.value.width,
    component: column.value.component,
    restrict_edit: column.value.restrict_edit,
    value: null,
  });

  closeModal();
};

const updateColumn = () => {
  if (!props.initColumn?.id || !canSave.value) return;

  emit('updated', {
    id: props.initColumn.id,
    title: column.value.title,
    options: column.value.options,
    width: column.value.width,
    component: column.value.component,
    restrict_edit: column.value.restrict_edit,
    value: props.initColumn.value,
  });

  closeModal();
};

const deleteColumn = async () => {
  if (!props.initColumn?.id) return;

  const deleteIt = await assertReadyToDeleteModal(
    'Delete Column',
    `Are you sure that you want to delete ${column.value.title}?`
  );
  if (!deleteIt) return;

  emit('deleted', props.initColumn.id);
  closeModal();
};

const closeModal = () => {
  showModal.value = false;
  emit('closed');
};
</script>

<template>
  <VTableCell
    main-cell
    :style="`min-width: ${minWidth}; width: ${width}`"
    style="max-width: 85px"
    component-type="th"
    :class="{ '!px-2': noPadding }"
    class="sticky right-0 [&_.left-side-border]:block"
    :classes="'bg-backgroundColor ' + classes">
    <div
      class="text-center"
      :class="{ 'pb-1': noPadding }">
      <VButton
        type="info"
        size="extra-small"
        icon="fa-plus"
        tool-tip-text="Click to create a new column"
        @click="openModal(null)" />
    </div>

    <CrudModal
      v-if="showModal"
      :title="(initColumn === null ? 'Create' : 'Update') + ' Column'"
      :loading="loading"
      :disabled="!canSave || unsavedOption"
      :update="column.id !== null"
      small
      @closed="closeModal"
      @create="createColumn"
      @update="updateColumn"
      @delete="deleteColumn">
      <div class="form-layout">
        <VSelect
          v-model="column.component"
          :can-edit="column.id === null"
          label="Type"
          :options="columnTypes.map((c) => ({ title: c.title, component: c.component, icon: c.class }))"
          option-value="title"
          option-key="component" />

        <TextInput
          v-model="column.title"
          label="Title"
          placeholder="Title of Column"
          @keydown.enter="column.id !== null ? updateColumn() : createColumn()" />
        <VSelect
          v-model="column.width"
          label="Width"
          :options="defaultColumnWidths"
          option-value="name"
          option-key="class" />

        <SettingToggle
          v-if="withRestrictEdit"
          v-model="column.restrict_edit"
          title="If enabled, this column can only be edited by those with writing-access on this event. Unrestricted columns can be edited by anyone with access to this event."
          label="Restrict edit" />

        <FieldListOptionEditor
          v-if="fieldHasOptions(column)"
          v-model:unsaved-option="unsavedOption"
          v-model:options="column.options" />
      </div>
    </CrudModal>
  </VTableCell>
</template>
