<script setup lang="ts">
import UrlInput from '@/components/Inputs/UrlInput.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { TermsOfUseContent } from '@/types/event-request';
import { ref } from 'vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';

type Props = {
  editMode: boolean;
  isTemplate: boolean;
  isNewRequest: boolean;
  content: TermsOfUseContent | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:content', arg: TermsOfUseContent): void;
}>();

const url = ref(props.content?.url || '');
const description = ref(props.content?.description || '');
const accepted = ref(props.content?.accepted || false);

const emitContent = () => {
  if (props.isTemplate) {
    emit('update:content', {
      url: url.value,
      description: description.value,
    });
  } else {
    emit('update:content', {
      accepted: accepted.value,
    });
  }
};

if (props.isTemplate && props.content) {
  emitContent();
}
</script>

<template>
  <div>
    <div class="mb-6">
      <p
        v-if="description && !isTemplate"
        class="sub-title">
        {{ description }}
      </p>
    </div>
    <div>
      <div
        v-if="!isTemplate"
        class="flex items-center gap-10">
        <a
          v-if="url && url.length > 1"
          :href="url"
          target="_blank">
          View Terms of Use
        </a>
        <div
          v-if="!isTemplate"
          :class="{ '[&_*]:text-pending': !accepted }"
          title="Must accept terms to be able to request this event."
          class="mt-2 flex max-w-fit items-center gap-5">
          <SettingCheck
            v-model="accepted"
            :can-edit="isNewRequest"
            :disabled="!isNewRequest"
            label="Accept"
            @update:model-value="emitContent" />
          <i
            v-if="!accepted"
            class="fa fa-fw fa-info-circle text-3xl"></i>
        </div>
      </div>
    </div>
    <div
      v-if="isTemplate"
      class="flex flex-col gap-4">
      <UrlInput
        v-model="url"
        label="Url to Terms of Use"
        @update:model-value="emitContent" />
      <TextareaInput
        v-model="description"
        placeholder="Description of terms of use"
        label="Description"
        @blur="emitContent" />
    </div>
  </div>
</template>
