<script setup lang="ts">
import NumberInput from '@/components/Inputs/NumberInput.vue';

type Props = {
  modelValue: string | number | null;
  isHidden?: boolean;
  canEdit?: boolean;
  required?: boolean;
  label?: string | null;
  labelPlacement?: string;
  size?: 'small' | 'medium' | 'large' | 'block';
  max?: number;
};

const props = withDefaults(defineProps<Props>(), {
  isHidden: false,
  required: false,
  canEdit: true,
  label: null,
  max: 100,
  labelPlacement: 'top',
  size: 'medium',
});

const emit = defineEmits<{
  (event: 'update:modelValue', arg: string): void;
  (event: 'focus'): void;
  (event: 'blur', arg: string): void;
}>();

const onBlur = (newNumber: string) => {
  if (newNumber === '' || newNumber === null || newNumber === undefined) {
    emit('blur', Number(0).toFixed(2));
    return;
  }

  emit('blur', String(parseFloat(newNumber) / 100));
};

const onModelValueUpdate = (newNumber: string) => {
  if (newNumber === '' || newNumber === null || newNumber === undefined) {
    emit('update:modelValue', Number(0).toFixed(2));
    return;
  }

  emit('update:modelValue', String(parseFloat(newNumber) / 100));
};

const getModelValue = () => {
  const newValue = props.modelValue ? Number(parseFloat(props.modelValue) * 100) : 0;

  if (newValue.toString().split('.')[1]?.length > 2) {
    return newValue.toFixed(2);
  }
  return newValue;
};
</script>

<template>
  <NumberInput
    :model-value="getModelValue()"
    icon-right="fa-percent"
    :required="required"
    :min="0"
    :max="max"
    :can-edit="canEdit"
    :is-hidden="isHidden"
    :label="label"
    :size="size"
    :with-decimals="true"
    :emit-null-value="false"
    :label-placement="labelPlacement"
    class="[&>div>div>input]:text-right"
    @focus="$emit('focus')"
    @blur="onBlur"
    @update:model-value="onModelValueUpdate" />
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
