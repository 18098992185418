export const auditableEventFields: { id: string; name: string; missing: string; type?: string }[] = [
  { id: 'venue_id', name: 'Venue', missing: 'No Venue' },
  { id: 'name', name: 'Event Name', missing: ' - ' },
  { id: 'start_date', name: 'Event Start Date', missing: ' - ' },
  { id: 'end_date', name: 'Event End Date', missing: ' - ' },
  { id: 'description', name: 'Description', missing: ' - ', type: 'field-text' },
];

export const auditableKeyContactFields = () => {
  return [
    { id: 'name', name: 'Name', missing: ' - ' },
    { id: 'email', name: 'Email', missing: ' - ' },
    { id: 'phone', name: 'Phone', missing: ' - ' },
    { id: 'country_code', name: 'Country Code', missing: ' - ' },
    { id: 'role', name: 'Role', missing: ' - ' },
    { id: 'contact_id', name: 'Connected Contact', missing: ' - ' },
  ];
};

export const auditableNotepadFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'order', name: 'Order', missing: ' - ' },
    {
      id: 'content',
      name: 'Content',
      missing: ' - ',
      type: 'field-rich-text',
    },
  ];
};

export const auditableShowTimeFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    {
      id: 'description',
      name: 'Description',
      missing: ' - ',
      type: 'field-text',
    },
    { id: 'start', name: 'Start', missing: ' - ' },
    { id: 'end', name: 'End', missing: ' - ' },
    { id: 'room_id', name: 'Room', missing: ' - ' },
  ];
};

export const auditableRoomBookingFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'confirmed', name: 'Confirm', missing: ' - ' },
    { id: 'start', name: 'Start', missing: ' - ' },
    { id: 'end', name: 'End', missing: ' - ' },
  ];
};

export const auditableEventFestivalPivotFields = () => {
  return [{ id: 'festival_type_id', name: 'Event Type', missing: ' - ' }];
};

export const auditableAssignmentFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    {
      id: 'description',
      name: 'Description',
      missing: ' - ',
      type: 'field-text',
    },
    { id: 'start', name: 'Start', missing: ' - ' },
    { id: 'end', name: 'End', missing: ' - ' },
  ];
};

export const auditableShiftFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'confirmed', name: 'Confirm', missing: ' - ' },
    { id: 'start', name: 'Start', missing: ' - ' },
    { id: 'end', name: 'End', missing: ' - ' },
    { id: 'shift_type_id', name: 'Shift Type', missing: ' - ' },
    { id: 'user_id', name: 'User', missing: ' - ' },
    { id: 'via_id', name: 'Via', missing: ' - ' },
    {
      id: 'description',
      name: 'Description',
      missing: ' - ',
      type: 'field-text',
    },
    { id: 'write', name: 'Write', missing: ' - ' },
    { id: 'accepted_at', name: 'Accepted', missing: ' - ' },
    { id: 'declined_at', name: 'Declined', missing: ' - ' },
    // { id: 'for_sale', name: 'For Sale', missing: ' - ' },
    { id: 'approved', name: 'Approved', missing: ' - ' },
    { id: 'place_id', name: 'Place', missing: ' - ' },
    { id: 'place_string', name: 'Place', missing: ' - ' },
    { id: 'in_timeline', name: 'In Timeline', missing: ' - ' },
    {
      id: 'notes',
      name: 'Notes',
      missing: ' - ',
      type: 'field-text',
    },
    { id: 'check_in', name: 'Checked In', missing: ' - ' },
    { id: 'check_out', name: 'Checked Out', missing: ' - ' },
    { id: 'time_slot_id', name: 'Time Slot', missing: ' - ' },
  ];
};

export const auditableEventShiftPivotFields = () => {
  return [{ id: 'event_id', name: 'Event', missing: ' - ' }];
};

export const auditablePerformanceFields = () => {
  return [
    { id: 'name', name: 'Performance Name', missing: ' - ' },
    { id: 'pseudonym', name: 'Performance Pseudonym', missing: ' - ' },
    { id: 'rating', name: 'Rating', missing: ' - ' },
    { id: 'confirmed', name: 'Confirmation', missing: ' - ' },
    { id: 'performance_type_id', name: 'Performance Type', missing: ' - ' },
  ];
};

export const auditablePerformanceInfoFieldPivotFields = () => {
  return [{ id: 'value', name: 'Value', missing: ' - ' }];
};

export const auditablePartnerContactPerformancePivotFields = () => {
  return [
    { id: 'partner_company_id', name: 'Company', missing: ' - ' },
    { id: 'performance_partner_type_id', name: 'Partner Type', missing: ' - ' },
    { id: 'primary', name: 'Primary', missing: 'False' },
    { id: 'travel_party', name: 'Travel Party', missing: 'False' },
    { id: 'send_email', name: 'Email Receiver', missing: 'False' },
    { id: 'role', name: 'Role', missing: ' - ' },
  ];
};

export const auditablePartnerCompanyPerformancePivotFields = () => {
  return [{ id: 'role', name: 'Role', missing: ' - ' }];
};

export const auditableTravelPlanFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'pieces_of_luggage', name: 'Pieces of Luggage', missing: ' - ' },
    { id: 'is_arrival', name: 'Is Arrival', missing: 'Is Departure' },
    { id: 'first_departure_time', name: 'First Departure Time', missing: ' - ' },
    {
      id: 'notes',
      name: 'Notes',
      missing: ' - ',
      type: 'field-text',
    },
    { id: 'contact_id', name: 'Contact', missing: ' - ' },
  ];
};

export const auditableTravelFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'departure_place', name: 'Departure Place', missing: ' - ' },
    { id: 'departure_time', name: 'Departure Time', missing: ' - ' },
    { id: 'arrival_place', name: 'Arrival Place', missing: ' - ' },
    { id: 'arrival_time', name: 'Arrival Time', missing: ' - ' },
    { id: 'travel_type_id', name: 'Travel Type', missing: ' - ' },
  ];
};

export const auditableTravelTicketFields = () => {
  return [
    { id: 'passenger_id', name: 'Passenger', missing: ' - ' },
    { id: 'booking_ref', name: 'Booking Reference', missing: ' - ' },
    { id: 'comments', name: 'Comments', missing: ' - ' },
  ];
};

export const auditableAccommodationFields = () => {
  return [
    { id: 'accommodation_place_id', name: 'Place', missing: ' - ' },
    { id: 'start_date', name: 'Start Date', missing: ' - ' },
    { id: 'end_date', name: 'End Date', missing: ' - ' },
    { id: 'check_in', name: 'Check In', missing: ' - ' },
    { id: 'check_out', name: 'Check Out', missing: ' - ' },
    { id: 'reference', name: 'Reference', missing: ' - ' },
    { id: 'booked_by', name: 'Booked by', missing: ' - ' },
    {
      id: 'notes',
      name: 'Notes',
      missing: ' - ',
      type: 'field-text',
    },
    { id: 'approved_at', name: 'Approved', missing: 'False' },
  ];
};

export const auditableAccommodationGuestFields = () => {
  return [
    { id: 'guest_id', name: 'Guest', missing: ' - ' },
    { id: 'reference', name: 'Reference', missing: ' - ' },
    {
      id: 'notes',
      name: 'Notes',
      missing: ' - ',
      type: 'field-text',
    },
  ];
};

export const auditableAccommodationAccommodationRoomPivotFields = () => {
  return [{ id: 'count', name: 'Count', missing: ' - ' }];
};

export const auditableEconomyFields = () => {
  return [
    { id: 'currency_id', name: 'Currency', missing: ' - ' },
    { id: 'conversion_rate', name: 'Conversion Rate', missing: ' - ' },
    { id: 'fee', name: 'Fee', missing: ' - ' },
    {
      id: 'fee_confirmed_at',
      name: 'Fee Confirmed',
      missing: 'False',
      notMissing: 'True',
    },
  ];
};

export const auditableEconomyAccountRowFields = () => {
  return [
    { id: 'cost_carrier_id', name: 'Cost Carrier', missing: ' - ' },
    { id: 'vat', name: 'VAT', missing: ' - ' },
    { id: 'fee', name: 'Fee', missing: ' - ' },
    { id: 'description', name: 'Description', missing: ' - ' },
    { id: 'budget_amount', name: 'Budget Amount', missing: ' - ' },
    { id: 'budget_conversion_rate', name: 'Budget Conversion Rate', missing: ' - ' },
    { id: 'budget_currency_id', name: 'Budget Currency', missing: ' - ' },

    { id: 'actual_amount', name: 'Actual Amount', missing: ' - ' },
    { id: 'actual_conversion_rate', name: 'Actual Conversion Rate', missing: ' - ' },
    { id: 'actual_currency_id', name: 'Actual Currency', missing: ' - ' },

    {
      id: 'paid_at',
      name: 'Marked as Paid',
      missing: 'False',
      notMissing: 'True',
    },
    {
      id: 'confirmed_at',
      name: 'Marked as Confirmed',
      missing: 'False',
      notMissing: 'True',
    },
    {
      id: 'include_in_settlement',
      name: 'Include in Settlement',
      missing: 'False',
    },
    {
      id: 'notes',
      name: 'Notes',
      missing: ' - ',
      type: 'field-text',
    },
  ];
};

export const auditableEconomyArtistFeeRowFields = () => {
  return [
    { id: 'account_id', name: 'Account', missing: ' - ' },
    { id: 'description', name: 'Description', missing: ' - ' },
    { id: 'vat', name: 'VAT', missing: ' - ' },
    { id: 'artist_tax', name: 'Artist Tax', missing: ' - ' },
    { id: 'percentage', name: 'Percent of Fee', missing: ' - ' },
    { id: 'currency_id', name: 'Currency', missing: ' - ' },
    { id: 'amount', name: 'Amount', missing: ' - ' },
    { id: 'conversion_rate', name: 'Conversion Rate', missing: ' - ' },
    { id: 'due_date', name: 'Due Date', missing: ' - ' },
    {
      id: 'paid_at',
      name: 'Marked as Paid',
      missing: 'False',
      notMissing: 'True',
    },
    {
      id: 'confirmed_at',
      name: 'Marked as Confirmed',
      missing: 'False',
      notMissing: 'True',
    },
  ];
};

export const auditableDocumentFields = () => {
  return [{ id: 'filename', name: 'File Name', missing: ' - ' }];
};

export const auditableMetaDataFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'show_time_id', name: 'Show Time', missing: ' - ' },
  ];
};

export const auditableMetaDataFieldFields = () => {
  return [{ id: 'value', name: 'Value', missing: ' - ' }];
};

export const auditableCheckListFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'start', name: 'Start', missing: ' - ' },
    {
      id: 'description',
      name: 'Description',
      missing: ' - ',
      type: 'field-text',
    },
    {
      id: 'locked',
      name: 'Locked',
      missing: 'False',
    },
  ];
};

export const auditableCheckListRowFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    {
      id: 'description',
      name: 'Description',
      missing: ' - ',
      type: 'field-text',
    },
    {
      id: 'input',
      name: 'Notes',
      missing: ' - ',
      type: 'field-text',
    },
    {
      id: 'done',
      name: 'Done',
      missing: 'False',
    },
  ];
};

export const auditableInvoiceBasisFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'total', name: 'Total', missing: ' - ' },
    { id: 'with_vat', name: 'With VAT', missing: ' - ' },
  ];
};

export const auditableInvoiceRowFields = () => {
  return [
    { id: 'title', name: 'Title', missing: ' - ' },
    { id: 'quantity', name: 'Quantity', missing: ' - ' },
    { id: 'price', name: 'price', missing: ' - ' },
    { id: 'total', name: 'Total', missing: ' - ' },
    { id: 'vat', name: 'VAT', missing: ' - ' },
    { id: 'discount', name: 'Discount', missing: ' - ' },
    { id: 'category_id', name: 'Category', missing: ' - ' },
    { id: 'is_cost', name: 'Is Cost', missing: 'False' },
  ];
};

export const auditableAccreditationFields = () => {
  return [
    { id: 'comments', name: 'Comments', missing: ' - ' },
    { id: 'collected_at', name: 'Collected At', missing: ' - ' },
    { id: 'approved_at', name: 'Approved At', missing: ' - ' },
    { id: 'start', name: 'Start', missing: ' - ' },
    { id: 'end', name: 'End', missing: ' - ' },
    { id: 'accreditation_level_id', name: 'Accreditation Level', missing: ' - ' },
  ];
};
