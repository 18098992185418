<script setup lang="ts">
import { useToast } from 'vue-toastification';
import { nextTick, onMounted, ref } from 'vue';
import { useSmallScreen } from '@/composables/use-small-screen';

const { isSmallScreen } = useSmallScreen();

const resendVerificationEmail = () => {
  axios
    .post('/api/profile/resend-email-verification')
    .then((res) => {
      useToast().success(res.data.message);
    })
    .catch((error) => {
      useToast().error(error.response.data.message);
    });
};

const wrapper = ref<HTMLDivElement | null>(null);

onMounted(async () => {
  await nextTick();
  // setter height on Mounted. Tror det blir litt for mye å skulle sjekke på size endringer
  const h = wrapper.value?.clientHeight ?? 36;
  document.documentElement.style.setProperty('--verification-banner', `${h}px`);
});
</script>

<template>
  <div
    ref="wrapper"
    class="relative bg-pending py-3 lg:py-2 px-edge">
    <div class="mx-auto flex md:flex-col justify-between gap-1 lg:flex-row lg:items-center items-center lg:gap-3">
      <div
        class="flex lg:px-0 px-3 flex-col gap-2 justify-start text-balance text-sm text-black lg:flex-row lg:items-center lg:text-pretty">
        <i class="!hidden lg:!inline-block far fa-fw fa-circle-exclamation"></i>
        <span class="inline-block font-semibold">Verify your email address</span>
        <span
          v-if="!isSmallScreen"
          class="mx-3 lg:inline-block hidden">
          &middot;
        </span>
        <div
          v-if="!isSmallScreen"
          class="inline-block text-sm invisible md:visible">
          To access all features, please verify your email address, check your inbox for the verification email.
        </div>
      </div>
      <div class="flex flex-row items-center justify-between gap-3 lg:justify-end">
        <button
          class="inline-flex items-center border border-black md:border-transparent text-sm font-semibold rounded-md text-black lg:text-black/80 duration-150 hover:bg-white/20 p-3 lg:-mx-3 leading-none"
          @click="resendVerificationEmail">
          <span class="whitespace-nowrap">Resend verification email</span>
        </button>
      </div>
    </div>
  </div>
</template>
