<script setup lang="ts">
import type { ShowTime } from '@/types/event-request';
import moment from 'moment';
import ShowTimeEditor from '@/components/ShowTimes/ShowTimeEditor.vue';
import { dateTimeFormat } from '@/variables/date-format';
import { createUuId, sortArrayByTime } from '@/util/globals';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { computed, nextTick, ref, watch } from 'vue';
import VButton from '@/components/Inputs/VButton.vue';
import { changeAndFormatStamp, getDiffInInterval, timeStampsAreSame } from '@/util/timeFunctions';
import { useSmallScreen } from '@/composables/use-small-screen';

type Props = {
  editMode: boolean;
  isTemplate: boolean;
  isNewRequest: boolean;
  eventStart?: string | null;
  eventEnd?: string | null;
  content: ShowTime[] | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:content', args: ShowTime[]): void;
}>();

const canSave = computed(() => props.eventStart && props.editMode);
const { isSmallScreen } = useSmallScreen();

const showTimes = ref([
  ...(props.content?.map((s) => ({
    title: s.title,
    start: s.start,
    end: s.end,
    editing: false,
    id: createUuId('row_'),
  })) ?? []),
]);

watch(
  showTimes,
  (newVal) => {
    if (canSave.value && props.isNewRequest) {
      emit(
        'update:content',
        newVal.map((showTime: ShowTime) => {
          return {
            title: showTime.title,
            start: showTime.start,
            end: showTime.end,
          };
        })
      );
    }
  },
  { deep: true }
);

watch(
  () => props.eventStart,
  (newVal, oldVal) => {
    if (!timeStampsAreSame(newVal, oldVal, 'day')) {
      const diff = getDiffInInterval(newVal, oldVal, 'days', false);
      showTimes.value.forEach((show) => {
        show.start = changeAndFormatStamp({
          stamp: show.start,
          addDays: diff,
        });
        show.end = changeAndFormatStamp({
          stamp: show.end,
          addDays: diff,
        });
      });
    }
  }
);

const sortShowTimes = () => {
  nextTick(() => {
    showTimes.value = sortArrayByTime(showTimes.value);
  });
};

const emitShowTimes = () => {
  emit(
    'update:content',
    showTimes.value.map((showTime) => {
      return {
        title: showTime.title,
        start: showTime.start,
        end: showTime.end,
      };
    })
  );
};

const addShowTime = () => {
  let s = '';
  let e = '';

  if (showTimes.value.length === 0) {
    s = props.eventStart;
    e = moment(props.eventStart).add(1, 'hours').format(dateTimeFormat);
  } else {
    const lastObject = showTimes.value[showTimes.value.length - 1];
    const start = moment(lastObject.end);
    const duration = moment.duration(moment(lastObject.end).diff(lastObject.start));
    const end = start.clone().add(duration.asMinutes(), 'minutes');
    s = start.format(dateTimeFormat);
    e = end.format(dateTimeFormat);
  }
  showTimes.value.push({
    id: null,
    start: s,
    end: e,
    editing: true,
    title: '',
  });
  sortShowTimes();

  if (!props.isNewRequest) {
    emitShowTimes();
  }
};
</script>

<template>
  <h4
    v-if="isTemplate"
    class="text-textColor-soft">
    Show Times will come here.
  </h4>
  <div v-else>
    <InputLabel
      v-if="showTimes.length > 0"
      label="Show Times" />
    <div
      :class="{
        '[&_.show-time-editor-display-container]:flex-col [&_.show-time-editor-display-container]:items-start [&_.show-time-editor-display-container_.button-container-for-show-time-editor]:m-auto	[&_.show-time-editor-display-container>.flex]:flex-col':
          isSmallScreen,
      }"
      class="striped-children flex flex-col gap-3 [&_.duration-selector]:col-span-2">
      <ShowTimeEditor
        v-for="(show, index) in showTimes"
        :key="show.id"
        :show-time="show"
        :start-editing="isNewRequest"
        :earliest-date="eventStart"
        :latest-date="eventEnd"
        :can-edit="canSave"
        @delete="[showTimes.splice(showTimes.indexOf(show), 1), emitShowTimes()]"
        @update:show-time="[(showTimes[index] = $event), sortShowTimes($event), emitShowTimes()]" />
    </div>
    <VButton
      v-if="editMode && isNewRequest"
      :disabled="!canSave"
      disabled-tool-tip-text="Cannot add show time before the event has a name and a start."
      type="create"
      icon="fa-plus"
      size="small"
      title="Add Show Time"
      class="float-right mt-5"
      @click="addShowTime" />

    <div
      v-if="!isNewRequest && !showTimes.length"
      class="text-center italic text-textColor-soft">
      No shows created.
    </div>
  </div>
</template>
