import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import { FormModelType, FormResource } from '@/types/form';
import { getForms } from '@/services/api-form';

export const formsStore = defineStore('forms-store', () => {
  const list = ref<Map<string, FormResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: FormModelType, modelId: number) => {
    if (model === 'GlobalModel') return;

    Echo.join(`On.${model}.${modelId}`)
      .listen(`.form.created`, async () => {
        await fetchForms(model, modelId, true);
      })
      .listen(`.form.updated`, async () => {
        await fetchForms(model, modelId, true);
      })
      .listen(`.form.deleted`, async () => {
        await fetchForms(model, modelId, true);
      });
  };

  const fetchForms = async (model: FormModelType, modelId: number, force: boolean = false) => {
    if (!force && list.value.has(formatModel(model, modelId))) return list.value.get(formatModel(model, modelId));

    loading.value = true;

    const { data } = await getForms(model, modelId);

    if (!list.value.has(formatModel(model, modelId))) {
      listenForBroadcast(model, modelId);
    }

    if (model !== 'GlobalModel') list.value.set(formatModel(model, modelId), data);

    loading.value = false;

    return data;
  };

  const cleanUp = () => {
    list.value.forEach((_, key) => {
      if (key.includes('Event_')) {
        list.value.delete(key);
      }
    });
  };

  return {
    list,
    loading,
    cleanUp,
    fetchForms,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(formsStore, import.meta.hot));
}
