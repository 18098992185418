<script lang="ts" setup>
import { nextTick, ref } from 'vue';

type Props = {
  pageX?: number | null;
  pageY?: number | null;
  modelValue?: string | null;
  containerClasses?: string | null;
  containerStyle?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  pageX: null,
  pageY: null,
  modelValue: null,
  containerClasses: 'rounded border bg-backgroundColor p-4',
  containerStyle: '',
});

defineEmits<{
  closed: [];
}>();

const open = ref(false);
const marginLeft = ref(0);
const marginTop = ref(0);
const container = ref<HTMLElement | null>(null);

const setPosition = () => {
  if (!container.value) return;
  const rect = container.value.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  if (rect.right > viewportWidth) {
    marginLeft.value = viewportWidth - rect.right - 20;
  }
  if (rect.bottom > viewportHeight) {
    marginTop.value = viewportHeight - rect.bottom - 20;
  }
};
const openDropdown = async () => {
  if (!props.pageX || !props.pageY) return;
  open.value = false;
  await nextTick();
  open.value = true;
  await nextTick();
  setPosition();
};
openDropdown();
</script>
<template>
  <div v-if="open">
    <div
      v-if="open"
      id="backdrop__floating-window"
      class="fixed left-0 top-0 z-[1002] h-full w-full"
      @click="[(open = false), $emit('closed')]" />
    <div
      v-if="open"
      ref="container"
      :style="`left: ${pageX}px; top: ${pageY}px;${containerStyle}; margin-left: ${marginLeft}px; margin-top: ${marginTop}px`"
      :class="containerClasses"
      class="fixed z-[1003] shadow-md">
      <slot />
    </div>
  </div>
</template>
