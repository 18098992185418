<script setup lang="ts">
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import VButton from '@/components/Inputs/VButton.vue';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { computed, ref, watch } from 'vue';
import { formatStampAsDate } from '@/util/timeFunctions';
import { copyObject } from '@/util/object-helpers';

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
    required: false,
  },
  canEdit: {
    type: Boolean,
    default: false,
    required: false,
  },
  withIcon: {
    type: Boolean,
    default: true,
    required: false,
  },
  allowNull: {
    type: Boolean,
    default: true,
    required: false,
  },
  mode: {
    type: String,
    default: null,
    required: false,
  },
});

const emit = defineEmits(['blur']);

const year = ref(null);
const month = ref(null);
const day = ref(null);

const setupDob = () => {
  if (props.modelValue) {
    if (props.mode === 'dob') {
      year.value = formatStampAsDate(props.modelValue, 'YYYY');
      month.value = Number(formatStampAsDate(props.modelValue, 'MM'));
      day.value = Number(formatStampAsDate(props.modelValue, 'DD'));
    }
  }
};
setupDob();
watch(
  () => props.modelValue,
  () => setupDob()
);

const months = [
  { id: 1, name: 'January', days: 31 },
  { id: 2, name: 'February', days: 29 },
  { id: 3, name: 'March', days: 31 },
  { id: 4, name: 'April', days: 30 },
  { id: 5, name: 'May', days: 31 },
  { id: 6, name: 'June', days: 30 },
  { id: 7, name: 'July', days: 31 },
  { id: 8, name: 'August', days: 31 },
  { id: 9, name: 'September', days: 30 },
  { id: 10, name: 'October', days: 31 },
  { id: 11, name: 'November', days: 30 },
  { id: 12, name: 'December', days: 31 },
];

const days = computed(() => {
  return Array.from({ length: 31 }, (_, index) => index + 1);
});

const checkIfDobDone = () => {
  if (!props.mode === 'dob') return;
  if (!props.canEdit) return;
  if (!year.value || !month.value || !day.value || year.value < 1800) {
    if (props.modelValue) emit('blur', null);
    return;
  }
  const date = new Date(year.value, month.value - 1, day.value, 0, 0, 0, 0);
  emit('blur', formatStampAsDate(date));
};

const yearKey = ref(0);
watch(year, () => {
  if (!year.value) return;
  const trim = copyObject(year.value);
  if (trim !== year.value) {
    year.value = trim;
    yearKey.value++;
  }
  if (year.value.toString().length > 4) {
    year.value = Number(year.value.toString().slice(0, 4));
    yearKey.value++;
  }
});
</script>

<template>
  <template v-if="mode === 'dob'">
    <div class="grid gap-5 grid-cols-[1fr_1fr_1fr_50px] items-center [&_label]:font-normal">
      <VSelect
        v-model="day"
        placeholder="Day"
        :options="days"
        :can-edit="canEdit"
        @update:model-value="checkIfDobDone" />
      <VSelect
        v-model="month"
        placeholder="Month"
        :options="months"
        :can-edit="canEdit"
        @update:model-value="checkIfDobDone" />
      <NumberInput
        :key="yearKey"
        v-model="year"
        size="block"
        placeholder="Year"
        :can-edit="canEdit"
        @blur="checkIfDobDone" />
      <div>
        <VButton
          v-if="(year || month || day) && canEdit"
          size="extra-small"
          icon="fa-times"
          type="warning"
          class="btn-outline"
          @click="[(year = null), (month = null), (day = null), checkIfDobDone()]"></VButton>
      </div>
    </div>
  </template>
  <VDatepicker
    v-else
    :model-value="modelValue"
    :clearable="allowNull"
    :can-edit="canEdit"
    :label="null"
    :always-show-clear="false"
    :with-icon="withIcon"
    @update:model-value="$emit('blur', $event)" />
</template>
