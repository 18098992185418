export type AssignmentResource = {
  id: string;
  title: string;
  description?: string;
  resourceIds: string[];
  start: string | null;
  end: string | null;
  durationEditable?: boolean;
  color: string;
  editable: boolean;
  resourceEditable: boolean;
  slug: string | null;
  viewableIn: string[];
  recurring_original_id?: number | null;
  display?: string;
  className?: string;
  resourceId?: number;
};

export const getAssignments = async (eventId: number, start?: string, end?: string) => {
  const url = '/api/assignments/';
  return axios.get<AssignmentResource[]>(url, {
    params: {
      start,
      end,
      event_id: eventId,
    },
  });
};

type AssignmentsData = {
  title: string;
  description: string;
  start: string;
  end: string | null;
  is_global?: boolean;
  assignables: {
    id: number;
    type: string;
    context: string;
  }[];
};
export const postAssignment = async (eventId: number, data: AssignmentsData) => {
  const url = '/api/assignments/';
  return axios.post<AssignmentResource>(url, {
    ...data,
    event_id: eventId,
  });
};

export const destroyAssignment = async (id: number, global: boolean) => {
  const url = `/api/assignments/${id}`;
  return axios.delete<string>(url, {
    params: {
      global,
    },
  });
};

export const patchAssignment = async (id: number, eventId: number, data: AssignmentsData) => {
  const url = `/api/assignments/${id}`;
  return axios.patch<AssignmentResource>(url, data);
};

export const undoAssignment = async (id: number) => {
  const url = `/api/assignments/${id}/undo`;
  return axios.post(url);
};
