<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal';
import { ZIndexPopUpModal } from '@/variables/z-indexes';
import { getKey } from '@/util/globals';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

type Button = {
  class: string;
  title: string;
  emit: string;
  style?: string;
  icon?: string;
};

type Props = {
  title: string;
  content?: string | null;
  titleHighlight?: string | string[];
  buttons?: Button[];
  overrideClasses?: string;
};
const props = withDefaults(defineProps<Props>(), {
  title: '',
  content: null,
  titleHighlight: '',
  buttons: () => [
    {
      class: 'btn',
      title: 'Cancel',
      emit: 'cancel',
    },
    {
      class: 'btn-warning',
      title: 'Confirm',
      emit: 'confirm',
    },
  ],
  overrideClasses: '',
});

defineEmits<{
  (event: 'cancel'): void;
  (event: 'close'): void;
  (event: 'keep'): void;
  (event: 'confirm', close: () => void): void;
  (event: 'sure', close: () => void): void;
  (event: 'remove', close: () => void): void;
  (event: 'first', close: () => void): void;
  (event: 'second', close: () => void): void;
  (event: 'third', close: () => void): void;
  (event: 'forth', close: () => void): void;
  (event: 'fifth', close: () => void): void;
  (event: 'pdf', close: () => void): void;
  (event: 'csv', close: () => void): void;
}>();

const getTitle = () => {
  let title = props.title;

  const replaceWord = (string: string, word: string) => {
    return string.replace(word, `<h2 class="text-highlight mx-2">${word}</h2>`);
  };

  if (props.titleHighlight) {
    switch (typeof props.titleHighlight) {
      case 'object': {
        for (let i = 0; i < props.titleHighlight.length; i++) {
          title = replaceWord(title, props.titleHighlight[i]);
        }
        break;
      }
      case 'string': {
        title = replaceWord(title, props.titleHighlight);
        break;
      }
      default: {
        break;
      }
    }
  }
  return title;
};

const setIndex = ({ index }: { index: number }) => {
  if (index === 0) return ZIndexPopUpModal;
  return ZIndexPopUpModal + 2 * index;
};
</script>

<template>
  <VueFinalModal
    :content-transition="{
      'enter-active-class': 'transition duration-300 ease-in-out transform',
      'enter-from-class': 'scale-0 opacity-0',
      'enter-to-class': '',
      'leave-active-class': 'transition duration-200 ease-in-out transform',
      'leave-to-class': 'opacity-0',
      'leave-from-class': '',
    }"
    overlay-class="bg-[--color-backdrop-modal]"
    class="flex items-center justify-center"
    :z-index-fn="setIndex"
    :content-class="`bg-backgroundColor rounded border w-[400px] text-textColor max-h-full ${overrideClasses}`"
    @click-outside="$emit('cancel')">
    <div class="relative m-2 p-5">
      <div class="grid grid-cols-[auto_30px] items-center">
        <h2 class="flex-1 font-headers">
          <h2
            v-if="title"
            class="flex font-headers"
            v-html="safeHtmlStringify(getTitle())" />
        </h2>

        <button
          class="float-right h-8 w-8 rounded-full hover:bg-backgroundColor-content"
          style="margin-right: -23px; margin-top: -10px"
          @click.stop="$emit('cancel')">
          <i class="fa fa-fw fa-times" />
        </button>
      </div>
      <div
        v-if="content"
        class="pb-8 pt-4 text-base"
        v-html="safeHtmlStringify(content)" />
      <div class="mt-3 flex justify-between gap-2">
        <button
          v-for="button in buttons"
          :key="button.title"
          class="min-w-[75px] truncate px-4"
          :title="getKey(button, 'title')"
          :class="getKey(button, 'class')"
          :style="getKey(button, 'style')"
          @click.stop="$emit(button.emit)">
          <i
            v-if="getKey(button, 'icon') !== null"
            :class="getKey(button, 'icon')"
            class="fa fa-fw"></i>
          {{ button.title }}
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>
