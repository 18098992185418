import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import { getRunningOrders } from '@/services/api-running-order';
import { RunningOrderModelType } from '@/types/global';
import { RunningorderResource } from '@/types/runningorder';

export const runningorderStore = defineStore('runningorder-store', () => {
  const list = ref<Map<string, RunningorderResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: RunningOrderModelType, modelId: number) => {
    Echo.join(`On.${model}.${modelId}`)
      .listen(`.runningOrder.created`, async () => {
        await fetchRunningorder(model, modelId, true);
      })
      .listen(`.runningOrder.updated`, async () => {
        await fetchRunningorder(model, modelId, true);
      })
      .listen(`.runningOrder.deleted`, async () => {
        await fetchRunningorder(model, modelId, true);
      });
  };

  const fetchRunningorder = async (model: RunningOrderModelType, modelId: number, force: boolean = false) => {
    if (!force && list.value.has(formatModel(model, modelId))) return list.value.get(formatModel(model, modelId));

    loading.value = true;

    const { data } = await getRunningOrders(model, modelId);

    if (!list.value.has(formatModel(model, modelId)) && model !== 'GlobalModel') {
      listenForBroadcast(model, modelId);
    }

    if (model !== 'GlobalModel') list.value.set(formatModel(model, modelId), data);

    loading.value = false;

    return data;
  };

  return {
    list,
    loading,
    fetchRunningorder,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(runningorderStore, import.meta.hot));
}
