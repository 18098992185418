<script setup lang="ts">
import { GroupMinimalResource } from '@/types/group';
import CrudModal from '@/components/Modals/CrudModal.vue';
import GroupSelect from '@/components/GroupSelect.vue';
import { PartnerCompanyResource } from '@/types/partners';

type Props = {
  partnerCompany: PartnerCompanyResource;
};

const props = withDefaults(defineProps<Props>(), {
  initEventGroup: null,
});

const emit = defineEmits<{
  (event: 'connected', ...args: PartnerCompanyResource): void;
  (event: 'closed'): void;
}>();

const onSelectGroup = async (group: GroupMinimalResource) => {
  await axios.patch(`/api/partners/companies/${props.partnerCompany.id}`, {
    group_id: group.id,
  });
  emit('connected', { ...props.partnerCompany, group_id: group.id });
};
</script>
<template>
  <CrudModal
    small
    only-close-button
    :title="'Connect ' + partnerCompany.name + ' to Group'"
    :title-highlight="[partnerCompany.name]"
    @closed="$emit('closed')">
    <GroupSelect
      :with-project-leaders="false"
      :init-search-string="partnerCompany.name"
      @selected-group="onSelectGroup" />
  </CrudModal>
</template>
