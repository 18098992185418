import { formatModel } from '@/util/store-helpers';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useGuestListSettingsStore = defineStore('guest-list-settings', () => {
  const list = ref<Map<string, any[]>>(new Map());
  const loading = ref(false);

  const fetchGuestListSettings = async (modelType: string, modelId: number) => {
    if (modelType.includes('App\\')) throw new Error('modelType should not include App\\');

    loading.value = true;
    const { data } = await axios.get('/api/guest-list-settings', {
      params: {
        model_type: `App\\${modelType}`,
        model_id: modelId,
      },
    });
    list.value.set(formatModel(modelType, modelId), data);
    loading.value = false;
  };

  return {
    list,
    loading,
    fetchGuestListSettings,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGuestListSettingsStore, import.meta.hot));
}
