<script lang="ts" setup>
import { inject, reactive, ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { groupCreatedEventsCount } from '@/provide/keys';

const props = defineProps({
  eventStartDate: {
    type: String,
    required: true,
  },
  eventId: {
    type: Number,
    required: true,
  },
  eventName: {
    type: String,
    required: true,
  },
  isSimple: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasPartners: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasMetaData: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasShifts: {
    type: Boolean,
    required: false,
    default: false,
  },
  isVenue: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'duplicated'): void;
}>();

const elementsToDuplicate = reactive({
  invites: true,
  assignments: true,
  room_bookings: true,
  boards: true,
  forms: true,
  running_orders: true,
  show_times: true,
  with_key_contacts: true,
  shifts: true,
  shiftsAssigned: false,
  metaDataValues: false,
  withPartners: false,
});
const duplicateDates = ref<string[]>([]);
const createdEventsCount = inject(groupCreatedEventsCount);

const loading = ref(false);

const duplicate = async (close: () => void) => {
  loading.value = true;

  for (let i = 0; i < duplicateDates.value.length; i++) {
    await duplicateDate(duplicateDates.value[i]);
  }
  useToast().success('Duplicated event');
  emit('duplicated');
  loading.value = false;
  close();
};
const duplicateDate = async (date) => {
  await axios.post(`/api/events/${props.eventId}/duplicate`, {
    date,
    with_invites: !props.isSimple ? elementsToDuplicate.invites : false,
    with_assignments: !props.isSimple ? elementsToDuplicate.assignments : false,
    with_room_bookings: !props.isSimple ? elementsToDuplicate.room_bookings : true,
    with_boards: !props.isSimple ? elementsToDuplicate.boards : false,
    with_forms: !props.isSimple ? elementsToDuplicate.forms : false,
    with_runningorders: !props.isSimple ? elementsToDuplicate.running_orders : false,
    with_show_times: !props.isSimple ? elementsToDuplicate.show_times : false,
    with_key_contacts: !props.isSimple ? elementsToDuplicate.with_key_contacts : false,
    with_shifts: !props.isSimple ? elementsToDuplicate.shifts : false,
    with_shifts_assigned: elementsToDuplicate.shifts && !props.isSimple ? elementsToDuplicate.shiftsAssigned : false,
    with_meta_data_values: !props.isSimple ? elementsToDuplicate.metaDataValues : false,
    with_partners: props.isSimple ? true : elementsToDuplicate.withPartners,
  });
  createdEventsCount?.eventCreated();
};
</script>

<template>
  <CrudModal
    :title="`Duplicate ${eventName}`"
    :title-highlight="eventName"
    :create-button-text="'Duplicate (' + duplicateDates.length + ')'"
    :disabled="!duplicateDates.length"
    :loading="loading"
    @create="duplicate"
    @closed="$emit('closed')">
    <div class="grid grid-cols-[1fr_295px] gap-2">
      <div
        v-if="!isSimple"
        class="flex flex-col gap-5 [&>div>h4]:text-highlight [&>div]:flex [&>div]:flex-col [&>div]:gap-2">
        <div>
          <h4>Event elements</h4>
          <SettingCheck
            v-model="elementsToDuplicate.assignments"
            label="Assignments" />
          <SettingCheck
            v-model="elementsToDuplicate.boards"
            label="Boards" />
          <SettingCheck
            v-model="elementsToDuplicate.forms"
            label="Forms" />
          <SettingCheck
            v-model="elementsToDuplicate.running_orders"
            label="Running Orders" />
          <SettingCheck
            v-model="elementsToDuplicate.show_times"
            label="Show Times" />
          <SettingCheck
            v-model="elementsToDuplicate.with_key_contacts"
            label="Key Contacts" />
        </div>
        <div>
          <h4>People</h4>
          <SettingCheck
            v-model="elementsToDuplicate.invites"
            label="Invites" />
          <SettingCheck
            v-if="hasShifts"
            v-model="elementsToDuplicate.shifts"
            label="Shifts" />
          <SettingCheck
            v-if="hasShifts"
            v-model="elementsToDuplicate.shiftsAssigned"
            :disabled="!elementsToDuplicate.shifts"
            label="Assign Shift(s)" />
        </div>
        <div v-if="hasMetaData || hasPartners || isVenue">
          <h4>Internal</h4>
          <SettingCheck
            v-if="isVenue"
            v-model="elementsToDuplicate.room_bookings"
            label="Room Bookings" />
          <SettingCheck
            v-if="hasMetaData"
            v-model="elementsToDuplicate.metaDataValues"
            label="Meta Data Field Values" />
          <SettingCheck
            v-if="hasPartners"
            v-model="elementsToDuplicate.withPartners"
            title="If enabled, contacts and companies added to the event will be included when duplicating."
            label="Contacts & Companies" />
        </div>
      </div>
      <div class="flex flex-col items-center">
        <VDatepicker
          v-model="duplicateDates"
          inline
          :highlight-days="[eventStartDate]"
          :start-date="eventStartDate"
          multi-dates />
        <div class="mt-2 w-full">
          <VTable>
            <VTableRow
              v-for="(date, index) in duplicateDates"
              :key="date">
              <VTableCell main-cell>{{ date }}</VTableCell>
              <VTableCell style="width: 50px">
                <VButton
                  type="btn-in-table"
                  icon="fa-trash"
                  @click="duplicateDates.splice(index, 1)" />
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>
      </div>
    </div>
  </CrudModal>
</template>
