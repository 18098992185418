<script lang="ts" setup>
import { inject, onBeforeUnmount, onMounted, Ref, ref, watch } from 'vue';
import ContentHeader from '@/components/Content/ContentHeader.vue';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import { getQueryString, shouldBeOpened, updateQueryString } from '@/util/query-helpers';
import type { Action } from '@/components/Inputs/Components/ActionButtons.vue';
import ActionButtons from '@/components/Inputs/Components/ActionButtons.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { adminSameTabClickKey, extraPixelsOverContentContainer } from '@/provide/keys';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

type Props = {
  savingIssue?: boolean;
  loading?: boolean;
  canEdit?: boolean;
  editMode?: boolean;
  fullSize?: boolean;
  startOpen?: boolean;
  title?: string | null;
  superHeader?: string | null;
  preIcon?: string | null;
  postIcon?: string | null;
  withBorder?: boolean;
  actionsInPrimaryHeader?: boolean;
  justContentWithoutHeader?: boolean;
  actions?: Action[];
  actionsAsButtons?: boolean | null;
  overrideQueryString?: string | null;
  extraHeight?: number;
  alignCenter?: boolean | null;
  withSecondBorder?: boolean;
  contentClass?: string;
  fullSizePageContainerClasses?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  savingIssue: false,
  loading: false,
  canEdit: false,
  editMode: false,
  startOpen: false,
  fullSize: true,
  title: null,
  overrideQueryString: null,
  superHeader: null,
  preIcon: null,
  postIcon: null,
  withBorder: false,
  actionsInPrimaryHeader: false,
  justContentWithoutHeader: false,
  actions: () => [],
  actionsAsButtons: true,
  extraHeight: 0,
  alignCenter: false,
  withSecondBorder: true,
  contentClass: undefined,
  fullSizePageContainerClasses: null,
});

const emit = defineEmits<{
  (event: 'open', value: boolean): void;
  (event: 'update:editMode', value: boolean): void;
  (event: 'edit'): void;
}>();

const sameTabClick = inject(adminSameTabClickKey, null) as Ref<boolean>;

const widthOfContent = inject('widthOfContent', 100);
const open = ref(false);
const queryString = getQueryString(props.overrideQueryString);
const { isSmallScreen } = useSmallScreen();

const toggleOpen = (forceOpen = false) => {
  open.value = forceOpen ? true : !open.value;
  emit('open', open.value);
  updateQueryString(queryString, open.value);
};

if (sameTabClick) {
  watch(sameTabClick, () => {
    open.value = false;
    emit('open', false);
    updateQueryString(queryString, false);
  });
}

onMounted(() => {
  if (shouldBeOpened(queryString)) toggleOpen(true);
  if (props.startOpen) toggleOpen(true);
});
onBeforeUnmount(() => {
  if (queryString) updateQueryString(null, false);
});

const extraSizeOverContentContainer = inject(extraPixelsOverContentContainer, ref(0));

const height = CSS.supports('height: 100dvh') ? '100dvh' : '100vh';
</script>

<template>
  <div
    v-if="!justContentWithoutHeader"
    :class="{ 'px-edge': !open }">
    <div
      class="hover:border-borderColor-strong border hover:shadow-md rounded-md main-header mt-[1px] grid cursor-pointer hover:bg-row-hover [&:hover_.text-textColor-soft]:text-textColor"
      :class="!fullSize && open ? '' : withBorder ? 'shadow-border' : ''"
      @click="toggleOpen(false)">
      <div class="flex justify-between">
        <div
          class="w-full py-3"
          :class="!fullSize || preIcon ? '  grid grid-cols-[75px_auto] ' : ' flex flex-1 py-5 pl-edge [&>div]:w-full'">
          <div
            v-if="!fullSize || preIcon"
            class="px-edge py-5 text-center flex items-center justify-center">
            <ChevronToggle
              v-if="!fullSize"
              :model-value="open"
              :loading="loading"
              classes="text-textColor-soft hover:text-highlight" />
            <i
              v-else-if="preIcon"
              class="fa fa-fw"
              :class="preIcon" />
          </div>
          <div class="flex flex-col gap-2 justify-center">
            <div
              v-if="props.superHeader"
              class="sub-title mb-[-5px] uppercase text-highlight font-headers">
              {{ props.superHeader }}
            </div>

            <slot name="title">
              <div class="flex items-center gap-5 font-headers">
                <h3>{{ title }}</h3>
                <div
                  v-if="postIcon"
                  class="text-center">
                  <i
                    class="fa fa-fw"
                    :class="postIcon" />
                </div>
              </div>
            </slot>

            <slot name="underHeader" />
          </div>
        </div>
        <div>
          <template v-if="open && actions && !fullSize && actionsInPrimaryHeader">
            <div
              v-if="actionsAsButtons"
              class="h-full pr-edge">
              <ActionButtonGroup :actions="actions" />
            </div>
            <div v-else>
              <ActionButtons
                inline
                :actions="actions" />
            </div>
          </template>
        </div>
        <div
          v-if="!open && fullSize"
          class="flex h-full items-center pr-5">
          <i class="fa fa-fw fa-chevron-right text-textColor-soft"> </i>
        </div>
      </div>
    </div>
    <div
      v-if="open && !fullSize"
      class="shadow-border slide-down-content-container">
      <slot name="slideDownContent" />
    </div>
    <div
      v-if="open && fullSize"
      class="fixed z-10 flex flex-col bg-backgroundColor full-size-page-container"
      :class="fullSizePageContainerClasses"
      :style="
        'width: ' +
        widthOfContent +
        'px;' +
        (isSmallScreen
          ? `height: ${height};`
          : 'height: calc(' + height + ' - var(--navbar-height)  - ' + (extraSizeOverContentContainer + 'px') + '); ') +
        (isSmallScreen
          ? 'top: 0;'
          : 'top: calc(var(--navbar-height) + 2px + ' + extraSizeOverContentContainer + 'px); ')
      ">
      <div
        class="full-screen-header"
        :class="{ 'border-b': withSecondBorder }">
        <ContentHeader
          :title="title"
          :super-header="superHeader"
          :loading="loading"
          :pre-icon="preIcon || postIcon"
          with-back-button
          :edit-mode="editMode"
          :actions="actions"
          :actions-as-buttons="actionsAsButtons"
          :align-center="alignCenter"
          @edit="$emit('edit')"
          @back="toggleOpen(false)">
          <template #underTitle>
            <slot name="underHeader" />
          </template>
          <template #menuButton>
            <slot name="menuButton" />
          </template>
          <template #title>
            <slot name="title" />
          </template>
          <template #afterTitle>
            <slot name="afterTitle" />
          </template>
        </ContentHeader>
      </div>
      <div
        class="overflow-auto"
        :class="contentClass">
        <slot name="content" />
      </div>
    </div>
  </div>
  <div
    v-else
    class="overflow-auto">
    <slot name="content" />
  </div>
</template>
