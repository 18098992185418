import { ShiftResource } from '@/types/event';
import { ShiftModelType } from '@/types/shifts';
import { z } from 'zod';

type ShiftData = {
  title?: string | null;
  shift_type_id?: number | null;
  description?: string | null;
  start: string;
  end: string;
  approved: boolean;
  via_id?: number;
  via_type?: string;
  user_id?: number | null;
  invited_by?: number;
  is_global: boolean;
  event_ids: number[];
  write: boolean;
  in_timeline: boolean;
  place_id: number | null;
  place_type: string | null;
  place_string: string | null;
  time_slot_id: number | null;
  count: number | null;
};

export const postShift = async (model: ShiftModelType, modelId: number, data: ShiftData) => {
  return axios.post<ShiftResource>('/api/shifts', {
    ...data,
    model_type: 'App\\' + model,
    model_id: modelId,
  });
};

export const patchShift = async (shiftId: number, data: Partial<ShiftData>) => {
  return axios.patch<string>(`/api/shifts/${shiftId}`, data);
};

export const destroyShift = async (shiftId: number, global: boolean) => {
  return axios.delete<string>(`/api/shifts/${shiftId}`, {
    params: {
      is_global: global,
    },
  });
};

export const postShiftSlots = async (shiftId: number, count: number) => {
  return axios.post<string>(`/api/shifts/${shiftId}/slots`, {
    count: count,
  });
};

export const patchApproveShift = async (shiftId: number) => {
  return axios.patch<string>(`/api/shifts/${shiftId}/approve`);
};

export const patchCancelShift = async (shiftId: number) => {
  return axios.post<string>(`/api/shifts/${shiftId}/cancel`);
};

export const getShift = async (shiftId: number) => {
  return axios.get<ShiftResource>(`/api/shifts/${shiftId}`);
};

export const postResendShiftNotification = async (shiftId: number) => {
  return axios.post<string>(`/api/shifts/${shiftId}/resend`);
};

export const postAcceptShift = async (shiftId: number) => {
  return axios.post<string>(`/api/shifts/${shiftId}/accept`);
};

export const postDeclineShift = async (shiftId: number) => {
  return axios.post<string>(`/api/shifts/${shiftId}/decline`);
};

const DuplicateFestivalSectionShiftSchema = z.object({
  with_time_slots: z.boolean(),
  same_crew: z.boolean(),
  same_place: z.boolean(),
  use_time_slot_title: z.boolean(),
  write_permissions: z.boolean(),
  in_timeline: z.boolean(),
  slots_count: z.number().min(0),
  dates: z.array(z.string()).min(1, 'You need at least 1 date').nullable(),
  time_slot_ids: z.array(z.number()).min(1, 'You need at least 1 time slot').nullable(),
});

export type DuplicateFestivalSectionShiftData = z.infer<typeof DuplicateFestivalSectionShiftSchema>;

export const postDuplicateFestivalSectionShift = async (shiftId: number, data: DuplicateFestivalSectionShiftData) => {
  DuplicateFestivalSectionShiftSchema.parse(data);
  return axios.post<string>(`/api/shifts/${shiftId}/duplicate-festival-section-shift`, data);
};
