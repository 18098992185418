<script lang="ts" setup>
import VButton from '@/components/Inputs/VButton.vue';
import { InviteResource } from '@/types/invite';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VToggle from '@/components/Inputs/VToggle.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';

type Props = {
  invite: InviteResource;
  selectedMember: {
    parentId: number | null;
    model_id: number;
    title: string;
  };
  hasShift: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'invited', arg: unknown): void;
  (event: 'createShift', arg: { parentId: number | null; model_id: number }): void;
}>();

const loading = ref(false);

const inviteTitle = ref('');
const inviteCanWrite = ref(false);
const isGlobal = ref(false);

const inviteMember = async (close: () => void) => {
  if (!props.invite.event?.id) return;

  try {
    loading.value = true;
    const { data } = await axios.post('/api/invites', {
      event_id: props.invite.event.id,
      user_id: props.selectedMember.model_id,
      via: props.selectedMember.parentId,
      title: inviteTitle.value,
      write: props.invite.write ? inviteCanWrite.value : false,
      is_global: isGlobal.value,
    });
    loading.value = false;
    useToast().success('invited');
    emit('invited', { ...data });
    close();
  } catch (e) {
    useToast().error('Something went wrong');
    throw e;
  }
};
</script>

<template>
  <CrudModal
    v-slot="{ close }"
    create-button-text="Invite"
    small
    :loading="loading"
    :title="`Invite ${selectedMember.title} to ${invite.event?.name}`"
    :title-highlight="selectedMember.title"
    @create="inviteMember"
    @closed="$emit('closed')">
    <div class="form-layout">
      <TextInput
        v-model="inviteTitle"
        label="Title" />

      <SettingToggle
        v-if="invite.write"
        v-model="inviteCanWrite"
        label="Give write-access to event" />

      <SettingToggle
        v-if="invite.recurring_original_id"
        v-model="isGlobal"
        label="Invite to all recurrences of event" />

      <div
        v-if="hasShift"
        class="absolute bottom-[40px]">
        <VButton
          title="Create as shift"
          size="sm"
          @click="
            [$emit('createShift', { parentId: selectedMember.parentId, model_id: selectedMember.model_id }), close()]
          " />
      </div>
    </div>
  </CrudModal>
</template>
