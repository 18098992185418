import { TemplateObject } from '@/components/Models/TemplateGallery/TemplateGalleryModal.vue';
import { getKey } from '@/util/globals';
import { formatQueryString, updateQueryString } from '@/util/query-helpers';
import { useEmitStore } from '@/store/EmitStore';
import CheckList from '@/components/Models/CheckLists/CheckList.vue';
import { postCheckLists } from '@/services/api-check-lists';

export const nameOfCheckListType = 'checkList';
export const checkListTemplateGalleryRootEmitKey = 'new-check-list-from-gallery';

export const getAllCheckListTemplates = function (groupId: number): TemplateObject[] {
  return {
    title: 'Import Check List Templates',
    props: {
      isTemplate: true,
      isDisplay: true,
      canEdit: false,
    },
    importFunction: async (template: TemplateObject) => {
      const { data } = await postCheckLists('Group', groupId, {
        title: getKey(template.data, 'title'),
        rows: getKey(template.data, 'rows'),
      });
      updateQueryString(formatQueryString('CheckList', data.id), true);
      const { rootEmit } = useEmitStore();
      rootEmit('template-gallery-import', { key: checkListTemplateGalleryRootEmitKey, data: data });
    },
    type: nameOfCheckListType,
    dataPropName: 'model-value',
    component: CheckList,
    templates: [
      {
        'uuid': 'dbe79383-35d7-4deb-9d82-4f8cd1bf0390',
        'title': 'Opening Routines',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Opening Routines',
          'rows': [
            {
              'title': 'Turn on the Light',
              'order': 1000,
              'description': 'Main switch by the door',
            },
            {
              'title': 'Entrance',
              'order': 2000,
              'description': 'Check doors',
            },
            {
              'title': 'Toilets',
              'order': 3000,
              'description': 'Check toilets are clean and have enough soap and paper towels',
            },
            {
              'title': 'Emergency Exits',
              'order': 4000,
              'description': 'Are all the emergency exits free?',
            },
            {
              'title': 'Turn off alarm',
              'order': 5000,
              'description': 'Alarm code is 0101',
            },
            {
              'title': 'Outside',
              'order': 6000,
              'description': 'Sweep the outside area',
            },
            {
              'title': 'Cash Register',
              'order': 7000,
              'description': 'Check change in the cash register',
            },
            {
              'title': 'Turn on music',
              'order': 8000,
              'description': 'Use suitable playlist',
            },
          ],
        },
      },
      {
        'uuid': '24494446-8d04-4430-bec5-a06f851caf84',
        'title': 'Closing routines',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Closing routines',
          'rows': [
            {
              'title': 'Clear venue',
              'description': 'Make sure everybody is out of the venue',
              'order': 1000,
            },
            {
              'title': 'Clean seating',
              'description': 'Clean up any cups, wrappers etc.',
              'order': 2000,
            },
            {
              'title': 'Clean up any spillages',
              'order': 3000,
            },
            {
              'title': 'Pack down and load out',
              'order': 4000,
            },
            {
              'title': 'Complete event report',
              'description': 'Submit notification in Crescat',
              'order': 5000,
            },
            {
              'title': 'Check windows',
              'order': 6000,
            },
            {
              'title': 'Turn off lights',
              'order': 7000,
            },
            {
              'title': 'Close the doors',
              'order': 8000,
            },
            {
              'title': 'Set the alarm and lock up',
              'description': 'Alarm code is 0101',
              'order': 9000,
            },
          ],
        },
      },
      {
        'uuid': '10a3f97b-b4d1-4cc7-8fcb-b3c6b1a7160c',
        'title': 'Fire/emergency routine',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Fire/emergency routine',
          'rows': [
            {
              'title': 'Check fire doors',
              'description': 'Ensure there is nothing blocking the doors.',
              'order': 1000,
            },
            {
              'title': 'Check gangways',
              'description': 'Remove any obstructions.',
              'order': 2000,
            },
            {
              'title': 'Check lights',
              'description': 'Check emergency lights are working.',
              'order': 3000,
            },
            {
              'title': 'Check fire extinguishers',
              'description': 'Ensure fire extinguishers are operational.',
              'order': 4000,
            },
            {
              'title': 'Ensure fire alarm is armed',
              'description': 'Alarm code is 0202',
              'order': 5000,
            },
          ],
        },
      },
      {
        'uuid': 'f1776631-ea17-4993-905e-f21247751170',
        'title': 'Show stop',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Show stop',
          'rows': [
            {
              'title': 'Cut music',
              'order': 1000,
            },
            {
              'title': 'Turn on lights',
              'order': 2000,
            },
            {
              'title': 'Communicate room evacuation',
              'description': 'Ensure communication is calm and clear',
              'order': 3000,
            },
            {
              'title': 'Raise alarm',
              'order': 4000,
            },
            {
              'title': 'Call emergency services',
              'order': 5000,
            },
            {
              'title': 'Secure venue',
              'description': 'Ensure no attendees are in the room',
              'order': 5000,
            },
          ],
        },
      },
      {
        'uuid': 'f19bf891-128d-4b8b-8b42-717251a46316',
        'title': 'Cleaning routines',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Cleaning routines',
          'rows': [
            {
              'title': 'Clean seats',
              'order': 1000,
            },
            {
              'title': 'Clean tables',
              'order': 2000,
            },
            {
              'title': 'Clean stage',
              'order': 3000,
            },
            {
              'title': 'Clean beer lines and restock fridges',
              'order': 4000,
            },
            {
              'title': 'Clean bar',
              'order': 5000,
            },
            {
              'title': 'Clean toilets',
              'order': 6000,
            },
            {
              'title': 'Dispose of waste',
              'order': 7000,
            },
          ],
        },
      },
      {
        'uuid': '8963fc6d-cd1d-4fbe-a8a9-58445769c9b3',
        'title': 'Wedding day checklist',
        'subTitle': 'Report',
        'descriptionText': '<p>Hi this is an description.</p>',
        'data': {
          'title': 'Wedding day checklist',
          'rows': [
            {
              'title': 'Open venue',
              'order': 1000,
            },
            {
              'title': 'Conduct staff meeting',
              'description': 'Go over schedule for the day and key responsibilities',
              'order': 2000,
            },
            {
              'title': 'Decorate venue for ceremony',
              'description': 'Flowers, candles, lighting etc.',
              'order': 3000,
            },
            {
              'title': 'Arrange seating',
              'order': 4000,
            },
            {
              'title': 'Put name labels on seats',
              'order': 5000,
            },
            {
              'title': 'Ready the bar and welcome drinks',
              'order': 6000,
            },
            {
              'title': "Ready the kitchen and prepare hors d'oeuvres",
              'order': 7000,
            },
            {
              'title': 'Open doors to guests',
              'order': 8000,
            },
            {
              'title': 'Clear venue after ceremony',
              'order': 9000,
            },
            {
              'title': 'Decorate venue for reception',
              'description': 'Flowers, candles, lighting etc.',
              'order': 10000,
            },
            {
              'title': 'Set tables and chairs',
              'order': 11000,
            },
            {
              'title': 'Ready the bar and wine service',
              'order': 12000,
            },
            {
              'title': 'Welcome guests',
              'order': 13000,
            },
            {
              'title': 'Prepare DJ/live entertainment',
              'description': 'Conduct sound check and lighting check',
              'order': 14000,
            },
            {
              'title': 'Clear venue after reception',
              'order': 15000,
            },
            {
              'title': 'Decorate venue for evening party',
              'description': 'Lighting, decorations etc',
              'order': 16000,
            },
            {
              'title': 'Welcome guests',
              'order': 17000,
            },
            {
              'title': 'Last orders',
              'order': 18000,
            },
            {
              'title': 'Clear venue after evening party',
              'order': 19000,
            },
          ],
        },
      },
    ],
  };
};
