export const groupRoomTabs = () => {
  const array = [];
  if (true) {
    array.push({
      key: 'rooms',
      title: 'Rooms Calendar',
    });
  }
  array.push({
    key: 'rooms_manager',
    title: 'Rooms Manager',
  });
  return array;
};
