import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import { getMetaDataList } from '@/services/api-meta-data';
import { MetaDataModelType, MetaDataResource } from '@/types/meta-data';

export const metaDataStore = defineStore('meta-data-store', () => {
  const list = ref<Map<string, MetaDataResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: MetaDataModelType, modelId: number) => {
    Echo.channel(`private-${model}.${modelId}`)
      .listen(`.metaData.created`, async () => {
        await fetchMetaData(model, modelId, true);
      })
      .listen(`.metaData.updated`, async () => {
        await fetchMetaData(model, modelId, true);
      })
      .listen(`.metaData.deleted`, async () => {
        await fetchMetaData(model, modelId, true);
      });
  };

  const fetchMetaData = async (model: MetaDataModelType, modelId: number, force: boolean = false) => {
    if (!force && list.value.has(formatModel(model, modelId))) return list.value.get(formatModel(model, modelId));

    loading.value = true;

    const { data } = await getMetaDataList(model, modelId);

    if (!list.value.has(formatModel(model, modelId))) {
      listenForBroadcast(model, modelId);
    }

    list.value.set(formatModel(model, modelId), data);

    loading.value = false;

    return data;
  };

  return {
    list,
    loading,
    fetchMetaData,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(metaDataStore, import.meta.hot));
}
