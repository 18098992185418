<template>
  <teleport to="body">
    <div
      v-if="open"
      :style="'z-index: ' + ZIndexPopUpModal + ';'"
      class="fixed top-0 left-0 flex h-full w-full flex-col bg-backgroundColor">
      <div class="c flex divide-x border-b">
        <div class="flex-1 px-5 py-2">
          <h3>{{ image.filename }}</h3>
          <small
            v-if="image.user"
            class="uppercase">
            {{ image.user.name }} {{ image.created_at }}
          </small>
        </div>
        <div
          class="hover:bg-backgroundColor-tertiary flex w-10 cursor-pointer items-center justify-center p-5 uppercase"
          @click="downloadImage">
          <IconWithLoading
            :loading="loading"
            icon="fa-download" />
        </div>
        <div
          v-if="canEdit"
          class="hover:bg-backgroundColor-tertiary cursor-pointer p-5 text-center uppercase"
          @click="edit">
          <i class="fa fa-fw fa-pen" /> Edit
        </div>
        <div
          class="hover:bg-backgroundColor-tertiary flex w-10 cursor-pointer items-center p-5 text-center"
          @click="close">
          <i class="fa fa-fw fa-times m-auto" />
        </div>
      </div>
      <div class="flex h-full items-center p-5">
        <img
          class="min-h-1 min-w-1 m-auto max-h-full max-w-full"
          :src="image.download_url" />
      </div>
    </div>
  </teleport>
</template>

<script>
import { ZIndexPopUpModal } from '@/variables/z-indexes';
import { useSmallScreen } from '@/composables/use-small-screen';
import { useToast } from 'vue-toastification';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';

export default {
  components: { IconWithLoading },
  props: {
    image: {
      required: false,
      type: Object,
      default: null,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  emits: ['close', 'edit'],
  data() {
    return {
      open: true,
      loading: false,
      ZIndexPopUpModal,
      isSmallScreen: useSmallScreen().isSmallScreen,
      toast: useToast(),
    };
  },
  methods: {
    edit() {
      this.$emit('edit');
      this.open = false;
    },
    close() {
      this.$emit('close');
      this.open = false;
    },

    downloadImage() {
      if (this.isSmallScreen) {
        // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // window.open(`/documents/${this.image.id}/download`);
        window.open(this.image.download_url);
      } else if (!this.loading) {
        this.loading = true;
        axios({
          url: this.image.download_url,
          method: 'GET',
          responseType: 'blob', // important
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            const splitName = this.image.filename.split('.');
            if (splitName[splitName.length - 1] === this.image.extension) {
              link.setAttribute('download', this.image.filename);
            } else {
              link.setAttribute('download', `${this.image.filename}.${this.image.extension}`);
            }

            document.body.appendChild(link);
            link.click();
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
            this.toast.warning('Something went wrong, please try again later');
          });
      }
    },
    openEditNameModal() {
      this.close();
      this.$emit('open-name-modal');
    },
  },
};
</script>
