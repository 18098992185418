<script setup lang="ts">
import { ref } from 'vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import DisplayPhone from '@/components/Display/DisplayPhone.vue';
import DisplayEmail from '@/components/Display/DisplayEmail.vue';

type User = {
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  avatar_url?: string | null;
  country_code?: string | null;
};

type Props = {
  name?: string | null;
  phone?: string | null;
  countryCode?: string | null;
  email?: string | null;
  user?: User | null;
  showImage?: boolean;
  internalToggle?: boolean;
  avatarUrl?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  name: null,
  phone: null,
  countryCode: null,
  email: null,
  user: null,
  showImage: true,
  internalToggle: false,
  avatarUrl: null,
});

defineEmits<{
  (event: 'closed'): void;
}>();

const open = ref(!props.internalToggle);
</script>
<template>
  <div
    v-if="internalToggle"
    class="cursor-pointer"
    @click="open = !open">
    <slot name="toggle" />
  </div>
  <CrudModal
    v-if="open"
    small
    only-close-button
    @closed="[$emit('closed'), internalToggle ? (open = false) : null]">
    <div class="grid grid-cols-2 items-center gap-5">
      <div class="text-center">
        <img
          v-if="showImage && (avatarUrl || (user && user.avatar_url))"
          :src="user && user.hasOwnProperty('avatar_url') ? user.avatar_url : avatarUrl"
          alt="Default user avatar"
          class="responsive-image image-wrapper object-cover max-h-13 max-w-13" />
        <img
          v-if="!showImage"
          :src="$page.props.asset_url + 'assets/images/default-avatar.png'"
          alt="User Profile image"
          class="responsive-image image-wrapper h-13 w-13" />
      </div>
      <div class="flex flex-col gap-6">
        <div class="name-wrapper">
          <h2>
            {{ user && user.hasOwnProperty('name') ? user.name : name }}
          </h2>
        </div>
        <div class="info-wrapper flex flex-col gap-3">
          <DisplayEmail
            text-color-class="text-textColor-soft"
            :email="user && user.hasOwnProperty('email') ? user.email : email" />
          <DisplayPhone
            text-color-class="text-textColor-soft"
            :phone-number="user && user.hasOwnProperty('phone') ? user.phone : phone"
            :country-code="user && user.hasOwnProperty('country_code') ? user.country_code : countryCode" />
        </div>
      </div>
    </div>
    <slot name="underContent" />
  </CrudModal>
</template>
