<script lang="ts" setup>
import { onBeforeUnmount, onMounted, provide, ref } from 'vue';
import ContentHeader from '@/components/Content/ContentHeader.vue';
import { usePageTabsStore } from '@/store/PageTabsStore';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import type { Action } from '@/components/Inputs/Components/ActionButtons.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { getKey } from '@/util/globals';

type Props = {
  loading?: boolean;
  firstLoad?: boolean;
  withBackButton?: boolean;
  actions?: Action[];
  showTabs?: boolean;
  tabs?: [];
  actionsAsButtons?: boolean | null;
  title?: string | null;
  titleInfo?: string;
  superHeader?: string | null;
  preIcon?: string;
  underTitle?: string;
  backAction?: (() => void) | null;
  emptyAction?: () => void;
  emptyMessage?: string;
  emptyIcon?: string;
  wrapperClasses?: string;
  contentSlotClasses?: string;
  emptyButtonActionText?: string;
  fullSize?: boolean;
  minimizeContainer?: boolean;
  stripedChildren?: boolean;
  heightReduction?: number | null;
  teleportHeaderId?: string;
};

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  firstLoad: true,
  withBackButton: true,
  fullSize: true,
  minimizeContainer: false,
  stripedChildren: true,
  actionsAsButtons: true,
  actions: () => [],
  tabs: [],
  showTabs: false,
  title: null,
  titleInfo: '',
  preIcon: '',
  underTitle: '',
  backAction: null,
  superHeader: null,
  heightReduction: 30,
  emptyAction: () => {},
  emptyMessage: '',
  emptyIcon: null,
  wrapperClasses: '',
  contentSlotClasses: '',
  emptyButtonActionText: 'Create New',
  teleportHeaderId: undefined,
});

const pageTabsStore = usePageTabsStore();

const widthOfContent = ref(100);
const heightOfHeader = ref('var(--content-header-height)');
const contentHeaderElem = ref<HTMLDivElement | null>(null);
const container = ref<HTMLDivElement | null>(null);

const heightOfContent = ref('100%');

provide('widthOfContent', widthOfContent);
const { isSmallScreen } = useSmallScreen();

const getHeightOfContent = () => {
  if (!contentHeaderElem.value) return;
  if (props.minimizeContainer || isSmallScreen.value) {
    if (container.value) {
      container.value.style.removeProperty('height');
    }
    return;
  }
  let height = window.innerHeight;
  const style = getComputedStyle(document.body);
  const remToPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const currentHeight = style.getPropertyValue('--navbar-current-height');
  if (currentHeight.includes('rem')) {
    height -= currentHeight.split('rem')[0] * remToPx;
  } else {
    height -= currentHeight.split('px')[0];
  }
  height -= contentHeaderElem.value.clientHeight;
  if (props.heightReduction) {
    height -= props.heightReduction;
  }
  // height -= 40;
  if (!props.fullSize && height > 400) {
    height = height * 0.5;
  }
  heightOfContent.value = height + 'px;';

  try {
    if (container.value.parentElement.parentElement.classList.contains('h-full')) {
      const otherHeight =
        container.value.parentElement.parentElement.clientHeight - contentHeaderElem.value.clientHeight;
      heightOfContent.value = otherHeight + 'px;';
    }
  } catch (__) {}
};

const getWidthOfContent = () => {
  if (!contentHeaderElem.value) return;
  widthOfContent.value = contentHeaderElem.value.clientWidth;
  heightOfHeader.value = `${contentHeaderElem.value.clientHeight}px`;
  getHeightOfContent();
};

onMounted(() => {
  window.addEventListener('resize', getWidthOfContent);
  [0, 50, 100, 500].forEach((num) => {
    setTimeout(() => {
      getWidthOfContent();
    }, num);
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', getWidthOfContent);
});
const addKBLinkToUnderTitle = (tab) => {
  if (!tab) return null;
  const kbLink = getKey(tab, 'kbLink');
  if (!kbLink) return tab.description;
  return `${tab.description} Learn more <a href='${kbLink}' target='_blank'>HERE</a>.`;
};
</script>

<template>
  <div
    class="overflow-hidden"
    :class="wrapperClasses">
    <div ref="contentHeaderElem">
      <ContentHeader
        :loading="loading"
        :title="title ? title : pageTabsStore.activeTab?.title"
        :title-info="titleInfo"
        :super-header="superHeader"
        :teleport-id="teleportHeaderId"
        :is-page-header="true"
        :under-title="
          underTitle
            ? underTitle
            : pageTabsStore && pageTabsStore.activeTab
              ? addKBLinkToUnderTitle(pageTabsStore.activeTab)
              : null
        "
        :with-back-button="withBackButton"
        :actions="actions"
        :actions-as-buttons="actionsAsButtons"
        @back="backAction ? backAction() : pageTabsStore.goBack()">
        <template
          v-if="$slots.afterTitle"
          #afterTitle>
          <slot name="afterTitle" />
        </template>
        <template
          v-if="$slots.underTitle"
          #underTitle>
          <slot name="underTitle" />
        </template>
      </ContentHeader>
    </div>

    <div v-if="showTabs && pageTabsStore.activeTab?.tabs?.length">
      <section class="flex gap-4 px-edge">
        <div
          v-for="tab in pageTabsStore.activeTab?.tabs"
          :key="tab.key"
          :dusk="`tab-${pageTabsStore.activeTab.key}-${tab.key}`"
          :class="
            pageTabsStore.activeSubTab?.key === tab.key
              ? 'min-w-fit border-b-2 border-textColor text-textColor'
              : 'text-textColor-soft truncate'
          "
          :title="tab.title"
          class="h-full px-1 cursor-pointer pb-2 text-sm capitalize hover:min-w-fit hover:text-clip hover:text-textColor lg:text-lg select-none"
          @click="pageTabsStore.setActiveSubTab(tab)">
          {{ tab.title }}
        </div>
      </section>
    </div>

    <div
      ref="container"
      class="overflow-auto border-t flex flex-col gap-edge py-edge"
      :style="'height: ' + heightOfContent + ';'"
      :class="contentSlotClasses + (stripedChildren && !emptyMessage ? ' [&_.main-header:hover]:bg-row-hover ' : '')">
      <slot :sub-tab="pageTabsStore.activeSubTab" />
      <EmptyStateFullPage
        v-if="emptyMessage && !loading && firstLoad"
        :icon="emptyIcon ? emptyIcon : preIcon"
        :description="emptyMessage"
        :button-text="emptyAction ? emptyButtonActionText : null"
        :button-function="emptyAction" />
      <div
        v-if="!firstLoad && loading"
        class="text-5xl text-center text-textColor-soft mt-10">
        <i class="fa fa-fw fa-circle-o-notch fa-spin" /> <br />
        <span class=""> Loading </span>
      </div>
    </div>
  </div>
</template>
