<script setup lang="ts">
import { useToast } from 'vue-toastification';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { concatName } from '@/services/api-partners';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import { ref } from 'vue';
import { getKey } from '@/util/globals';

type Props = {
  invoiceBasisId: number;
  downloading: boolean;
  model: string;
  modelId: number;
  showTimes?: string[];
};

const props = withDefaults(defineProps<Props>(), {
  showTimes: () => [],
});

const emit = defineEmits<{
  'update:downloading': [arg: boolean];
}>();

const toast = useToast();

const modalOpen = ref(true);
const asCSV = ref(false);
const metaData = ref([]);
const partners = ref(null);
const includedMetaDataIds = ref([]);
const includedCompanyIds = ref([]);
const includedContactIds = ref([]);

const fetchPartners = async () => {
  if (props.model !== 'Invite') return;
  const { data } = await axios.get(`/api/invites/${props.modelId}/partners`);
  partners.value = data;
  if (partners.value?.contacts?.length) {
    partners.value.contacts = partners.value.contacts.map((contact) => {
      return {
        ...contact,
        name: concatName(contact),
      };
    });
  }
};

const fetchMetaDatas = async () => {
  if (metaData.value.length > 0) return;
  const { data } = await axios.get('/api/meta-data', {
    params: {
      model_type: `App\\${props.model}`,
      model_id: props.modelId,
    },
  });
  metaData.value = data;
};

const getData = async () => {
  await fetchMetaDatas();
  await fetchPartners();
};

getData();

const downloadReport = async () => {
  if (props.downloading) return;
  emit('update:downloading', true);

  const response = await axios
    .post(`/api/invoice-bases/${props.invoiceBasisId}/download`, {
      asCSV: asCSV.value,
      metaDataIds: !asCSV.value ? includedMetaDataIds.value : [],
      contactIds: !asCSV.value ? includedContactIds.value : [],
      companyIds: !asCSV.value ? includedCompanyIds.value : [],
    })
    .catch((error) => {
      console.error(error);
      emit('update:downloading', false);
      toast.warning('Something went wrong, please try again later');
    });

  if (response.status === 204) {
    toast.success('There are no data in the requested invoice basis.');
    emit('update:downloading', false);
    return;
  }
  if (response.status === 205) {
    toast.success('Can only download invoice bases for events.');
    emit('update:downloading', false);
    return;
  }
  await downloadFile(response.data.url, response.data.name);
  modalOpen.value = false;
  emit('update:downloading', false);
};
</script>

<template>
  <ReportModal
    v-model:as-c-s-v="asCSV"
    :with-button="false"
    :can-download="true"
    title="Download Report"
    :working="downloading"
    @download="downloadReport">
    <transition name="slide-down">
      <div
        v-if="!asCSV"
        class="form-layout">
        <VMultiselect
          v-if="partners && getKey(partners, 'contacts', []).length > 0"
          v-model="includedContactIds"
          :close-on-select="false"
          :options="partners.contacts"
          with-add-all
          label="Contacts To Include" />
        <VMultiselect
          v-if="partners && getKey(partners, 'companies', []).length > 0"
          v-model="includedCompanyIds"
          :close-on-select="false"
          :options="partners.companies"
          with-add-all
          label="Companies To Include" />
        <VMultiselect
          v-if="metaData"
          v-model="includedMetaDataIds"
          :close-on-select="false"
          option-label="title"
          :options="metaData"
          with-add-all
          label="Meta Data To Include" />
      </div>
    </transition>
  </ReportModal>
</template>
