import { useLocalStorage } from '@vueuse/core';
import { computed } from 'vue';
import { sendAnErrorToSlack } from '@/util/error-handler';

export type OrderDataListBy = 'asc' | 'desc' | 'count' | 'last_use';

export const useLocalStoreDataListOptions = (localStorageString: string, orderBy: OrderDataListBy) => {
  const localDataListOption = useLocalStorage<{ value: string; count: number; last_use: string }[]>(
    localStorageString,
    []
  );

  const allDataListOptions = computed(() => {
    if (!Array.isArray(localDataListOption.value)) {
      console.error('datalist is not an array');
      sendAnErrorToSlack('datalist is not an array');
      return [];
    }

    try {
      return localDataListOption.value
        .slice()
        .sort((a, b) => {
          if (orderBy === 'count') {
            return b.count - a.count;
          }
          if (orderBy === 'asc') {
            return a.value.localeCompare(b.value);
          }
          if (orderBy === 'desc') {
            return b.value.localeCompare(a.value);
          }
          return new Date(b.last_use || '').getTime() - new Date(a.last_use || '').getTime();
        })
        .map((o) => o.value);
    } catch (error) {
      sendAnErrorToSlack('use local store data list options error');
      return [];
    }
  });

  const saveToLocalStorage = (e: string) => {
    if (!e || e.trim() === '') {
      return;
    }

    if (!Array.isArray(localDataListOption.value)) {
      console.error('datalist is not an array');
      sendAnErrorToSlack('datalist is not an array');
      return;
    }

    try {
      const index = localDataListOption.value.findIndex((o) => o.value.toLowerCase() === e.toLowerCase());

      if (index > -1) {
        localDataListOption.value[index].count += 1;
        localDataListOption.value[index].last_use = new Date().toISOString();
      } else {
        localDataListOption.value.push({
          value: e,
          count: 1,
          last_use: new Date().toISOString(),
        });
      }
    } catch (error) {
      console.error(error);
      sendAnErrorToSlack('use local store data list options error');
    }
  };

  return {
    options: allDataListOptions,
    saveToLocalStorage,
  };
};
