<script setup lang="ts">
import { EventMinimalResource } from '@/types/event';
import moment from 'moment';
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { dateFormat } from '@/variables/date-format';
import { postEventDaySheet } from '@/services/api-event';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import VSelect from '@/components/Inputs/VSelect.vue';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { getCrewIcon } from '@/util/event-timeline-functions';
import { arrayToJoinString, getKey } from '@/util/globals';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { getRunningOrders } from '@/services/api-running-order';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

type Props = {
  event: EventMinimalResource;
  isOpen: boolean;
};

const props = defineProps<Props>();

const toast = useToast();

const eventCrew = ref([]);
const eventRooms = ref([]);
const roomsToInclude = ref([]);
const crewToInclude = ref([]);
const notepadIdsToInclude = ref([]);
const includeKeyContacts = ref(true);
const includeContactList = ref(true);
const includeRoomBookings = ref(true);
const includeShowTimes = ref(true);
const includeDescription = ref(true);
const advancedOptions = ref(false);
const selectedDate = ref(null);
const notepads = ref([]);
const runningOrders = ref([]);
const runningOrdersToInclude = ref([]);
const runningOrderColumnsToInclude = ref([]);

const compact = ref(false);

const showPreview = ref(false);

const downloading = ref(false);
const modalOpen = ref(false);

const filteredEventCrew = computed(() => {
  if (compact.value) {
    return eventCrew.value.filter((member) => {
      if (member.model === 'user') {
        return false;
      }
      return !(member.model === 'eventResource' && member.type === 'Crew');
    });
  }
  return eventCrew.value;
});

const elementsToInclude = computed(() => {
  return roomsToInclude.value.concat(crewToInclude.value);
});

const getNotepads = async () => {
  if (notepads.value.length > 0) return;
  const { data } = await axios.get('/api/notepads', {
    params: {
      model_type: 'App\\Event',
      model_id: props.event.id,
    },
  });
  notepads.value = data;
  notepadIdsToInclude.value = [];
  if (data.length !== 0) {
    data.forEach((notepad) => {
      notepadIdsToInclude.value.push(notepad.id);
    });
  }
};
const fetchRunningOrders = async () => {
  if (runningOrders.value.length > 0) return;
  const { data } = await getRunningOrders('Event', props.event.id);
  runningOrders.value = data;
  runningOrdersToInclude.value = [];
  runningOrderColumnsToInclude.value = [];
};

const includeAllElements = () => {
  if (elementsToInclude.value.length === filteredEventCrew.value.length + eventRooms.value.length) {
    roomsToInclude.value = [];
    crewToInclude.value = [];
  } else {
    eventRooms.value.forEach((room) => {
      const index = _.findIndex(elementsToInclude.value, (r) => r === room.id);
      if (index === -1) {
        roomsToInclude.value.push(room.id);
      }
    });
    filteredEventCrew.value.forEach((crew) => {
      const index = _.findIndex(elementsToInclude.value, (r) => r === crew.id);
      if (index === -1) {
        crewToInclude.value.push(crew.id);
      }
    });
  }
};

const getEventCrew = async () => {
  if (eventCrew.value.length > 0) return;

  const { data } = await axios.get(`/api/events/${props.event.id}/crew?format=fullcalendar`);
  eventCrew.value = [];
  eventRooms.value = [];
  data.forEach((item) => {
    if (item.type === 'Crew') {
      addToEventCrew(item);
    } else if (item.type === 'Rooms') {
      eventRooms.value.push(item);
    }
  });
  includeAllElements();
};

const openModal = async () => {
  modalOpen.value = false;
  await nextTick();
  modalOpen.value = true;
  await getEventCrew();
  await getNotepads();
  await fetchRunningOrders();
};

const addToEventCrew = (item) => {
  eventCrew.value.push(item);

  if (item.hasOwnProperty('children')) {
    for (let index = 0; index < item.children.length; index++) {
      addToEventCrew(item.children[index]);
    }
  }
};

const closeModal = () => {
  modalOpen.value = false;
};

onBeforeUnmount(() => {
  closeModal();
});

const toggleSelectedDate = () => {
  if (selectedDate.value === null) {
    selectedDate.value = moment(props.event.start_date).format(dateFormat);
  } else {
    selectedDate.value = null;
  }
};

watch(compact, () => {
  roomsToInclude.value = [];
  crewToInclude.value = [];
  includeAllElements();
  selectedDate.value = compact.value ? moment(props.event.start_date).format(dateFormat) : null;
});

const downloadDaySheet = async () => {
  if (!roomsToInclude.value.length && !crewToInclude.value.length) {
    toast.warning("Rooms to include and crew to include can't both be empty");
    return;
  }
  if (downloading.value) return;

  downloading.value = true;

  const data = {
    includeKeyContacts: includeKeyContacts.value,
    includeShowTimes: includeShowTimes.value,
    includeContactList: includeContactList.value,
    includeRoomBookings: includeRoomBookings.value,
    includeDescription: includeDescription.value,
    elementsToInclude: elementsToInclude.value,
    notepadsToInclude: notepadIdsToInclude.value,
    running_order_ids: runningOrdersToInclude.value,
    running_order_column_ids: runningOrderColumnsToInclude.value,
    compact: compact.value,
    selectedDate: selectedDate.value,
  };
  try {
    const response = await postEventDaySheet(props.event.id, data);
    if (response.status === 204) {
      toast.warning(response.data);
      downloading.value = false;
      return;
    }
    await downloadFile(response.data.url, response.data.name);
    downloading.value = false;
    closeModal();
  } catch (e) {
    downloading.value = false;
    toast.warning('Something went wrong, please try again later');
    throw e;
  }
};

watch(advancedOptions, () => {
  if (!advancedOptions.value) {
    roomsToInclude.value = [];
    crewToInclude.value = [];
    includeAllElements();
  }
});

watch(
  () => props.isOpen,
  (open) => {
    if (open) {
      openModal();
    }
  },
  { immediate: true }
);

const generatePreviewUrl = _.debounce(() => {
  showIFrame.value = false;
  const params = new URLSearchParams({
    event_id: props.event.id,
    includeKeyContacts: includeKeyContacts.value,
    includeShowTimes: includeShowTimes.value,
    includeContactList: includeContactList.value,
    includeRoomBookings: includeRoomBookings.value,
    includeDescription: includeDescription.value,
    elementsToInclude: elementsToInclude.value,
    notepadsToInclude: notepadIdsToInclude.value,
    running_order_ids: runningOrdersToInclude.value,
    running_order_column_ids: runningOrderColumnsToInclude.value,
    compact: compact.value,
    selectedDate: selectedDate.value,
  });
  previewUrl.value = `/reports/day-sheet/preview?${params.toString()}`;
  runBackground();
}, 1000);

watch(
  [
    includeKeyContacts,
    includeShowTimes,
    includeContactList,
    includeRoomBookings,
    includeDescription,
    elementsToInclude,
    notepadIdsToInclude,
    compact,
    selectedDate,
    runningOrdersToInclude,
    runningOrderColumnsToInclude,
  ],
  () => {
    generatePreviewUrl();
  }
);

const previewUrl = ref(null);
const iframe = ref<HTMLIFrameElement | null>(null);
const showIFrame = ref(false);
const setBackgroundOfIframe = () => {
  if (!iframe.value) return;
  const body = iframe.value.contentDocument.querySelector('body');
  if (!body) return;
  body.style.backgroundColor = 'white';
  const firstChild = body.firstElementChild;
  if (!firstChild) return;
  firstChild.style.backgroundColor = 'white';
  setTimeout(() => {
    showIFrame.value = true;
  }, 100);
};
const runBackground = () => {
  showIFrame.value = false;
  [0, 100, 200, 500, 1000, 2000].forEach((time) => {
    setTimeout(() => {
      setBackgroundOfIframe();
    }, time);
  });
};
runBackground();

const toggleRunningOrder = (runningOrder) => {
  const index = runningOrdersToInclude.value.indexOf(runningOrder.id);
  if (index > -1) {
    runningOrdersToInclude.value.splice(index, 1);
  } else {
    runningOrdersToInclude.value.push(runningOrder.id);
  }
};
</script>

<template>
  <div>
    <ReportModal
      v-if="modalOpen"
      :can-download="roomsToInclude.length + crewToInclude.length > 0"
      :with-button="false"
      title="Download Event Day Sheet"
      :small="!showPreview"
      :x-large="showPreview"
      title-highlight="Day Sheet"
      :working="downloading"
      has-preview
      @preview="showPreview = !showPreview"
      @download="downloadDaySheet">
      <div :class="{ 'grid grid-cols-[250px_830px] gap-5': showPreview }">
        <div class="flex flex-col gap-edge">
          <div>
            <VSelect
              v-model="compact"
              label="Mode"
              :options="[
                { id: true, name: 'Compact' },
                { id: false, name: 'Full Length' },
              ]" />
          </div>
          <div class="flex flex-col gap-3">
            <SettingCheck
              v-model="includeKeyContacts"
              label="Include Key Contacts"
              title="If enabled, the report will include key contacts of the event." />
            <SettingCheck
              v-model="includeShowTimes"
              label="Include Show Times"
              title="If enabled, the report will include show times of the event." />
            <SettingCheck
              v-model="includeDescription"
              label="Include Description"
              title="If enabled, the report will include the description of the event." />
            <SettingCheck
              v-if="!compact"
              v-model="includeRoomBookings"
              label="Include Room Bookings"
              title="If enabled, the report will include all bookings of rooms to the event." />
            <SettingCheck
              v-if="!compact"
              v-model="includeContactList"
              label="Include Contact List"
              title="If enabled, the report will include a list of all members of the event." />
          </div>
          <hr />
          <div class="form-layout">
            <div
              :class="{ 'opacity-50': showPreview }"
              class="flex cursor-pointer items-center gap-3"
              title="If enabled, you will have additional elements of this report you can toggle."
              @click="advancedOptions = !advancedOptions">
              <ChevronToggle
                :model-value="advancedOptions || showPreview"
                class="text-sm"
                @update:model-value="advancedOptions = $event" />
              <h4>Show Advanced Options</h4>
              <i class="fa fa-solid fa-info-circle ml-5 text-textColor-soft" />
            </div>
            <p
              v-if="!advancedOptions && !showPreview"
              class="text-textColor-soft">
              The day sheet will automatically include all assignments, contacts, rooms and such for this event.
            </p>
          </div>
          <div
            v-if="advancedOptions || showPreview"
            class="form-layout">
            <VMultiselect
              v-if="notepads.length > 0"
              v-model="notepadIdsToInclude"
              label="Add Notepads"
              with-add-all
              :options="notepads"
              option-label="title"
              label-key="id" />
            <VMultiselect
              v-if="filteredEventCrew?.length > 0"
              v-model="crewToInclude"
              label="Add Crew"
              with-add-all
              :options="
                filteredEventCrew
                  ?.flatMap((c) => [c].concat(getKey(c, 'children', [])))
                  .filter((c) => c !== null)
                  .map((crew) => ({
                    ...crew,
                    icon:
                      getCrewIcon(crew.model, crew.type) +
                      (getCrewIcon(crew.model, crew.type) === 'fa-user' ? ' ml-5 ' : ''),
                  }))
              "
              option-label="title" />
            <VMultiselect
              v-if="eventRooms.length > 0"
              v-model="roomsToInclude"
              label="Add Rooms"
              with-add-all
              :options="eventRooms"
              option-label="title" />
            <div
              v-if="!compact && runningOrders.length > 0"
              class="grid grid-cols-[auto_70px]">
              <div>
                <InputLabel label="Running Orders" />
                {{
                  runningOrdersToInclude.length > 0
                    ? arrayToJoinString(
                        runningOrders.filter((r) => runningOrdersToInclude.includes(r.id)).map((r) => r.title)
                      )
                    : 'N/A'
                }}
              </div>

              <ActionButtonGroup
                :actions="[
                  {
                    icon: 'fa-plus',
                    title: 'Add',
                    closeOnClick: false,
                    dropdown: runningOrders.flatMap((r) => {
                      return [
                        {
                          title: r.title,
                          value: r.id,
                          selected: runningOrdersToInclude.includes(r.id),
                          action: () => {
                            toggleRunningOrder(r);
                          },
                        },
                      ].concat(
                        runningOrdersToInclude.includes(r.id)
                          ? r.running_order_columns.map((c) => {
                              return {
                                title: c.title,
                                disabled: !runningOrdersToInclude.includes(r.id),
                                value: c.id,
                                class: 'pl-6',
                                selected:
                                  runningOrdersToInclude.includes(r.id) && runningOrderColumnsToInclude.includes(c.id),
                                action: () => {
                                  const index = runningOrderColumnsToInclude.indexOf(c.id);
                                  if (index > -1) {
                                    runningOrderColumnsToInclude.splice(index, 1);
                                  } else {
                                    runningOrderColumnsToInclude.push(c.id);
                                  }
                                },
                              };
                            })
                          : []
                      );
                    }),
                  },
                ]" />
            </div>
            <div :class="{ 'grid grid-cols-2': !showPreview }">
              <SettingToggle
                v-if="!compact"
                :model-value="selectedDate !== null"
                label="Select Day"
                title="If enabled, you can select which day to select."
                @update:model-value="toggleSelectedDate()" />
              <VDatepicker
                v-if="!compact || selectedDate !== null"
                v-model="selectedDate"
                :can-edit="compact || selectedDate !== null"
                :required="true"
                :min-date="null"
                label="Select Day" />
            </div>
          </div>
        </div>
        <div
          v-if="showPreview"
          class="flex flex-col w-[850px] min-h-[70vh]">
          <InputLabel label="Preview" />
          <iframe
            v-show="showIFrame"
            ref="iframe"
            class="[&>*]:bg-white flex-1 w-[830px]"
            :src="previewUrl" />
        </div>
      </div>
    </ReportModal>
  </div>
</template>
