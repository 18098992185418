<script setup lang="ts">
import { getEventGlobalTimeline } from '@/services/api-event';
import { getTimelineTemplates } from '@/services/api-timeline-templates';
import { GlobalTimelineEvent } from '@/types/event';
import type { InviteResource } from '@/types/invite';
import { TimelineTemplateResource } from '@/types/timeline-template';
import { router } from '@inertiajs/vue3';
import moment from 'moment';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { dateFormat } from '@/variables/date-format';
import { addTimelineTemplateToEvent } from '@/util/timeline-templates';
import EventDaysheetModal from '@/components/Event/Show/EventDaysheetModal.vue';
import AssignmentModal from '@/components/Event/AssignmentModal.vue';
import { getRoute } from '@/util/route';
import VButton from '@/components/Inputs/VButton.vue';
import { useEmitStore } from '@/store/EmitStore';
import { useSmallScreen } from '@/composables/use-small-screen';
import { newGreen } from '@/variables/colors';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { formatStampAsDate, timeStampsAreSame } from '@/util/timeFunctions';
import { groupItemsByKey, NEXT_DAY_HOUR } from '@/util/globals';
import AuditsLastEditText from '@/components/Audits/AuditsLastEditText.vue';
import { auditableAssignmentFields, auditableShowTimeFields } from '@/helpers/auditHelper';
import { RoomBookingResource } from '@/types/room-booking';

type Props = {
  invite: InviteResource;
  show: boolean;
  admin: boolean;
  onEvent: boolean;
  roomBookings?: RoomBookingResource[];
};
const props = withDefaults(defineProps<Props>(), {
  roomBookings: () => [],
});

defineEmits<{
  (event: 'update:show', arg: boolean): void;
}>();

const { rootEmit } = useEmitStore();
const toast = useToast();

const auditTicker = ref(0);
const loaded = ref(false);
const loading = ref(false);
const assignments = ref<GlobalTimelineEvent[]>([]);
const showTimes = ref([]);

if ('show_times' in props.invite) {
  showTimes.value = props.invite.show_times.map((show) => ({
    color: newGreen,
    start: show.start,
    start_date: moment(show.start).format(dateFormat),
    end: show.end,
    title: show.title ? show.title : 'Show',
    rooms: show.room ? show.room : null,
  }));
}
const timelineTemplateLoaded = ref(false);
const timelineTemplates = ref<TimelineTemplateResource[]>([]);
const showDaySheetModal = ref(false);
const showCreateAssignmentModal = ref(false);
const pageY = ref(0);
const timelineTemplateMenuVisible = ref(false);

const sortedItems = computed(() => {
  return _.orderBy(assignments.value.concat(showTimes.value), 'start');
});

const anyAssignmentHasWhere = computed(() => {
  return sortedItems.value.filter((assignment) => assignment.rooms && assignment.rooms.length > 0).length > 0;
});

const dates = computed(() => {
  const res = [];
  sortedItems.value.forEach((item) => {
    const startTime = moment(item.start);
    const startHour = startTime.hour();
    let id = formatStampAsDate(item.start);
    if (startHour < NEXT_DAY_HOUR) {
      id = moment(item.start).subtract(1, 'day').format('YYYY-MM-DD');
    }

    res.push({
      temp_id: id,
      ...item,
    });
  });

  return _.groupBy(res, 'temp_id');
});

const fetchGlobalAssignments = async () => {
  if (loaded.value || !props.invite.event) return;

  loaded.value = true;
  loading.value = true;

  const { data } = await getEventGlobalTimeline(props.invite.event.id);

  assignments.value = data;

  loading.value = false;

  if (props.invite.invitable?.id && props.invite.invitable_type === 'App\\Group' && !timelineTemplateLoaded.value) {
    const { data } = await getTimelineTemplates(props.invite.invitable.id);
    timelineTemplates.value = data;
    timelineTemplateLoaded.value = true;
  }
};

watch(
  () => props.show,
  (show) => {
    if (show) {
      showCreateAssignmentModal.value = false;
      fetchGlobalAssignments();
    }
  }
);

if (props.show) {
  fetchGlobalAssignments();
}

const formattedDate = (date: string) => {
  return moment(date).format('dddd Do [of] MMMM');
};

const getAssignmentDuration = (assignment) => {
  if (assignment.end) {
    const val = moment(assignment.end).valueOf() - moment(assignment.start).valueOf();
    if (val > 24 * 60 * 60 * 1000) {
      const num = Math.floor(val / (60 * 60 * 1000));
      return `${moment(assignment.start).format('HH:mm')} - ${moment(assignment.end).format('HH:mm')} (${num}h)`;
    }
    return `${moment(assignment.start).format('HH:mm')} - ${moment(assignment.end).format('HH:mm')}`;
  }
  return moment(assignment.start).format('HH:mm');
};

const downloadDaySheet = () => {
  showDaySheetModal.value = false;
  nextTick(() => {
    showDaySheetModal.value = true;
  });
};

const showTimelineTemplateMenu = async (event) => {
  pageY.value = event?.pageY + 10;
  timelineTemplateMenuVisible.value = false;
  await nextTick();
  timelineTemplateMenuVisible.value = true;
};

const showAssignmentModal = ref(false);
const currentAssignment = ref(null);

const openAssignmentModal = async (assignment = null) => {
  if (assignment && !assignment.is_assignment) return;
  currentAssignment.value = null;

  if (assignment) {
    currentAssignment.value = {
      id: assignment.id,
      title: assignment.title,
      description: assignment.description,
      start: assignment.start,
      resourceIds: assignment.assignable_contexts,
      end: assignment.end,
      is_global: false,
      recurring_original_id: null,
    };
  } else {
    let { start } = props.invite;
    if (assignments.value.length > 0) {
      start = assignments.value[assignments.value.length - 1].end
        ? assignments.value[assignments.value.length - 1].end
        : assignments.value[assignments.value.length - 1].start;
    }
    currentAssignment.value = {
      title: '',
      description: '',
      start,
      end: null,
      resourceIds: ['global'],
      is_global: false,
      recurring_original_id: null,
    };
  }

  showAssignmentModal.value = true;
};

const goToEventTimeline = (event = null) => {
  if (event && event.ctrlKey) {
    toast.success('Opening Event');
    window.open(`${getRoute('events.show', props.invite.event.slug)}?#timeline`);
  } else {
    toast.success('Going to Event');
    router.visit(`${getRoute('events.show', props.invite.event.slug)}?#timeline`);
  }
};

const addTemplateToEvent = async (template: TimelineTemplateResource) => {
  if (!props.admin || !props.invite.write || !props.invite.event) return;
  timelineTemplateMenuVisible.value = false;
  await addTimelineTemplateToEvent(template, props.invite.event);
  loaded.value = false;
  await fetchGlobalAssignments();
  rootEmit('assignments_added_to_event');
};

const actions = computed(() => {
  const array = [
    {
      title: 'Day Sheet',
      icon: 'fa-download',
      primary: false,
      action: () => {
        downloadDaySheet();
      },
    },
    props.admin
      ? {
          title: 'Add Assignment',
          primary: false,
          icon: 'fa-plus',
          action: () => {
            openAssignmentModal(null);
          },
          buttonDropdownTitle: 'Add Timeline Template',
          buttonDropdown:
            timelineTemplates.value.length > 0
              ? [
                  {
                    title: 'Add Timeline Template',
                    type: 'header',
                  },
                ].concat(
                  timelineTemplates.value.map((template) => {
                    return {
                      title: template.title,
                      action: (close: () => void) => {
                        addTemplateToEvent(template);
                        close();
                      },
                    };
                  })
                )
              : [],
        }
      : null,
  ];

  return array.filter((i) => i !== null);
});
const { isSmallScreen } = useSmallScreen();

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'update-assignment-intermediate-step': {
      loaded.value = false;
      fetchGlobalAssignments();
      break;
    }
    default:
      break;
  }
});

const getAllowedAuditSidebarFields = () => {
  return [
    { model: 'App\\Assignment', fields: auditableAssignmentFields() },
    { model: 'App\\ShowTime', fields: auditableShowTimeFields() },
  ];
};

const getRoomBookingsAsRooms = () => {
  if (!props.roomBookings || props.roomBookings.length === 0) return [];
  const grouped = groupItemsByKey(props.roomBookings, 'room_id');
  return grouped.map(function (group) {
    return {
      id: 'room_' + group.room_id,
      title: group.items[0].room_name,
      model: 'room',
      model_id: group.room_id,
    };
  });
};
const getAllowedTimeSlots = () => {
  if (!props.roomBookings || props.roomBookings.length === 0) return [];
  const grouped = groupItemsByKey(props.roomBookings, 'room_id');
  return grouped.map(function (group) {
    return {
      id: 'room_' + group.room_id,
      timeSlots: group.items.map(function (i) {
        return {
          start: i.start,
          end: i.end,
        };
      }),
    };
  });
};
</script>

<template>
  <div>
    <BoxContainer
      v-if="invite.isAccepted"
      header="Timeline"
      :actions="!isSmallScreen ? actions : []"
      togglable>
      <VTable
        v-if="sortedItems.length === 0"
        edge-to-edge>
        <VTableRow no-background>
          <VTableCell> No Assignments Added</VTableCell>
        </VTableRow>
      </VTable>

      <VTable
        v-for="(date, index) in dates"
        :key="date.start_date"
        edge-to-edge
        row-size="small">
        <VTableRow no-background>
          <VTableCell />
          <VTableCell
            main-cell
            colspan="100%">
            <div
              :class="{ 'pt-5': !timeStampsAreSame(date[0].start_date, Object.keys(dates)[0]) }"
              class="text-sm font-headers">
              {{ formattedDate(date[0].start_date) }}
            </div>
          </VTableCell>
        </VTableRow>
        <VTableRow head>
          <VTableCell style="width: 40px" />
          <VTableCell style="width: 90px"> When</VTableCell>
          <VTableCell style="max-width: 300px"> What</VTableCell>
          <VTableCell
            v-if="anyAssignmentHasWhere && !isSmallScreen"
            style="width: 300px" />
          <VTableCell
            v-if="admin && invite.write && !isSmallScreen"
            style="width: 50px" />
        </VTableRow>
        <VTableRow
          v-for="assignment in date"
          :key="assignment.id"
          hide-edit-button
          :title="assignment.description"
          class="change-fa-icon group">
          <VTableCell>
            <i
              class="fa fa-fw fa-circle text-xs"
              :style="'color: ' + assignment.color + ';'" />
          </VTableCell>
          <VTableCell>
            <div class="text-sm">
              {{ getAssignmentDuration(assignment) }}
            </div>
          </VTableCell>
          <VTableCell
            v-if="!isSmallScreen"
            :title="assignment.title"
            main-cell>
            <div>
              {{ assignment.title }}
            </div>
          </VTableCell>
          <VTableCell
            v-if="isSmallScreen"
            :title="assignment.title">
            <div class="text-textColor">
              {{ assignment.title }}
            </div>
            <div class="text-textColor-soft">
              {{ assignment.rooms }}
            </div>
          </VTableCell>

          <VTableCell
            v-if="anyAssignmentHasWhere && !isSmallScreen"
            :title="assignment.rooms">
            <div>
              {{ assignment.rooms }}
            </div>
          </VTableCell>
          <VTableCell
            v-if="admin && invite.write && !isSmallScreen"
            style="width: 50px">
            <VButton
              v-if="assignment.is_assignment"
              size="inTable"
              class="btn-xs"
              icon="fa-pencil"
              @click="openAssignmentModal(assignment)" />
          </VTableCell>
        </VTableRow>
      </VTable>
      <AuditsLastEditText
        v-if="admin && invite && (assignments.length > 0 || showTimes.length > 0)"
        :delay="1000"
        :audit-ticker="auditTicker"
        can-open-audits-modal
        title="Audits for Timeline"
        :allowed-fields="getAllowedAuditSidebarFields()"
        :queryable-parameters="[
          {
            name: 'Assignment',
            id: 'App\\Assignment',
          },
          {
            name: 'Assignable',
            id: 'App\\Assignable',
          },
          {
            name: 'ShowTime',
            id: 'App\\ShowTime',
          },
        ]"
        :url="'/api/audits/events/' + invite.event.id" />
    </BoxContainer>
    <EventDaysheetModal
      :is-open="showDaySheetModal"
      :event="invite.event" />

    <AssignmentModal
      v-if="showAssignmentModal"
      :event-id="invite.event?.id"
      :is-recurring="!!invite.event?.recurring_original_id"
      :write="true"
      :simple="true"
      allow-rooms-to-be-configured
      :event-crew="[{ id: 'global', title: 'Global', model: 'global', model_id: null }]"
      :rooms="getRoomBookingsAsRooms()"
      :allowed-time-slots="getAllowedTimeSlots()"
      :init-assignment="currentAssignment"
      @go-to-event="goToEventTimeline"
      @closed="showAssignmentModal = false"
      @deleted="[(loaded = false), fetchGlobalAssignments(), rootEmit('assignments_added_to_event'), auditTicker++]"
      @updated="[(loaded = false), fetchGlobalAssignments(), rootEmit('assignments_added_to_event'), auditTicker++]"
      @created="[(loaded = false), fetchGlobalAssignments(), rootEmit('assignments_added_to_event'), auditTicker++]" />
  </div>
</template>
