import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { BoardResource } from '@/types/board';
import { getBoards } from '@/services/api-board';
import { formatModel } from '@/util/store-helpers';

type BoardModel = 'Group' | 'Event' | 'GlobalModel';

export const boardStore = defineStore('boards-store', () => {
  const list = ref<Map<string, BoardResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: BoardModel, modelId: number) => {
    if (model === 'GlobalModel') return;

    Echo.join(`On.${model}.${modelId}`)
      .listen(`.board.created`, async () => {
        await fetchBoards(model, modelId, true);
      })
      .listen(`.board.updated`, async () => {
        await fetchBoards(model, modelId, true);
      })
      .listen(`.board.deleted`, async () => {
        await fetchBoards(model, modelId, true);
      });
  };

  const fetchBoards = async (model: BoardModel, modelId: number, force: boolean = false) => {
    if (!force && list.value.has(formatModel(model, modelId))) return list.value.get(formatModel(model, modelId));

    loading.value = true;

    const { data } = await getBoards(model, modelId);

    if (!list.value.has(formatModel(model, modelId)) && model !== 'GlobalModel') {
      listenForBroadcast(model, modelId);
    }

    if (model !== 'GlobalModel') list.value.set(formatModel(model, modelId), data);

    loading.value = false;

    return data;
  };

  return {
    list,
    loading,
    fetchBoards,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(boardStore, import.meta.hot));
}
