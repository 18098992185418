<script lang="ts" setup>
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import { getGroupAssignments } from '@/services/api-group';
import { destroyShift, patchShift, postShift, postShiftSlots } from '@/services/api-shifts';
import { getUserById } from '@/services/api-user';
import { ShiftResource } from '@/types/event';
import type { shiftEvent, ShiftTypeResource } from '@/types/shifts';
import { ShiftModelType } from '@/types/shifts';
import { UserMinimalResource } from '@/types/user';
import { addHours, addMinutes, currentDateTime } from '@/util/date';
import { usePage } from '@inertiajs/vue3';
import { z } from 'zod';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { FestivalSectionEvent, FestivalSectionTimeSlot, FestivalSectionVenue } from '@/types/festival-section';
import type { ProjectLeader } from '@/types/group';
import { capitalize } from '@/util/string-utils';
import {
  changeAndFormatStamp,
  formatStampAsHumanReadableDateTimeShort,
  humanReadableMinutes,
} from '@/util/timeFunctions';
import { dateTimeFormat } from '@/variables/date-format';
import { computed, inject, nextTick, ref } from 'vue';
import moment from 'moment';
import { useToast } from 'vue-toastification';
import { arrayToJoinString, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import ShiftFormConnectEventsSection from '@/components/Shifts/ShiftFormConnectEventsSection.vue';
import ShiftFormConnectEventsSectionFestival from '@/components/Shifts/ShiftFormConnectEventsSectionFestival.vue';
import { festivalSectionsKey } from '@/provide/keys';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

type Props = {
  canEdit: boolean;
  isRecurring?: boolean;
  withEventIds?: boolean | null;
  canBeInvited?: boolean | null;
  multipleEvents: boolean;
  initialEvents?: any[];
  withEvents: boolean;
  autoApproved?: boolean;
  defaultInvitedById?: number | null;
  projectLeaders?: ProjectLeader[];
  initalShift: {
    id?: number | null;
    start: string;
    end: string;
    title?: string;
    shift_type_id?: number | null;
    via_type?: string;
    via_id?: number;
    user_id: number | null;
    description?: string | null;
    invited_by?: number;
    write?: boolean;
    approved?: boolean;
    count?: number;
    in_timeline?: boolean;
    time_slot_id?: number | null;
    place_string?: string | null;
    place_id?: number | null;
    place_type?: string | null;
    crew_slots_count?: number;
    initial_events?: {
      id: number;
      name: string;
    }[];
  };
  shiftTypes?: ShiftTypeResource[];
  model: ShiftModelType;
  modelId: number;
  allResources?: {
    id: string;
    model: string;
    model_id: number;
    title: string;
    parentId?: string;
  }[];
  emitInsteadOfCreate?: boolean;
  withCount?: boolean;
  timeSlots?: FestivalSectionTimeSlot[];
  venues?: FestivalSectionVenue[];
  hasCrewSlots?: boolean;
  showEventSelector?: boolean;
  allAvailableEvents?: FestivalSectionEvent[];
  withinSame?: boolean;
  appendToTitle?: string;
  onFestivalSectionList?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  timeSlots: () => [],
  venues: () => [],
  allResources: () => [],
  allAvailableEvents: () => [],
  projectLeaders: () => [],
  initialEvents: () => [],
  shiftTypes: () => [],
  defaultInvitedById: null,
  hasCrewSlots: false,
  showEventSelector: true,
  isRecurring: false,
  withinSame: false,
  appendToTitle: '',
  onFestivalSectionList: false,
  canBeInvited: null,
  withEventIds: null,
});

const emit = defineEmits<{
  (e: 'closed'): void;
  (e: 'created', value: ShiftResource): void;
  (e: 'updated', value: ShiftResource): void;
  (e: 'deleted', value: number): void;
  (e: 'slotCountUpdated', arg: any): void;
  (e: 'showAssignModal'): void;
}>();

const shiftSchema = z.object({
  shift_type_id: z.number().nullable(),
  title: z.string().nullable(),
  start: z.string(),
  end: z.string(),
  description: z.string().nullable(),
  via_id: z.number().optional(),
  user_id: z.number().nullable(),
  invited_by: z.number().optional(),
  event_ids: z.array(z.number()),
  write: z.boolean(),
  in_timeline: z.boolean(),
  approved: z.boolean(),
  for_sale: z.boolean(),
  count: z.number().nullable(),
  time_slot_id: z.number().nullable(),
  place_string: z.string().nullable(),
  crew_slots_count: z.number().nullable(),
});

type IShift = z.infer<typeof shiftSchema>;

const shift = ref<IShift>({
  shift_type_id: props.initalShift.shift_type_id ?? null,
  title: props.initalShift.title ?? null,
  start: props.initalShift.start ?? currentDateTime(),
  end: props.initalShift.end ?? addMinutes(addHours(currentDateTime(), 6), 1),
  description: props.initalShift.description ?? null,
  via_id: props.initalShift.via_id,
  user_id: props.initalShift.user_id ?? null,
  write: props.initalShift.write ?? false,
  in_timeline: props.initalShift.in_timeline ?? false,
  invited_by: props.initalShift.invited_by ?? props.defaultInvitedById ?? usePage().props.auth.user.id,
  event_ids: props.initalShift.initial_events?.map((e) => e.id) ?? [],
  shift_interest_pivots: getKey(props.initalShift, 'shift_interest_pivots', []),
  approved: props.initalShift.approved ?? false,
  for_sale: props.initalShift.for_sale ?? false,
  count: props.initalShift.count ?? null,
  time_slot_id: props.initalShift.time_slot_id ?? null,
  place_string: props.initalShift.place_string ?? null,
  crew_slots_count: 0,
});
if (props.initalShift && getKey(props.initalShift, 'shift_crew_slots') !== null) {
  shift.value.crew_slots_count = props.initalShift.shift_crew_slots.length;
} else if (props.initalShift && getKey(props.initalShift, 'crew_slots_count') !== null) {
  shift.value.crew_slots_count = props.initalShift.crew_slots_count;
}
const allFestivalSections = inject(festivalSectionsKey, null);
const groupResources = ref(
  props.allResources?.filter((r) => r.model === 'group' && r.id.includes('un_assigned_shifts'))
);

const shiftCount = ref(1);

const events = ref<shiftEvent[]>([]);
const additionalEvents = ref<shiftEvent[]>([]);

const fetchEventsForGroup = async () => {
  const start = moment(shift.value.start).startOf('day').format(dateTimeFormat);
  const end = moment(shift.value.end).endOf('day').format(dateTimeFormat);

  const params = {
    with_resource_ids: false,
    start,
    end,
    with_cancelled: false,
    with_simple: false,
    with_shifts: false,
  };

  const { data } = await getGroupAssignments(props.modelId, params);

  events.value = data
    .filter((e) => !e.shift_id && e.event_id && e.title)
    .map((e) => ({
      id: e.event_id,
      name: e.title,
      venue_id: e.venue_id,
      start: e.start,
      end: e.end,
    }));
};

const fetchEventsForFestival = () => {
  if (props.initalShift.via_type !== 'App\\Models\\Festivals\\FestivalSection') return;

  if (!props.allAvailableEvents?.length) return;

  const start = moment(shift.value.start).startOf('day').format(dateTimeFormat);
  const end = moment(shift.value.end).endOf('day').add(6, 'hours').format(dateTimeFormat);

  events.value = props.allAvailableEvents.map((e) => ({
    id: e.event_id,
    name: `${e.title} `,
    venue_id: e.resourceIds.filter((r) => r.includes('venue')).map((r) => parseInt(r.split('_')[1]))[0] ?? null,
    start: e.start,
    end: e.end,
  }));
};

const fetching = ref(false);
const fetchEvents = async () => {
  if (!props.withEvents) return;
  if (fetching.value) return;
  fetching.value = true;
  switch (props.model) {
    case 'Group':
      await fetchEventsForGroup();
      break;
    case 'Festival':
      await fetchEventsForFestival();
      break;
    default:
      events.value = [];
  }
  fetching.value = false;
};

const uniqueEvents = computed((): shiftEvent[] => {
  const unique: shiftEvent[] = [];
  events.value.forEach((e) => {
    if (!unique.find((u) => u.id === e.id)) unique.push(e);
  });
  additionalEvents.value.forEach((e) => {
    if (!unique.find((u) => u.id === e.id)) unique.push(e);
  });
  return unique;
});

const loadedUnknownUser = ref<UserMinimalResource | null>(null);

const fetchUnknownUser = async (id: number) => {
  const { data } = await getUserById(id);
  loadedUnknownUser.value = data;
};

const isGlobal = ref(props.isRecurring);

const shiftTimeSlotOptionText = (timeslot: FestivalSectionTimeSlot) => {
  if (timeslot === null) return null;
  if (timeslot.id === null) return timeslot.title;
  let text = timeslot.title;
  if (timeslot.start) {
    if (timeslot.end) {
      text = `${text} (${formatStampAsHumanReadableDateTimeShort(timeslot.start)}
          - ${formatStampAsHumanReadableDateTimeShort(timeslot.end)})`;
    } else {
      text = `${text} (${formatStampAsHumanReadableDateTimeShort(timeslot.start)})`;
    }
  } else if (!timeslot.start && timeslot.date) {
    if (timeslot.minutes) {
      text = `${text} (${timeslot.date} duration: ${humanReadableMinutes(timeslot.minutes)})`;
    } else {
      text = `${text} (${formatStampAsHumanReadableDateTimeShort(timeslot.date)})`;
    }
  } else if (!timeslot.start && !timeslot.date && timeslot.minutes) {
    text = `${text} (${humanReadableMinutes(timeslot.minutes)})`;
  }
  return text;
};

const selectedVenue = ref<FestivalSectionVenue | null>(null);

const allVenues: FestivalSectionVenue[] = [];
if (props.venues) {
  props.venues.forEach((v) => {
    allVenues.push({ ...v, icon: 'fa-house fa-regular mr-3' });
    if (v.children?.length) {
      v.children.forEach((c) => {
        allVenues.push({ ...c, icon: 'fa-cube fa-regular mr-3 ml-3' });
      });
    }
  });
}

if (allVenues.length) {
  if (props.initalShift.place_id) {
    const vr = allVenues?.find(
      (v) =>
        v.model_id === props.initalShift.place_id &&
        'App\\' + capitalize(v.model ?? '') === props.initalShift.place_type
    );
    if (vr) selectedVenue.value = vr;
    // } else {
    //   const vr = allVenues?.find((v) => v.model_id === null);
    //   if (vr) selectedVenue.value = vr;
  }
}

const userResources = computed(() => {
  if (!shift.value.via_id) return [];

  if (allFestivalSections) {
    return allFestivalSections.festivalSections.value
      ?.filter((p) => {
        return p.id === shift.value.via_id;
      })
      .flatMap((p) => {
        return p.users.map((u) => {
          return {
            id: 'user_' + u.id,
            model: 'user',
            model_id: u.id,
            title: u.name,
            parentId: null,
          };
        });
      })
      .concat(
        loadedUnknownUser.value
          ? [
              {
                id: `user_${loadedUnknownUser.value.id}`,
                model: 'user',
                model_id: loadedUnknownUser.value.id,
                title: loadedUnknownUser.value.name,
              },
            ]
          : []
      );
  }

  const array: {
    id: string;
    model: string;
    model_id: number;
    title: string;
    parentId?: string;
  }[] = [];

  switch (props.initalShift.via_type) {
    case 'App\\Group': {
      props.allResources?.forEach((r) => {
        if (
          r.model === 'user' &&
          (r.parentId === `App\\Group_${shift.value.via_id}_un_assigned_shifts` ||
            r.parentId === `group_${shift.value.via_id}`)
        ) {
          array.push(r);
        }
      });
      break;
    }
    case 'App\\Models\\Festivals\\FestivalSection': {
      props.allResources?.forEach((r) => {
        if (r.model === 'user') {
          array.push(r);
        }
      });
      break;
    }
    default: {
      break;
    }
  }
  if (loadedUnknownUser.value?.name && array.length) {
    array.push({
      id: `user_${loadedUnknownUser.value.id}`,
      model: 'user',
      model_id: loadedUnknownUser.value.id,
      title: loadedUnknownUser.value.name,
    });
  }
  return array;
});

const loading = ref(false);

const deleteShift = async (close: () => void) => {
  if (!props.initalShift.id) return;

  const yes = await useCertaintyModal().assertCertain('Delete Shift', 'Are you sure you want to delete this shift?');
  if (!yes) return;

  if (props.emitInsteadOfCreate) {
    emit('deleted', props.initalShift.id);
    close();
    return;
  }

  loading.value = true;

  await destroyShift(props.initalShift.id, props.isRecurring);

  emit('deleted', props.initalShift.id);
  close();
};

const updateShiftCrewSlots = async (ShiftId: number) => {
  if (!props.hasCrewSlots || !shift.value.crew_slots_count) return;

  await postShiftSlots(ShiftId, shift.value.crew_slots_count);
};

const updateShift = async (close: () => void) => {
  if (!props.initalShift.id) return;
  if (props.emitInsteadOfCreate) {
    emit('updated', { ...props.initalShift, ...shift.value });
    useToast().success('Shift Updated');
    close();
    return;
  }

  loading.value = true;

  const params = {
    title: shift.value.title,
    description: shift.value.description,
    shift_type_id: shift.value.shift_type_id,
    write: shift.value.write,
    in_timeline: shift.value.in_timeline,
    approved: shift.value.approved,
    for_sale: shift.value.for_sale,
    start: shift.value.start,
    end: shift.value.end,
    user_id: shift.value.user_id,
    via_id: shift.value.via_id,
    via_type: props.initalShift.via_type,
    invited_by: shift.value.invited_by,
    is_global: isGlobal.value,
    time_slot_id: shift.value.time_slot_id,
    place_id: selectedVenue.value?.model_id ?? null,
    place_type: selectedVenue.value?.model ? 'App\\' + capitalize(selectedVenue.value.model) : null,
    place_string: selectedVenue.value?.id ? null : shift.value.place_string,
  };
  if (computedWithEventIds.value) {
    params.event_ids = shift.value.event_ids;
  }

  await patchShift(props.initalShift.id, params);
  if (props.hasCrewSlots) {
    await updateShiftCrewSlots(props.initalShift.id);
  }
  useToast().success('Shift updated');
  if (computedWithEventIds.value) {
    shift.value.events = shift.value.event_ids.map((id) => uniqueEvents.value.find((e) => e.id === id));
  }
  emit('updated', { ...props.initalShift, ...shift.value });
  loading.value = false;
  close();
};

const createShift = async (close: () => void, approve = false) => {
  if (props.emitInsteadOfCreate) {
    emit('created', { ...props.initalShift, ...shift.value, approved: props.autoApproved || approve });
    useToast().success('Shift added');
    close();
    return;
  }

  loading.value = true;

  const params = {
    start: shift.value.start,
    end: shift.value.end,
    title: shift.value.title,
    description: shift.value.description,
    shift_type_id: shift.value.shift_type_id,
    approved: props.autoApproved || approve,
    via_id: shift.value.via_id,
    via_type: props.initalShift.via_type,
    user_id: shift.value.user_id,
    invited_by: shift.value.invited_by,
    is_global: isGlobal.value,
    event_ids: computedWithEventIds.value ? shift.value.event_ids : [],
    write: shift.value.write,
    in_timeline: shift.value.in_timeline,
    place_id: selectedVenue.value?.model_id ?? null,
    place_type: selectedVenue.value?.model ? 'App\\' + capitalize(selectedVenue.value.model) : null,
    place_string: selectedVenue.value?.id ? null : shift.value.place_string,
    time_slot_id: shift.value.time_slot_id,
    for_sale: shift.value.for_sale,
    count: shift.value.count,
  };

  if (props.model === 'Group' && shift.value.user_id === null && shiftCount.value >= 2) {
    let emitData = null;
    for (let i = 0; i < shiftCount.value; i++) {
      const { data } = await postShift(props.model, props.modelId, params);
      emitData = data;
    }
    emit('created', emitData);
  } else {
    const { data } = await postShift(props.model, props.modelId, params);

    if (props.hasCrewSlots && shift.value.crew_slots_count && shift.value.crew_slots_count > 0) {
      await updateShiftCrewSlots(data.id);
    }
    emit('created', data);
  }

  useToast().success('Shift created');

  close();
};

const timeSlotUpdated = () => {
  const timeSlot = getItemFromArrayBasedOnId(shift.value.time_slot_id, props.timeSlots, null);
  if (timeSlot) {
    if (timeSlot.start && timeSlot.end) {
      shift.value.start = timeSlot.start;
      shift.value.end = timeSlot.end;
    } else if (timeSlot.date) {
      shift.value.start = changeAndFormatStamp({
        stamp: timeSlot.date,
        addMinutes: 12 * 60,
      });
      let minutes = timeSlot.minutes;
      if (!minutes) minutes = 120;
      shift.value.end = changeAndFormatStamp({
        stamp: shift.value.start,
        addMinutes: 12 * 60 + minutes,
      });
    }
  }
};
const showEventSelector = computed(() => {
  return uniqueEvents.value.length && !isGlobal.value && computedWithEventIds.value && props.showEventSelector;
});
const hasFurtherOptions = computed(() => {
  return (
    props.withCount ||
    props.hasCrewSlots ||
    computedCanBeInvited.value ||
    (!!props.initalShift.id && !props.autoApproved) ||
    (props.isRecurring && props.initialEvents?.length === 1)
  );
});

const shiftTypeUpdated = async () => {
  await nextTick();
  // if (!props.initalShift?.id && shift.value.shift_type_id !== null) {
  const shiftType = getItemFromArrayBasedOnId(shift.value.shift_type_id, props.shiftTypes);
  if (!shiftType) return;
  const items = [];
  if (shiftType.project_leader_id) {
    shift.value.invited_by = shiftType.project_leader_id;
    items.push('Project Leader');
  }
  if (shiftType.start_minutes) {
    shift.value.start = changeAndFormatStamp({
      stamp: shift.value.start,
      startOf: 'day',
      addMinutes: shiftType.start_minutes,
    });
    items.push('Start');
  }
  if (shiftType.duration) {
    shift.value.end = changeAndFormatStamp({
      stamp: shift.value.start,
      addMinutes: shiftType.duration,
    });
    items.push('Duration');
  }
  if (shiftType.via_id && shiftType.via_type) {
    // shift.value.user_id = null;
    shift.value.via_id = shiftType.via_id;
    shift.value.via_type = shiftType.via_type;
    items.push('Assigned Group');
    nextTick(() => {
      if (shift.value.user_id) {
        if (userResources.value.filter((u) => u.model_id === shift.value.user_id).length === 0) {
          useToast().info(
            getItemFromArrayBasedOnId(
              shift.value.user_id,
              props.allResources.filter((r) => r.model === 'user'),
              { title: 'Assigned User' },
              'model_id'
            )?.title +
              ' has been removed as not of ' +
              getItemFromArrayBasedOnId(
                shift.value.via_id,
                groupResources.value,
                { title: 'selected group' },
                'model_id'
              )?.title
          );
          shift.value.user_id = null;
        }
      }
    });
  }
  shift.value.in_timeline = shiftType.in_timeline;
  items.push('Timeline Status');
  shift.value.for_sale = shiftType.for_sale;
  shift.value.write = shiftType.write;
  if (items.length > 0) {
    useToast().success(`${arrayToJoinString(items)} has been updated according to ${shiftType.title}`);
  }
  // }
};

const setPlaceFromEvent = (withToast = true) => {
  if (!showEventSelector.value) return;
  if (props.multipleEvents) return;
  if (shift.value.event_ids.length !== 1) return;
  const uniqueEvent = getItemFromArrayBasedOnId(shift.value.event_ids[0], uniqueEvents.value);
  if (uniqueEvent && uniqueEvent.venue_id) {
    const venue = getItemFromArrayBasedOnId(uniqueEvent.venue_id, allVenues, null, 'model_id');
    if (venue) {
      selectedVenue.value = venue;
      if (withToast) useToast().info('Venue has been set from event');
    }
  }
};

const computedWithEventIds = computed(() => {
  if (props.withEventIds !== null) return props.withEventIds;
  if (!selectedFestivalSection.value) return false;
  return selectedFestivalSection.value.shift_follows_events;
});
const computedCanBeInvited = computed(() => {
  if (props.canBeInvited !== null) return props.canBeInvited;
  if (!selectedFestivalSection.value) return true;
  return selectedFestivalSection.value.invite;
});
const selectedFestivalSection = computed(() => {
  if (!allFestivalSections || !shift.value.via_id) return null;
  if (shift.value.via_id) {
    return getItemFromArrayBasedOnId(shift.value.via_id, allFestivalSections.festivalSections.value);
  }
});

const setup = async () => {
  await fetchEvents();
  if (!props.initalShift.id) {
    setPlaceFromEvent(false);
  }
  if (allFestivalSections) {
    await allFestivalSections.fetchSections(false);
    if (!props.initalShift.id) {
      if (selectedFestivalSection.value) {
        shift.value.in_timeline = selectedFestivalSection.value.in_timeline;
      }
    }
  }
  if (props.initalShift.user_id) {
    if (!userResources.value?.some((res) => res.model === 'user' && res.model_id === props.initalShift.user_id)) {
      await fetchUnknownUser(props.initalShift.user_id);
    }
  }
};
setup();
</script>

<template>
  <CrudSlideout
    :title="(!!initalShift?.id ? 'Update Shift' : 'Create Shift') + appendToTitle"
    medium
    :within-same="withinSame"
    :only-close-button="!canEdit"
    title-highlight="Shift"
    :update="!!initalShift?.id"
    :loading="loading"
    :disabled="!canEdit"
    :base-z-index="200"
    @update="updateShift"
    @delete="deleteShift"
    @closed="$emit('closed')">
    <div class="divide-y">
      <div class="form-layout grid-cols-2 p-edge">
        <VSelect
          v-if="shiftTypes?.length"
          v-model="shift.shift_type_id"
          nullable
          nullable-display-text="N/A"
          option-value="title"
          label="Shift Type"
          :options="shiftTypes"
          @update:model-value="shiftTypeUpdated" />
        <VSelect
          v-if="timeSlots?.length"
          v-model="shift.time_slot_id"
          label="Time Slot"
          nullable
          :can-edit="canEdit"
          nullable-display-text="Without Time Slot"
          :options="
            timeSlots.map((t) => {
              return {
                id: t.id,
                name: shiftTimeSlotOptionText(t),
              };
            })
          "
          @update:model-value="timeSlotUpdated">
        </VSelect>

        <TextInput
          v-model="shift.title"
          label="Title"
          :can-edit="canEdit"
          placeholder="Title of shift, if not same as type" />

        <StartEndPicker
          v-model:start="shift.start"
          v-model:end="shift.end"
          class="md:col-span-2"
          with-duration
          vertical
          :allow-no-duration="false"
          :can-edit="canEdit"
          required
          with-time
          :duration-options="[60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720]"
          @update:start="fetchEvents()"
          @update:end="fetchEvents()" />
      </div>

      <div class="form-layout grid-cols-2 p-edge">
        <VSelect
          v-if="onFestivalSectionList && allFestivalSections?.festivalSections.value.length"
          v-model="shift.via_id"
          :can-edit="initalShift?.id === null && canEdit"
          can-search
          label="Festival Section"
          :options="allFestivalSections.festivalSections.value" />

        <div v-if="groupResources?.length > 0">
          <VSelect
            v-model="shift.via_id"
            label="Assigned Group"
            option-value="title"
            option-key="model_id"
            :options="groupResources"
            @update:model-value="
              [
                nextTick(() => {
                  if (
                    shift.user_id !== null &&
                    userResources.filter((u) => u.model_id === shift.user_id).length === 0
                  ) {
                    useToast().info(
                      getItemFromArrayBasedOnId(
                        shift.user_id,
                        allResources.filter((r) => r.model === 'user'),
                        { title: 'Assigned User' },
                        'model_id'
                      )?.title +
                        ' has been removed as not of ' +
                        getItemFromArrayBasedOnId(shift.via_id, groupResources, { title: 'selected group' }, 'model_id')
                          ?.title
                    );
                    shift.user_id = null;
                  }
                }),
              ]
            " />
        </div>
        <div v-if="userResources.length">
          <VSelect
            v-model="shift.user_id"
            nullable-display-text="Not assigned"
            nullable
            can-search
            :can-edit="canEdit"
            option-key="model_id"
            option-value="title"
            :groups="shift.shift_interest_pivots.length > 0"
            :options="
              shift.shift_interest_pivots.length > 0
                ? [
                    {
                      label: 'Has Requested Shift',
                      options: userResources.filter(
                        (c) =>
                          c.model === 'user' && shift.shift_interest_pivots.map((p) => p.user_id).includes(c.model_id)
                      ),
                    },
                    {
                      label: 'Others',
                      options: userResources.filter(
                        (c) =>
                          c.model === 'user' && !shift.shift_interest_pivots.map((p) => p.user_id).includes(c.model_id)
                      ),
                    },
                  ]
                : userResources
            "
            :label="model === 'Festival' ? 'Assigned Crew' : 'Assigned User'" />
        </div>

        <div v-else-if="shift.via_id && model === 'Group'">
          <TextInput
            :can-edit="false"
            :placeholder="
              'No users available in ' +
              getItemFromArrayBasedOnId(shift.via_id, groupResources, { title: 'Crew' }, 'model_id')?.title
            "
            :label="model === 'Festival' ? 'Assigned Crew' : 'Assigned User'" />
        </div>

        <div v-if="projectLeaders?.length > 0">
          <VSelect
            v-model="shift.invited_by"
            label="Project Leader"
            option-value="name"
            required
            :options="projectLeaders" />
        </div>
        <div v-if="allVenues.length">
          <VSelect
            v-model="selectedVenue"
            object
            nullable
            nullable-display-text="Select a place, or input Place"
            placeholder="Select a place, or input"
            :can-edit="canEdit"
            option-value="title"
            can-search
            :options="allVenues.filter((v) => v.id !== 'withoutResources')"
            label="Where" />
        </div>

        <div v-if="allVenues.length && !selectedVenue?.model_id">
          <TextInput
            v-model="shift.place_string"
            :can-edit="canEdit"
            label="Where*"
            placeholder="Where is it ?" />
        </div>
      </div>

      <div
        v-if="showEventSelector"
        class="p-edge">
        <ShiftFormConnectEventsSection
          v-if="!isGlobal && multipleEvents"
          v-model:event-ids="shift.event_ids"
          :model-id="modelId"
          :model="model"
          :shift="shift"
          :initial-shift="initalShift"
          :events="uniqueEvents"
          @add-events="additionalEvents.push(...$event)" />

        <ShiftFormConnectEventsSectionFestival
          v-else-if="!multipleEvents"
          :event-id="shift.event_ids[0] ?? null"
          :model-id="modelId"
          :model="model"
          :shift="shift"
          :initial-shift="initalShift"
          :events="uniqueEvents"
          @update:event-id="[(shift.event_ids = $event ? [$event] : []), setPlaceFromEvent()]" />

        <!--        <div class="grid grid-cols-2 form-layout">-->
        <!--          <VSelect-->
        <!--            v-if="!multipleEvents"-->
        <!--            :model-value="shift.event_ids[0] ?? null"-->
        <!--            :can-edit="canEdit"-->
        <!--            nullable-->
        <!--            label="Select Event"-->
        <!--            nullable-display-text="No Events"-->
        <!--            :options="uniqueEvents"-->
        <!--            @update:model-value="[(shift.event_ids = $event ? [$event] : []), setPlaceFromEvent()]" />-->
        <!--        </div>-->
      </div>

      <div
        v-if="hasFurtherOptions"
        class="space-y-7 p-edge">
        <NumberInput
          v-if="withCount"
          v-model="shift.count"
          :disabled="!canEdit"
          :min="1"
          size="small"
          :with-controlles="true"
          label="Count" />

        <NumberInput
          v-if="hasCrewSlots"
          v-model="shift.crew_slots_count"
          :disabled="!canEdit"
          :with-controlles="true"
          size="small"
          :min="0"
          :max="100"
          label="Responder slots" />
        <div class="grid grid-cols-3">
          <div class="col-span-2">
            <SettingToggle
              :model-value="computedCanBeInvited ? shift.write : false"
              :can-edit="canEdit"
              :disabled="!computedCanBeInvited"
              label="Write Permission"
              @update:model-value="shift.write = $event" />

            <SettingToggle
              :model-value="computedCanBeInvited ? shift.in_timeline : false"
              :can-edit="canEdit"
              :disabled="!computedCanBeInvited"
              label="In timeline"
              @update:model-value="shift.in_timeline = $event" />

            <SettingToggle
              v-if="!!initalShift.id && !autoApproved"
              v-model="shift.approved"
              :can-edit="canEdit"
              label="Approve Shift" />

            <SettingToggle
              v-if="(shift.approved || autoApproved) && (model === 'Group' || model === 'Festival')"
              v-model="shift.for_sale"
              :disabled="(shift.user_id !== null && initalShift.id === null) || !canEdit"
              :can-edit="canEdit"
              label="For Sale" />

            <SettingToggle
              v-if="isRecurring && initialEvents?.length === 1"
              v-model="isGlobal"
              :can-edit="canEdit"
              label="Add to all Recurrences of Event" />
          </div>
          <div
            v-if="
              shift.shift_interest_pivots.length > 0 &&
              (shift.user_id === null || (shift.for_sale && initalShift.for_sale)) &&
              model === 'Group'
            "
            class="flex justify-end">
            <ActionButtonGroup
              class="!h-[30px]"
              :actions="[
                {
                  title: 'Assign To Interested Users',
                  icon: 'fa-plus',
                  action: ($event) => {
                    emit('showAssignModal');
                  },
                },
              ]" />
          </div>
        </div>
      </div>
      <div
        v-if="getItemFromArrayBasedOnId(shift.shift_type_id, shiftTypes, { description: null }).description !== null"
        class="col-span-2 flex flex-col p-edge">
        <InputLabel
          :label="
            'Description from ' +
            getItemFromArrayBasedOnId(shift.shift_type_id, shiftTypes, { description: null }).title
          " />
        <span class="text-textColor-soft">
          {{ getItemFromArrayBasedOnId(shift.shift_type_id, shiftTypes, { description: null }).description }}
        </span>
      </div>
      <div class="p-edge">
        <TextareaInput
          v-model="shift.description"
          :can-edit="canEdit"
          label="Description" />
      </div>
    </div>
    <template
      v-if="!initalShift.id && canEdit"
      #footer="{ close }">
      <div class="flex h-full items-center justify-between px-edge">
        <div
          v-if="model === 'Group' && !emitInsteadOfCreate && !hasCrewSlots"
          class="flex items-center gap-3">
          <InputLabel label="Create"></InputLabel>
          <NumberInput
            :model-value="shift.user_id === null ? shiftCount : 0"
            :can-edit="shift.user_id === null"
            size="tiny"
            :min="0"
            :with-controlles="true"
            @update:model-value="shiftCount = $event" />
          <InputLabel label="Shifts"></InputLabel>
        </div>
        <div class="flex h-full items-center justify-end gap-4">
          <VButton
            title="create"
            type="success"
            class="btn-outline"
            @click="createShift(close, false)" />
          <VButton
            v-if="!autoApproved"
            type="success"
            title="create & approve"
            @click="createShift(close, true)" />
        </div>
      </div>
    </template>
  </CrudSlideout>
</template>
