import { FestivalSectionPublicFormResponse } from '@/types/festival-section';
import type {
  FestivalSectionCrew,
  FestivalSectionCalendarElement,
  FestivalSectionEvent,
  FestivalSectionResource,
  FestivalSectionVenue,
  PreferredTimeSlot,
} from '@/types/festival-section';

export const getFestivalSectionCrew = async (festivalSectionId: number) => {
  return axios.get<FestivalSectionCrew[]>(`/api/festival-sections/${festivalSectionId}/crew`);
};

export const getFestivalSectionVenue = async (festivalSectionId: number) => {
  return axios.get<FestivalSectionVenue[]>(`/api/festival-sections/${festivalSectionId}/venues`);
};

export const getFestivalSectionScheduler = async (festivalSectionId: number) => {
  return axios.get<FestivalSectionEvent[]>(`/api/festival-sections/${festivalSectionId}/festival-scheduler`);
};

export const getFestivalSection = async (
  festivalSectionId: number,
  params: {
    with_users: boolean;
    with_public_forms: boolean;
    with_time_slots: boolean;
  }
) => {
  return axios.get<FestivalSectionResource>(`/api/festival-sections/${festivalSectionId}`, {
    params,
  });
};

export const getFestivalSectionCalendar = async (
  festivalSectionId: number,
  params: {
    start: string;
    end: string;
  }
) => {
  return axios.get<FestivalSectionCalendarElement[]>(`/api/festival-sections/${festivalSectionId}/calendar`, {
    params,
  });
};

export const getPreferredTimeSlotsFestivalSection = async (festivalSectionId: number) => {
  return axios.get<PreferredTimeSlot[]>(`/api/festival-sections/${festivalSectionId}/preferred-time-slots`);
};

export const getFestivalSectionPublicFormResponses = async (festivalSectionId: number, publicFormId: number) => {
  return axios.get<FestivalSectionPublicFormResponse[]>(
    `/api/festival-sections/${festivalSectionId}/public-forms/${publicFormId}`
  );
};
