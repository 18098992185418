<script setup lang="ts">
import type { RoomBooking } from '@/types/event-request';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { createUuId, getItemFromArrayBasedOnId, getKey, getUrlVar, sortArrayByTime } from '@/util/globals';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import RoomBookingTable from '@/components/RoomBookingTable.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import SectionRoomBookingRoomBookingModal from '@/components/Groups/Settings/EventRequest/Sections/SectionRoomBookingRoomBookingModal.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import RoomBookingModal from '@/components/Rooms/RoomBookingModal.vue';
import VButton from '@/components/Inputs/VButton.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { stampIsValid } from '@/util/timeFunctions';
import { getRoomBooking } from '@/services/api-room-booking';

type Props = {
  content: object | null;
  overrideEditMode: boolean;
  overrideIsTemplate: boolean;
  editMode: boolean;
  isTemplate: boolean;
  rooms: {
    id: number;
    name: string;
  }[];
  eventStart?: string | null;
  eventEnd?: string | null;
  responseMode?: boolean;
  isAnswered: boolean;
  isNewRequest: boolean;
  initalRoomBookings: string[];
  slug?: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'update:content', arg: RoomBooking): void;
  (event: 'timesChanged', arg: object): void;
}>();
const { assertReadyToDeleteModal } = useDeleteObjectModal();
const toast = useToast();

const canSave = computed(() => props.editMode);

const generateUniqueRowId = () => createUuId('booking_');

type EditRoomBooking = {
  working: boolean;
  room_id: number;
  title: string;
  start: string;
  end: string;
  id: string;
  issues: boolean;
};
const ignoreAllRestrictions = ref(getKey(props.content, 'ignore_all_restrictions', false));

watch(ignoreAllRestrictions, () => {
  if (canSave.value) {
    emitContent();
  }
});

const differentiateRestrictions = ref(getKey(props.content, 'differentiate_restrictions', false));

watch(differentiateRestrictions, () => {
  if (canSave.value) {
    emitContent();
  }
});
const allowDoubleBooking = ref(getKey(props.content, 'allow_double_booking', false));

watch(allowDoubleBooking, () => {
  if (canSave.value) {
    emitContent();
  }
});

const description = ref(props.content?.description ?? '');

watch(description, () => {
  if (canSave.value) {
    emitContent();
  }
});

const roomBookings = ref<EditRoomBooking[]>([]);
if (props.content.roomBookings?.length) {
  props.content.roomBookings.forEach((a) => {
    roomBookings.value.push({
      working: !props.isAnswered,
      room_id: a.room_id,
      title: a.title,
      start: a.start,
      end: a.end,
      id: generateUniqueRowId(),
      issues: props.isAnswered ? null : false,
    });
  });
}

watch(
  roomBookings,
  () => {
    if (canSave.value) {
      emitContent();
    }
  },
  { deep: true }
);

type Room = {
  id: number;
  name: string;
};

const includedRooms = ref<Room[]>([]);

const checkIfUrlGivesARoomToBook = () => {
  if (!props.isNewRequest) return;
  const roomIdFromUrl = getUrlVar('room_id');
  if (!roomIdFromUrl) return;
  const room = getItemFromArrayBasedOnId(Number(roomIdFromUrl), includedRooms.value);
  if (!room) return;
  addBookingOfRoom(room);
};

if (!props.isNewRequest && !props.isTemplate) {
  includedRooms.value = [...props.rooms];
}
const contentLoaded = ref(false);
const getRoomsFromContent = () => {
  if (props.content && props.content.room_ids && props.content.room_ids.length && !props.responseMode) {
    contentLoaded.value = false;
    includedRooms.value = [];

    props.content.room_ids.forEach((id) => {
      const index = _.findIndex(props.rooms, (r) => r.id === id);
      if (index > -1) {
        includedRooms.value.push({
          id: props.rooms[index].id,
          name: props.rooms[index].name,
        });
      }
    });
    setTimeout(() => {
      checkIfUrlGivesARoomToBook();
    }, 50);
  }
  nextTick(() => {
    contentLoaded.value = true;
  });
};
setTimeout(
  () => {
    getRoomsFromContent();
  },
  props.isTemplate ? 2000 : 0
);

watch(
  includedRooms,
  () => {
    if (canSave.value && props.isTemplate) {
      emitContent();
    }
  },
  { deep: true }
);

watch(
  () => props.rooms,
  () => {
    if (props.responseMode) {
      includedRooms.value = [...props.rooms];
    }
  },
  { deep: true }
);

const selectedRoom = ref(null);
const showBookingModal = ref(false);

const rechecking = ref(false);
const modalOpen = ref(false);
const selectedRoomBooking = ref(null);

const emitContent = () => {
  if (!contentLoaded.value) return;
  if (props.isTemplate) {
    emit('update:content', {
      room_ids: includedRooms.value.map((r) => r.id),
      differentiate_restrictions: differentiateRestrictions.value,
      ignore_all_restrictions: ignoreAllRestrictions.value,
      allow_double_booking: allowDoubleBooking.value,
    });
    return;
  }

  emit('update:content', {
    roomBookings: roomBookings.value.map((a) => ({
      title: a.title,
      room_id: a.room_id,
      start: a.start,
      end: a.end,
    })),
    description: description.value,
  });
};

const checkBookingForResponseToRequest = async (booking, openModal, start?: string, end?: string) => {
  if (props.isAnswered) {
    booking.working = false;
    return;
  }
  const { data } = await getRoomBooking(booking.room_id, start || booking.start, end || booking.end);
  booking.working = false;
  if (start) {
    booking.start = start;
  }
  if (end) {
    booking.end = end;
  }
  booking.issues = getKey(data, 'status') !== 'Available';
  booking.other_uses = getKey(data, 'other_uses', []);
  booking.restrictions = getKey(data, 'restrictions', []);
};

const recheckAllRoomBookingsForResponse = async (start?: string, end?: string) => {
  if (props.isAnswered) {
    return;
  }
  await nextTick();
  for (let i = 0; i < roomBookings.value.length; i++) {
    await checkBookingForResponseToRequest(roomBookings.value[i], false, start, end);
  }
};

const editBooking = (booking) => {
  if (!props.editMode) return;
  showBookingModal.value = false;
  selectedRoom.value = null;
  selectedRoomBooking.value = null;
  nextTick(() => {
    const index = _.findIndex(includedRooms.value, (r) => r.id === booking.room_id);
    if (index > -1) {
      selectedRoom.value = includedRooms[index];
      selectedRoomBooking.value = {
        id: booking.id,
        title: booking.title,
        room_title: booking.title,
        start: booking.start,
        end: booking.end,
        confirmed: true,
        room_id: booking.room_id,
      };
      showBookingModal.value = true;
    } else {
      toast.warning('Something went wrong, please try again later.');
    }
  });
};

const checkBookingForNewRequest = async (
  booking: {
    working: boolean;
    room_id: number;
    title: string;
    start: string;
    end: string;
    id: string;
    issues: boolean;
  },
  openModal: boolean
) => {
  if (props.isAnswered) {
    booking.working = false;
    return;
  }
  booking.working = true;
  if (!props.slug) {
    await checkBookingForResponseToRequest(booking, openModal);
    return;
  }
  const { data } = await axios.post(`/event-requests/${props.slug}/available`, {
    room_id: booking.room_id,
    start: booking.start,
    end: booking.end,
  });

  booking.working = false;
  if (!props.responseMode) {
    if (ignoreAllRestrictions.value) {
      booking.issues = getKey(data, 'other_uses', []).length > 0;
    } else {
      booking.issues = getKey(data, 'status') !== 'Available';
    }
  } else {
    booking.issues = getKey(data, 'status') !== 'Available';
  }
  if (booking.issues && openModal) {
    toast.warning('There are issues with the requested booking.');
    editBooking(booking);
  }
};

const recheckAllRoomBookingsForNewRequest = async (start: string, end: string) => {
  await nextTick();
  for (let i = 0; i < roomBookings.value.length; i++) {
    if (start && end) {
      roomBookings.value[i].start = start;
      roomBookings.value[i].end = end;
    }
    await checkBookingForNewRequest(roomBookings.value[i], false);
  }
};

const recheckAllRoomBookings = (start?: string, end?: string) => {
  if (props.isAnswered) return;
  if (rechecking.value) return;
  rechecking.value = true;
  setTimeout(() => {
    rechecking.value = false;
  }, 1000);

  if (props.responseMode) {
    recheckAllRoomBookingsForResponse(start, end);
  } else {
    recheckAllRoomBookingsForNewRequest(start, end);
  }
};

const checkIfRoomsShouldBeEmitted = () => {
  if (!props.isTemplate) {
    if (!('roomBookings' in props.content)) {
      if (canSave.value) {
        emitContent();
      } else {
        setTimeout(() => {
          checkIfRoomsShouldBeEmitted();
        }, 500);
      }
    }
  }
};

const removeBooking = async (booking) => {
  const deleteIt = await assertReadyToDeleteModal(
    'Remove Booking of Room',
    `Are you sure you want to remove the booking of ${
      getItemFromArrayBasedOnId(booking.room_id, props.rooms, {
        name: 'this room',
      }).name
    }? It can not be restored.`,
    'Remove'
  );
  if (!deleteIt) return;
  const index = roomBookings.value.indexOf(booking);
  if (index > -1) {
    roomBookings.value.splice(index, 1);
    toast.success('Removed.');
  } else {
    toast.warning('Something happened. Please try again later.');
  }
};

const sortRoomBookings = () => {
  nextTick(() => {
    roomBookings.value = sortArrayByTime(roomBookings.value);
  });
};

const openRoomModal = () => {
  modalOpen.value = false;
  nextTick(() => {
    modalOpen.value = true;
  });
};

const addRoomsToRoomBookingSection = () => {
  if (!props.isTemplate || !props.editMode) {
    return;
  }
  openRoomModal();
};

const checkBooking = (
  booking: {
    working: boolean;
    room_id: number;
    title: string;
    start: string;
    end: string;
    id: string;
    issues: boolean;
  },
  openModal: boolean
) => {
  if (props.responseMode) {
    checkBookingForResponseToRequest(booking, openModal);
  } else {
    checkBookingForNewRequest(booking, openModal);
  }
};

const addBookingOfRoom = (room) => {
  if (roomBookings.value.map((b) => b.room_id).includes(room.id)) return;
  if (!canSave.value) return;
  const id = generateUniqueRowId();
  roomBookings.value.push({
    room_id: room.id,
    title: '',
    start: props.eventStart,
    end: props.eventEnd,
    id,
    issues: false,
    working: true,
  });
  const index = _.findIndex(roomBookings.value, (r) => r.id === id);
  checkBooking(roomBookings.value[index], true);
};

const addRoomBooking = (booking) => {
  roomBookings.value.push({
    room_id: booking.room_id,
    start: booking.start,
    end: booking.end,
    title: booking.title,
    issues: false,
    id: generateUniqueRowId(),
  });

  roomBookings.value.forEach((b) => {
    b.start = booking.start;
    b.end = booking.end;
  });

  emit('timesChanged', {
    start: booking.start,
    end: booking.end,
  });
  sortRoomBookings();
};

const patchRoomBooking = (booking) => {
  const index = _.findIndex(roomBookings.value, (b) => b.id === booking.id);
  if (index > -1) {
    roomBookings.value.splice(index, 1);
  }

  addRoomBooking(booking);
};

const deleteRoomBookingInAnswerMode = async (data) => {
  if (!props.responseMode) return;
  if (!selectedRoomBooking.value) return;
  const deleteIt = await assertReadyToDeleteModal(
    `Remove ${
      getItemFromArrayBasedOnId(selectedRoomBooking.value.room_id, props.rooms, { name: 'this room' }).name
    } From Event`,
    `Are you sure you want to do this?`
  );
  if (!deleteIt) return;
  roomBookings.value = roomBookings.value.filter((r) => r.room_id !== selectedRoomBooking.value.room_id);
  data?.close();
};
const removeRoomBooking = () => {
  roomBookings.value = roomBookings.value.filter((r) => r.room_id !== selectedRoomBooking.value.room_id);
};

watch([() => props.eventStart, () => props.eventEnd], () => {
  setTimeout(() => {
    recheckAllRoomBookings(props.eventStart, props.eventEnd);
  }, 50);
});

recheckAllRoomBookings();
checkIfRoomsShouldBeEmitted();
</script>

<template>
  <div
    :class="isTemplate || !responseMode || editMode ? 'md:grid-cols-[200px_auto] gap-10' : ''"
    class="group grid !p-0 pt-3">
    <div
      v-if="(isTemplate && rooms.length) || (!responseMode && includedRooms.length > 0) || (responseMode && editMode)">
      <div class="flex justify-between">
        <h6 class="pull-left sub-title flex justify-between pb-1 pl-edge text-textColor-soft">Rooms</h6>
        <VButton
          v-if="isTemplate && editMode"
          size="extra-small"
          title="Manage Rooms"
          icon="fa-plus"
          @click="addRoomsToRoomBookingSection" />
      </div>
      <div
        v-if="editMode || isTemplate || isNewRequest"
        class="pl-edg h-full">
        <VTable
          row-size="small"
          edge-to-edge
          header-hover>
          <VTableRow
            v-for="room in includedRooms"
            :key="room.id"
            :main-row="roomBookings.map((b) => b.room_id).includes(room.id)">
            <VTableCell>
              {{ getItemFromArrayBasedOnId(room.id, rooms, { name: 'N/A' }).name }}
            </VTableCell>
            <VTableCell
              main-cell
              style="width: 50px">
              <VButton
                v-if="!isTemplate && canSave && !roomBookings.map((b) => b.room_id).includes(room.id)"
                size="inTable"
                :disabled="!stampIsValid(eventStart) || !stampIsValid(eventEnd)"
                disabled-tool-tip-text="Start And End must be set before a room can be added."
                icon="fa-plus"
                :tool-tip-text="'Book ' + room.name"
                @click="addBookingOfRoom(room)" />
              <IconWithLoading
                v-if="!isTemplate && !canSave"
                icon="fa-ban"
                title="Date must be set to add rooms." />
            </VTableCell>
          </VTableRow>
        </VTable>
      </div>
    </div>

    <h4
      v-if="isTemplate"
      class="text-textColor-soft">
      Room Bookings will come here.
    </h4>
    <div v-if="!isTemplate">
      <RoomBookingTable
        v-if="includedRooms.length"
        :rooms="includedRooms"
        :room-bookings="roomBookings"
        :start-date="eventStart"
        :can-edit="editMode"
        @edit-booking="editBooking"
        @remove-booking="removeBooking" />
      <TextareaInput
        :model-value="description"
        wrapper-class="mt-6"
        placeholder="Write down other remarks regarding your needs for rooms"
        :min-rows="3"
        label="Other Remarks"
        @blur="description = $event" />
    </div>

    <CrudModal
      v-if="modalOpen"
      title="Rooms to include"
      small
      :only-close-button="true"
      @closed="modalOpen = false">
      <div class="form-layout">
        <VMultiselect
          v-model="includedRooms"
          label="Rooms to include"
          title="Which Rooms should be possible to select?"
          :object="true"
          :options="rooms" />
        <SettingCheck
          v-model="allowDoubleBooking"
          label="Allow Requests if already taken"
          title="Would you like to allow for requesting rooms if it already is booked?" />
        <SettingCheck
          v-model="differentiateRestrictions"
          label="Differentiate Restrictions and Bookings"
          title="Would you like inform that usage is restricted when that is the case, or always just tell that it is already in use?" />
        <SettingCheck
          v-model="ignoreAllRestrictions"
          label="Ignore all Restrictions for requested"
          title="Would you like to ignore restrictions, label displaying them for requester? Toggling this on, will hide restrictions from requester." />
      </div>
    </CrudModal>

    <SectionRoomBookingRoomBookingModal
      v-if="showBookingModal && !responseMode"
      :start="eventStart"
      :end="eventEnd"
      :init-booking="selectedRoomBooking"
      :room="getItemFromArrayBasedOnId(selectedRoomBooking.room_id, rooms)"
      :slug="slug"
      :differentiate-restrictions="differentiateRestrictions"
      :ignore-all-restrictions="ignoreAllRestrictions"
      :allow-double-booking="allowDoubleBooking"
      @create="addRoomBooking"
      @update="patchRoomBooking"
      @delete="removeRoomBooking"
      @closed="showBookingModal = false" />

    <RoomBookingModal
      v-if="showBookingModal && responseMode"
      :room="getItemFromArrayBasedOnId(selectedRoomBooking.room_id, rooms)"
      :start="eventStart"
      :end="eventEnd"
      :initial-booking="selectedRoomBooking"
      :is-recurring="false"
      @patch-booking="
        [
          patchRoomBooking({ ...$event.booking, id: selectedRoomBooking.id, room_id: selectedRoomBooking.room_id }),
          $event.close(),
        ]
      "
      @remove-booking="deleteRoomBookingInAnswerMode"
      @closed="showBookingModal = false" />
  </div>
</template>
