export const dateFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const timeFormat = 'HH:mm';
export const secondFormat = 'HH:mm:ss';
export const monthFormat = 'MMMM';
export const yearFormat = 'YYYY';
export const humanReadableDateWithYearFormat = 'Do [of] MMMM YYYY';
export const humanReadableDateFormat = 'dddd Do [of] MMMM';
export const humanReadableDateFormatShort = 'ddd Do [of] MMM';
export const humanReadableDateFormatExtraShort = 'dd Do [of] MM';
export const humanReadableDateFormatWithoutMonth = 'dddd Do';
export const humanReadableWeekDay = 'dddd';

export const hoursBeforeShiftCheckIn = 2;
