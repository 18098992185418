import { PublicFormResponseResource } from '@/types/public-form-response';

const BASE_URL = '/api/public-form-responses';

export const getPublicFormResponses = (params: {
  page: number;
  search: string;
  per_page: number;
  status: string;
  only_assigned: boolean;
  public_form_fields: any;
  with_preferred_festival_sections: boolean;
  with_preferred_time_slots: boolean;
  order_by: string;
  festival_section_id: number;
  public_form_ids: number[];
  public_form_id: number;
}) => {
  return axios.get<{ data: PublicFormResponseResource[] }>(BASE_URL, { params });
};

export const getPublicFormResponse = (
  id: number,
  params: {
    with_preferred_festival_sections: boolean;
    with_preferred_time_slots: boolean;
    with_public_form_fields: boolean;
  }
) => {
  return axios.get<PublicFormResponseResource>(`${BASE_URL}/${id}`, { params });
};

export const postAcceptPublicFormResponse = (id: number) => {
  return axios.post<string>(`${BASE_URL}/${id}/accept`);
};

export const updateFestivalSectionOrder = async (
  publicFormResponseUuid: string,
  festivalSectionId: number,
  order: number
) => {
  const url = `/public-form-responses/${publicFormResponseUuid}/preferred-festival-sections/${festivalSectionId}`;
  const response = await axios.post(url, { order: Number(order) });
  return response.data;
};

export const toggleFestivalSection = async (
  publicFormResponseUuid: string,
  festivalSectionId: number,
  value: any,
  order: number
) => {
  const url = `/public-form-responses/${publicFormResponseUuid}/preferred-festival-sections/${festivalSectionId}`;
  if (!value) {
    await axios.delete(url);
  } else {
    await axios.post(url, { order: Number(order) });
  }
};
