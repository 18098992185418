import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { useEventTypesStore } from '@/store/EventTypesStore';
import { formatModel } from '@/util/store-helpers';
import { eventTypesKey } from '@/provide/keys';
import type { EventTypesComposable } from '@/provide/keys';
import type { ModelType } from '@/types/global';
import type { EventTypeResource } from '@/types/event-request';

export const useEventTypes = (model: ModelType, modelId: number, shouldProvide = true): EventTypesComposable => {
  const { list, loading } = storeToRefs(useEventTypesStore());
  const { fetchEventTypes } = useEventTypesStore();

  const eventTypes = computed(() => list.value.get(formatModel(model, modelId)) || []);

  const fetch = async (force = false) => {
    if (force || !list.value.has(formatModel(model, modelId))) {
      await fetchEventTypes(model, modelId);
    }
  };

  const addItem = (eventType: EventTypeResource) => {
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(key, [...current, eventType]);
  };

  const removeItem = (eventTypeId: number) => {
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(
      key,
      current.filter((item) => item.id !== eventTypeId)
    );
  };

  const updateItem = (eventType: EventTypeResource) => {
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(
      key,
      current.map((item) => (item.id === eventType.id ? eventType : item))
    );
  };

  if (shouldProvide) {
    provide(eventTypesKey, {
      eventTypes,
      fetch,
      addItem,
      removeItem,
      updateItem,
      loading: readonly(loading),
    });
  }

  return {
    eventTypes,
    loading,
    fetch,
    addItem,
    removeItem,
    updateItem,
  };
};
