<script lang="ts" setup>
import ContextSidebarList from '@/components/ContextSidebar/ContextSidebarList.vue';
import ContextSidebarListItem from '@/components/ContextSidebar/ContextSidebarListItem.vue';
import CrescatLogo from '@/components/Images/CrescatLogo.vue';
import LogoCompact from '@/components/Images/LogoCompact.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VButton from '@/components/Inputs/VButton.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { useUserStore } from '@/store/UserStore';
import type { FestivalResource } from '@/types/festival';
import type { ContextSidebarTab } from '@/types/global';
import type { GroupResource } from '@/types/group';
import type { UserGroupsResource } from '@/types/inertia';
import {
  festivalContextFormat,
  getTypeAndIdFromContext,
  groupContextFormat,
  personalContextFormat,
} from '@/util/context-format';
import { getIndexFromArrayBasedOnId, getKey } from '@/util/globals';
import { getRoute } from '@/util/route';
import { createFestivalTabs } from '@/util/tabs/festival-tabs';
import { groupTabs, isAdminOfGroup } from '@/util/tabs/group-tabs';
import { usePersonalMenu } from '@/util/tabs/personal-tabs';
import { router, usePage } from '@inertiajs/vue3';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useSmallScreen } from '@/composables/use-small-screen';
import UnlockPaidGroupFeaturePill from '@/components/UnlockFeatures/UnlockPaidGroupFeaturePill.vue';

type Props = {
  group?: GroupResource;
  festival?: FestivalResource;
};

defineProps<Props>();

const userStore = useUserStore();
const contextSidebarStore = useContextSidebarStore();

const userGroups = usePage().props.auth.user.groups;

const { isSmallScreen } = useSmallScreen();

const contextSelectorOpen = ref(false);

const createContextOptionTitle = (group: UserGroupsResource) => {
  let title = `${group.name}`;

  const parentName = getKey(group, 'parent_name');
  if (parentName) {
    title += ` (${parentName})`;
  } else if (group.parent_id) {
    const parentGroup = userGroups.find((g) => g.id === group.parent_id);
    if (parentGroup?.id) {
      title += ` (${parentGroup.name})`;
    }
  }
  return title;
};

contextSidebarStore.contextOptions = [
  ...userGroups
    .filter((g) => g.read)
    .map((group) => ({
      key: groupContextFormat(group.id),
      title: createContextOptionTitle(group),
      slug: group.slug,
    })),
  {
    key: personalContextFormat(),
    title: userStore.name,
    slug: null,
  },
  ...contextSidebarStore.adminOpenGroup.map((group) => ({
    key: groupContextFormat(group.id),
    title: createContextOptionTitle(group),
    slug: group.slug,
  })),
];

// contextSidebarStore.contextOptions = [
//   {
//     key: 1,
//     title: 'tyer',
//     slug: 'null',
//   },
// ];

// const contextsOptions = ref([
//   ...userGroups
//     .filter((g) => g.read)
//     .map((group) => ({
//       key: groupContextFormat(group.id),
//       title: createContextOptionTitle(group),
//       slug: group.slug,
//     })),
//   {
//     key: personalContextFormat(),
//     title: userStore.name,
//     slug: null,
//   },
//   ...contextSidebarStore.adminOpenGroup.map((group) => ({
//     key: groupContextFormat(group.id),
//     title: createContextOptionTitle(group),
//     slug: group.slug,
//   })),
// ]);

watch(
  () => contextSidebarStore.personalFestivals,
  (festivals) => {
    festivals.forEach((festival) => {
      if (!contextSidebarStore.contextOptions.some((o) => o.key === festivalContextFormat(festival.id))) {
        contextSidebarStore.contextOptions.push({
          key: festivalContextFormat(festival.id),
          title: festival.name,
          slug: festival.slug,
        });
      }
    });

    if (usePage().props.festival) {
      try {
        const festivalParentPartOfContext = contextSidebarStore.contextOptions.some(
          (c) => c.key === groupContextFormat(usePage().props.festival.owner_id)
        );

        if (!usePage().props.festival.is_active || !festivalParentPartOfContext) {
          if (!festivals.some((f) => f.id === usePage().props.festival.id)) {
            contextSidebarStore.contextOptions.push({
              key: festivalContextFormat(usePage().props.festival.id),
              title: usePage().props.festival.name,
              slug: usePage().props.festival.slug,
            });
          }
        }

        const activeFestivals = festivals.filter((f) => f.is_active);

        if (contextSidebarStore.currentContext === 'personal') {
          if (!activeFestivals.some((f) => f.id === usePage().props.festival?.id)) {
            contextSidebarStore.currentContext = festivalContextFormat(usePage().props.festival.id);
          }
        } else {
          if (usePage().props.festival.is_active) {
            if (!activeFestivals.some((f) => f.id === usePage().props.festival?.id) && !festivalParentPartOfContext) {
              contextSidebarStore.currentContext = festivalContextFormat(usePage().props.festival.id);
            }
          } else {
            if (!activeFestivals.some((f) => f.id === usePage().props.festival?.id)) {
              contextSidebarStore.currentContext = festivalContextFormat(usePage().props.festival.id);
            }
          }
        }
      } catch (e) {
        throw e;
      }
    }
  }
);

watch(
  () => contextSidebarStore.adminOpenGroup,
  (groups) => {
    groups.forEach((group) => {
      if (!contextSidebarStore.contextOptions.some((o) => o.key === groupContextFormat(group.id))) {
        contextSidebarStore.contextOptions.push({
          key: groupContextFormat(group.id),
          title: group.name,
          slug: group.slug,
        });
      }
    });
  },
  { deep: true }
);

if (contextSidebarStore.currentContext) {
  const oldId = contextSidebarStore.currentContextId;
  switch (contextSidebarStore.currentContextType) {
    case 'festival': {
      contextSidebarStore.currentContext = null;
      contextSidebarStore.fill({ festivalId: oldId });
      break;
    }
    case 'group': {
      contextSidebarStore.currentContext = null;
      contextSidebarStore.fill({ groupId: oldId });
      break;
    }
    default: {
      contextSidebarStore.currentContext = null;
      contextSidebarStore.fill({});
      break;
    }
  }
} else {
  switch (usePage().props.auth.user.custom_home_type) {
    case 'App\\Festival': {
      contextSidebarStore.fill({ festivalId: usePage().props.auth.user.custom_home_id });
      break;
    }
    case 'App\\Group': {
      contextSidebarStore.fill({ groupId: usePage().props.auth.user.custom_home_id });
      break;
    }
    default: {
      contextSidebarStore.fill({});
      break;
    }
  }
}

const inContext = computed(() => {
  if (contextSidebarStore.currentGroup) {
    return userGroups.filter((gr) => gr.read).some((g) => g.slug === contextSidebarStore.currentGroup.slug);
  }
  if (contextSidebarStore.currentFestival) {
    return false;
  }
  return true;
});

const currentTabs = ref<ContextSidebarTab[]>([]);

type Section = {
  title: string;
  id: string;
  open: boolean;
  icon: string;
  items: {
    icon: string;
    title: string;
    open?: boolean;
    key: string;
    route?: string | null;
    menu?: ContextSidebarTab[];
  }[];
};

const sections = ref<Section[]>([]);

const currentUrlIsInArray = (array: string[]) => {
  return array.some((url) => window.location.href.includes(url));
};

const getAllPotentialFestivalUrls = (festival: object) => {
  return [
    getRoute('festivals.show', festival.slug),
    getRoute('festivals.administrator', festival.slug),
    getRoute('festivals.performances.show', [festival.slug, '']),
    getRoute('festivals.partnerContact.show', [festival.slug, '']),
    getRoute('festivals.partnerCompany.show', [festival.slug, '']),
  ];
};

watch(
  [
    () => contextSidebarStore.currentGroup,
    () => contextSidebarStore.currentFestival,
    () => contextSidebarStore.personal,
  ],
  ([group, festival, personal]) => {
    sections.value = [];

    if (group?.id) {
      currentTabs.value = groupTabs(group, userStore.admin);
      if (!group.parent_id) {
        if (!sections.value.some((s) => s.title === 'Sub Groups') && group.childGroups.length) {
          sections.value.push({
            title: 'Sub Groups',
            id: 'sub_group',
            open: true,
            icon: 'fa-group fa-regular',
            items: [
              ...group.childGroups.map((g) => ({
                icon: 'fa-group',
                title: g.name,
                open: false,
                key: groupContextFormat(g.id),
                route: getRoute('groups.show', g.slug),
                menu: groupTabs(g, userStore.admin, false),
              })),
            ],
          });
        }
        if (group.festivals?.length && isAdminOfGroup(userStore.admin, group, null)) {
          if (!sections.value.some((s) => s.title === 'Festivals')) {
            sections.value.push({
              title: 'Festivals',
              id: 'festival',
              icon: 'fa-tent fa-regular',
              open: currentUrlIsInArray(group.festivals.flatMap((f) => getAllPotentialFestivalUrls(f))),
              items: [
                ...group.festivals.map((f) => ({
                  icon: 'fa-tent fa-regular',
                  title: f.name,
                  open: currentUrlIsInArray(getAllPotentialFestivalUrls(f)),
                  route: getRoute('festivals.show', f.slug),
                  key: festivalContextFormat(f.id),
                  menu: createFestivalTabs(f, usePage().props.auth.user.is_admin, isSmallScreen.value),
                })),
              ],
            });
          }
        }
      }
    } else if (festival?.id) {
      currentTabs.value = createFestivalTabs(festival, usePage().props.auth.user.is_admin, isSmallScreen.value);
    } else if (personal) {
      currentTabs.value = usePersonalMenu();
      if (userGroups.length) {
        sections.value.push({
          title: 'Groups',
          id: 'group',
          open: false,
          icon: 'fa-group fa-regular',
          items: [
            ...userGroups.map((g) => ({
              icon: 'fa-group',
              title: g.name + (g.parent_name ? ` (${g.parent_name})` : ''),
              open: false,
              key: groupContextFormat(g.id),
              route: getRoute('groups.show', g.slug),
              menu: groupTabs(g, userStore.admin),
            })),
          ],
        });
      }
      if (contextSidebarStore.personalFestivals.length) {
        sections.value.push({
          title: 'Festivals',
          id: 'festival',
          open: false,
          icon: 'fa-tent fa-regular',
          items: [
            ...contextSidebarStore.personalFestivals.map((f) => ({
              icon: 'fa-tent fa-regular',
              id: f.id,
              title: f.name,
              open: false,
              route: getRoute('festivals.show', f.slug),
              key: festivalContextFormat(f.id),
              menu: createFestivalTabs(f, userStore.admin, isSmallScreen.value),
            })),
          ],
        });
      }
      if (contextSidebarStore.personalFestivalSections.length) {
        contextSidebarStore.personalFestivalSections.forEach((festivalSectionInvite) => {
          let sectionOfFestivalsIndex = getIndexFromArrayBasedOnId('festival', sections.value);
          if (sectionOfFestivalsIndex === -1) {
            sections.value.push({
              title: 'Festivals',
              id: 'festival',
              open: false,
              icon: 'fa-tent fa-regular',
              items: [],
            });
          }
          sectionOfFestivalsIndex = getIndexFromArrayBasedOnId('festival', sections.value);
          const festivalIndex = getIndexFromArrayBasedOnId(
            festivalSectionInvite.festival.id,
            sections.value[sectionOfFestivalsIndex].items
          );
          if (festivalIndex > -1) {
            sections.value[sectionOfFestivalsIndex].items[festivalIndex].menu.push({
              hash: '',
              key: `festival_${festivalSectionInvite.festival.id}_festival_sections`,
              title: festivalSectionInvite.festival_section.name,
              icon: 'fa-group',
              route: getRoute('festivals.festivalSections.show', [
                festivalSectionInvite.festival.slug,
                festivalSectionInvite.festival_section.slug,
              ]).toString(),
            });
          } else {
            sections.value[sectionOfFestivalsIndex].items.push({
              icon: 'fa-tent fa-regular',
              menu: [
                {
                  hash: '',
                  key: `festival_${festivalSectionInvite.festival.id}_festival_sections`,
                  title: festivalSectionInvite.festival_section.name,
                  icon: 'fa-group',
                  route: getRoute('festivals.festivalSections.show', [
                    festivalSectionInvite.festival.slug,
                    festivalSectionInvite.festival_section.slug,
                  ]).toString(),
                },
              ],
              key: festivalContextFormat(festivalSectionInvite.festival.id),
              title: festivalSectionInvite.festival.name,
              route: getRoute('festivals.festivalSections.show', [
                festivalSectionInvite.festival.slug,
                festivalSectionInvite.festival_section.slug,
              ]).toString(),
            });
          }
        });
      }
    }

    if (festival === null && inContext.value) {
      if (contextSidebarStore.venueSoftAccessList.length) {
        sections.value.push({
          title: 'Venues',
          id: 'venue_soft_access_list',
          icon: 'fa-map-marker fa-regular',
          open: false,
          items: contextSidebarStore.venueSoftAccessList.map((v) => ({
            icon: 'fa-map-marker',
            title: `${v.venue} - ${v.name}`,
            key: `venue_soft_access_list_${v.id}`,
            route: getRoute('venueAccess.show', v.slug),
          })),
        });
      }
      if (contextSidebarStore.eventRequestTemplates.length) {
        sections.value.push({
          title: 'Request Events',
          id: 'event_request',
          icon: 'fa-map-marker fa-regular',
          open: false,
          items: contextSidebarStore.eventRequestTemplates.map((v) => ({
            icon: 'fa-map-marker',
            title: `${v.title} - ${v.owner}`,
            key: `event_request_${v.id}`,
            route: getRoute('eventRequest.show', v.slug),
          })),
        });
      }
    }
  },
  { immediate: true }
);

const onContextChange = (newContext: string) => {
  const context = contextSidebarStore.contextOptions.find((c) => c.key === newContext);
  if (!context) {
    useToast().error('Context not found');
    return;
  }

  const { id, type } = getTypeAndIdFromContext(context.key);
  switch (type) {
    case 'group': {
      router.visit(getRoute('groups.show', context.slug));
      if (!id) {
        useToast().error('Group not found');
        throw new Error('Group not found');
      }
      contextSidebarStore.currentView = groupContextFormat(id);
      break;
    }
    case 'festival': {
      router.visit(getRoute('festivals.show', context.slug));
      if (!id) {
        useToast().error('Festival not found');
        throw new Error('Festival not found');
      }
      contextSidebarStore.currentView = festivalContextFormat(id);
      break;
    }
    default: {
      router.visit(getRoute('events'));
      contextSidebarStore.currentView = personalContextFormat();
    }
  }
};

const goHome = () => {
  window.location.href = getRoute('home');

  switch (userStore.customHomeType) {
    case 'App\\Group': {
      contextSidebarStore.currentContext = groupContextFormat(userStore.customHomeId);
      break;
    }
    case 'App\\Festival': {
      contextSidebarStore.currentContext = festivalContextFormat(userStore.customHomeId);
      break;
    }
    default: {
      contextSidebarStore.currentContext = personalContextFormat();
    }
  }
};

const onMouseEnter = () => {
  if (contextSidebarStore.sidebarLarge) return;
  contextSidebarStore.sidebarOpen = true;
};

const onMouseLeave = () => {
  if (contextSidebarStore.sidebarLarge) return;
  if (contextSelectorOpen.value) return;
  contextSidebarStore.sidebarOpen = false;
};

const toggleSidebar = () => {
  contextSidebarStore.sidebarLarge = !contextSidebarStore.sidebarLarge;
};

const hasBeenOneSecond = ref(false);
setTimeout(() => {
  hasBeenOneSecond.value = true;
}, 400);
</script>

<template>
  <div
    :class="[
      contextSidebarStore.sidebarLarge || contextSelectorOpen ? 'w-context-sidebar-open' : 'w-context-sidebar-closed',
      hasBeenOneSecond ? '' : '[&>div]:invisible',
    ]"
    class="context-sidebar z-20 h-full overflow-x-hidden border-r bg-sidebarMain transition-[width] hover:w-context-sidebar-open"
    data-intro="sidebar-menu"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave">
    <div
      :class="contextSidebarStore.sidebarOpen ? 'justify-center' : 'justify-start'"
      class="context-top flex h-context-sidebar-top-height flex-col gap-5 border-borderColor-soft bg-sidebarMain text-center">
      <LogoCompact
        v-if="!contextSidebarStore.sidebarOpen && !contextSelectorOpen"
        class="w-[20px] mx-auto fill-textColor" />

      <div class="group flex items-center justify-between py-2 text-center">
        <div
          v-if="contextSidebarStore.sidebarOpen || contextSelectorOpen"
          class="mt-3 cursor-pointer"
          title="Go Back Home"
          @click="goHome">
          <CrescatLogo
            class="mx-auto hover:fill-highlight"
            height="60%"
            title="Go Back Home"
            width="60%" />
        </div>
      </div>
      <div
        v-if="contextSidebarStore.sidebarOpen || contextSelectorOpen"
        class="mx-5 flex flex-col justify-center"
        data-intro="group-name">
        <InputLabel
          class="mx-auto mb-3 !text-sidebarActiveText"
          label="Select affiliation"
          super-text />
        <VSelect
          v-model="contextSidebarStore.currentContext"
          :options="contextSidebarStore.contextOptions"
          can-edit
          dusk="context-affiliation-selector"
          label-class="justify-center"
          option-key="key"
          option-value="title"
          select-class="bg-row"
          wrapper-class="[&_*]:!bg-backgroundColor [&_*]:!text-textColor"
          @dropdown-opened="contextSelectorOpen = true"
          @dropdown-closed="contextSelectorOpen = false"
          @update:model-value="onContextChange" />
      </div>
    </div>
    <div class="flex h-context-sidebar-main-height flex-col overflow-hidden gap-3">
      <div style="flex: 0 1 auto">
        <ContextSidebarList
          :model-value="true"
          section-id="main">
          <ContextSidebarListItem
            v-for="tab in currentTabs"
            :key="tab.key"
            main-menu
            :completion-tracker-name="getKey(tab, 'completionTrackerName')"
            :hash="tab.hash"
            :icon="tab.icon"
            :item-id="tab.key"
            :route="tab.route"
            :title="tab.title" />
        </ContextSidebarList>
      </div>
      <hr
        v-if="sections.length > 0"
        class="border-borderColor-soft" />
      <div
        :class="{ 'overflow-auto': contextSidebarStore.sidebarOpen }"
        class="tiny-scrollbar flex flex-col gap-5"
        style="flex: 1 1 auto">
        <div
          v-for="section in sections"
          :key="section.id">
          <ContextSidebarList
            :key="section.title"
            v-model="section.open"
            :dusk="`context-sidebar-section-${section.id}`"
            :section-id="section.id"
            :data-intro="section.title === 'Sub Groups' ? 'sub-groups-in-sidebar' : null"
            :icon="section.icon"
            :icon-on-small-screen="true"
            :title="section.title"
            is-section-title>
            <ContextSidebarList
              v-for="item in section.items"
              :key="item.key"
              v-model="item.open"
              :dusk="`context-sidebar-section-${item.key}`"
              :can-open="!!item.menu"
              :icon="item.icon"
              :is-sub-menu="item.menu?.length > 0"
              :route="item.route"
              :section-id="item.key"
              :title="item.title"
              class="pl-[10px]"
              @open-list="section.open = true">
              <ContextSidebarListItem
                v-for="tab in item.menu"
                :key="tab.key"
                :hash="tab.hash"
                :icon="tab.icon"
                :item-id="tab.key"
                :route="tab.route"
                :title="tab.title"
                class="pl-[35px]"
                @open-list="[(item.open = true), (section.open = true)]" />
            </ContextSidebarList>
          </ContextSidebarList>
        </div>
      </div>
    </div>
    <div class="flex h-context-sidebar-bottom-height items-center justify-center">
      <VButton
        v-if="contextSidebarStore.sidebarOpen"
        dusk="context-sidebar-toggle"
        :icon="(contextSidebarStore.sidebarLarge ? ' ' : ' rotate-180 ') + '  fa-arrow-left-to-line transition '"
        class="btn-outline-text"
        size="small"
        @click="toggleSidebar">
      </VButton>
    </div>
  </div>

  <UnlockPaidGroupFeaturePill />
</template>
