<script setup lang="ts">
import { InviteResource } from '@/types/invite';
import { computed, nextTick, ref } from 'vue';
import { formatStampAsDate, formatStampAsDateTime, formatStampAsTime, timeStampsAreSame } from '@/util/timeFunctions';
import {
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  getKey,
  sortArrayByTime,
} from '@/util/globals';
import { concatName } from '@/services/api-partners';
import { useVenueRooms } from '@/composables/use-venue-rooms';
import { useToast } from 'vue-toastification';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VButton from '@/components/Inputs/VButton.vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import PartnerCompanyCreateModal from '@/components/Partners/PartnerCompanyCreateModal.vue';
import PartnerContactCreateModal from '@/components/Partners/PartnerContactCreateModal.vue';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { groupRecurringDates } from '@/helpers/recurringFunctions';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { HoldEventDatePivotResource, HoldEventResource } from '@/types/hold-events';
import GroupCreateEventSectionPerformances from '@/components/Groups/partials/GroupCreateEventSectionPerformances.vue';
import { PartnerCompanyResource, PartnerContactResource } from '@/types/partners';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useEventTypes } from '@/composables/use-event-types';
import { useGroupProjectLeaders } from '@/composables/use-group-project-leaders';
import { useGroup } from '@/composables/use-group';
import { getRoute } from '@/util/route';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { RoomBookingResource } from '@/types/room-booking';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { getRoomBooking } from '@/services/api-room-booking';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import VToggle from '@/components/Inputs/VToggle.vue';
import HoldEventConfirmModal from '@/components/Models/HoldEvents/HoldEventConfirmModal.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import ShowTimeCRUDModal from '@/components/ShowTimes/ShowTimeCRUDModal.vue';
import EventRequestSlideOut from '@/components/Models/EventRequests/EventRequestSlideOut.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';

type Props = {
  group: {
    id: number;
  };
  canEdit: boolean;
  venueId?: number | null;
  invite: InviteResource | null;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
  venueId: null,
});

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'deleted'): void;
  (event: 'accepted'): void;
  (event: 'created', ...args: []): void;
  (event: 'updated', ...args: []): void;
}>();

const { group: localGroup, fetch: fetchGroup } = useGroup(props.group.id);

const { venueRooms: rooms, fetch: fetchRooms } = useVenueRooms(props.venueId, props.group.id, false);
const toast = useToast();

const modalOpen = ref(false);
const event = ref(null);
const localInvite = ref(null);
const holdEvent = ref<HoldEventResource | null>(null);
const allRoomBookings = ref([]);
const openRoomBookings = ref(new Set([]));
const roomBookings = ref([]);

const showTimes = ref([]);

const performances = ref([]);
const partnerCompanies = ref<PartnerCompanyResource[]>([]);
const partnerContacts = ref<PartnerContactResource[]>([]);

const selectedEventRequestId = ref(null);
const working = ref(false);
const editMode = ref(false);

const eventChanged = ref(false);
const inviteChanged = ref(false);

interface LocalRoomBookingResource extends RoomBookingResource {
  other_uses: [];
  restrictions: [];
  working: boolean;
}

interface LocalHoldEventDatePivotResource extends HoldEventDatePivotResource {
  roomBookings: LocalRoomBookingResource[];
}

const holdDates = ref<LocalHoldEventDatePivotResource[]>([]);

const updateEvent = async () => {
  if (!eventChanged.value && !inviteChanged.value) return;
  working.value = true;
  if (eventChanged.value) {
    await axios.post(`/api/events/${props.invite.event.id}`, {
      name: event.value.name,
      description: event.value.description,
    });
    eventChanged.value = false;
  }
  if (inviteChanged.value) {
    await axios.patch(`/api/invites/${props.invite.id}`, {
      start: localInvite.value.start,
      end: localInvite.value.end,
      event_type_id: localInvite.value.event_type_id,
      project_leader_id: localInvite.value.project_leader_id,
    });
    inviteChanged.value = false;
  }
  working.value = false;
  useToast().info('Updated');
  emit('updated');
  emit('closed');
  modalOpen.value = false;
};

const addPerformance = async (performance) => {
  if (getItemFromArrayBasedOnId(performance.id, performances.value) !== null) {
    useToast().warning(performance.name + ' already added.');
    return;
  }
  await axios.post(`/api/performances/${performance.id}/invites/attach`, {
    invite_id: props.invite.id,
  });
  performances.value.push({
    ...performance,
    slug: getRoute('groups.performances.show', [props.invite.invitable.slug, performance.slug]),
  });
  useToast().success(performance.name + ' added.');
};

const removePerformance = async (performance) => {
  const certain = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove ' + performance.name,
    'Are you sure you want to remove ' + performance.name + ' from ' + props.invite.event.name + '?'
  );
  if (!certain) return;
  await axios.post(`/api/performances/${performance.id}/invites/detach`, {
    invite_id: props.invite.id,
  });
  const index = getIndexFromArrayBasedOnId(performance.id, performances.value);
  if (index > -1) {
    performances.value.splice(index, 1);
  }
  useToast().success(performance.name + ' removed.');
};

const checkRoomAvailability = async (roomId: number) => {
  const { data } = await axios.post('/api/room-booking', {
    invite_id: props.invite.id,
    room_id: roomId,
    start: formatStampAsDateTime(localInvite.value.start),
    end: formatStampAsDateTime(localInvite.value.end),
    confirmed: false,
  });
  // roomBookings.value.push(data);
  roomBookings.value.push({
    room_id: roomId,
    id: data.id,
    restrictions: [],
    otherUse: [],
    isOk: true,
    title: null,
    loading: false,
  });
  useToast().info('Room Added.');
};

const acceptHoldEvent = async (close: () => void) => {
  // const { rootEmit } = useEmitStore();
  // rootEmit('close-all-drop-downs');
  // if (!props.invite) return;
  // const certain = await useCertaintyModal().assertCertain(
  //   'Convert into Event',
  //   `Are you sure you want to convert ${event.value.name} from a room booking into an event? This is permanent.`
  // );
  // if (!certain) return;
  // isStillSimple.value = false;
  // await updateSimpleEvent(close);
};
const declineHoldEvent = async (close: () => void) => {
  // const { rootEmit } = useEmitStore();
  // rootEmit('close-all-drop-downs');
  // if (!props.invite) return;
  // const certain = await useCertaintyModal().assertCertain(
  //   'Convert into Event',
  //   `Are you sure you want to convert ${event.value.name} from a room booking into an event? This is permanent.`
  // );
  // if (!certain) return;
  // isStillSimple.value = false;
  // await updateSimpleEvent(close);
};

const removeBooking = async (booking) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Room from Booking',
    'Are you sure you want to remove ' +
      getItemFromArrayBasedOnId(booking.room_id, rooms.value, { name: 'this room' }).name +
      '? This is permanent.'
  );
  if (!deleteIt) return;
  if (booking.persisted) {
    await axios.delete(`/api/room-booking/${booking.id}`).catch(() => {
      toast.warning('Could not delete booking');
    });
  }
  const index = roomBookings.value.indexOf(booking);
  if (index > -1) {
    roomBookings.value.splice(index, 1);
  }
  toast.success('Room has been removed');
};

const getRestrictionText = (booking: LocalRoomBookingResource) => {
  if (booking.restrictions.length) {
    const reasons = [];
    booking.restrictions.forEach((restriction) => {
      const roomName = getKey(restriction, 'other_room_name', getKey(restriction, 'room_name', 'A Room'));
      reasons.push({
        reason: `${restriction.eventName} in ${roomName}`,
        message: restriction.message,
        title: `${roomName} is booked for ${restriction.eventName} from ${formattedRestrictionDate(restriction.start)} to ${formattedRestrictionDate(restriction.end)}`,
      });
    });
    return reasons;
  }
  return {};
};
const getOtherUseText = (booking: LocalRoomBookingResource) => {
  if (booking.other_uses.length) {
    let reasons = [];
    booking.other_uses.forEach((otherUse) => {
      reasons.push({
        eventName: otherUse.event.name,
        title: `${otherUse.event.name} has booked ${
          getItemFromArrayBasedOnId(booking.room_id, rooms.value, { name: '' }).name
        }
            from ${formattedRestrictionDate(otherUse.start)} to ${formattedRestrictionDate(otherUse.end)}`,
      });
    });
    return reasons;
  }
  return [];
};
const formattedRestrictionDate = (time) => {
  if (timeStampsAreSame(time, event.value.start, 'day')) {
    return formatStampAsTime(time);
  }
  return formatStampAsDateTime(time, 'MM-DD HH:mm');
};

const attachCompany = async (company) => {
  if (props.invite) {
    await axios.post(`/api/partners/companies/${company.id}/invites/${props.invite.id}/attach`).then(() => {});
  }
  partnerCompanies.value.push({
    name: company.name,
    id: company.id,
    partner_type_id: company.partner_type_id,
  });
  toast.success(`${company.name} added`);
};

const detachCompany = async (company) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Company',
    `Are you sure you want to remove ${company.name} from ${event.value.name} ?`
  );
  if (!deleteIt) return;
  if (props.invite) {
    await axios.post(`/api/partners/companies/${company.id}/invites/${props.invite.id}/detach`);
  }
  const index = getIndexFromArrayBasedOnId(company.id, partnerCompanies.value);
  if (index > -1) {
    partnerCompanies.value.splice(index, 1);
  }
  toast.success(`${company.name} removed`);
};

const attachContact = async (contact) => {
  if (props.invite) {
    await axios.post(`/api/partners/contacts/${contact.id}/invites/${props.invite.id}/attach`);
  }
  partnerContacts.value.push({
    first_name: contact.first_name,
    last_name: contact.last_name,
    phone: contact.phone,
    email: contact.email,
    id: contact.id,
  });
  toast.success(`${concatName(contact)} added`);
};

const detachContact = async (contact) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Contact',
    `Are you sure you want to remove ${concatName(contact)} from ${event.value.name} ?`
  );
  if (!deleteIt) return;
  if (props.invite) {
    await axios.post(`/api/partners/contacts/${contact.id}/invites/${props.invite.id}/detach`);
  }
  const index = getIndexFromArrayBasedOnId(contact.id, partnerContacts.value);
  if (index > -1) {
    partnerContacts.value.splice(index, 1);
  }
  toast.success(`${concatName(contact)} removed.`);
};

const fetchRoomBookings = async () => {
  if (!props.invite) return;
  if (!props.invite.event.is_hold) return;
  if (props.invite.hold_event.accepted_at) return;
  if (props.invite.hold_event.declined_at) return;

  working.value = true;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/room-booking`);
  allRoomBookings.value = data;
  roomBookings.value = [];

  const groupedByEventDateId = Object.values(
    data.reduce((acc, booking) => {
      const {
        hold_event_room_booking_pivot: { hold_event_date_id },
      } = booking;

      if (!acc[hold_event_date_id]) {
        acc[hold_event_date_id] = [];
      }

      acc[hold_event_date_id].push(booking);
      return acc;
    }, {})
  );
  if (groupedByEventDateId.length > 0) {
    groupedByEventDateId[0].forEach((booking) => {
      roomBookings.value.push({
        persisted: true,
        room_id: booking.room_id,
        id: booking.id,
        restrictions: [],
        otherUse: [],
        isOk: true,
        title: booking.room_title ? booking.room_title : '',
      });
    });
  }
  working.value = false;
};

const { eventTypes, fetch: fetchEventTypes } = useEventTypes('Group', props.group.id);
const { projectLeaders, fetchGroupProjectLeaders } = useGroupProjectLeaders(props.group.id);

const setup = async () => {
  if (!props.invite || !props.invite.hold_event) {
    emit('closed');
    modalOpen.value = false;
    return;
  }

  localInvite.value = props.invite;
  holdEvent.value = props.invite.hold_event;
  holdDates.value = props.invite.hold_event.hold_event_date_pivots;

  partnerCompanies.value = props.invite.partner_companies;
  partnerContacts.value = props.invite.partner_contacts;
  performances.value = props.invite.performances;

  showTimes.value = props.invite.show_times;

  editMode.value = false;
  event.value = {
    name: props.invite.event.name,
    description: props.invite.event.description,
    start: formatStampAsDateTime(props.invite.start),
    end: formatStampAsDateTime(props.invite.end),
  };
  await nextTick();
  modalOpen.value = true;
  await fetchRooms(false);
  await fetchEventTypes();
  await fetchGroup();
  await fetchGroupProjectLeaders();
  await fetchRoomBookings();

  for (let i = 0; i < holdDates.value.length; i++) {
    holdDates.value[i].roomBookings = [];
    const localRoomBookings = allRoomBookings.value.filter(
      (r) =>
        r.hold_event_room_booking_pivot && r.hold_event_room_booking_pivot.hold_event_date_id === holdDates.value[i].id
    );
    for (let j = 0; j < localRoomBookings.length; j++) {
      holdDates.value[i].roomBookings.push({
        ...localRoomBookings[j],
        other_uses: [],
        restrictions: [],
        working: true,
      });
    }
  }
  for (let i = 0; i < holdDates.value.length; i++) {
    for (let j = 0; j < holdDates.value[i].roomBookings.length; j++) {
      const { data } = await getRoomBooking(
        holdDates.value[i].roomBookings[j].room_id,
        holdDates.value[i].roomBookings[j].start,
        holdDates.value[i].roomBookings[j].end,
        holdDates.value[i].event_id
      );
      holdDates.value[i].roomBookings[j].other_uses = getKey(data, 'other_uses', []);
      holdDates.value[i].roomBookings[j].restrictions = getKey(data, 'restrictions', []);
      holdDates.value[i].roomBookings[j].working = false;
    }
  }
};
setup();

const searchForCompany = ref(false);
const searchForContact = ref(false);
const dateSelectorOpen = ref(false);
const pageY = ref(null);
const pageX = ref(null);
const openCompanySearch = (event, newSearchForCompany = true) => {
  searchForCompany.value = false;
  searchForContact.value = false;
  dateSelectorOpen.value = false;
  pageY.value = null;
  pageX.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - (newSearchForCompany ? 200 : 300);
    pageY.value = event.target.getBoundingClientRect().y + 38;
    searchForCompany.value = newSearchForCompany;
    searchForContact.value = !newSearchForCompany;
  });
};
const openDateSelector = (event) => {
  searchForCompany.value = false;
  searchForContact.value = false;
  dateSelectorOpen.value = false;
  pageY.value = null;
  pageX.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 200;
    pageY.value = event.target.getBoundingClientRect().y + 38;
    dateSelectorOpen.value = true;
  });
};

const createCompanyName = ref<string | null>(null);
const showCreatePartnerSlideOut = ref(false);

const createCompany = (companyName: string) => {
  createCompanyName.value = companyName;
  showCreatePartnerSlideOut.value = false;
  nextTick(() => {
    showCreatePartnerSlideOut.value = true;
  });
};
const newPartnerContactName = ref('');
const showPartnerContactModal = ref(false);
const createPartnerContact = (name: string) => {
  newPartnerContactName.value = name;
  showPartnerContactModal.value = false;
  nextTick(() => {
    showPartnerContactModal.value = true;
  });
};

const sortedHoldDates = computed(() => {
  return _.orderBy(holdDates.value);
});
const groupedHoldDates = computed(() => {
  return _.orderBy(groupRecurringDates(_.orderBy(holdDates.value)));
});

const addHoldEventDatePivot = async (date: string) => {
  const { data } = await axios.post(`/api/hold-events/${holdEvent.value.id}/hold-event-date-pivots`, {
    'date': date,
  });
  holdDates.value.push(data);
  emit('updated');
  useToast().info('Date Added.');
};
const deleteHoldEventDatePivot = async (datePivot: HoldEventDatePivotResource) => {
  if (holdDates.value.length === 1) {
    const certain = await useCertaintyModal().assertCertain(
      'Delete Hold',
      'You are removing the last date of the hold. This will remove the hold all together. Are you sure you want to do that?'
    );
    if (!certain) return;
    await axios.delete(`/api/events/${props.invite.event.id}`);
    toast.success('Deleted');
    emit('deleted');
    modalOpen.value = false;
    return;
  }
  await axios.delete(`/api/hold-events/${holdEvent.value.id}/hold-event-date-pivots/${datePivot.id}`);
  const index = getIndexFromArrayBasedOnId(datePivot.id, holdDates.value);
  if (index > -1) {
    holdDates.value.splice(index, 1);
  }
  emit('updated');
  useToast().info('Date Removed.');
};
const updateHoldEventDatePivot = async (datePivot: HoldEventDatePivotResource) => {
  await axios.patch(`/api/hold-events/${holdEvent.value.id}/hold-event-date-pivots/${datePivot.id}`, {
    possible: datePivot.possible,
    primary: datePivot.primary,
    notes: datePivot.notes,
  });
};

const updateHoldEvent = async () => {
  if (!holdEvent.value) return;
  await axios.patch(`/api/hold-events/${holdEvent.value.id}`, {
    notes: holdEvent.value.notes,
  });
};

const holdDatesUpdated = async (newHoldDates: []) => {
  if (newHoldDates.length === holdDates.value.length) return;

  if (newHoldDates.length > holdDates.value.length) {
    const existingDates = holdDates.value.map((d) => formatStampAsDate(d.date));
    const addedDate = newHoldDates.filter((d) => !existingDates.includes(d));
    if (addedDate.length !== 1) return;
    await addHoldEventDatePivot(addedDate[0]);
    return;
  }

  const removedDate = holdDates.value.filter((d) => !newHoldDates.includes(formatStampAsDate(d.date)));
  if (removedDate.length !== 1) return;
  await deleteHoldEventDatePivot(removedDate[0]);
};
const deleteEvent = async () => {
  if (!props.invite) return;
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Delete Hold',
    'Are you sure you want to delete this hold? This is permanent.'
  );
  if (!deleteIt) return;
  await axios.delete(`/api/events/${props.invite.event.id}`).catch(() => {
    toast.warning('Could not delete.');
  });
  toast.success('Deleted');
  emit('deleted');
  modalOpen.value = false;
};
const acceptModalOpen = ref(false);
const openAcceptModal = async () => {
  if (!holdEvent.value) return;
  acceptModalOpen.value = false;
  await nextTick();
  acceptModalOpen.value = true;
};

const selectedShowTime = ref(null);
const addShowTime = async () => {
  selectedShowTime.value = null;
  await nextTick();
  selectedShowTime.value = { id: null };
  // let s = null;
  // let e = null;
  // if (showTimes.value.length === 0) {
  //   s = changeAndFormatStamp({
  //     stamp: localInvite.value.start,
  //     startOf: 'day',
  //     addMinutes: 12 * 60,
  //   });
  //   e = changeAndFormatStamp({
  //     stamp: localInvite.value.start,
  //     startOf: 'day',
  //     addMinutes: 13 * 60,
  //   });
  // } else {
  //   const lastObject = showTimes.value[showTimes.value.length - 1];
  //   const start = moment(lastObject.end);
  //   const duration = moment.duration(moment(lastObject.end).diff(lastObject.start));
  //   const end = start.clone().add(duration.asMinutes(), 'minutes');
  //   s = start.format(dateTimeFormat);
  //   e = end.format(dateTimeFormat);
  // }
  // showTimes.value.push({
  //   id: null,
  //   start: s,
  //   end: e,
  //   editing: false,
  //   title: null,
  //   room_id: null,
  //   uuid: createUuId('show_'),
  // });
  // sortShowTimes();
};

const sortShowTimes = () => {
  nextTick(() => {
    showTimes.value = sortArrayByTime(showTimes.value);
  });
};
</script>
<template>
  <CrudSlideout
    v-if="modalOpen && event && holdEvent"
    update
    title="Hold Event"
    small
    main-content-classes="[&_.h-navbar]:h-10"
    :loading="working"
    @closed="[$emit('closed'), (modalOpen = false)]">
    <template #title>
      <div class="h-full">
        <div class="text-sm py-1 h-[30px] items-center font-headers flex justify-between">
          <span v-if="holdEvent?.accepted_at === null && holdEvent?.declined_at === null">
            <i class="fa fa-fw fa-exclamation-circle fa-regular text-pending"></i>
            This Event is <span class="text-pending">Pending Confirmation</span>
          </span>
          <span v-else>
            <i
              v-if="holdEvent.accepted_at"
              class="fa fa-fw fa-check fa-regular text-success"></i>
            <i
              v-if="holdEvent.declined_at"
              class="fa fa-fw fa-ban fa-regular text-warning"></i>
            This Event has been
            <span
              v-if="holdEvent.accepted_at"
              class="text-success">
              Accepted
            </span>
            <span
              v-if="holdEvent.declined_at"
              class="text-warning">
              Declined
            </span>
          </span>
        </div>
        <h1>Hold Event</h1>
      </div>
    </template>

    <div class="flex flex-col [&>div:last-child]:border-none [&>div]:border-b [&>div]:pb-7">
      <div class="form-layout p-edge grid-cols-2">
        <div class="flex gap-5 col-span-2 items-center">
          <TextInput
            v-model="event.name"
            :can-edit="editMode"
            :set-focus="true"
            :is-hidden="!editMode"
            label="Name"
            text-wrapper-class="flex-1"
            @update:model-value="[(eventChanged = true)]" />
          <ActionButtonGroup
            :actions="[
              !editMode
                ? {
                    title: 'Edit',
                    icon: 'fa-pencil',
                    disabled: holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null,
                    action: () => {
                      editMode = true;
                    },
                  }
                : {
                    title: 'Cancel Edit',
                    icon: 'fa-times',
                    action: () => {
                      editMode = false;
                    },
                  },
            ]" />
        </div>

        <TextareaInput
          v-model="event.description"
          :can-edit="editMode"
          :is-hidden="!editMode"
          :min-height="30"
          wrapper-class="col-span-2"
          label="Description"
          @update:model-value="[(eventChanged = true)]" />

        <VSelect
          v-model="localInvite.event_type_id"
          :options="eventTypes"
          nullable
          label="Event type"
          :can-edit="editMode"
          :can-search="true"
          @update:model-value="[(inviteChanged = true)]" />
        <VSelect
          v-if="localGroup && localGroup.using_project_leaders && projectLeaders.length"
          v-model="localInvite.project_leader_id"
          :options="projectLeaders"
          :can-edit="editMode"
          nullable
          :can-search="true"
          label="Project Leader"
          @update:model-value="[(inviteChanged = true)]" />

        <StartEndPicker
          v-model:start="localInvite.start"
          v-model:end="localInvite.end"
          :can-edit="editMode"
          :is-hidden="!editMode"
          required
          vertical
          class="col-span-2"
          :with-date="false"
          :allow-no-duration="false"
          with-duration
          with-time
          @update:start="[(inviteChanged = true)]"
          @update:end="[(inviteChanged = true)]" />

        <div class="flex col-span-2 justify-end">
          <VButton
            type="success"
            size="tiny"
            icon="fa-save"
            title="Update"
            :disabled="editMode ? !eventChanged && !inviteChanged : true"
            @click="updateEvent" />
        </div>
      </div>

      <div
        v-if="holdEvent"
        class="p-edge flex flex-col gap-edge">
        <h2 class="">Notes</h2>
        <TextareaInput
          v-model="holdEvent.notes"
          :can-edit="holdEvent?.accepted_at === null && holdEvent?.declined_at === null"
          :min-height="30"
          wrapper-class="col-span-2"
          @blur="updateHoldEvent" />
      </div>
      <div class="">
        <div class="flex justify-between p-edge">
          <h2 class="">Dates</h2>
          <div>
            <ActionButtonGroup
              :actions="[
                {
                  title: 'Add',
                  icon: 'fa-plus',
                  disabled: holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null,
                  primary: false,
                  action: ($event) => {
                    openDateSelector($event);
                  },
                },
              ]" />

            <FloatingWindowContainer
              v-if="pageX && pageY && dateSelectorOpen"
              :page-x="pageX"
              :page-y="pageY">
              <VDatepicker
                :model-value="holdDates.map((d) => d.date)"
                multi-dates
                inline
                :start-date="event.start_date"
                @update:model-value="holdDatesUpdated" />
            </FloatingWindowContainer>
          </div>
        </div>
        <div class="flex flex-col gap-5 px-edge">
          <BoxContainer
            v-for="date in sortedHoldDates"
            :togglable="holdEvent?.accepted_at === null && holdEvent?.declined_at === null"
            :default-open="false"
            :actions="[
              {
                icon: 'fa-trash',
                disabled: holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null,
                action: () => {
                  deleteHoldEventDatePivot(date);
                },
              },
            ]"
            :loading="getKey(date, 'roomBookings', []).filter((r) => r.working).length > 0"
            :header="formatStampAsDate(date.date, 'dddd MMMM Do')">
            <template #afterTitle>
              <DisplayBadge
                v-if="date.primary"
                color="success"
                text="Primary" />
            </template>
            <div
              v-if="holdEvent?.accepted_at === null && holdEvent?.declined_at === null"
              class="flex flex-col gap-5">
              <div class="flex gap-edge">
                <div>
                  <InputLabel
                    super-text
                    class="mb-3"
                    label="Primary"></InputLabel>
                  <VToggle
                    v-model="date.primary"
                    can-edit
                    @update:model-value="updateHoldEventDatePivot(date)" />
                </div>
                <div class="flex-1">
                  <InputLabel
                    super-text
                    label="Date Notes"></InputLabel>
                  <TextareaInput
                    v-model="date.notes"
                    :min-height="30"
                    @blur="updateHoldEventDatePivot(date)" />
                </div>
              </div>
              <div v-if="getKey(date, 'roomBookings', []).length > 0">
                <h4 class="mb-3">Room Bookings</h4>
                <VTable edge-to-edge>
                  <template v-for="booking in getKey(date, 'roomBookings', [])">
                    <VTableRow
                      :clickable="booking.other_uses.length > 0 || booking.restrictions.length > 0"
                      @click="
                        openRoomBookings.has(booking.id)
                          ? openRoomBookings.delete(booking.id)
                          : openRoomBookings.add(booking.id)
                      ">
                      <VTableCell style="width: 35px">
                        <ChevronToggle
                          v-if="booking.other_uses.length > 0 || booking.restrictions.length > 0"
                          :model-value="openRoomBookings.has(booking.id)" />
                      </VTableCell>
                      <VTableCell main-cell>
                        {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
                      </VTableCell>
                      <VTableCell style="width: 35px">
                        <IconWithLoading
                          :icon="
                            booking.other_uses.length > 0 || booking.restrictions.length > 0
                              ? 'fa-exclamation-circle text-pending'
                              : 'fa-check'
                          "
                          :loading="booking.working"></IconWithLoading>
                      </VTableCell>
                    </VTableRow>
                    <template v-if="openRoomBookings.has(booking.id)">
                      <VTableRow
                        v-if="booking.other_uses.length > 0"
                        no-background>
                        <VTableCell />
                        <VTableCell
                          :colspan="2"
                          main-cell>
                          <div class="grid grid-cols-[30px_auto] items-center">
                            <div>
                              <i class="fa fa-fw fa-info text-2xl text-textColor" />
                            </div>
                            <div class="flex flex-col">
                              <div class="sub-title text-highlight">
                                Other uses of
                                {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
                              </div>
                              <div
                                v-for="reason in getOtherUseText(booking)"
                                :key="reason.id"
                                class="ml-4 p-2"
                                :title="reason.title">
                                <strong>Event: </strong>{{ reason.eventName }}
                              </div>
                            </div>
                          </div>
                        </VTableCell>
                      </VTableRow>
                      <VTableRow
                        v-if="booking.restrictions.length > 0"
                        no-background>
                        <VTableCell
                          :colspan="3"
                          main-cell>
                          <div class="grid grid-cols-[30px_auto] items-center">
                            <div>
                              <i class="fa fa-fw fa-info text-2xl text-textColor" />
                            </div>
                            <div class="flex flex-col">
                              <div class="sub-title text-highlight">
                                Restrictions on usage of
                                {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
                              </div>
                              <div
                                v-for="text in getRestrictionText(booking)"
                                :key="text.id"
                                class="ml-4 p-2"
                                :title="text.title">
                                <strong>Reason: </strong><small>{{ text.reason }}</small>
                                <br />
                                <strong>Issue: </strong><small>{{ text.message }}</small>
                              </div>
                            </div>
                          </div>
                        </VTableCell>
                      </VTableRow>
                    </template>
                  </template>
                </VTable>
              </div>
            </div>
          </BoxContainer>
        </div>
        <EmptyStateFullPage
          v-if="holdDates.length === 0"
          icon="fa-calendar fa-sm"
          size="tiny"
          :button-function="null"
          description="No Additional Dates Added" />
      </div>

      <div v-if="localGroup && localGroup?.venue_id && rooms.length > 0">
        <div class="flex justify-between p-edge">
          <h2 class="">Rooms</h2>
          <ActionButtonGroup
            :actions="[
              {
                title: 'Add',
                icon: 'fa-plus',
                disabled: holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null,
                maxHeightDropdown: '50vh',
                dropdown: [
                  {
                    title: 'Add Room',
                    type: 'header',
                  },
                ].concat(
                  rooms.map((r) => {
                    return {
                      title: r.name,
                      selected: roomBookings.map((b) => b.room_id).includes(r.id),
                      action: (close: () => void) => {
                        if (roomBookings.map((b) => b.room_id).includes(r.id)) {
                          removeBooking(r);
                        } else {
                          checkRoomAvailability(r.id);
                        }
                        close();
                      },
                    };
                  })
                ),
              },
            ]" />
        </div>

        <EmptyStateFullPage
          v-if="roomBookings.length === 0"
          icon="fa-cube fa-sm"
          size="tiny"
          :button-function="null"
          description="No Rooms Added" />
        <VTable
          v-if="roomBookings.length > 0"
          edge-to-edge>
          <template #head>
            <VTableRow head>
              <VTableCell>Room</VTableCell>
              <!--              <VTableCell style="width: 200px">Title</VTableCell>-->
              <VTableCell style="width: 70px"></VTableCell>
            </VTableRow>
          </template>
          <template v-for="booking in roomBookings">
            <VTableRow>
              <VTableCell main-cell>
                {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
              </VTableCell>
              <!--              <VTableCell class="!p-0">-->
              <!--                <TextInput-->
              <!--                  v-model="booking.title"-->
              <!--                  placeholder="Room Title"-->
              <!--                  :can-edit="editMode"-->
              <!--                  is-hidden></TextInput>-->
              <!--              </VTableCell>-->
              <VTableCell>
                <VButton
                  size="inTable"
                  class="float-right"
                  icon="fa-trash"
                  @click.stop="removeBooking(booking)"></VButton>
              </VTableCell>
            </VTableRow>
            <VTableRow
              v-if="booking.otherUse.length && !booking.isOk"
              :key="'otherUse_' + booking.id"
              no-background>
              <VTableCell
                :colspan="3"
                main-cell>
                <div class="grid grid-cols-[30px_auto] items-center">
                  <div>
                    <i class="fa fa-fw fa-info text-2xl text-textColor" />
                  </div>
                  <div class="flex flex-col">
                    <div class="sub-title text-highlight">
                      Other uses of
                      {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
                    </div>
                    <div
                      v-for="reason in getOtherUseText(booking)"
                      :key="reason.id"
                      class="ml-4 p-2"
                      :title="reason.title">
                      <strong>Event: </strong>{{ reason.eventName }}
                    </div>
                  </div>
                </div>
              </VTableCell>
              <VTableCell main-cell>
                <VButton
                  size="inTable"
                  title="Ignore"
                  class="float-right"
                  @click="[(booking.otherUse = []), (booking.isOk = booking.restrictions.length === 0)]" />
              </VTableCell>
            </VTableRow>
            <VTableRow
              v-if="booking.restrictions.length && !booking.isOk"
              :key="'restricitons_' + booking.id"
              no-background>
              <VTableCell
                :colspan="3"
                main-cell>
                <div class="grid grid-cols-[30px_auto] items-center">
                  <div>
                    <i class="fa fa-fw fa-info text-2xl text-textColor" />
                  </div>
                  <div class="striped-children flex flex-col">
                    <div class="sub-title text-highlight">
                      Restrictions on usage of
                      {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
                    </div>
                    <div
                      v-for="text in getRestrictionText(booking)"
                      :key="text.id"
                      class="ml-4 p-2"
                      :title="text.title">
                      <strong>Reason: </strong><small>{{ text.reason }}</small>
                      <br />
                      <strong>Issue: </strong><small>{{ text.message }}</small>
                    </div>
                  </div>
                </div>
              </VTableCell>
              <VTableCell main-cell>
                <VButton
                  size="inTable"
                  title="Ignore"
                  class="float-right"
                  @click="[(booking.restrictions = []), (booking.isOk = booking.otherUse.length === 0)]" />
              </VTableCell>
            </VTableRow>
          </template>
        </VTable>
      </div>
      <div>
        <div class="flex justify-between p-edge">
          <h2 class="">Show Times</h2>
          <ActionButtonGroup
            :actions="[
              {
                title: 'Add',
                icon: 'fa-plus',
                disabled: holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null,
                action: async () => {
                  selectedShowTime = null;
                  await nextTick();
                  selectedShowTime = { id: null };
                },
              },
            ]" />
        </div>

        <EmptyStateFullPage
          v-if="showTimes.length === 0"
          icon="fa-cube fa-sm"
          size="tiny"
          :button-function="null"
          description="No Shows Added" />

        <div class="space-y-5 px-edge">
          <div
            v-for="show in showTimes"
            class="p-edge border rounded">
            <div class="flex justify-between gap-5">
              <div>
                <InputLabel
                  super-text
                  label="When"></InputLabel>
                {{ formatStampAsTime(show.start) }}-{{ formatStampAsTime(show.end) }}
              </div>

              <ActionButtonGroup
                :actions="[
                  {
                    title: 'Edit ',
                    icon: 'fa-pencil',
                    disabled: holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null,
                    action: async () => {
                      selectedShowTime = null;
                      await nextTick();
                      selectedShowTime = show;
                    },
                  },
                ]" />
            </div>
            <div
              v-if="show.room_id || show.title"
              class="grid grid-cols-2 gap-5 mt-edge">
              <div v-if="show.room_id">
                <InputLabel
                  super-text
                  label="Where"></InputLabel>
                {{ getItemFromArrayBasedOnId(show.room_id, rooms, { name: '' }).name }}
              </div>
              <div v-if="show.title">
                <InputLabel
                  super-text
                  label="Title"></InputLabel>
                {{ show.title }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <GroupCreateEventSectionPerformances
        v-if="localGroup && localGroup.has_performances"
        :performances="performances"
        on-hold-event
        :group="group"
        :event="event"
        :can-edit="holdEvent?.accepted_at === null && holdEvent?.declined_at === null"
        @add-performance="addPerformance"
        @remove-performance="removePerformance" />
      <div v-if="localGroup && localGroup.has_partners">
        <div class="flex justify-between p-edge">
          <h2 class="">Partners</h2>
          <ActionButtonGroup
            :actions="[
              {
                title: 'Add Company',
                icon: 'fa-plus',
                disabled: holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null,
                action: ($event) => {
                  openCompanySearch($event, true);
                },
              },
              {
                title: 'Add Contact',
                icon: 'fa-plus',
                disabled: holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null,
                action: ($event) => {
                  openCompanySearch($event, false);
                },
              },
            ]" />
        </div>

        <SearchSelectFloatingWindow
          v-if="pageX && pageY && searchForCompany"
          url="/api/partners/companies"
          placeholder="Search For Company"
          :debounce-time="500"
          can-create
          :params="{
            model_type: 'App\\Group',
            model_id: group.id,
          }"
          :page-y="pageY"
          :page-x="pageX"
          :already-selected-ids="partnerCompanies.map((item) => item.id)"
          @create="createCompany"
          @selected="attachCompany" />

        <PartnerCompanyCreateModal
          v-if="showCreatePartnerSlideOut"
          :init-company="{ name: createCompanyName }"
          :model-id="group.id"
          model="Group"
          :can-add-contacts="false"
          @closed="showCreatePartnerSlideOut = false"
          @saved="attachCompany($event)" />

        <SearchSelectFloatingWindow
          v-if="pageX && pageY && searchForContact"
          url="/api/partners/contacts"
          placeholder="Search For Contact"
          :debounce-time="500"
          can-create
          :params="{
            model_type: 'App\\Group',
            model_id: group.id,
          }"
          :page-y="pageY"
          :page-x="pageX"
          :already-selected-ids="partnerContacts.map((item) => item.id)"
          @create="createPartnerContact"
          @selected="attachContact" />

        <PartnerContactCreateModal
          v-if="showPartnerContactModal"
          :model-value="{ name: newPartnerContactName }"
          :model-id="group.id"
          model="Group"
          :can-add-company="false"
          @closed="showPartnerContactModal = false"
          @created="attachContact($event)" />

        <div class="form-layout grid-cols-[1fr_1fr] gap-x-4 gap-y-6">
          <div class="">
            <div
              v-if="partnerCompanies.length > 0"
              class="pl-edge">
              <h2>Companies</h2>
            </div>
            <VTable edge-to-edge>
              <VTableRow v-for="company in partnerCompanies">
                <VTableCell main-cell>
                  {{ company.name }}
                </VTableCell>
                <VTableCell style="width: 60px">
                  <VButton
                    size="inTable"
                    icon="fa-trash"
                    @click="detachCompany(company)">
                  </VButton>
                </VTableCell>
              </VTableRow>
            </VTable>

            <EmptyStateFullPage
              v-if="partnerCompanies.length === 0"
              icon="fa-group"
              size="tiny"
              :button-function="null"
              description="No Companies Added" />
          </div>
          <div class="">
            <div
              v-if="partnerContacts.length > 0"
              class="pl-edge">
              <h2>Contacts</h2>
            </div>
            <VTable edge-to-edge>
              <VTableRow v-for="contact in partnerContacts">
                <VTableCell main-cell>
                  {{ concatName(contact) }}
                </VTableCell>
                <VTableCell style="width: 60px">
                  <VButton
                    size="inTable"
                    icon="fa-trash"
                    @click="detachContact(contact)">
                  </VButton>
                </VTableCell>
              </VTableRow>
            </VTable>
            <EmptyStateFullPage
              v-if="partnerContacts.length === 0"
              icon="fa-user"
              size="tiny"
              :button-function="null"
              description="No Contacts Added" />
          </div>
        </div>
      </div>
    </div>

    <template #footer="{ close }">
      <div class="flex h-full w-full items-center justify-end px-edge gap-edge">
        <VButton
          v-if="invite.event_request_id"
          icon="fa-inbox fa-regular"
          title="Show Request"
          @click="
            [
              (selectedEventRequestId = null),
              nextTick(() => {
                selectedEventRequestId = invite.event_request_id;
              }),
            ]
          " />
        <VButton
          icon="fa-check"
          type="success"
          title="Confirm Event"
          :disabled="holdEvent?.accepted_at !== null || holdEvent?.declined_at !== null"
          :loading="working"
          @click="openAcceptModal" />
      </div>
      <div
        v-if="invite"
        class="flex h-full w-full items-center justify-end px-edge">
        <div>
          <VButton
            type="warning"
            title="Delete"
            :loading="working"
            @click="deleteEvent(close)" />
        </div>
      </div>
    </template>
  </CrudSlideout>

  <HoldEventConfirmModal
    v-if="acceptModalOpen && invite"
    :invite="invite"
    @accepted="[emit('accepted'), (acceptModalOpen = false), (modalOpen = false)]" />

  <ShowTimeCRUDModal
    v-if="selectedShowTime"
    :show-time="selectedShowTime"
    :with-date="false"
    :rooms="
      roomBookings.map((b) => {
        const room = getItemFromArrayBasedOnId(b.room_id, rooms, { name: '' });
        return {
          id: room.id,
          name: room.name,
        };
      })
    "
    :event="{
      id: invite.event.id,
      start_date: localInvite.start,
    }"
    :show-times="showTimes"
    @closed="selectedShowTime = null"
    @created="[(showTimes = exchangeValuesOfObject($event, showTimes))]"
    @updated="[(showTimes = exchangeValuesOfObject($event, showTimes))]"
    @deleted="[(showTimes = showTimes.filter((s) => s.id !== $event))]" />

  <EventRequestSlideOut
    v-if="selectedEventRequestId"
    v-model:working="working"
    :event-request-id="selectedEventRequestId"
    :group-id="group.id"
    :venue-id="venueId"
    is-display-on-hold
    @confirm-hold="[openAcceptModal(), (selectedEventRequestId = null)]"
    @closed="selectedEventRequestId = null" />
</template>
