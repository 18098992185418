export const getCrewIcon = (model: string, type: string) => {
  switch (model) {
    case 'user': {
      return 'fa-user';
    }
    case 'global': {
      return 'fa-globe';
    }
    case 'event_group':
    case 'eventGroup':
    case 'group': {
      return 'fa-group';
    }
    case 'performance': {
      return 'fa-music';
    }
    case 'room': {
      return 'fa-cube';
    }
    case 'eventResource': {
      switch (type) {
        case 'Crew': {
          return 'fa-user';
        }
      }
      break;
    }
    default: {
      return '';
    }
  }
};
