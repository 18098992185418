<script setup lang="ts">
import type { ShiftResource } from '@/types/event';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { humanReadableDateFormat, timeFormat } from '@/variables/date-format';
import { formatStampAsDateTime, getNow, isSameOrBefore, timeStampsAreSame } from '@/util/timeFunctions';
import DateHourSelector from '@/components/Inputs/Date/DateHourSelector.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { checkIntoShift, checkOutOfShift, clearCheckInAdOutOfShift } from '@/helpers/shiftFunctions';
import VButton from '@/components/Inputs/VButton.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';

type Props = {
  shift: ShiftResource;
  allowUpdating: boolean;
  isCheckOutModal: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'checkedIn', arg: any): void;
  (event: 'checkedOut', arg: any): void;
  (event: 'checkCleared', arg: any): void;
  (event: 'shiftUpdated', arg: any): void;
}>();

const toast = useToast();

const notes = ref(props.shift.notes);
const modalOpen = ref(true);
const working = ref(false);
const timeSlot = ref(null);

const checkIn = async () => {
  if (props.isCheckOutModal) return;

  working.value = true;

  const response = await checkIntoShift(props.shift.id, timeSlot.value);

  if (!response) return;
  emit('shiftUpdated', {
    id: props.shift.id,
    check_in: timeSlot.value,
  });
  emit('checkedIn', timeSlot.value);
  working.value = false;
  modalOpen.value = false;
};

const checkOut = async () => {
  if (!props.isCheckOutModal) return;
  const response = await checkOutOfShift(props.shift.id, timeSlot.value, notes.value);
  if (!response) return;
  emit('shiftUpdated', {
    id: props.shift.id,
    check_out: timeSlot.value,
    notes: notes.value,
  });
  emit('checkedOut', { check_out: timeSlot.value, notes: notes.value });
  working.value = false;
  modalOpen.value = false;
};

const checkInOrOut = () => {
  if (props.isCheckOutModal) {
    checkOut();
  } else {
    checkIn();
  }
  modalOpen.value = false;
};

const clearSlotCheckInAndOut = async () => {
  if (!props.allowUpdating) return;
  const certain = await useCertaintyModal().assertCertain(
    'Clear Check in and out',
    'Are you sure you want to clear these values?'
  );
  if (!certain) return;
  working.value = true;
  await clearCheckInAdOutOfShift(props.shift.id);
  emit('checkCleared');
  emit('shiftUpdated', {
    id: props.shift.id,
    check_in: null,
    check_out: null,
    notes: null,
  });
  working.value = false;
  modalOpen.value = false;
};

const setTimeNow = () => {
  if (props.isCheckOutModal) {
    if (!isSameOrBefore(props.shift.check_in, getNow())) {
      toast.warning('Cannot Check Out Before Checking In.');
      return;
    }
  }
  timeSlot.value = getNow();
};

if (props.shift.check_in && props.shift.check_out && !props.allowUpdating) {
  toast.warning('Already Checked in and out of.');
  emit('closed');
}

if (props.isCheckOutModal) {
  timeSlot.value = props.shift.check_out ? props.shift.check_out : props.shift.end;
} else {
  timeSlot.value = props.shift.check_in ? props.shift.check_in : props.shift.start;
}
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    :hide-all-crud-modals="false"
    :modal-in-modal="true"
    only-close-button
    :title="'Check ' + (isCheckOutModal ? 'Out' : 'In')"
    @closed="$emit('closed')">
    <div class="flex flex-col gap-5">
      <div v-if="!isCheckOutModal">
        {{
          shift.check_in
            ? 'Already checked in at ' +
              formatStampAsDateTime(shift.check_in, humanReadableDateFormat + ' ' + timeFormat)
            : ''
        }}
      </div>
      <div v-if="isCheckOutModal">
        {{
          shift.check_out
            ? 'Already checked out at ' +
              formatStampAsDateTime(shift.check_out, humanReadableDateFormat + ' ' + timeFormat)
            : ''
        }}
      </div>
      <div>
        <div class="grid grid-cols-[auto_100px] align-bottom">
          <InputLabel :label="'When Would you like to check ' + (isCheckOutModal ? 'Out' : 'In') + '?'" />
          <VButton
            v-if="!timeStampsAreSame(isCheckOutModal ? shift.end : shift.start, timeSlot, 'minute')"
            size="extra-small"
            type="btn-outline"
            class="float-right"
            @click="timeSlot = isCheckOutModal ? shift.end : shift.start">
            Set Shift {{ isCheckOutModal ? 'end' : 'start' }}
          </VButton>
          <VButton
            v-else
            size="extra-small"
            type="btn-outline"
            title="Select Now"
            class="float-right"
            @click="setTimeNow()" />
        </div>
        <DateHourSelector
          v-model:dateTime="timeSlot"
          :type="isCheckOutModal ? 'end' : 'start'"
          :vertical="false"
          :required="true"
          :earliest-date="shift.check_in"
          :latest-date="shift.check_out"
          :can-edit="true" />
      </div>

      <TextareaInput
        v-if="isCheckOutModal"
        v-model="notes"
        label="Notes" />

      <div class="grid w-full grid-cols-[60px_auto_60px]">
        <div></div>
        <div class="text-center">
          <VButton
            :icon="isCheckOutModal ? 'fa-check-double' : 'fa-check'"
            type="success"
            :loading="working"
            :title="
              (isCheckOutModal && shift.check_out ? 'Update ' : '') +
              (!isCheckOutModal && shift.check_in ? 'Update ' : '') +
              'Check ' +
              (isCheckOutModal ? 'Out' : 'In')
            "
            @click="checkInOrOut()" />
        </div>
        <VButton
          v-if="shift.check_in && allowUpdating"
          size="extra-small"
          tool-tip-text="Remove existing Check in and check out (if exists)."
          type="warning"
          icon="fa-times"
          title="Clear"
          @click="clearSlotCheckInAndOut" />
      </div>
    </div>
  </CrudModal>
</template>
