import { TimelineTemplateResource } from '@/types/timeline-template';

export const getTimelineTemplates = (groupId: number) => {
  const url = '/api/timeline-templates';
  const params = {
    model_type: 'App\\Group',
    model_id: groupId,
    simple: true,
  };
  return axios.get<TimelineTemplateResource[]>(url, { params });
};

export const getTimelineTemplateEvents = (templateId: number, eventId: number) => {
  return axios.get<number>(`/api/timeline-templates/${templateId}/events`, {
    params: {
      event_id: eventId,
    },
  });
};

export const postTimelineTemplateEvent = (templateId: number, eventId: number, isGlobal: boolean) => {
  return axios.post<string>(`/api/timeline-templates/${templateId}/events`, {
    event_id: eventId,
    is_global: isGlobal,
  });
};
