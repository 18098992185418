<script lang="ts" setup>
import { nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { concatName } from '@/services/api-partners';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import { eventInvite } from '@/services/api-invite';
import ConnectPartnerCompanyToGroupModal from '@/components/Partners/ConnectPartnerCompanyToGroupModal.vue';
import { PartnerCompanyResource, PartnerContactResource } from '@/types/partners';
import { getKey } from '@/util/globals';
import { InviteResource } from '@/types/invite';

type Props = {
  contact?: any;
  existingContacts: any[];
  company?: any;
  existingCompanies: any[];
  invite?: InviteResource | null;
  eventName?: string;
};

const props = withDefaults(defineProps<Props>(), {
  contact: null,
  company: null,
  invite: null,
  eventName: 'Event',
});

const emit = defineEmits<{
  (e: 'companyAdded', data: { name: string; id: number }): void;
  (e: 'contactAdded', data: { first_name: string; last_name: string; phone: string; email: string; id: number }): void;
  (e: 'closed'): void;
}>();

const modalOpen = ref(false);
const companies = ref([]);
const contacts = ref([]);

const openModal = async () => {
  modalOpen.value = false;
  await nextTick();
  modalOpen.value = true;
};
const connectPartnerCompanyToGroupCompany = ref(null);
const connectPartnerCompanyToGroupCompanyHasWriteAccess = ref(false);

const checkIfAnyConnectionsToPrompt = async () => {
  if (props.company) {
    const { data } = await axios.get(`/api/partners/companies/${props.company.id}/contacts/`);
    if (!data.length) {
      emit('closed');
      return;
    }
    contacts.value = data;
    companies.value = null;
    modalOpen.value = true;
    await openModal();
    return;
  }
  if (props.contact) {
    const { data } = await axios.get(`/api/partners/contacts/${props.contact.id}/companies/`);
    if (!data.length) {
      emit('closed');
      return;
    }
    contacts.value = null;
    companies.value = data;
    await openModal();
  }
};

checkIfAnyConnectionsToPrompt();

const addCompanyToInvite = async (c) => {
  if (props.existingCompanies.map((e) => e.id).includes(c.id)) {
    return;
  }
  if (props.invite) {
    await axios.post(`/api/partners/companies/${c.id}/invites/${props.invite.id}/attach`);
    emit('companyAdded', { name: c.name, id: c.id, group_id: c.group_id });
    useToast().success(`${c.name} added`);
    checkToInvitePartnerCompany(c);
  } else {
    emit('companyAdded', {
      name: c.name,
      id: c.id,
      group_id: c.group_id,
    });
    useToast().success(`${c.name} added`);
  }
};
const addContactToInvite = async (c) => {
  if (props.existingContacts.map((e) => e.id).includes(c.id)) {
    return;
  }
  if (props.invite) {
    const { data } = await axios.post(`/api/partners/contacts/${c.id}/invites/${props.invite.id}/attach`);
    emit('contactAdded', {
      first_name: c.first_name,
      last_name: c.last_name,
      phone: c.phone,
      email: c.email,
      id: c.id,
    });
    useToast().success(`${c.name} added`);
    checkToInvitePartnerContact(c);
  } else {
    emit('contactAdded', {
      first_name: c.first_name,
      last_name: c.last_name,
      phone: c.phone,
      email: c.email,
      id: c.id,
    });
    useToast().success(`${c.name} added`);
  }
};

const checkToInvitePartnerContact = async (contact: PartnerContactResource) => {
  if (!props.invite) return;
  if (contact.email) {
    const invite = await useMultipleButtonsModal().threeButtonModal({
      title: 'Invite ' + concatName(contact),
      description: 'Would you like to invite ' + contact.first_name + ' to ' + props.eventName + '?',
      button: {
        text: 'Invite',
        type: 'success',
      },
      options: [
        {
          value: 'first',
          label: 'With Write Access',
        },
        {
          value: 'second',
          label: 'Without Write Access',
        },
      ],
    });
    if (invite === 'cancel') return;
    await axios
      .post(`/api/events/${props.invite.event.id}/invite-user`, {
        user_id: getKey(contact, 'user_id'),
        partner_contact_id: contact.id,
        email: contact.email,
        write: invite === 'first',
        in_timeline: true,
      })
      .catch((error) => {
        if (error.response.status === 409) {
          useToast().warning(concatName(c) + ' is already invited.');
          return;
        }
        useToast().warning('Something went wrong.');
        return;
      });
    useToast().success('Invited.');
  }
};

const checkToInvitePartnerCompany = async (company: PartnerCompanyResource) => {
  if (!props.invite) return;
  const invite = await useMultipleButtonsModal().threeButtonModal({
    title: 'Invite ' + c.name,
    description: 'Would you like to invite ' + c.name + ' to ' + props.eventName + '?',
    button: {
      text: 'Invite',
      type: 'success',
    },
    options: [
      {
        value: 'first',
        label: 'With Write Access',
      },
      {
        value: 'second',
        label: 'Without Write Access',
      },
    ],
  });
  if (invite === 'cancel') return;
  if (company.group_id) {
    await eventInvite(props.invite.event.id, {
      group_id: company.group_id,
      write: invite === 'first',
      skipProjectLeader: true,
    });
  } else {
    connectPartnerCompanyToGroupCompanyHasWriteAccess.value = invite === 'first';
    connectPartnerCompanyToGroupCompany.value = null;
    await nextTick();
    connectPartnerCompanyToGroupCompany.value = company;
  }
};

const invitePartnerCompanyWithGroupToEvent = async (company: PartnerCompanyResource) => {
  if (!company.group_id) return;
  await eventInvite(props.inviteId, {
    group_id: company.group_id,
    write: connectPartnerCompanyToGroupCompanyHasWriteAccess.value,
    skipProjectLeader: true,
  });
  connectPartnerCompanyToGroupCompany.value = null;
  connectPartnerCompanyToGroupCompanyHasWriteAccess.value = false;
};

const partOfExistingCompanies = (id: number) => props.existingCompanies.some((e) => e.id === id);
const partOfExistingContacts = (id: number) => props.existingContacts.some((e) => e.id === id);
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    :title="
      'Add ' +
      (company === null ? 'Companies' : 'Contacts') +
      ' of ' +
      (company ? company.name : '') +
      (contact ? concatName(contact) : '') +
      ' to ' +
      eventName
    "
    create-button-text="Done"
    @create="[(modalOpen = false), $emit('closed')]"
    @closed="[(modalOpen = false), $emit('closed')]">
    <VTable v-if="contact">
      <VTableRow
        v-for="comp in companies"
        :key="comp.id"
        :main-row="partOfExistingCompanies(comp.id)">
        <VTableCell style="width: 60px">
          <VButton
            title="add"
            size="inTable"
            icon="fa-plus"
            :disabled="partOfExistingCompanies(comp.id)"
            @click="addCompanyToInvite(comp)" />
        </VTableCell>
        <VTableCell>
          {{ comp.name }}
        </VTableCell>
      </VTableRow>
    </VTable>
    <VTable v-if="company">
      <VTableRow
        v-for="cont in contacts"
        :key="cont.id"
        :main-row="partOfExistingContacts(cont.id)">
        <VTableCell style="width: 60px">
          <VButton
            title="add"
            size="inTable"
            icon="fa-plus"
            :disabled="partOfExistingContacts(cont.id)"
            @click="addContactToInvite(cont)" />
        </VTableCell>
        <VTableCell>
          {{ concatName(cont) }}
        </VTableCell>
      </VTableRow>
    </VTable>
  </CrudModal>

  <ConnectPartnerCompanyToGroupModal
    v-if="connectPartnerCompanyToGroupCompany"
    :partner-company="connectPartnerCompanyToGroupCompany"
    @connected="invitePartnerCompanyWithGroupToEvent" />
</template>
