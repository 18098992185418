<script lang="ts" setup>
import TaskList from '@/components/Tasks/TaskList.vue';
import { InviteResource } from '@/types/invite';
import { getKey } from '@/util/globals';
import { useEventMembers } from '@/composables/use-event-members';
import { inject } from 'vue';
import { eventMembersKey } from '@/provide/keys';

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

type Props = {
  canEdit?: boolean | null;
  groupId: number;
  invite: InviteResource;
};
const eventMembers = inject(eventMembersKey, null);
if (!eventMembers) {
  useEventMembers(props.invite.event.id);
}

const getOwner = () => {
  if (props.invite.festival) {
    return {
      id: props.invite.festival.id,
      model: 'Festival',
    };
  }
  return {
    id: props.groupId,
    model: 'Group',
  };
};
</script>

<template>
  <TaskList
    v-if="getOwner()"
    :owner-id="getKey(getOwner(), 'id')"
    :owner-model="getKey(getOwner(), 'model')"
    :can-edit="canEdit"
    :event-id="invite.event?.id"
    :invite-id="invite.id"
    :columns-to-show="['user_selector', 'group_selector', 'private_status']"
    :filters="[!invite.parent_id ? 'child_tasks' : null, 'event_tasks'].filter((i) => i !== null)"
    :locked-keys="[invite.parent_id ? 'via' : null].filter((i) => i !== null)" />
</template>
