<script setup lang="ts">
import { MoreInformationContent } from '@/types/event-request';
import { ref, nextTick, computed } from 'vue';
import UrlInput from '@/components/Inputs/UrlInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VRichText from '@/components/Inputs/VRichText.vue';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import DisplayLink from '@/components/Display/DisplayLink.vue';

type Props = {
  content?: MoreInformationContent | null;
  overrideEditMode?: boolean;
  overrideIsTemplate?: boolean;
  editMode?: boolean;
  isTemplate?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  content: null,
  overrideEditMode: false,
  overrideIsTemplate: false,
  editMode: false,
  isTemplate: false,
});

const emits = defineEmits<{
  (event: 'update:content', arg: MoreInformationContent): void;
}>();

const localEditMode = computed(() => {
  return props.overrideEditMode ? props.overrideEditMode : props.editMode;
});

const localIsTemplate = computed(() => {
  return props.overrideIsTemplate ? props.overrideIsTemplate : props.isTemplate;
});

const url = ref(props.content && props.content.url ? props.content.url : '');
const title = ref(props.content && props.content.title ? props.content.title : '');
const text = ref(props.content && props.content.text ? props.content.text : '');
const hasLink = ref(props.content && props.content.url !== null);

const emitContent = () => {
  if (!localEditMode.value || !localIsTemplate.value) return;
  nextTick(() => {
    emits('update:content', {
      url: hasLink.value ? url.value : null,
      title: hasLink.value ? title.value : null,
      text: text.value,
    });
  });
};
</script>
<template>
  <div>
    <div>
      <div class="flex flex-col">
        <DisplayLink
          v-if="!localIsTemplate && hasLink"
          :link="url"
          new-tab
          with-icon
          :text="title" />
        <DisplayRichText
          v-if="text && !localIsTemplate"
          :content="text" />
      </div>

      <div
        v-if="localIsTemplate"
        class="form-layout h-14 mb-5 grid grid-cols-1 sm:grid-cols-[180px_1fr_1fr]">
        <SettingToggle
          v-model="hasLink"
          :can-edit="localEditMode"
          title="If enabled, you can include a link with additional information"
          label="Include a link" />

        <UrlInput
          v-if="hasLink"
          v-model="url"
          :can-edit="localEditMode"
          label="Link"
          @blur="emitContent" />

        <TextInput
          v-if="hasLink"
          v-model="title"
          :can-edit="localEditMode"
          label="Title of Link"
          @blur="emitContent" />
      </div>
      <VRichText
        v-if="localIsTemplate"
        v-model="text"
        white-background
        label="Text to be displayed"
        :can-edit="localEditMode"
        @blur="emitContent" />
    </div>
  </div>
</template>
