<script setup lang="ts">
import { ref, nextTick } from 'vue';
import moment from 'moment';
import SessionStorageHelper from '@/components/Helpers/SessionStorageHelper.vue';

const props = defineProps({
  storageString: {
    type: String,
    required: false,
    default: null,
  },
  url: {
    type: String,
    required: true,
  },
  params: {
    type: Object,
    required: false,
    default: null,
  },
  sessionStorageLifeTime: {
    type: Number,
    required: false,
    default: 60,
  },
  shouldLoad: {
    type: Boolean,
    required: false,
    default: true,
  },
  delay: {
    type: Number,
    required: false,
    default: 0,
  },
  loadIfNothing: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const emits = defineEmits(['next', 'values']);
const storage = ref(null);
const open = ref(true);

function fetch(overrideDelay = false) {
  open.value = true;
  nextTick(() => {
    if (storage.value && storage.value.outdated) {
      if (moment().valueOf() < storage.value.outdated) {
        emits('values', storage.value.items);
        emits('next');
        return;
      }
    }
    if (!props.loadIfNothing) {
      return;
    }

    if (!overrideDelay && props.delay > 0) {
      open.value = false;
      setTimeout(() => {
        fetch(true);
      }, props.delay);
      return;
    }
    axios
      .get(
        props.url,
        props.params
          ? {
              params: props.params,
            }
          : null
      )
      .then((resp) => {
        storage.value = {
          items: resp.data,
          outdated: moment().add(props.sessionStorageLifeTime, 'minutes').valueOf(),
        };
        emits('values', storage.value.items);
        emits('next');
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

function creating() {
  if (!props.shouldLoad) {
    emits('next');
  } else {
    fetch();
  }
}

creating();

// export default {
//   // data() {
//   //   return {
//   //     storage: null,
//   //     open: true,
//   //   };
//   // },
//   // watch: {
//   //   parentValues: {
//   //     handler() {
//   //       this.$emit('values', this.parentValues);
//   //     },
//   //     deep: true,
//   //   },
//   // },
//   // created() {
//   //   this.$root.$on(`${this.storageString}_refresh`, (data) => {
//   //     this.storage = null;
//   //     sessionStorage.removeItem(this.storageString);
//   //     this.open = false;
//   //     setTimeout(() => {
//   //       this.open = true;
//   //       this.$nextTick(() => {
//   //         this.fetch();
//   //       });
//   //     }, Math.floor(Math.random() * 5) * 1000);
//   //   });
//   // },
//
//
//   methods: {
//     fetch(overrideDelay = false) {
//       this.open = true;
//       this.$nextTick(() => {
//         if (this.storage && this.storage.outdated) {
//           if (moment().valueOf() < this.storage.outdated) {
//             this.$emit('values', this.storage.items);
//             this.$emit('next');
//             return;
//           }
//         }
//         if (!this.loadIfNothing) {
//           return;
//         }
//
//         if (!overrideDelay && this.delay > 0) {
//           this.open = false;
//           setTimeout(() => {
//             this.fetch(true);
//           }, this.delay);
//           return;
//         }
//         axios.get(this.url, this.params ? {
//           params: this.params,
//         } : null)
//           .then((resp) => {
//             this.storage = {
//               items: resp.data,
//               outdated: moment().add(this.sessionStorageLifeTime, 'minutes').valueOf(),
//             };
//             this.$emit('values', this.storage.items);
//             this.$emit('next');
//           })
//           .catch((error) => {
//             console.error(error);
//           });
//       });
//     },
//   },
// };
</script>
<template>
  <SessionStorageHelper
    v-if="storageString && open"
    :session-storage-string="storageString"
    :value="storage"
    @newValue="storage = $event" />
</template>
