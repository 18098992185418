<script lang="ts" setup>
import { computed } from 'vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { formatDurationAsMinutes } from '@/util/timeFunctions';

type Props = {
  modelValue?: number | null | string;
  durations?: number[];
  canEdit?: boolean;
  isHidden?: boolean;
  isMinutes?: boolean;
  allowNoDuration?: boolean;
  label?: string;
  labelTitle?: string;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  durations: () => [15, 30, 45, 60, 75, 90, 105, 120, 240, 360, 480],
  canEdit: true,
  isHidden: false,
  isMinutes: false,
  allowNoDuration: false,
  label: 'Duration',
  labelTitle: null,
});

defineEmits<{
  (event: 'update:modelValue', value: number | null): void;
}>();

const allDurations = computed(() => {
  let localDurations = [...props.durations];
  if (!props.durations?.includes(props.modelValue)) {
    localDurations?.push(props.modelValue);
  }
  if (props.allowNoDuration && !props.durations?.includes(0)) {
    localDurations = [0, ...localDurations];
    // localDurations?.push(0);
  }
  return localDurations.sort((a, b) => (a > b ? 1 : -1));
});

const formattedDurations = computed(() =>
  allDurations.value.map((dur) => ({
    id: dur,
    name: props.isMinutes ? formatDurationAsMinutes(dur) : dur,
  }))
);
</script>

<template>
  <VSelect
    :model-value="modelValue"
    :label="label"
    :label-title="labelTitle"
    :options="formattedDurations"
    :can-edit="canEdit"
    :is-hidden="isHidden"
    wrapper-class="w-full"
    @update:model-value="$emit('update:modelValue', $event)" />
</template>
