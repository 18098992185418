<script setup lang="ts">
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  items: string[];
};

withDefaults(defineProps<Props>(), {
  items: () => [],
});

const emit = defineEmits<{
  (event: 'addWord', word: string): void;
}>();

const addWord = (item: string) => {
  emit('addWord', item);
};

const formatWordForReading = (word: string) => {
  return word.replace('{{$', '').replace('}}', '');
};
</script>

<template>
  <div
    v-if="items.length > 0"
    class="mt-[0.4rem] flex flex-wrap items-center gap-3">
    <div
      class="text-xs uppercase text-textColor-soft"
      title="Add these string to exchange for a string with the corresponding information.">
      Add Tags
    </div>
    <VButton
      v-for="item in items"
      :key="item"
      size="extra-small"
      class="btn-outline-text"
      :title="formatWordForReading(item)"
      :tool-tip-text="'Add ' + formatWordForReading(item) + ' to title'"
      @click="addWord(item)">
    </VButton>
  </div>
</template>
