/* eslint-disable no-use-before-define */
import type { ProjectLeader } from '@/types/group';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useGroupProjectLeadersStore = defineStore('group-project-leaders', () => {
  const list = ref<Map<number, ProjectLeader[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (groupId: number) => {
    Echo.join(`On.Group.${groupId}`)
      .listen('.members.created', async () => {
        await fetchProjectLeaders(groupId);
      })
      .listen('.members.updated', async () => {
        await fetchProjectLeaders(groupId);
      })
      .listen('.members.deleted', async () => {
        await fetchProjectLeaders(groupId);
      });
  };

  const fetchProjectLeaders = async (groupId: number) => {
    loading.value = true;
    const { data } = await axios.get(`/api/groups/${groupId}/projectLeaders`);
    if (!list.value.has(groupId)) {
      listenForBroadcast(groupId);
    }
    list.value.set(groupId, data);
    loading.value = false;
  };

  return {
    list,
    loading,
    fetchProjectLeaders,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroupProjectLeadersStore, import.meta.hot));
}
