<script setup lang="ts">
import VTableCell from '@/components/Tables/VTableCell.vue';
import {
  RunningOrderCellResource,
  RunningOrderColumnResource,
  RunningorderResource,
  RunningOrderRowResource,
} from '@/types/runningorder';
import { computed, nextTick, ref, watch } from 'vue';
import {
  formatHoursAndMinutesAndSecondsAsSeconds,
  formatSecondsAsHoursAndMinutesAndSeconds,
  formatStampAsTime,
} from '@/util/timeFunctions';
import {
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  getKey,
  getSortedListOfValues,
} from '@/util/globals';
import { type Items } from '@/components/VList.vue';
import TextCell from '@/components/Config/ColumnConfig/Cells/TextCell.vue';
import VTimePicker from '@/components/Inputs/Date/VTimePicker.vue';
import {
  createRunningOrderCell,
  deleteRunningOrderRow,
  updateRunningOrderCell,
  updateRunningOrderRow,
} from '@/services/api-running-order';
import { useToast } from 'vue-toastification';
import { secondFormat } from '@/variables/date-format';
import { getStartOfRow } from '@/util/running-order-functions';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { usePage } from '@inertiajs/vue3';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { defaultColumnWidths } from '@/variables/mysc';
import DocumentCell from '@/components/Config/ColumnConfig/Cells/DocumentCell.vue';
import ImageCell from '@/components/Config/ColumnConfig/Cells/ImageCell.vue';
import WhisperIndicator from '@/components/Broadcasting/WhisperIndicator.vue';
import DisplayText from '@/components/Display/DisplayText.vue';

type Props = {
  isTemplate?: boolean;
  isDisplay?: boolean;
  runningOrder: RunningorderResource;
  canEdit?: boolean;
  editMode?: boolean;
  row: RunningOrderRowResource;
  index?: number;
  rowSelected?: boolean;
  isRowActive?: boolean;
  inShowMode?: boolean;
  withAuditsButton?: boolean;
  loading?: boolean;
  activeRowId?: number | null;
  activeColumns: RunningOrderColumnResource[];
  runningOrderCells: RunningOrderCellResource[];
  runningOrderRows: RunningOrderRowResource[];
  selectedRowIds?: Set<number> | null;
  activeField?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  index: 0,
  activeRowNumber: false,
  canEdit: false,
  editMode: false,
  inShowMode: false,
  isDisplay: false,
  rowSelected: false,
  withAuditsButton: false,
  loading: false,
  activeField: null,
  activeRowId: null,
  selectedRowIds: () => new Set(),
});

const emit = defineEmits<{
  'toggleRow': [];
  'remove': [];
  'setRowStart': [];
  'insertRowBefore': [];
  'insertRowAfter': [];
  'startShow': [];
  'duplicate': [];
  'keydown-enter': [arg: string];
  'open-audits': [arg: RunningOrderColumnResource | null];
  'update': [
    arg: {
      item: string;
      value: string | null;
    },
  ];
  'deleted': [id: number[]];
  'updateRow': [arg: object];
  'update:runningOrderCells': [arg: RunningOrderCellResource[]];
  'update:selectedRowIds': [arg: Set<any>];
  'update:activeField': [arg: string | null];
}>();

const { isSmallScreen } = useSmallScreen();

const allColors = computed(() => {
  return [{ color: null, definition: 'No Color', hex: null, class: 'none' }, ...props.runningOrder.color_definitions];
});

const canEditColumn = (column) => {
  if (props.isDisplay) return false;
  if (props.isTemplate) {
    if (props.editMode) {
      return column.component === 'runningorder-column-text';
    }
    return false;
  }
  if (!props.editMode) return false;

  if (!props.canEdit) {
    return !getKey(column, 'restrict_edit', true);
  }
  if (props.canEdit) {
    return true;
  }
  return !getKey(column, 'restrict_edit', true);
};

const getRowValue = (column: RunningOrderColumnResource) => {
  const cell = props.runningOrderCells.find(
    (c: RunningOrderCellResource) => c.running_order_row_id === props.row.id && c.running_order_column_id === column.id
  );
  if (!cell) return null;
  return cell.value || null;
};

const clickRowStart = () => {
  if (!props.editMode) return;
  if (!props.canEdit) return;
  if (props.inShowMode) {
    emit('setRowStart');
  } else {
    emit('setRowStart');
  }
};

const getStickyColumnClasses = () => {
  if (isSmallScreen.value) return '';
  return 'sticky z-[2]';
};

const isCurrentActivRow = computed(() => props.activeRowId === props.row.id);

const scheduledTriggerExplain = 'Scheduled Trigger means auto start at chosen time.';
const linkedTriggerExplain = 'Linked Trigger means it will automatically go to next when row reaches 00:00.';
const manualTriggerExplain = 'Manual Trigger means go-to-next must be manually triggered by the show caller.';

const itemsForDropdown = (row: RunningOrderRowResource) => {
  const array = [
    {
      value: 1,
      title: 'Duplicate',
      preIcon: 'fa-clone',
      action: (close: () => void) => {
        emit('duplicate');
        close();
      },
    },
    {
      title: !props.row.no_stage_timer ? 'Hide On Stage Timer' : 'Show On Stage Timer',
      preIcon: !props.row.no_stage_timer ? 'fa-desktop' : 'fa-desktop-slash',
      action: (close: () => void) => {
        updateRow('no_stage_timer', !row.no_stage_timer);
        close();
      },
    },
    {
      title: props.row.start_on_time
        ? 'Trigger: Scheduled'
        : props.row.auto_next
          ? 'Trigger: Linked'
          : 'Trigger: Manual',
      hoverTitle: props.row.start_on_time
        ? scheduledTriggerExplain
        : props.row.auto_next
          ? linkedTriggerExplain
          : manualTriggerExplain,
      subListLeft: true,
      items: [
        {
          title: 'Manual Trigger',
          hoverTitle: manualTriggerExplain,
          preIcon: 'fa-hand-point-down',
          action: (close: () => void) => {
            updateRow('start_on_time', false, 'auto_next', false);
            close();
          },
          class: !props.row.start_on_time && !props.row.auto_next ? '' : 'text-textColor-soft hover:text-textColor',
          postIcon: !props.row.start_on_time && !props.row.auto_next ? 'fa-check text-success' : 'fa',
        },
        {
          title: 'Linked Trigger',
          hoverTitle: linkedTriggerExplain,
          postIcon: props.row.auto_next ? 'fa-check text-success' : 'fa',
          class: props.row.auto_next ? '' : 'text-textColor-soft hover:text-textColor',
          preIcon: 'fa-link',
          action: (close: () => void) => {
            updateRow('start_on_time', false, 'auto_next', true);
            close();
          },
        },
        {
          title: 'Scheduled Trigger',
          hoverTitle: scheduledTriggerExplain,
          postIcon: props.row.start_on_time ? 'fa-check text-success' : 'fa',
          class: props.row.start_on_time ? '' : 'text-textColor-soft hover:text-textColor',
          preIcon: 'fa-clock',
          action: (close: () => void) => {
            updateRow('start_on_time', true, 'auto_next', false);
            close();
          },
        },
      ],
      preIcon: 'fa-play',
      action: (close: () => void) => {
        close();
      },
    },
    getIndexFromArrayBasedOnId(props.row.id, props.runningOrderRows) > 0
      ? {
          value: 2,
          title: 'Set Row Start',
          preIcon: 'fa-clock',
          action: (close: () => void) => {
            emit('setRowStart');
            close();
          },
        }
      : null,
  ].filter((i) => i !== null) as Items;

  if (row.header) {
    array.push({
      value: 3,
      title: 'Make Row Regular',
      preIcon: 'fa-paragraph fa-regular',
      action: (close: () => void) => {
        updateRow('header', false);
        close();
      },
    });
  } else {
    array.push({
      value: 3,
      title: 'Make Row Header',
      preIcon: 'fa-h1 fa-regular',
      action: (close: () => void) => {
        updateRow('header', true);
        close();
      },
    });
  }
  array.push({
    title: 'Delete Row',
    preIcon: 'fa-trash',
    class: 'text-warning',
    action: (close: () => void) => {
      deleteRow();
      close();
    },
  });

  array.push({
    type: 'divider',
  });

  array.push({
    title: 'Select Color',
    type: 'header',
  });

  allColors.value.forEach((color) => {
    array.push({
      title: color.definition,
      preIcon: 'fa-circle',
      value: color.definition,
      postIcon: row.color === color.class ? 'fa-check' : undefined,
      hex: color.hex,
      action: (close: () => void) => {
        updateRow('color', color.class);
        close();
      },
    });
  });

  return array;
};

const deleteRow = async () => {
  if (props.selectedRowIds && props.selectedRowIds.size > 0 && props.selectedRowIds.has(props.row.id)) {
    try {
      const promises = Array.from(props.selectedRowIds).map((id) => deleteRunningOrderRow(props.runningOrder.id, id));

      await Promise.all(promises);

      emit('deleted', Array.from(props.selectedRowIds));

      emit('update:selectedRowIds', new Set());

      useToast().success('Deleted');
    } catch (e) {
      useToast().error('Error deleting rows');
      throw e;
    }
  } else {
    await deleteRunningOrderRow(props.runningOrder.id, props.row.id);
    emit('deleted', [props.row.id]);
    useToast().success('Deleted');
  }
};

const updateRow = async (key, value, secondKey = null, secondValue = null) => {
  if (props.selectedRowIds && props.selectedRowIds.size > 0 && props.selectedRowIds.has(props.row.id)) {
    for (const itemId of props.selectedRowIds) {
      const item = getItemFromArrayBasedOnId(itemId, props.runningOrderRows);
      item[key] = value;
      if (secondKey) {
        item[secondKey] = secondValue;
      }
      await updateRunningOrderRow(props.runningOrder.id, item);
      emit('updateRow', item);
    }
    emit('update:selectedRowIds', new Set());
  } else {
    if (props.row[key] === value && !secondKey) return;
    const newRow = props.row;
    newRow[key] = value;
    if (secondKey) {
      newRow[secondKey] = secondValue;
    }

    await updateRunningOrderRow(props.runningOrder.id, newRow);
    emit('updateRow', newRow);
  }
};

const updateCell = async (column: RunningOrderColumnResource, value) => {
  if (props.selectedRowIds && props.selectedRowIds.size > 0 && props.selectedRowIds.has(props.row.id)) {
    for (const itemId of props.selectedRowIds) {
      const item = getItemFromArrayBasedOnId(itemId, props.runningOrderRows);
      await doActualUpdateOfCell(item, column, value);
    }
    emit('update:selectedRowIds', new Set());
  } else {
    await doActualUpdateOfCell(props.row, column, value);
  }
};
const doActualUpdateOfCell = async (row: RunningOrderRowResource, column: RunningOrderColumnResource, value) => {
  let cell = props.runningOrderCells.find(
    (c: RunningOrderCellResource) => c.running_order_row_id === row.id && c.running_order_column_id === column.id
  );
  if (cell && cell.value === value) return;

  if (cell) {
    cell.value = value;
    emit('update:runningOrderCells', exchangeValuesOfObject(cell, props.runningOrderCells));
    await updateRunningOrderCell(props.runningOrder.id, cell);
    return;
  }

  emit('update:runningOrderCells', [
    ...props.runningOrderCells,
    {
      running_order_row_id: row.id,
      running_order_column_id: column.id,
      value,
    } as RunningOrderCellResource,
  ]);

  const data = await createRunningOrderCell(props.runningOrder.id, {
    value: value,
    running_order_row_id: row.id,
    running_order_column_id: column.id,
  });
  const cells = [...props.runningOrderCells];
  const index = _.findIndex(
    cells,
    (cell) => cell.running_order_row_id === row.id && cell.running_order_column_id === column.id
  );
  if (index > -1) {
    cells.splice(index, 1);
  }
  cells.push(data.data);
  emit('update:runningOrderCells', cells);
};

const canStartShow = computed(() => {
  if (!props.canEdit) return false;
  return !(
    props.runningOrder.show_caller_id !== null && props.runningOrder.show_caller_id !== usePage().props.auth.user.id
  );
});

const startShow = () => {
  if (
    props.runningOrder.show_caller_id !== null &&
    props.runningOrder.show_caller_id !== usePage().props.auth.user.id
  ) {
    return false;
  }
  if (!props.canEdit) return;
  emit('startShow');
};

defineOptions({
  inheritAttrs: false,
});

const isFirstRow = computed(() => props.index === 0);

const itemBeforeIsLinkedTrigger = computed(() => {
  if (isFirstRow.value) return false;
  const myIndex = getIndexFromArrayBasedOnId(props.row.id, props.runningOrderRows);
  if (myIndex === 0) return false;
  const itemBefore = props.runningOrderRows[myIndex - 1];
  if (!itemBefore) return false;
  return itemBefore.auto_next;
});

const getDataListOptionsForColumn = (column: RunningOrderColumnResource) => {
  if (column.component !== 'runningorder-column-text') return null;
  const allValues = props.runningOrderCells
    .filter((cell: RunningOrderCellResource) => cell.running_order_column_id === column.id)
    .filter((cell: RunningOrderCellResource) => cell.value !== null)
    .map((cell: RunningOrderCellResource) => cell.value);

  return getSortedListOfValues(allValues);
};

const columnsWithInlineEdit = ['runningorder-column-document', 'runningorder-column-image'];

const getWidthOfColumn = (columnDefinition: RunningOrderColumnResource) => {
  const width = defaultColumnWidths.find((i) => columnDefinition.width === i.class);
  if (!width) return 100;
  return Number(width.size) * 100;
};
const pageX = ref(null);
const pageY = ref(null);
const heightFloatWindow = ref(null);
const widthFloatWindow = ref(null);
const selectedColumn = ref<RunningOrderColumnResource | null>(null);

const getFirstTdElement = (event) => {
  let element = event.target;
  while (element) {
    if (element.tagName && element.tagName.toLowerCase() === 'td') {
      break;
    }
    element = element.parentElement; // Move to the parent element
  }

  if (!element) {
    return event.target;
  }

  return element;
};
const openField = async (columnDefinition: RunningOrderColumnResource, event) => {
  if (!props.editMode) return;
  if (!props.canEdit) return;
  if (columnsWithInlineEdit.includes(columnDefinition.component)) return;
  if (getIndexFromArrayBasedOnId(columnDefinition.id, whispers.value) > -1) return;
  selectedColumn.value = null;
  await nextTick();
  selectedColumn.value = columnDefinition;
  emit('update:activeField', 'row_' + props.row.id + '_column_' + columnDefinition.id);
};

const getComponentName = (columnDefinition: RunningOrderColumnResource) => {
  switch (columnDefinition.component) {
    case 'runningorder-column-document': {
      return DocumentCell;
    }
    case 'runningorder-column-image': {
      return ImageCell;
    }
    default: {
      return TextCell;
    }
  }
};

const getProps = (columnDefinition: RunningOrderColumnResource) => {
  const defaultProps = {
    canEdit: props.inShowMode
      ? props.canEdit && columnDefinition.component === 'runningorder-column-text'
      : props.canEdit,
    'model-value': getRowValue(columnDefinition),
    'is-show-mode': props.inShowMode,
  };

  switch (columnDefinition.component) {
    case 'runningorder-column-text': {
      const setFocus =
        props.activeField !== null && props.activeField === 'row_' + props.row.id + '_column_' + columnDefinition.id;
      return {
        ...defaultProps,
        'set-focus': setFocus,
        'watch-for-focus-change': false,
        'tabindex': 0,
        'whisper': {
          channel: 'On.RunningOrder.' + props.runningOrder.id,
          string: 'row_' + props.row.id + '_column_' + columnDefinition.id,
        },
      };
    }
    default: {
      return defaultProps;
    }
  }
};

const echos = ref([]);
const whispers = ref([]);
const setupEchos = () => {
  props.activeColumns.forEach((column: RunningOrderColumnResource) => {
    const index = getIndexFromArrayBasedOnId(column.id, echos.value);
    if (index > -1) return;
    const echo = Echo.join('On.RunningOrder.' + props.runningOrder.id).listenForWhisper(
      'row_' + props.row.id + '_column_' + column.id,
      (e) => {
        if (e.typing && e.user) {
          const index = getIndexFromArrayBasedOnId(column.id, whispers.value);
          if (index !== -1) return;
          whispers.value.push({
            id: column.id,
            user: e.user,
          });
        } else {
          setTimeout(() => {
            const index = getIndexFromArrayBasedOnId(column.id, whispers.value);
            if (index === -1) return;
            whispers.value.splice(index, 1);
          }, 2000);
        }
      }
    );
    echos.value.push({
      id: column.id,
      echo: echo,
    });
  });
};
setupEchos();
watch(
  () => props.activeColumns,
  () => {
    setupEchos();
  }
);

const canBeTabbedInto = computed(() => {
  if (!props.activeField) return false;
  const previousRow = props.index === 0 ? null : props.runningOrderRows[props.index - 1];
  const nextRow = props.index + 1 === props.runningOrderRows.length ? null : props.runningOrderRows[props.index + 1];
  if (previousRow && props.activeField.includes('row_' + previousRow.id)) {
    return true;
  }
  if (props.activeField.includes('row_' + props.row.id)) {
    return true;
  }
  return !!(nextRow && props.activeField.includes('row_' + nextRow.id));
});
const timeoutId = ref(null);
const clearActiveField = (activeFieldString: string | null) => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
    timeoutId.value = null;
  }

  timeoutId.value = setTimeout(() => {
    if (activeFieldString === props.activeField) {
      console.log('denne skjer: ' + activeFieldString);
      emit('update:activeField', null);
    }
    clearTimeout(timeoutId.value);
    timeoutId.value = null;
  }, 200);
  selectedColumn.value = null;
};
</script>
<template>
  <VTableCell
    class="group/multiselect left-0 text-center !bg-backgroundColor"
    :class="getStickyColumnClasses()"
    clickable
    @click="emit('toggleRow')">
    <div v-if="inShowMode">
      <div
        v-if="activeRowId"
        class="w-full">
        <div
          v-if="activeRowId === row.id"
          class="text-center font-bold">
          <i class="fa fa-fw fa-play animate-bounce-horizontal ml-3"></i>
        </div>
        <div
          v-else
          class="text-center font-bold">
          {{ getIndexFromArrayBasedOnId(row.id, runningOrderRows) + 1 }}
        </div>
      </div>
      <div
        v-else
        class="w-full"
        @click="startShow">
        <div
          class="block text-center font-bold"
          :class="{ 'group-hover/item:hidden': canStartShow }">
          {{ getIndexFromArrayBasedOnId(row.id, runningOrderRows) + 1 }}
        </div>
        <small
          v-if="canStartShow"
          class="hidden w-full text-center group-hover/item:block">
          <i class="fa fa-fw fa-play fa-lg" />
        </small>
      </div>
    </div>
    <div v-else>
      <button
        v-if="canEdit && !inShowMode"
        class="invisible absolute left-0 right-0 top-[-7px] mx-auto max-w-fit place-items-center rounded-full bg-backgroundColor px-1 py-1 text-xs shadow group-hover/multiselect:visible"
        @click.stop="emit('insertRowBefore')">
        <i
          class="fa fa-fw fa-plus"
          style="color: rgb(var(--color-inverted-text)) !important" />
      </button>
      <!--      rgb(var(&#45;&#45;color-inverted-text) / var(&#45;&#45;tw-text-opacity)) !important-->
      <div
        v-if="!rowSelected"
        class="w-full">
        <div
          class="block text-center font-bold"
          :class="{ 'group-hover/multiselect:hidden': editMode && canEdit && !inShowMode }">
          {{ getIndexFromArrayBasedOnId(row.id, runningOrderRows) + 1 }}
        </div>
        <small
          v-if="editMode && canEdit && !inShowMode"
          class="hidden w-full text-center group-hover/multiselect:block">
          <i class="fa fa-fw fa-square-o fa-lg" />
        </small>
      </div>
      <i
        v-else-if="rowSelected"
        class="fa fa-fw fa-check-square-o fa-lg m-auto" />

      <button
        v-if="canEdit && !inShowMode"
        class="invisible absolute bottom-[-9px] left-0 right-0 z-20 mx-auto max-w-fit place-items-center rounded-full bg-backgroundColor-content px-1 py-1 text-xs shadow group-hover/multiselect:visible"
        @click.stop="emit('insertRowAfter')">
        <i
          class="fa fa-fw fa-plus"
          style="color: rgb(var(--color-inverted-text)) !important" />
      </button>
    </div>
  </VTableCell>

  <!--  <VTableCell-->
  <!--    class="left-[40px] text-center"-->
  <!--    :class="getStickyColumnClasses()">-->
  <!--    <div class="flex flex-col gap-3 pl-1">-->
  <!--      <i-->
  <!--        v-if="row.start_on_time"-->
  <!--        :title="scheduledTriggerExplain"-->
  <!--        class="fa fa-fw fa-clock fa-regular text-sm"></i>-->
  <!--      <i-->
  <!--        v-if="row.auto_next"-->
  <!--        :title="linkedTriggerExplain"-->
  <!--        class="fa fa-fw fa-link text-sm"></i>-->
  <!--      <i-->
  <!--        v-if="row.no_stage_timer"-->
  <!--        title="No Stage timer"-->
  <!--        class="fa fa-fw fa-desktop-slash text-sm"></i>-->
  <!--    </div>-->
  <!--  </VTableCell>-->

  <VTableCell
    class="left-[40px] text-center"
    :class="getStickyColumnClasses()"
    clickable
    @click="clickRowStart">
    <div class="text-center">
      <slot
        v-if="inShowMode"
        name="showModeStart" />
      <div
        v-else
        class="">
        {{ formatStampAsTime(getStartOfRow(row, runningOrderRows, runningOrder), null, secondFormat) }}
      </div>
    </div>
    <div
      v-if="!isFirstRow && itemBeforeIsLinkedTrigger"
      :class="isSmallScreen ? 'left-[75px]' : 'left-7'"
      class="absolute -top-[15px]">
      <div
        class="p-1 w-[6px] rounded-full bg-backgroundColor"
        title="Row will automatically go to next when done"
        style="height: 24px">
        <div class="w-full h-full bg-borderColor" />
      </div>
    </div>
  </VTableCell>

  <VTableCell
    class="left-[110px] [&_input]:p-[4px]"
    :class="getStickyColumnClasses()"
    classes="[&>*]:!p-0 !p-0 !bg-highlight [&_*]:h-full">
    <slot
      v-if="inShowMode && !editMode"
      name="showModeDuration" />
    <div v-else-if="loading">
      <IconWithLoading loading />
    </div>

    <VTimePicker
      v-else
      wrapper-classes="[&>*>*>*>*]:bg-transparent [&_input]:min-h-[40px]"
      is-hidden
      :with-seconds="true"
      input-class="rounded-none"
      :left-icon="null"
      :model-value="formatSecondsAsHoursAndMinutesAndSeconds(row.duration)"
      :with-icon="false"
      required
      :can-edit="editMode && canEdit && !isCurrentActivRow"
      :max-hours="99"
      @clicked-outside="[clearActiveField('row_' + row.id + '_duration')]"
      @focus="[emit('update:activeField', 'row_' + row.id + '_duration')]"
      @blur="
        [
          clearActiveField('row_' + row.id + '_duration'),
          updateRow('duration', formatHoursAndMinutesAndSecondsAsSeconds($event)),
        ]
      " />
  </VTableCell>

  <VTableCell
    main-cell
    :class="getStickyColumnClasses()"
    class="description left-[179px] [&_div]:!px-0 !pt-[1px] !p-0 !border-r-4">
    <div v-if="loading">
      <IconWithLoading loading />
    </div>

    <div
      v-else
      :class="{
        '-mt-[6px] mx-4 relative': !(canEdit && editMode),
        'relative [&_textarea]:pr-6 [&_.no-edit-text-cell-container]:!pr-6':
          row.start_on_time || row.auto_next || row.no_stage_timer,
      }">
      <div class="flex items-center">
        <div class="relative flex items-center w-full">
          <TextCell
            :model-value="row.title"
            :can-edit="canEdit && editMode"
            :tabindex="0"
            :whisper="{ channel: 'On.RunningOrder.' + runningOrder.id, string: 'row_' + row.id + '_title' }"
            @focus="[emit('update:activeField', 'row_' + row.id + '_title')]"
            @blur="[clearActiveField('row_' + row.id + '_title')]"
            @update:model-value="updateRow('title', $event)" />

          <div
            v-if="row.start_on_time || row.auto_next || row.no_stage_timer"
            :class="{
              ' mr-5': canEdit && editMode,
              'mt-[6px]': !(canEdit && editMode),
            }"
            class="absolute flex flex-col items-center gap-3 right-0 pl-1 mr-2">
            <i
              v-if="row.start_on_time"
              :title="scheduledTriggerExplain"
              class="fa fa-fw fa-clock fa-regular text-sm"></i>
            <i
              v-if="row.auto_next"
              :title="linkedTriggerExplain"
              class="fa fa-fw fa-link text-sm"></i>
            <i
              v-if="row.no_stage_timer"
              title="No Stage timer"
              class="fa fa-fw fa-desktop-slash text-sm"></i>
          </div>
        </div>
      </div>
    </div>

    <!--    <div-->
    <!--      v-else-->
    <!--      :class="{-->
    <!--        '-mt-[10px] mx-4': !(canEdit && editMode),-->
    <!--        'relative [&_textarea]:pr-6': row.start_on_time || row.auto_next || row.no_stage_timer,-->
    <!--      }">-->
    <!--      <div class="flex items-center">-->
    <!--        <TextCell-->
    <!--          :model-value="row.title"-->
    <!--          :can-edit="canEdit && editMode"-->
    <!--          :tabindex="0"-->
    <!--          :whisper="{ channel: 'On.RunningOrder.' + runningOrder.id, string: 'row_' + row.id + '_title' }"-->
    <!--          @focus="[emit('update:activeField', 'row_' + row.id + '_title')]"-->
    <!--          @blur="[clearActiveField('row_' + row.id + '_title')]"-->
    <!--          @update:model-value="updateRow('title', $event)" />-->
    <!--      </div>-->

    <!--      <div-->
    <!--        v-if="row.start_on_time || row.auto_next || row.no_stage_timer"-->
    <!--        class="flex flex-col gap-3 absolute pl-1 mr-2 top-0 right-0 items-center">-->
    <!--        <i-->
    <!--          v-if="row.start_on_time"-->
    <!--          :title="scheduledTriggerExplain"-->
    <!--          class="fa fa-fw fa-clock fa-regular text-sm"></i>-->
    <!--        <i-->
    <!--          v-if="row.auto_next"-->
    <!--          :title="linkedTriggerExplain"-->
    <!--          class="fa fa-fw fa-link text-sm"></i>-->
    <!--        <i-->
    <!--          v-if="row.no_stage_timer"-->
    <!--          title="No Stage timer"-->
    <!--          class="fa fa-fw fa-desktop-slash text-sm"></i>-->
    <!--      </div>-->
    <!--    </div>-->
  </VTableCell>

  <template v-if="loading">
    <VTableCell
      v-for="columnDefinition in activeColumns"
      :class="getStickyColumnClasses()">
      <div class="text-center flex justify-center">
        <IconWithLoading loading />
      </div>
    </VTableCell>
  </template>
  <template v-else>
    <VTableCell
      v-for="columnDefinition in activeColumns"
      :id="'running_order_row_' + row.id + '_column_' + columnDefinition.id"
      classes="relative group/button border-r !pl-[1px] !pt-[1px] !p-0 [&>*]:!p-0"
      @click.stop="openField(columnDefinition, $event)">
      <div :style="'min-height: 40px; width: ' + getWidthOfColumn(columnDefinition) + 'px!important;'">
        <div
          v-if="columnsWithInlineEdit.includes(columnDefinition.component) && canEdit"
          class="flex"
          style="min-height: 40px">
          <component
            :is="getComponentName(columnDefinition)"
            class="p-0 m-auto h-full w-full"
            v-bind="getProps(columnDefinition)"
            @focus="[emit('update:activeField', 'row_' + row.id + '_column_' + columnDefinition.id)]"
            @update:model-value="[updateCell(columnDefinition, $event)]" />
        </div>
        <div v-else>
          <TextCell
            v-if="
              (selectedColumn && columnDefinition.id === selectedColumn.id) ||
              activeField === 'row_' + row.id + '_column_' + columnDefinition.id ||
              canBeTabbedInto
            "
            class="p-0 w-full"
            v-bind="getProps(columnDefinition)"
            :style="'min-height: 40px;height: ' + heightFloatWindow + 'px;'"
            :data-list-options="getDataListOptionsForColumn(columnDefinition)"
            @focus="[emit('update:activeField', 'row_' + row.id + '_column_' + columnDefinition.id)]"
            @blur="[clearActiveField('row_' + row.id + '_column_' + columnDefinition.id)]"
            @update:model-value="
              [updateCell(columnDefinition, $event), (pageX = null), (pageY = null), (selectedColumn = null)]
            " />

          <div
            v-else-if="getRowValue(columnDefinition)"
            class="flex items-center min-h-[38px] px-[8px] h-full"
            @click.stop="openField(columnDefinition, $event)">
            <DisplayText
              v-if="getRowValue(columnDefinition)"
              :content="getRowValue(columnDefinition)"></DisplayText>
          </div>
          <div
            v-else
            class="w-full flex items-center min-h-[38px] px-[8px] cursor-pointer"
            @click.stop="openField(columnDefinition, $event)" />
          <WhisperIndicator
            v-if="getItemFromArrayBasedOnId(columnDefinition.id, whispers) !== null"
            class="bottom-0"
            :name="getItemFromArrayBasedOnId(columnDefinition.id, whispers, { user: { name: null } })?.user?.name" />
        </div>
        <div
          v-if="withAuditsButton"
          class="absolute top-0 right-0">
          <VButton
            tool-tip-text="Open Audits"
            class="hidden group-hover/button:block hover:bg-transparent"
            icon="fa-history"
            size="extra-small"
            @click="$emit('open-audits', columnDefinition)" />
        </div>
      </div>
    </VTableCell>

    <!--    <ColumnRunningOrderCell-->
    <!--      v-for="columnDefinition in activeColumns"-->
    <!--      :key="columnDefinition.id"-->
    <!--      :model-value="getRowValue(columnDefinition)"-->
    <!--      :column-definition="columnDefinition"-->
    <!--      :can-edit="canEditColumn(columnDefinition)"-->
    <!--      :column-id="columnDefinition.id"-->
    <!--      :width="columnDefinition.width"-->
    <!--      :index-key="index"-->
    <!--      :with-audits-button="withAuditsButton && canEditColumn(columnDefinition)"-->
    <!--      :is-show-mode="inShowMode"-->
    <!--      :whisper="{-->
    <!--        channel: 'On.RunningOrder.' + runningOrder.id,-->
    <!--        string: 'row_' + row.id + '_column_' + columnDefinition.id,-->
    <!--      }"-->
    <!--      :running-order-row="row"-->
    <!--      :running-order-cells="runningOrderCells"-->
    <!--      @open-audits="$emit('open-audits', columnDefinition)"-->
    <!--      @keydown-enter="$emit('keydown-enter', columnDefinition.id)"-->
    <!--      @update:model-value="updateCell(columnDefinition, $event)" />-->
  </template>

  <VTableCell
    v-if="canEdit && editMode && !inShowMode"
    class="!border-l-4">
    <div class="w-[50px] mx-auto">
      <VDropdown
        v-if="editMode && canEdit && !inShowMode"
        :items="itemsForDropdown(row)"
        title="Add Color to Row"
        close-on-click>
        <template #click-area>
          <div class="btn btn-in-table btn-outline hover:bg-transparent group-hover/item:block">
            <i class="fa fa-fw fa-bars" />
          </div>
        </template>
        <template #pre="{ item }">
          <CheckBox
            v-if="item.selected !== undefined"
            :model-value="item.selected" />
          <i
            :class="item.preIcon"
            :style="'color: ' + item.hex"
            class="fa fa-fw" />
        </template>
      </VDropdown>
    </div>
  </VTableCell>
  <VTableCell
    v-if="inShowMode"
    class="!border-l-4"
    style="min-width: 5px" />
</template>
