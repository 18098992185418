// Festival
export const canViewFestivalString = 'view';
export const canUpdateFestivalString = 'update';

export const canViewSchedulerOnFestivalString = 'view_scheduler';
export const canEditSchedulerOnFestivalString = 'schedule';

// Resources
export const canViewResourcesOnFestivalString = 'view_resources';
export const canEditResourcesOnFestivalString = 'edit_resources';

// Performance
export const canViewPerformancesOnFestivalString = 'view_performances';
export const canEditPerformancesOnFestivalString = 'edit_performances';

// Performances documents
export const canViewPerformanceDocumentsOnFestivalString = 'view_performance_info_section_documents';
export const canEditPerformanceDocumentsOnFestivalString = 'edit_performance_info_section_documents';

// Contacts
export const canViewContactsOnFestivalString = 'view_contacts';
export const canEditContactsOnFestivalString = 'edit_contacts';

// Travels
export const canViewTravelsOnFestivalString = 'view_travels';
export const canEditTravelsOnFestivalString = 'edit_travels';

// Accommodation
export const canViewAccommodationsOnFestivalString = 'view_accommodations';
export const canEditAccommodationsOnFestivalString = 'edit_accommodations';

// Accreditation
export const canViewAccreditationsOnFestivalString = 'view_accreditations';
export const canEditAccreditationsOnFestivalString = 'edit_accreditations';

// Finances
export const canViewFinancesOnFestivalString = 'view_finances';
export const canEditFinancesOnFestivalString = 'edit_finances';

export const canViewGuestListsOnFestivalString = 'view_guest_lists';
export const canEditGuestListsOnFestivalString = 'edit_guest_lists';

// Public Forms
export const canViewPublicFormsOnFestivalString = 'view_public_forms';
export const canEditPublicFormsOnFestivalString = 'edit_public_forms';

// Festival Sections
export const canViewFestivalSectionsOnFestivalString = 'view_festival_sections';
export const canEditFestivalSectionsOnFestivalString = 'edit_festival_sections';
