<script setup lang="ts">
import VTimeInput from '@/components/Inputs/Date/VTimePicker.vue';
import { ref } from 'vue';

type Props = {
  modelValue: string | null;
  leftIcon?: string | null;
  canEdit: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  leftIcon: '',
});

const emit = defineEmits<{
  (event: 'update:modelValue', arg: string | null): void;
  (event: 'blur', arg: string | null): void;
  (event: 'focus'): void;
}>();

const edit = ref(false);
const inFocus = ref(false);

const onChange = (value) => {
  emit('update:modelValue', value);
  if (props.modelValue !== value) {
    edit.value = false;
  }
};

const onBlur = (value) => {
  inFocus.value = false;
  setTimeout(() => {
    edit.value = false;
  }, 100);
  if (value !== null && value !== '' && value !== undefined && value !== false && value !== 'false') {
    const input = value.replace(/s/g, '0').replace(/m/g, '0');
    emit('update:modelValue', input);
  } else {
    emit('update:modelValue', null);
  }
};
</script>

<template>
  <div
    v-if="canEdit"
    class="h-full w-full"
    :class="{ 'text-textColor': inFocus }">
    <VTimeInput
      :model-value="modelValue"
      :can-edit="canEdit"
      :left-icon="leftIcon"
      input-class="rounded-none"
      wrapper-classes="w-full cursor-pointer"
      is-hidden
      @focus="[$emit('focus'), (inFocus = true)]"
      @clear="onBlur(null)"
      @blur="onBlur" />
  </div>
  <div
    v-else
    class="full-size overflow-auto p-1">
    <p
      v-if="modelValue"
      class="my-0">
      {{ modelValue }}
    </p>
    <slot
      v-else
      name="emptyState" />
  </div>
</template>
