<script setup lang="ts">
import { useEmitStore } from '@/store/EmitStore';
import { CheckListResource } from '@/types/check-list';
import { GroupResource } from '@/types/group';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId, getKey } from '@/util/globals';
import CheckListCRUDModal from '@/components/Models/CheckLists/CheckListCRUDModal.vue';
import CheckList from '@/components/Models/CheckLists/CheckList.vue';
import CheckListReportModal from '@/components/Models/CheckLists/CheckListReportModal.vue';
import ContentList from '@/components/Content/ContentList.vue';
import CheckListEventReportModal from '@/components/Models/CheckLists/CheckListEventReportModal.vue';
import AuditsSidebar from '@/components/Audits/AuditsSidebar.vue';
import { auditableCheckListFields, auditableCheckListRowFields } from '@/helpers/auditHelper';
import { computed, nextTick, onActivated, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { checkListStore } from '@/store/check-list-store';
import { checkListTemplateGalleryRootEmitKey } from '@/components/Models/TemplateGallery/templates/check-list-templates';

type Props = {
  model: 'Group' | 'Invite';
  modelId: number;
  canEdit: boolean;
  isForChild?: boolean;
  isRecurring: boolean;
  isTemplate: boolean;
  templateGroupId?: number | null;
  start?: string | null;
  group?: GroupResource | null;
  templateGalleryOpen?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  start: null,
  templateGroupId: null,
  group: null,
  isForChild: false,
  templateGalleryOpen: null,
});

const emits = defineEmits<{
  'update:templateGalleryOpen': [arg: boolean];
}>();

const { loading, list } = storeToRefs(checkListStore());
const { fetchCheckLists } = checkListStore();

const updateData = async (force = false) => {
  const data = await fetchCheckLists(props.model, props.modelId, force);

  checkLists.value = data ?? [];
};

const data = await fetchCheckLists(props.model, props.modelId, props.isTemplate);

const checkLists = ref(data ?? []);

onActivated(async () => {
  await updateData();
});

watch(
  list,
  async () => {
    await updateData();
  },
  { deep: true }
);

const auditsOpen = ref(false);
const reportModalOpen = ref(false);
const creatingCheckList = ref(false);

const showModal = ref(false);
const selectedCheckList = ref<CheckListResource | null>(null);

const openModal = (checkList: CheckListResource | null = null) => {
  selectedCheckList.value = checkList;
  showModal.value = true;
};

const onDeleteCheckList = async (checkListId: number) => {
  const index = getIndexFromArrayBasedOnId(checkListId, checkLists.value);
  if (index > -1) {
    checkLists.value.splice(index, 1);
  } else {
    await updateData();
  }
};

if (!props.isTemplate) {
  useEmitStore().$subscribe((_, state) => {
    switch (state.item?.key) {
      case 'check-list-added-to-event-via-group-add-to-event': {
        updateData(true);
        break;
      }
      default:
        break;
    }
  });
}

const actions = computed(() => {
  const array = [];

  if (props.isTemplate && props.canEdit && props.templateGalleryOpen !== null) {
    array.push({
      title: 'Template Gallery',
      icon: 'fa-books fa-regular',
      action: async () => {
        emits('update:templateGalleryOpen', false);
        await nextTick();
        emits('update:templateGalleryOpen', true);
      },
    });
  }

  if (!props.isTemplate && !props.isForChild) {
    array.push({
      title: 'Audits',
      icon: 'fa-history',
      action: async () => {
        auditsOpen.value = false;
        await nextTick();
        auditsOpen.value = true;
      },
    });
  }
  if (checkLists.value.length > 0) {
    array.push({
      title: 'Report',
      icon: 'fa-download',
      action: async () => {
        reportModalOpen.value = false;
        await nextTick();
        reportModalOpen.value = true;
      },
    });
  }
  if (props.canEdit && !props.isForChild) {
    array.push({
      title: 'Create',
      icon: 'fa-plus',
      loading: creatingCheckList.value,
      action: () => {
        openModal();
      },
    });
  }
  return array;
});

const getAllowedAuditSidebarFields = () => {
  return [
    { model: 'App\\CheckList', fields: auditableCheckListFields() },
    { model: 'App\\CheckListRow', fields: auditableCheckListRowFields() },
  ];
};
useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'template-gallery-import':
      const payload = state.item?.payload;
      if (!payload) return;
      if (getKey(payload, 'key') === checkListTemplateGalleryRootEmitKey) {
        checkLists.value.push(getKey(payload, 'data'));
      }
      break;
    default:
      break;
  }
});
</script>

<template>
  <ContentList
    :loading="loading"
    :with-back-button="isTemplate"
    :height-reduction="isTemplate ? 0 : 30"
    :title="isTemplate ? null : 'Check Lists'"
    :under-title="isTemplate ? null : ''"
    :pre-icon="isTemplate ? null : 'fa-list-check'"
    empty-icon="fa-list-check"
    :empty-message="checkLists.length === 0 && !loading ? 'No Check Lists Have Been Added' : null"
    :empty-action="
      canEdit
        ? () => {
            openModal();
          }
        : null
    "
    :actions="actions">
    <CheckList
      v-for="checkList in checkLists"
      :key="checkList.id"
      :model-value="checkList"
      :is-template="isTemplate"
      :can-edit="canEdit"
      :group="group"
      :is-display="false"
      :model="model"
      :model-id="modelId"
      @edit="openModal(checkList)" />
  </ContentList>

  <CheckListCRUDModal
    v-if="showModal"
    :init-check-list="selectedCheckList"
    :is-template="isTemplate"
    :model="model"
    :model-id="modelId"
    :start="start"
    :group="group"
    :template-group-id="templateGroupId"
    :is-recurring="isRecurring"
    @closed="showModal = false"
    @created="checkLists.push($event)"
    @updated="checkLists = exchangeValuesOfObject($event, checkLists)"
    @deleted="onDeleteCheckList" />

  <CheckListReportModal
    v-if="isTemplate && reportModalOpen"
    :check-lists="checkLists" />

  <CheckListEventReportModal
    v-if="!isTemplate && reportModalOpen"
    :check-lists="checkLists" />
  <AuditsSidebar
    v-if="auditsOpen && !isTemplate && model === 'Invite'"
    key="Audits for Check Lists"
    :can-filter-models="false"
    open-on-create
    :with-button="false"
    :url="'/api/audits/invites/' + modelId"
    :queryable-parameters="[
      { name: 'CheckList', id: 'App\\CheckList' },
      { name: 'CheckListRow', id: 'App\\CheckListRow' },
    ]"
    :allowed-fields="getAllowedAuditSidebarFields()"
    title="Audits for Check Lists" />
</template>
