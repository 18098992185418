import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useInvoiceProductCategoriesStore } from '@/store/invoices/InvoiceProductCategoriesStore';
import { InvoiceRowCategoryResource, InvoiceRowResource } from '@/types/invoice-row';

export const useInvoiceProductCategories = (groupId: number) => {
  const { list, loading } = storeToRefs(useInvoiceProductCategoriesStore());
  const {
    fetchInvoiceProductCategories: fetch,
    fetchProductCategory: fetchOneCategory,
    changeOutList: changeListValue,
    addOrUpdateInvoiceProductCategory: addOrUpdate,
    removeInvoiceProductCategory: remove,
    reorderProductCategories: updateOrder,
  } = useInvoiceProductCategoriesStore();

  const categories = computed(() => list.value.get(groupId) ?? []);

  const fetchInvoiceProductCategories = async (force = false) => {
    if (!groupId) return;
    if (force || !list.value.has(groupId)) {
      await fetch(groupId);
    }
  };

  const addOrUpdateInvoiceProductCategory = async (invoiceProductCategory: InvoiceRowCategoryResource) => {
    await addOrUpdate(groupId, invoiceProductCategory);
  };

  const reorderProductCategories = async (orderOfIds: []) => {
    await updateOrder(groupId, orderOfIds);
  };
  const removeInvoiceProductCategory = async (invoiceProductCategoryId: number) => {
    await remove(groupId, invoiceProductCategoryId);
  };
  const fetchProductCategory = async (InvoiceProductId: number) => {
    await fetchOneCategory(groupId, InvoiceProductId);
  };

  const changeOutList = (types: InvoiceRowResource[]) => {
    changeListValue(groupId, types);
  };

  return {
    categories,
    changeOutList,
    addOrUpdateInvoiceProductCategory,
    fetchInvoiceProductCategories,
    removeInvoiceProductCategory,
    fetchProductCategory,
    reorderProductCategories,
    loading,
  };
};
