import { arrayToJoinString } from '@/util/globals';

export const personalContextFormat = (): string => 'personal';

export const groupContextFormat = (groupId: number): string => `group_${groupId}`;

export const festivalContextFormat = (festivalId: number): string => `festival_${festivalId}`;

export const getTypeAndIdFromContext = (context: string): { type: string; id: number | null } => {
  const [type, idString] = context.split('_');
  const id = idString ? Number(idString) : null;
  return { type, id };
};

export const getTitleFromContext = (context: string): string => {
  let array = context.split('_');
  array = array.slice(2);
  return arrayToJoinString(array, ' ', ' ');
};
