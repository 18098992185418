<script lang="ts" setup>
import { usePageTabsStore } from '@/store/PageTabsStore';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import TheSubNavItem from '@/components/Navbar/TheSubNavItem.vue';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { PageTab } from '@/types/page-tab';
import VList from '@/components/VList.vue';
import { convertRemToPixels } from '@/util/globals';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { useNavBarTitleStore } from '@/composables/use-nav-bar-title';

const pageTabsStore = usePageTabsStore();
const container = ref<HTMLDivElement | null>(null);
const contextSidebarStore = useContextSidebarStore();
const useNavBarTitleStore1 = useNavBarTitleStore();

const showableTabs = ref(null);
const itemsAfterVisible = ref([]);

const getItemsAfterVisible = async () => {
  itemsAfterVisible.value = [];
  await nextTick();
  if (!pageTabsStore.tabs) return;
  itemsAfterVisible.value = pageTabsStore.tabs?.filter((i, index) => {
    return index > showableTabs.value;
  });
};

watch(
  () => pageTabsStore.tabs,
  () => {
    calcTabMaxWidth();
  }
);

const calcTabMaxWidth = async (tryAgain = true) => {
  if (!container.value) {
    if (tryAgain) {
      setTimeout(() => {
        calcTabMaxWidth(false);
      }, 100);
    }
    return;
  }
  const expectedWidthOfTab = convertRemToPixels(0.75) + 110;
  let newShowableTabs = Math.round(container.value.offsetWidth / expectedWidthOfTab);
  if (newShowableTabs < pageTabsStore.tabs.length) {
    newShowableTabs = Math.round((container.value.offsetWidth - 80 - convertRemToPixels(0.75)) / expectedWidthOfTab);
  }
  if (newShowableTabs !== showableTabs.value) {
    showableTabs.value = newShowableTabs;
  }
  getItemsAfterVisible();
};

const widthOfScreen = ref(window.innerWidth);
const resetSizes = () => {
  if (widthOfScreen.value !== window.innerWidth) {
    calcTabMaxWidth();
  }
  widthOfScreen.value = window.innerWidth;
};

onMounted(() => {
  calcTabMaxWidth(true);
  checkIfSubNavAlternativeIsEmpty();
  window.addEventListener('resize', resetSizes);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resetSizes);
});

setTimeout(() => {
  if (!showableTabs.value) {
    calcTabMaxWidth(false);
  }
}, 5000);

const dropDownList = (close: () => void) => {
  return itemsAfterVisible.value.map((item: PageTab) => {
    return {
      title: item.title,
      postIcon: pageTabsStore.activeTab?.key === item.key ? 'fa-check text-highlight' : '',
      action: () => {
        pageTabsStore.setActiveTab(item);
        close();
      },
    };
  });
};

const alternativeContainer = ref<HTMLDivElement | null>(null);
const subNavAlternativeIsEmpty = ref(true);
const checkIfSubNavAlternativeIsEmpty = () => {
  subNavAlternativeIsEmpty.value = true;
  for (let k = 0; k < 5000; k = k + 100) {
    setTimeout(() => {
      doTheCheckIfSubNavAlternativeIsEmpty();
    }, k);
  }
};
const doTheCheckIfSubNavAlternativeIsEmpty = async () => {
  if (!alternativeContainer.value) return;
  subNavAlternativeIsEmpty.value = false;
  await nextTick();
  subNavAlternativeIsEmpty.value = alternativeContainer.value.childNodes.length === 0;
};
watch(
  () => pageTabsStore.activeTab,
  () => {
    checkIfSubNavAlternativeIsEmpty();
  },
  { deep: true, immediate: true }
);
</script>

<template>
  <div
    v-if="pageTabsStore.inNavbar && useNavBarTitleStore1.allowItemsInSubNav"
    ref="container"
    :class="showableTabs ? 'opacity-100' : 'opacity-0'"
    class="flex h-[25px] w-full gap-4 lg:h-[30px] pl-edge"
    data-intro="page-tabs">
    <template
      v-for="(tab, index) in pageTabsStore.tabs"
      :key="tab.key">
      <TheSubNavItem
        v-if="!showableTabs || index <= showableTabs"
        :active="pageTabsStore.activeTab?.key === tab.key"
        :title="tab.title"
        @click="pageTabsStore.setActiveTab(tab)" />
    </template>
    <VDropdown
      v-if="itemsAfterVisible.length > 1"
      :items="
        itemsAfterVisible.map((item: PageTab) => {
          return {
            title: item.title,
          };
        })
      "
      close-on-click>
      <template #click-area>
        <TheSubNavItem
          v-if="itemsAfterVisible.length > 1"
          class="w-[80px]"
          :active="itemsAfterVisible.map((t) => t.key).includes(pageTabsStore.activeTab?.key)"
          :title="'+' + itemsAfterVisible.length + ' items'" />
      </template>
      <template #dropdown="{ close }">
        <VList :items="dropDownList(close)" />
      </template>
    </VDropdown>
    <TheSubNavItem
      v-if="itemsAfterVisible.length === 1"
      :active="itemsAfterVisible[0].key === pageTabsStore.activeTab?.key"
      :title="itemsAfterVisible[0].title"
      @click="pageTabsStore.setActiveTab(itemsAfterVisible[0])" />
    <div>
      <TheSubNavItem
        v-if="
          !pageTabsStore.tabs.length &&
          contextSidebarStore.currentSelectedSidebarItem &&
          contextSidebarStore.currentSelectedSidebarItem.title &&
          subNavAlternativeIsEmpty
        "
        active
        :title="contextSidebarStore.currentSelectedSidebarItem.title" />
    </div>
    <div
      id="sub_nav_alternative"
      ref="alternativeContainer"
      style="margin-left: calc(var(--edge-padding) * -1)"
      class="w-full"></div>
  </div>
</template>
