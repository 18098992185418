<script setup lang="ts">
import { getComponent, getProps } from '@/util/get-component';
import { z } from 'zod';
import { ModelType } from '@/types/global';
import { PartnerContactResource, PartnerFieldResource } from '@/types/partners';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import {
  addContactToCompany,
  createPartnerCompany,
  createPartnerContact,
  getPartnerFields,
  patchPartnerValue,
} from '@/services/api-partners';
import TextInput from '@/components/Inputs/TextInput.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import { getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

type Props = {
  modelValue: object;
  model: ModelType;
  modelId: number;
  withRoleField?: boolean;
  withPrimaryField?: boolean;
  canAddCompany?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  withPrimaryField: false,
  withRoleField: false,
  canAddCompany: true,
});

const emit = defineEmits<{
  (e: 'closed'): void;
  (e: 'company'): void;
  (e: 'created', arg: PartnerContactResource): void;
}>();

const toast = useToast();

const contactSchema = z.object({
  first_name: z.string().min(2).max(255),
  last_name: z.string().min(0).max(255),
  phone: z.string().nullable(),
  email: z.string().nullable(),
  role: z.string().nullable(),
  is_primary: z.boolean(),
  country_code: z.string().nullable(),
});

type Contact = z.infer<typeof contactSchema>;

const contact = ref<Contact>({
  first_name: props.modelValue.name ?? '',
  last_name: '',
  phone: null,
  country_code: null,
  email: null,
  role: null,
  is_primary: false,
});

const loading = ref(false);
const partnerFields = ref<PartnerFieldResource[]>([]);
const fieldValues = ref([]);

const companies = ref<
  {
    name: string;
    id: number | null;
    role: string;
    is_primary: boolean;
  }[]
>([]);

const fetchPartnerFields = async () => {
  const { data } = await getPartnerFields(props.model, props.modelId, 'App\\PartnerContact');
  partnerFields.value = _.orderBy(data, 'order');
};
fetchPartnerFields();

const assignValue = (value, field) => {
  const index = _.findIndex(fieldValues.value, (f) => f.id === field.id);
  if (index > -1) {
    fieldValues.value[index].value = value;
  } else {
    fieldValues.value.push({
      id: field.id,
      value,
    });
  }
};

const canSave = computed(() => contactSchema.safeParse(contact.value).success);

const createContact = async (close: () => void) => {
  if (!canSave.value) {
    toast.error('Please fill out all required fields');
    return;
  }
  loading.value = true;
  try {
    const { data: newContact } = await createPartnerContact(props.model, props.modelId, contact.value);
    for (let j = 0; j < companies.value.length; j++) {
      if (!companies.value[j].id) {
        const { data: newCompany } = await createPartnerCompany(props.model, props.modelId, {
          name: companies.value[j].name,
          partner_type_id: null,
          phone: null,
          email: null,
        });
        companies.value[j].id = newCompany.id;
      }
      await addContactToCompany(
        props.model,
        props.modelId,
        companies.value[j].id,
        newContact.id,
        companies.value[j].role,
        companies.value[j].id.is_primary
      );
      emit('company', companies.value[j]);
    }
    for (let j = 0; j < fieldValues.value.length; j++) {
      await patchPartnerValue(
        props.model,
        props.modelId,
        fieldValues.value[j].id,
        fieldValues.value[j].value,
        null,
        newContact.id
      );
    }

    toast.success('Contact Created');
    emit('created', newContact);
  } catch (e) {
    toast.success('Something went wrong - please try again later.');
    throw e;
  }
  loading.value = false;
  close();
};

const onUpdateCompany = (index: number, company: { id?: number; name: string }) => {
  if (company.id) {
    companies.value[index].id = company.id;
  }
  companies.value[index].name = company.name;
};

const pageY = ref(null);
const pageX = ref(null);
const openCompanySearch = (event) => {
  pageY.value = null;
  pageX.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 270;
    pageY.value = event.target.getBoundingClientRect().y + 30;
  });
};

const showCompanyCreation = computed(() => {
  return props.model === 'Group' && props.canAddCompany;
});

const setUp = async () => {
  const name = getKey(props.modelValue, 'name', '');
  if (!name) return;
  let split = name.split(' ');
  contact.value.first_name = split[0];
  if (split.length > 1) {
    split.splice(0, 1);
    contact.value.last_name = split.join(' ');
  }
};
setUp();
</script>

<template>
  <CrudSlideout
    :disabled="!canSave"
    :loading="loading"
    :tiny="!showCompanyCreation"
    :base-z-index="2000"
    :small="showCompanyCreation"
    title="Create New Contact"
    @closed="$emit('closed')"
    @create="createContact">
    <div class="[&>div:last-child]:border-none [&>div]:border-b [&>div]:p-edge">
      <div
        class="form-layout"
        :class="showCompanyCreation ? 'grid-cols-2' : ''">
        <TextInput
          v-model="contact.first_name"
          required
          label="First Name" />

        <TextInput
          v-model="contact.last_name"
          label="Last Name" />

        <TextInput
          v-if="withRoleField"
          v-model="contact.role"
          label="Role" />

        <SettingToggle
          v-if="withPrimaryField"
          v-model="contact.is_primary"
          title="If enabled, this contact will be a primary contact."
          label="Primary" />

        <PhoneInput
          v-model:phone="contact.phone"
          v-model:country-code="contact.country_code"
          size="block" />

        <EmailInput v-model:email="contact.email" />

        <template
          v-for="field in partnerFields"
          :key="field.id">
          <component
            :is="getComponent(field.component)"
            :label="field.title"
            v-bind="getProps(field)"
            :model-value="getItemFromArrayBasedOnId(field.id, fieldValues, { value: null }).value"
            :options="field.options"
            :is-template="true"
            :can-edit="true"
            placeholder="..."
            :with-buttons="false"
            @blur="assignValue($event, field)" />
        </template>
      </div>

      <div
        v-if="showCompanyCreation"
        class="flex p-edge justify-between">
        <h3>Companies</h3>
        <ActionButtonGroup
          :actions="[
            {
              title: 'Add Company',
              icon: 'fa-plus',
              disabled: !canSave,
              action: ($event) => {
                openCompanySearch($event);
              },
            },
          ]"></ActionButtonGroup>
      </div>

      <div
        v-for="(company, index) in companies"
        :key="company.id">
        <div class="flex justify-end">
          <ActionButtonGroup
            :actions="[
              {
                title: 'Delete',
                icon: 'fa-trash',
                action: () => {
                  companies.splice(index, 1);
                },
              },
            ]" />
        </div>
        <div class="form-layout grid-cols-2">
          <TextInput
            v-model="company.name"
            label="Name"
            :can-edit="company.id === null" />

          <TextInput
            v-model="company.role"
            label="Role of Contact" />

          <SettingCheck
            v-model="company.is_primary"
            title="If enabled, this contact will be a primary contact of the company."
            label="Primary" />
        </div>
      </div>
      <SearchSelectFloatingWindow
        v-if="pageX && pageY"
        url="/api/partners/companies"
        placeholder="Search For Company"
        :debounce-time="500"
        can-create
        :params="{
          model_type: 'App\\' + model,
          model_id: modelId,
        }"
        :page-y="pageY"
        :page-x="pageX"
        :already-selected-ids="companies.map((item) => item.id)"
        @create="companies.push({ id: null, name: $event, is_primary: false, role: '' })"
        @selected="
          companies.push({
            id: $event.id,
            name: $event.name,
            role: '',
            is_primary: false,
          })
        " />
    </div>
  </CrudSlideout>
</template>
