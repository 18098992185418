<script setup lang="ts">
import UserEditedByIcon from '@/components/Helpers/UserEditedByIcon.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import GridTemplate from '@/components/GridTemplate.vue';
import { getComponent } from '@/util/get-component';
import type { FieldResource } from '@/types/field';
import VButton from '@/components/Inputs/VButton.vue';
import { getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import { allAvailableFieldTypes, multiSelectFieldType, tableFieldType } from '@/util/fields';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

type Props = {
  modelValue: any[];
  sectionName?: string | null;
  documents?: any[];
  whisperChannel?: string | null;
  whisperString?: string | null;
  model?: string | null;
  modelId?: number | null;
  editContent?: boolean;
  editForm?: boolean;
  withIcons?: boolean | null;
  allowFieldsToOverrideCanEdit?: boolean;
  gridGap?: number;
  emitDocumentUploadedAsOwnMethod?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  sectionName: null,
  documents: () => [],
  whisperChannel: null,
  whisperString: null,
  model: null,
  modelId: null,
  editContent: false,
  editForm: false,
  withIcons: false,
  allowFieldsToOverrideCanEdit: false,
  gridGap: 3,
  emitDocumentUploadedAsOwnMethod: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: any[]): void;
  (event: 'openEditModal', field: FieldResource): void;
  (event: 'fieldOrderChanged', field: FieldResource, order: number): void;
  (event: 'assignValue', field: FieldResource, value: any): void;
  (event: 'documentUploaded', document: any): void;
}>();

const newOrder = (field: FieldResource, order: number) => {
  emit('fieldOrderChanged', field, order);
};
const getProps = (field: FieldResource) => {
  const defaultProps = {
    canEdit: props.editContent,
    'model-value': getKey(field, 'value', null),
  };

  if (props.allowFieldsToOverrideCanEdit && field.hasOwnProperty('write') && field.write) {
    defaultProps.canEdit = true;
  }

  const leftIcon = props.withIcons
    ? getItemFromArrayBasedOnId(
        field.component,
        [...allAvailableFieldTypes, ...multiSelectFieldType, ...tableFieldType],
        { icon: null },
        'component'
      ).icon
    : null;
  switch (field.component) {
    case 'field-text': {
      return {
        ...defaultProps,
        'min-height': 40,
        'min-rows': 1,
        'whisper':
          props.whisperChannel && props.whisperString
            ? { channel: props.whisperChannel, string: props.whisperString + field.id }
            : null,
        'icon-left': leftIcon,
      };
    }
    case 'field-rich-text': {
      return {
        ...defaultProps,
        'whisper':
          props.whisperChannel && props.whisperString
            ? { channel: props.whisperChannel, string: props.whisperString + field.id }
            : null,
      };
    }
    case 'field-date': {
      return {
        ...defaultProps,
        'mode': getKey(field, 'mode'),
      };
    }
    case 'field-number': {
      return {
        ...defaultProps,
        withDecimals: true,
        'icon-left': leftIcon,
        'size': 'block',
      };
    }
    case 'field-list': {
      return {
        ...defaultProps,
        options: field.options,
        'icon-left': leftIcon,
        nullableDisplayText: ' ',
      };
    }
    case 'field-multi-select': {
      return {
        ...defaultProps,
        options: field.options,
        'icon-left': leftIcon,
        nullableDisplayText: ' ',
      };
    }
    case 'field-table': {
      return {
        ...defaultProps,
        options: field.options,
        nullable: true,
        'icon-left': leftIcon,
        nullableDisplayText: ' ',
        editForm: props.editForm,
        editContent: props.editContent,
      };
    }
    case 'field-document':
    case 'field-public-document': {
      return {
        ...defaultProps,
        documents: props.documents,
        model: props.model,
        modelId: props.modelId,
        canEditImage: false,
        'emit-document-action-as': props.emitDocumentUploadedAsOwnMethod ? 'documentUploaded' : 'blur',
        documentUploaded: (document: any) => {
          emit('documentUploaded', { field, document });
          // @document-uploaded="$emit('documentUploaded', { field, document: $event })"
        },
      };
    }
    default: {
      return defaultProps;
    }
  }
};
</script>

<template>
  <div :class="{ 'p-2': editForm }">
    <GridTemplate
      v-slot="{ item: field }"
      :model-value="modelValue"
      :columns="4"
      :can-drag="editForm && modelValue.length > 1"
      :gap="gridGap"
      drag-handle=""
      @update:model-value="$emit('update:modelValue', $event)"
      @new-order="newOrder">
      <div
        class="flex h-full group"
        style="width: inherit"
        :class="{ 'cursor-grab border hover:border-borderColor-strong hover:bg-row-hover/50 rounded ': editForm }">
        <div
          v-if="editForm"
          class="flex w-6 items-center mr-4">
          <i
            v-if="editForm"
            class="m-auto cursor-grab text-textColor-soft group-hover:text-textColor"
            :class="editForm ? 'fa fa-arrows-up-down-left-right' : 'fa fa-arrows-up-down-left-right opacity-0'" />
        </div>
        <div
          class="flex h-full flex-1 flex-col"
          :class="editForm ? 'p-3' : 'p-1'"
          style="width: inherit">
          <div
            :class="{ 'grid': editForm || field.edited_by_id }"
            :style="
              editForm || field.edited_by_id ? 'grid-template-columns: 1fr ' + (editForm ? '30' : '18') + 'px' : ''
            ">
            <div class="flex-1 truncate flex gap-3">
              <InputLabel
                v-if="!field.displayTitle"
                :label="field.title"
                :icon="getKey(field, 'titleIcon', null)"
                :mandatory-text="getKey(field, 'required', false) ? 'Mandatory' : ''"
                :title="field.description" />
              <div
                v-if="field.displayTitle"
                v-html="safeHtmlStringify(field.displayTitle)" />
              <slot
                name="afterTitle"
                :field="field" />
            </div>
            <UserEditedByIcon
              v-if="field.edited_by_id && !editForm"
              :edited-at="field.updated_at"
              custom-style="height: 20px;"
              :user-id="field.edited_by_id" />

            <VButton
              v-if="editForm"
              size="extra-small"
              class="btn-outline-text"
              icon="fa-pencil w-[6px]"
              @click="$emit('openEditModal', field)" />
          </div>
          <div class="h-full w-full flex-1 items-center min-h-[40px]">
            <component
              :is="getComponent(field.component)"
              v-bind="getProps(field)"
              @document-uploaded="$emit('documentUploaded', { field: field, document: $event })"
              @blur="$emit('assignValue', $event, field)" />
          </div>
        </div>
      </div>
    </GridTemplate>
  </div>
</template>
