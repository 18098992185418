import { useToast } from 'vue-toastification';
import { formatStampAsTime, getDiffInInterval, getNow } from '@/util/timeFunctions';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { arrayToJoinString, getIndexFromArrayBasedOnId, getKey } from '@/util/globals';
import { useEmitStore } from '@/store/EmitStore';

const toast = useToast();

export const addTimeSlotForFestivalSectionToPublicForm = async (
  publicFormResponse,
  festivalSectionId,
  timeSlotId,
  timeSlots = []
) => {
  try {
    await axios.post(`/api/public-form-responses/${publicFormResponse.id}/shifts`, {
      time_slot_id: timeSlotId,
      festival_section_id: festivalSectionId,
    });
    const { rootEmit } = useEmitStore();
    rootEmit('publicForm_response_added_to_festivalSection_and_timeSlot', {
      festivalSectionId: festivalSectionId,
      timeSlotId: timeSlotId,
      countChanger: 1,
    });
    return true;
  } catch (e) {
    if (timeSlots.length > 0) {
      const slot = timeSlots.find((item) => item.id === timeSlotId);
      toast.warning(`Time slot ${slot ? slot.title : ''} not available`);
    }
    if (e.response.data) {
      toast.warning(e.response.data);
    }
    return false;
  }
};

export const removeTimeSlotForFestivalSectionToPublicForm = async (
  publicFormResponse,
  festivalSectionId,
  timeSlotId
) => {
  try {
    await axios.delete(`/api/public-form-responses/${publicFormResponse.id}/shifts`, {
      params: {
        time_slot_id: timeSlotId,
        festival_section_id: festivalSectionId,
      },
    });
    const { rootEmit } = useEmitStore();
    rootEmit('publicForm_response_added_to_festivalSection_and_timeSlot', {
      festivalSectionId: festivalSectionId,
      timeSlotId,
      countChanger: -1,
    });
    return true;
  } catch (e) {
    if (e.response.data) {
      toast.warning(e.response.data);
    }
    return false;
  }
};

export const addPublicFormResponseToFestivalSectionId = async (publicFormResponse, festivalSectionId) => {
  const index = getIndexFromArrayBasedOnId(festivalSectionId, publicFormResponse.preferred_festival_sections);
  const order =
    index > -1
      ? publicFormResponse.preferred_festival_sections[index].order
      : Math.max(publicFormResponse.preferred_festival_sections.map((s) => s.order).filter((s) => s !== null));

  await axios.post(`/api/public-form-responses/${publicFormResponse.id}/festival-sections/${festivalSectionId}`, {
    order: (order === 0 ? null : order + 1) ?? null,
  });
  const { rootEmit } = useEmitStore();
  rootEmit('publicForm_response_added_to_festivalSection_and_timeSlot', {
    festivalSectionId: festivalSectionId,
    countChanger: 1,
  });
  return true;
};

export const acceptPublicFormResponse = async (
  isPending,
  publicFormResponseId,
  withToast = true,
  festivalSectionId = null
) => {
  if (!isPending) return false;
  try {
    const params = {};
    if (festivalSectionId) {
      params.festival_section_id = festivalSectionId;
    }
    await axios.post(`/api/public-form-responses/${publicFormResponseId}/accept`, params);
    if (withToast) {
      toast.success('Accepted');
    }
    if (festivalSectionId) {
      const { rootEmit } = useEmitStore();
      rootEmit('publicForm_response_added_to_festivalSection_and_timeSlot', {
        festivalSectionId: festivalSectionId,
        countChanger: 1,
      });
    }
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const declineResponse = async (publicFormResponseId, withToast = true) => {
  await axios.post(`/api/public-form-responses/${publicFormResponseId}/decline`);
  if (withToast) {
    toast.success('Declined');
  }
};

export const declinePublicFormResponse = async (isPending = false, publicFormResponseId = null, withToast = true) => {
  if (!isPending) return false;
  if (!publicFormResponseId) return false;

  const certaintyModal = useCertaintyModal();

  const areYouSure = await certaintyModal.assertCertain(
    'Decline Response',
    'Are you sure you want to decline this response?'
  );
  if (!areYouSure) return false;
  await declineResponse(publicFormResponseId, withToast);
  return true;
};

export const assignSlotOfShiftToPublicFormResponse = async (slotId: number, responseId: number) => {
  try {
    await axios.post(`/api/shift-crew-slots/${slotId}/assign`, {
      model_id: responseId,
      model_type: 'App\\Models\\PublicForms\\PublicFormResponse',
    });
    return true;
  } catch (e) {
    if (e.response.data) {
      toast.warning(e.response.data);
    } else {
      toast.warning('Could not update shift');
    }
    return false;
  }
};

export const getTitleForPublicFormResponseAvailableInSlot = (publicFormResponse, whoToShow = null) => {
  if (whoToShow === 'all') {
    const otherShifts = getKey(publicFormResponse, 'shift_crew_slots', []);
    if (otherShifts.length === 0) {
      return publicFormResponse.name + ' is available in this time frame';
    }
    return (
      publicFormResponse.name +
      ' already has ' +
      otherShifts.length +
      ' shifts in this time frame. These are:' +
      arrayToJoinString(
        otherShifts.map((s) => {
          const duration = getDiffInInterval(s.shift.start, s.shift.end, 'hours');
          return (
            (s.shift?.title ?? 'Shift') +
            ' for ' +
            (s.shift.via?.name ?? 'Unknown Section') +
            ' - ' +
            formatStampAsTime(s.shift.start) +
            ' to ' +
            formatStampAsTime(s.shift.end) +
            (duration > 20 ? '( ' + duration + 'H)' : '') +
            ')'
          );
        })
      )
    );
  }
  return null;
};
