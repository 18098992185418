<script setup lang="ts">
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTimePicker from '@/components/Inputs/Date/VTimePicker.vue';
import VButton from '@/components/Inputs/VButton.vue';
import {
  formatHoursAndMinutesAndSecondsAsSeconds,
  formatSecondsAsHoursAndMinutesAndSeconds,
  formatStampAsTime,
} from '@/util/timeFunctions';
import { ref } from 'vue';
import { RunningorderResource, RunningOrderRowResource } from '@/types/runningorder';
import { getStartOfRow } from '@/util/running-order-functions';
import { secondFormat } from '@/variables/date-format';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';
import { useToast } from 'vue-toastification';
import { updateRunningOrderRow } from '@/services/api-running-order';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

type Props = {
  selectedRunningOrderRow: RunningOrderRowResource;
  runningOrderRows: RunningOrderRowResource[];
  runningorder: RunningorderResource;
  showMode: boolean;
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'closed'): void;
  (
    event: 'update-row',
    arg: {
      id: string;
      duration: number;
    }
  ): void;
  (
    event: 'subtract-duration-of-row',
    arg: {
      id: string;
      subtract: number;
    }
  ): void;
  (event: 'update-rows', arg: RunningOrderRowResource[]): void;
  (
    event: 'update:start',
    arg: {
      id: string;
      start: string;
    }
  ): void;
}>();
const modalOpen = ref(true);
const time = ref(null);
const currentStart = ref(null);
const row = ref({ ...props.selectedRunningOrderRow });
if (props.showMode) {
  time.value = null;
} else {
  currentStart.value = formatStampAsTime(
    getStartOfRow(props.selectedRunningOrderRow, props.runningOrderRows, props.runningorder),
    null,
    secondFormat
  );
  time.value = formatStampAsTime(
    getStartOfRow(props.selectedRunningOrderRow, props.runningOrderRows, props.runningorder),
    null,
    secondFormat
  );
}
const buttons = [
  { name: '30 sec', value: 30 },
  { name: '1 min', value: 60 },
  { name: '5 min', value: 5 * 60 },
  { name: '10 min', value: 10 * 60 },
  { name: '15 min', value: 15 * 60 },
];

const emitTime = async () => {
  modalOpen.value = false;

  let diffInStarts =
    formatHoursAndMinutesAndSecondsAsSeconds(time.value) - formatHoursAndMinutesAndSecondsAsSeconds(currentStart.value);
  if (diffInStarts === 0) {
    return;
  }

  if (diffInStarts >= 0) {
    const indexOfRow = getIndexFromArrayBasedOnId(props.selectedRunningOrderRow.id, props.runningOrderRows);
    if (indexOfRow === 0) {
      useToast().warning('Cannot update first row this way. Set the start of the running order.');
      return;
    }

    const data = {
      id: props.runningOrderRows[indexOfRow - 1].id,
      duration: props.runningOrderRows[indexOfRow - 1].duration + diffInStarts,
    };

    await axios.patch<RunningOrderRowResource>(
      '/api/runningorders/' + props.runningorder.id + '/running-order-rows/' + data.id,
      {
        duration: data.duration,
      }
    );

    useToast().info('Row Duration Updated.');
    emit('update-row', data);
    modalOpen.value = false;
    return;
  }
  const index = getIndexFromArrayBasedOnId(props.selectedRunningOrderRow.id, props.runningOrderRows);
  let rowsPriorToRow = props.runningOrderRows.filter((row, i) => i < index);
  const durationOfRowsPriorToRow = rowsPriorToRow.map((r) => r.duration).reduce((partialSum, a) => partialSum + a, 0);
  if (diffInStarts + durationOfRowsPriorToRow < 0) {
    useToast().warning('Cannot update start, as duration of running order is too short.');
    return;
  }
  let allRows = [...props.runningOrderRows];
  rowsPriorToRow = [...rowsPriorToRow].reverse();
  let diffInStartNotSubtractedYet = diffInStarts * -1;
  for (let i = 0; i < rowsPriorToRow.length; i++) {
    if (diffInStartNotSubtractedYet <= 0) {
      continue;
    }
    let duration = rowsPriorToRow[i].duration;
    if (duration >= diffInStartNotSubtractedYet) {
      duration = duration - diffInStartNotSubtractedYet;
      emit('subtract-duration-of-row', { id: rowsPriorToRow[i].id, subtract: diffInStartNotSubtractedYet });
      diffInStartNotSubtractedYet = 0;
    } else {
      diffInStartNotSubtractedYet = diffInStartNotSubtractedYet - duration;
      emit('subtract-duration-of-row', { id: rowsPriorToRow[i].id, subtract: duration });
      duration = 0;
    }
    await updateRunningOrderRow(props.runningorder.id, {
      id: rowsPriorToRow[i].id,
      duration: duration,
    });
    allRows = exchangeValuesOfObject(
      {
        id: rowsPriorToRow[i].id,
        duration: duration,
      },
      allRows
    );
  }

  emit('update-rows', allRows);
  modalOpen.value = false;
};

const changeStart = (value: number, add: boolean) => {
  const localTime = formatHoursAndMinutesAndSecondsAsSeconds(time.value);
  time.value = formatSecondsAsHoursAndMinutesAndSeconds(localTime + (add ? value : -1 * value));
  // modalOpen.value = false;
  // emitTime();
};
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    create-button-text="Update"
    title="Set Start of Row"
    small
    @create="emitTime"
    @closed="$emit('closed')">
    <!--    <div class="grid grid-cols-[2fr_1fr] gap-5">-->
    <div class="flex flex-col gap-7">
      <div class="grid grid-cols-2 gap-5">
        <div class="flex flex-col">
          <InputLabel
            super-text
            label="Current Start" />
          <VTimePicker
            :model-value="
              formatStampAsTime(
                getStartOfRow(props.selectedRunningOrderRow, props.runningOrderRows, props.runningorder),
                null,
                secondFormat
              )
            "
            :can-edit="false"
            with-seconds />
        </div>
        <div class="flex flex-col">
          <InputLabel
            super-text
            label="New Start" />
          <VTimePicker
            v-model="time"
            with-seconds
            required />
        </div>
      </div>
      <div>
        <InputLabel
          super-text
          label="Adjust time by"></InputLabel>
        <div class="grid grid-cols-2 gap-5">
          <div class="flex flex-col gap-5 px-8">
            <VButton
              v-for="button in buttons"
              :key="button.name"
              class="btn-outline"
              size="tiny"
              icon="fa-minus"
              :title="button.name"
              @click="changeStart(button.value, false)" />
          </div>
          <div class="flex flex-col gap-5 px-8">
            <VButton
              v-for="button in buttons"
              :key="button.name"
              size="tiny"
              class="btn-outline"
              icon="fa-plus"
              :title="button.name"
              @click="changeStart(button.value, true)" />
          </div>
        </div>
      </div>
    </div>
  </CrudModal>
</template>
