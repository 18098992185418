import { GroupMinimalResource } from '@/types/group';
import type { UserMinimalResource } from '@/types/user';

// Path: app/Http/Controllers/Api/UserController.php SHOW
export const getUserById = async (userId: number) => {
  const url = `/api/users/${userId}`;
  return axios.get<UserMinimalResource>(url);
};

// Path: app/Http/Controllers/Api/UserController.php groups
export const getGroupsForUser = async (userId: number, isAdmin: boolean) => {
  const url = `/api/users/${userId}/groups`;
  const params = {
    is_admin: isAdmin,
  };
  return axios.get<GroupMinimalResource[]>(url, { params });
};
