import { App } from 'vue';
import * as Sentry from '@sentry/vue';

export const sentryError = (vueApp: App) => {
  Sentry.init({
    app: vueApp,
    dsn: 'https://a97ff7a5cc66466895ed2fa7473f3d6c@o429848.ingest.sentry.io/5377201',
    attachProps: true,
    logErrors: true,
    trackComponents: true,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracePropagationTargets: ['127.0.0.1', 'crescat.test', 'crescat.io', /^\//],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    tracesSampleRate: 0.1,
    environment: import.meta.env.MODE,
  });
};
