<script lang="ts" setup>
import { useToast } from 'vue-toastification';
import BaseInput from '@/components/Base/BaseInput.vue';

type Props = {
  modelValue?: string | null;
  label?: string | null;
  isHidden?: boolean;
  canEdit?: boolean;
  setFocus?: boolean;
  leftIcon?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  label: null,
  isHidden: false,
  setFocus: false,
  canEdit: true,
  leftIcon: 'fa-link',
});

const emit = defineEmits<{
  (event: 'update:modelValue', arg: string | null): void;
}>();

const toast = useToast();

// check if has top level domain
const hasTopDomain = (url: string) => {
  const split = url.split('.');
  return split.length > 1;
};

//check if url has multiple https or http
const hasMultipleHttp = (url: string) => {
  const split = url.split('://');
  return split.length > 2;
};

const addHttp = () => {
  if (!props.modelValue) return;
  if (props.modelValue === 'https://') return;
  if (props.modelValue === 'http://') return;

  if (hasTopDomain(props.modelValue) && !hasMultipleHttp(props.modelValue)) {
    if (props.modelValue.startsWith('http')) {
      emit('update:modelValue', props.modelValue);
    } else {
      emit('update:modelValue', `https://${props.modelValue}`);
    }
  } else {
    toast.error('Invalid URL');
    emit('update:modelValue', '');
  }
};

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <BaseInput
    v-bind="{ ...$attrs, ...$props }"
    :model-value="modelValue"
    :label="label"
    placeholder="https://example.com"
    type="url"
    :set-focus="setFocus"
    :left-icon="leftIcon"
    :max-length="190"
    @update:model-value="($event) => emit('update:modelValue', $event)"
    @blur="addHttp">
    <template #end>
      <slot />
    </template>
  </BaseInput>
</template>
