import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { taskFieldsKey } from '@/provide/keys';
import { formatModel } from '@/util/store-helpers';
import { TaskFieldResource } from '@/types/tasks';
import { useTaskFieldsStore } from '@/store/TaskFieldsStore';
import { ModelType } from '@/types/global';

export const useTaskFields = (modelType: ModelType, modelId: number, shouldProvide = true, hasTasks = false) => {
  const { list, loading } = storeToRefs(useTaskFieldsStore());
  const { getForModel, removeById, addOrUpdate, reorderFieldsByIds } = useTaskFieldsStore();

  const taskFields = computed(() => list.value.get(formatModel(modelType, modelId) ?? []) ?? []);

  const fetchTaskFields = async (force = false) => {
    if (!hasTasks) return;
    await getForModel(modelType, modelId, force);
  };

  const addOrUpdateField = async (field: TaskFieldResource) => {
    if (!hasTasks) return;
    await addOrUpdate(modelType, modelId, field);
  };

  const removeField = async (fieldId: number) => {
    if (!hasTasks) return;
    await removeById(modelType, modelId, fieldId);
  };

  const reorderFields = async (fieldIds: string | number[]) => {
    if (!hasTasks) return;
    await reorderFieldsByIds(modelType, modelId, fieldIds);
  };

  if (shouldProvide) {
    provide(taskFieldsKey, {
      taskFields: readonly(taskFields),
      loading: readonly(loading),
      fetchTaskFields,
      removeField,
      reorderFields,
      addOrUpdateField,
    });
  }

  return {
    taskFields,
    fetchTaskFields,
    loading,
    removeField,
    reorderFields,
    addOrUpdateField,
  };
};
