import { DateTime, Duration, DurationLikeObject, DurationUnit } from 'luxon';
import type { DurationLike } from 'luxon';

const dateFormat = 'yyyy-MM-dd';
const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
const timeFormat = 'HH:mm';
const secondFormat = 'HH:mm:ss';
const monthFormat = 'MMMM';
const yearFormat = 'YYYY';

// create datetime object
export const createDateTime = (date: string) => {
  if (DateTime.fromISO(date).isValid) {
    return DateTime.fromISO(date);
  }
  return DateTime.fromFormat(date, dateTimeFormat);
};

export const currentDateTime = () => DateTime.now().toISO();

// functions
export const addTime = (dateTime: string, time: DurationLike) => {
  const d = createDateTime(dateTime);
  return d.plus(time).toFormat(dateTimeFormat);
};

export const addSeconds = (dateTime: string, seconds: number) => {
  const d = createDateTime(dateTime);
  return d.plus({ seconds }).toFormat(dateTimeFormat);
};

export const addMinutes = (dateTime: string, minutes: number) => {
  const d = createDateTime(dateTime);
  return d.plus({ minutes }).toFormat(dateTimeFormat);
};

export const addHours = (dateTime: string, hours: number) => {
  const d = createDateTime(dateTime);
  return d.plus({ hours }).toFormat(dateTimeFormat);
};

export const addDays = (dateTime: string, days: number) => {
  const d = createDateTime(dateTime);
  return d.plus({ days }).toFormat(dateTimeFormat);
};

export const addMonths = (dateTime: string, months: number) => {
  const d = createDateTime(dateTime);
  return d.plus({ months }).toFormat(dateTimeFormat);
};

export const addYears = (dateTime: string, years: number) => {
  const d = createDateTime(dateTime);
  return d.plus({ years }).toFormat(dateTimeFormat);
};

export const isBefore = (dateTime: string, compare: string) => {
  const d = createDateTime(dateTime);
  const c = createDateTime(compare);
  return d < c;
};

export const isAfter = (dateTime: string, compare: string) => {
  const d = createDateTime(dateTime);
  const c = createDateTime(compare);
  return d > c;
};

export const insideInterval = (dateTimeStart: string, dateTimeEnd: string, compareDate: string) => {
  const s = createDateTime(dateTimeStart);
  const e = createDateTime(dateTimeEnd);
  const c = createDateTime(compareDate);
  return s < c && c < e;
};

export const duration = (dateTimeStart: string, dateTimeEnd: string, unit: DurationUnit = 'hours') => {
  const s = createDateTime(dateTimeStart);
  const e = createDateTime(dateTimeEnd);
  return e.diff(s, unit).toObject();
};

export const createDurationObjectFromFormat = (time: string, unit: keyof DurationLikeObject) => {
  const d = Duration.fromISOTime(time).shiftTo(unit);
  return d.toObject();
};

export const getWeekNumberFromDate = (date: string) => {
  const d = createDateTime(date);
  return d.weekNumber;
};

// format date & times
// Backend Formats
export const toBackendDateTimeFormat = (dateTime: string) => createDateTime(dateTime).toFormat(dateTimeFormat);
export const toBackendDateFormat = (dateTime: string) => createDateTime(dateTime).toFormat(dateFormat);
export const toBackendTimeFormat = (dateTime: string) => createDateTime(dateTime).toFormat(timeFormat);
export const toBackendTimeSecondFormat = (dateTime: string) => createDateTime(dateTime).toFormat(secondFormat);
export const toBackendMonthFormat = (dateTime: string) => createDateTime(dateTime).toFormat(monthFormat);
export const toBackendYearFormat = (dateTime: string) => createDateTime(dateTime).toFormat(yearFormat);
// human formats

export const toHumanDateTimeFormat = (dateTime: string) => createDateTime(dateTime).toFormat(dateTimeFormat);
export const toHumanDateFormat = (dateTime: string) => createDateTime(dateTime).toFormat('dd/LL/yyyy');
export const toHumanTimeFormat = (dateTime: string) => createDateTime(dateTime).toFormat(timeFormat);
export const toHumanDuration = (dateTimeStart: string, dateTimeEnd: string) => {
  const d = duration(dateTimeStart, dateTimeEnd);
  return Duration.fromMillis(d.milliseconds ?? 0).toFormat("hh 'h' mm 'm'");
};
export const toSpecificDateFormat = (dateTime: string, format: string) => createDateTime(dateTime).toFormat(format);
// export const dateTime = (IsoDateTime: string) => DateTime.fromISO(IsoDateTime);
// export const IsoToDate = (ISODate: string) => DateTime.fromISO(ISODate).toISODate();
// export const IsoToTime = (ISODate: string) => DateTime.fromISO(ISODate).toISOTime();
// export const currentDateTime = () => DateTime.now();
// export const duration = (startISO: string, endISO: string) => dateTime(endISO).diff(dateTime(startISO), ['years', 'months', 'days', 'hours', 'minutes', 'seconds']).toObject();
// export const humanTime = (isoDate: string) => DateTime.fromISO(isoDate).toFormat(timeFormat);
// export const humanDate = (isoDate: string) => DateTime.fromISO(isoDate).toFormat(dateFormat);
// export const humanDateTime = (isoDate: string) => DateTime.fromISO(isoDate).toFormat(dateTimeFormat);
// export const humanDateTimeToObject = (dateTimeHuman: string) => DateTime.fromFormat(dateTimeHuman, dateTimeFormat);
// export const humanDateToObject = (dateHuman: string) => DateTime.fromFormat(dateHuman, dateFormat);
// export const humanTimeToObject = (timeHuman: string) => DateTime.fromFormat(timeHuman, timeFormat);
export const secondToHourMinuteAndSecond = (seconds: number) => {
  if (seconds === null || seconds === undefined || isNaN(seconds)) return '00:00:00';
  const d = Duration.fromObject({ seconds });
  return d.toFormat('hh:mm:ss');
};
