import { postAcceptShift, postDeclineShift, postResendShiftNotification } from '@/services/api-shifts';
import { useToast } from 'vue-toastification';
import moment from 'moment';
import { getNow, isWithinIntervalOfObject, momentIsAfter } from '@/util/timeFunctions';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { green, red, transparent, white, yellow } from '@/variables/colors';
import { arrayToJoinString } from '@/util/globals';
import { dateTimeFormat, hoursBeforeShiftCheckIn } from '@/variables/date-format';
import { useEmitStore } from '@/store/EmitStore';

const toast = useToast();

export const shiftStatus = (shift = null) => {
  if (!shift) {
    return '';
  }
  if (shift.cancelled_at !== null) {
    return 'Cancelled';
  }
  if (!shift.approved) {
    return 'Unapproved';
  }
  if (shift.for_sale) {
    return 'For Sale';
  }
  if (!shift.user_id) {
    return 'Unassigned';
  }
  if (shift.hasOwnProperty('accepted_at') && shift.hasOwnProperty('declined_at')) {
    if (shift.accepted_at === null && shift.declined_at === null) {
      return 'Pending';
    }
    if (shift.accepted_at !== null && shift.declined_at === null) {
      return 'Accepted';
    }
    if (shift.accepted_at === null && shift.declined_at !== null) {
      return 'Declined';
    }
  }
  if (shift.hasOwnProperty('isAccepted') && shift.hasOwnProperty('isDeclined')) {
    if (!shift.isAccepted && !shift.isDeclined) {
      return 'Pending';
    }
    if (shift.isAccepted && !shift.isDeclined) {
      return 'Accepted';
    }
    if (!shift.isAccepted && shift.isDeclined) {
      return 'Declined';
    }
  }
  return 'N/A';
};

export const getColorOfShift = (shift, asHex = true) => {
  if (!shift.approved) return asHex ? white : 'white';
  if (shift.cancelled_at !== null) return asHex ? white : 'white';
  if (shift.accepted_at !== null) return asHex ? green : 'green';
  if (shift.declined_at !== null) return asHex ? red : 'red';
  if (shift.user_id === null) return asHex ? transparent : 'transparent';
  return asHex ? yellow : 'yellow';
};

export const getTitleOfShift = (shift) => {
  if (!shift.approved) return 'Shift is not approved.';
  if (shift.cancelled_at !== null) return 'Shift is Cancelled.';
  if (shift.accepted_at !== null) return 'Shift is Accepted.';
  if (shift.declined_at !== null) return 'Shift is Declined.';
  return 'Shift is Pending.';
};

export const canCheckIntoShift = (shift, hoursBeforeCheckinAllowed = hoursBeforeShiftCheckIn) => {
  if (shift.user_id === null) return false;
  if (shift.check_in !== null) return false;
  return momentIsAfter(shift.start, hoursBeforeCheckinAllowed);
};

export const canCheckOutOfShift = (shift) => {
  return shift.check_in !== null && shift.check_out === null;
};

export const checkIntoShift = async (shiftId, timeStamp = null) => {
  const params = {};
  if (timeStamp) {
    params.check_in = timeStamp;
  }

  const resp = await axios
    .post(`/api/shifts/${shiftId}/check-in`, {
      check_in: timeStamp || getNow(),
    })
    .catch((error) => {
      toast.success(error.response.data.message ? error.response.data.message : 'Could Not Check In');
      return false;
    });
  if (!resp) return false;
  toast.success('Checked In.');
  return true;
};

export const checkOutOfShift = async (shiftId, timeStamp = null, notes = null) => {
  const resp = await axios
    .post(`/api/shifts/${shiftId}/check-out`, {
      check_out: timeStamp,
      notes: notes,
    })
    .catch((error) => {
      toast.success(error.response.data.message ? error.response.data.message : 'Could Not Check Out');
      return false;
    });
  if (!resp) return false;
  toast.success('Checked Out.');
  return true;
};

export const clearCheckInAdOutOfShift = async (shiftId: number) => {
  const resp = await axios.post(`/api/shifts/${shiftId}/clear-check-in-and-out`).catch((error) => {
    toast.success(error.response.data.message ? error.response.data.message : 'Could not clear');
    return false;
  });
  if (!resp) return false;
  toast.success('Cleared.');
  return true;
};

export const declineShift = async (
  shiftId: number,
  shiftApproved: boolean,
  shiftAssigned: boolean,
  withToast = true,
  modalId?: string
) => {
  if (!shiftId) return false;
  if (!shiftAssigned) {
    toast.warning('Cannot decline un-assigned shift.');
    return false;
  }

  const certain = await useCertaintyModal().assertCertain(
    'Decline Shift',
    'Are you sure you want to decline this shift?',
    undefined,
    undefined,
    undefined,
    modalId
  );
  if (!certain) return false;

  await postDeclineShift(shiftId);

  if (withToast) {
    toast.success('Declined');
  }
  return true;
};

export const resendShiftNotification = async (shift) => {
  if (!shift) {
    return false;
  }
  if (!shift.approved) {
    toast.warning('Shift is not approved.');
    return false;
  }
  if (
    (shift.hasOwnProperty('isAssigned') && !shift.isAssigned) ||
    (shift.hasOwnProperty('user_id') && shift.user_id === null)
  ) {
    toast.warning('Shift is not assigned.');
    return false;
  }
  if (shift.hasOwnProperty('end') && moment(shift.end).isSameOrBefore(moment())) {
    toast.warning('Cannot resend notification to shift in the past..');
    return false;
  }
  const isAccepted =
    (shift.hasOwnProperty('isAccepted') && shift.isAccepted) ||
    (shift.hasOwnProperty('accepted_at') && shift.accepted_at !== null);
  const isDeclined =
    (shift.hasOwnProperty('isDeclined') && shift.isDeclined) ||
    (shift.hasOwnProperty('declined_at') && shift.declined_at !== null);
  if (isAccepted || isDeclined) {
    toast.warning(`Shift has already been ${isDeclined ? 'declined' : 'accepted'}.`);
    return false;
  }
  await postResendShiftNotification(shift.id);
  toast.success('Reminder sent');
  return true;
};

export const acceptShift = async (
  shiftId: number,
  shiftApproved: boolean,
  shiftAssigned: boolean,
  modalId?: string,
  alreayCertian = false
) => {
  if (!shiftId) return false;
  if (!shiftApproved) {
    toast.warning('Cannot accept unapproved shift.');
    return false;
  }
  if (!shiftAssigned) {
    toast.warning('Cannot accept unassigned shift.');
    return false;
  }

  if (!alreayCertian) {
    const certain = await useCertaintyModal().assertCertain(
      'Accept Shift',
      'Are you sure you want to accept this shift?',
      undefined,
      undefined,
      undefined,
      modalId
    );
    if (!certain) return false;
  }

  await postAcceptShift(shiftId);

  toast.success('Accepted');
  return true;
};

export const getShiftsForUserFromFullCalendarResource = (
  events: {
    start: string;
    end: string;
    shift_id?: number;
    isAssigned?: boolean;
    resourceIds: number[];
    events: {
      name: string;
    }[];
  }[],
  shift: {
    start: string;
    end: string;
  },
  resources: {
    id: number;
    model_id: number;
  }[]
) => {
  return events
    .filter((e) => e.hasOwnProperty('shift_id') && e.shift_id !== null)
    .filter((e) => e.hasOwnProperty('isAssigned') && e.isAssigned)
    .filter((s) =>
      isWithinIntervalOfObject(
        shift.start,
        shift.end,
        moment(s.start).format(dateTimeFormat),
        moment(s.end).format(dateTimeFormat)
      )
    )
    .map((s) => {
      const index = _.findIndex(resources, (resource) => resource.id === s.resourceIds[0]);
      return {
        id: s.shift_id,
        title: arrayToJoinString(s.events.map((e) => e.name)),
        resourceId: s.resourceIds[0],
        start: s.start,
        end: s.end,
        user_id: index === -1 ? null : resources[index].model_id,
      };
    });
};

export const getEventsFromResourceForInterval = (events, start, end) => {
  return events
    .filter((e) => e.hasOwnProperty('event_id') && e.event_id !== null && e.event_id !== undefined)
    .filter((s) =>
      isWithinIntervalOfObject(start, end, moment(s.start).format(dateTimeFormat), moment(s.end).format(dateTimeFormat))
    );
};

export const getEventsForUserFromFullCalendarResource = (
  events: {
    start: string;
    end: string;
    shift_id?: number;
    isAssigned?: boolean;
    resourceIds: number[];
    events: {
      name: string;
    }[];
  }[],
  shift: {
    start: string;
    end: string;
  },
  resources: any
) => {
  return getEventsFromResourceForInterval(events, shift.start, shift.end)
    .filter((e) => _.findIndex(e.resourceIds, (resourceId) => resourceId.includes('user')))
    .flatMap((e) =>
      e.resourceIds
        .filter((resourceId) => resourceId.includes('user'))
        .map((resourceId) => {
          const index = _.findIndex(resources, (resource) => resource.id === resourceId);
          return {
            id: e.event_id,
            title: e.title,
            resourceId,
            start: e.start,
            end: e.end,
            user_id: index === -1 ? null : resources[index].model_id,
          };
        })
    );
};

export const canCheckInShiftCrewSlot = (shift, shiftCrewSlot) => {
  if (shiftCrewSlot.check_in) {
    return false;
  }
  return moment().isAfter(moment(shift.start).subtract(2, 'hours'));
};

export const canCheckOutShiftCrewSlot = (shiftCrewSlot) => {
  return shiftCrewSlot.check_in !== null && shiftCrewSlot.check_out === null;
};

export const clearAllCrewSlotsOfShift = (shift) => {
  shift.shift_crew_slots.forEach((slot) => {
    slot.slottable_id = null;
    slot.slottable_type = null;
  });
};

export const updateCountOfShiftUpForSaleForShift = (shift, newUpForSaleCount) => {
  shift.shift_crew_slots
    .filter((slot) => slot.slottable_id === null)
    .forEach((slot, i) => {
      slot.for_sale = i < newUpForSaleCount;
    });
};

export const updateCountOfSlotsExistingForShift = async (shift, newCount) => {
  if (newCount === 0) {
    shift.shift_crew_slots = [];
    return;
  }
  if (newCount > shift.shift_crew_slots.length) {
    setTimeout(async () => {
      const { data } = await axios.get(`/api/shifts/${shift.id}`);
      const { rootEmit } = useEmitStore();
      rootEmit('shiftCrewSlotsUpdated', data);
      // this.$root.$emit('shiftCrewSlotsUpdated', response.data);
    }, 500);
  } else {
    while (shift.shift_crew_slots.length > newCount) {
      const slotIndex = _.findIndex(shift.shift_crew_slots, (slot) => slot.slottable_id === null);
      if (slotIndex > -1) {
        shift.shift_crew_slots.splice(slotIndex, 1);
      } else {
        toast.warning('No unassigned slots left. ');
        break;
      }
    }
  }
};

export const cancelShift = async (shiftId: number) => {
  const certain = await useCertaintyModal().assertCertain(
    'Cancel Shift',
    'Are you sure you want to cancel this shift?'
  );
  if (!certain) return false;

  await axios.post(`/api/shifts/${shiftId}/cancel`);
  toast.success('Cancelled.');
  return true;
};

export const reinstateShift = async (shiftId: number, modalId?: string) => {
  const certain = await useCertaintyModal().assertCertain(
    'Reinstate Shift',
    'Are you sure you want to reinstate this shift? It will come back as un-assigned.',
    undefined,
    undefined,
    undefined,
    modalId
  );
  if (!certain) return false;
  await axios.post(`/api/shifts/${shiftId}/reinstate`);
  toast.success('Reinstated.');
  return true;
};

export const checkInShiftCrewSlot = async (shiftCrewSlotId, timeStamp = null) => {
  const params = {};
  if (timeStamp) {
    params.check_in = timeStamp;
  }
  await axios.post(`/api/shift-crew-slots/${shiftCrewSlotId}/check-in`, params);
  toast.success('Checked In.');
  return true;
};

export const checkOutShiftCrewSlot = async (shiftCrewSlotId, timeStamp = null, message = null) => {
  const params = {};
  if (timeStamp) {
    params.check_in = timeStamp;
  }
  if (message) {
    params.message = message;
  }
  await axios.post(`/api/shift-crew-slots/${shiftCrewSlotId}/check-out`, params);
  toast.success('Checked Out.');
  return true;
};

export const unAssignShiftCrewSlot = async (shiftCrewSlot) => {
  if (shiftCrewSlot.slottable_id === null) {
    toast.warning('Slot not assigned to anyone');
    return false;
  }
  await axios.post(`/api/shift-crew-slots/${shiftCrewSlot.id}/un-assign`);
  return true;
};

export const shiftIsFuture = (shift) => {
  if (shift) {
    return moment(shift.start).isAfter(getNow());
  }
  return false;
};

export const shiftIsOngoing = (shift) => {
  return (
    moment(shift.start).isSame(moment(), 'day') ||
    moment(shift.end).isSame(moment(), 'day') ||
    moment(shift.end).subtract(4, 'hours').isSame(moment(), 'day')
  );
};

export const updateShiftSlotCount = async (shiftId, shiftCrewSlotCount = 0) => {
  if (shiftCrewSlotCount === 0) {
    await axios.delete(`/api/shifts/${shiftId}/slots`);
  } else {
    await axios.post(`/api/shifts/${shiftId}/slots`, {
      count: shiftCrewSlotCount,
    });
  }
  return true;
};

export const shiftCrewSlotIsOngoing = (slot) => {
  return (
    moment(slot.shift.start).isSame(moment(), 'day') ||
    moment(slot.shift.end).isSame(moment(), 'day') ||
    moment(slot.shift.end).subtract(4, 'hours').isSame(moment(), 'day')
  );
};

export const shiftCrewSlotIsFuture = (slot) => {
  return moment(slot.shift.start).isAfter(moment().endOf('day').add(4, 'hours'));
};
