import { computed, onBeforeUnmount, onMounted, Ref, ref } from 'vue';

export const lastKeyStroke = (elementRef: Ref<HTMLElement | null>) => {
  const key = ref(null);

  const listen = (event) => {
    if (elementRef) {
      key.value = event.key;
    }
  };

  onMounted(() => {
    document.addEventListener('keydown', listen);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('keydown', listen);
  });

  return computed(() => {
    return key.value;
  });
};
