<script setup lang="ts">
import { addDocumentToFormPublic } from '@/services/api-documents';
import { FormSectionResource } from '@/types/form';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { createUuId, exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';
import AddFieldButton from '@/components/Fields/AddFieldButton.vue';
import FieldsList from '@/components/Fields/FieldsList.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import { useEmitStore } from '@/store/EmitStore';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'edit'): void;
  (
    event: 'update:section',
    arg: {
      section: FormSectionResource;
      action: 'created' | 'updated' | 'deleted';
    }
  ): void;
}>();

const { rootEmit } = useEmitStore();

type Props = {
  form?: any;
  section: FormSectionResource;
  editMode: boolean;
  isTemplate: boolean;
  canEdit: boolean;
  model?: string;
  modelId?: number;
  movingSection: boolean;
};

const toast = useToast();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const selectedField = ref(null);

const fieldAdded = (field) => {
  const section = JSON.parse(JSON.stringify(props.section));
  section.fields.push({
    id: field.id ? field.id : createUuId('field_'),
    title: field.title,
    value: null,
    linebreak_after: field.linebreak_after,
    component: field.component,
    options: field.options,
    class: field.class,
  });
  emit('update:section', { section, action: 'created' });
};

const fieldUpdated = (field) => {
  const section = JSON.parse(JSON.stringify(props.section));
  section.fields = exchangeValuesOfObject(field, section.fields, ['id', 'component']);
  emit('update:section', { section, action: 'updated' });
};

const removeField = async (fieldId) => {
  const section = JSON.parse(JSON.stringify(props.section));
  const index = getIndexFromArrayBasedOnId(fieldId, section.fields);
  if (index === -1) return;

  const deleteIt = await assertReadyToDeleteModal(
    `Remove ${section.fields[index].title}`,
    'Are you sure you want to remove this field?'
  );
  if (!deleteIt) return;

  section.fields.splice(index, 1);
  emit('update:section', { section, action: 'deleted' });
};

const documentUploaded = async ({ field, document }) => {
  const { data } = await addDocumentToFormPublic(
    props.form.slug,
    document.uuid,
    props.form.title + ' - ' + props.section.title + ' - ' + field.title
  );
  const section = JSON.parse(JSON.stringify(props.section));
  rootEmit('re-fetch-document-center');

  const index = getIndexFromArrayBasedOnId(field.id, section.fields);
  if (index === -1) return;
  section.fields[index].value = data.id;
  emit('update:section', { section, action: 'updated' });
};

const assignValue = async (value, field) => {
  const section = JSON.parse(JSON.stringify(props.section));
  const index = getIndexFromArrayBasedOnId(field.id, section.fields);
  if (index === -1) return;
  section.fields[index].value = value;
  emit('update:section', { section, action: 'updated' });
};

const fieldsWithOrder = computed(() => {
  const section = JSON.parse(JSON.stringify(props.section));
  return section.fields.map((field, index) => {
    field.order = (index + 1) * 10000;
    return field;
  });
});

const actions = computed(() => {
  if (!props.editMode || props.movingSection) return [];
  return [
    {
      title: 'Add Field',
      icon: 'fa-plus',
      action: async () => {
        selectedField.value = null;
        await nextTick();
        selectedField.value = { id: null };
      },
    },
    {
      title: 'Edit',
      icon: 'fa-pencil',
      action: () => {
        emit('edit');
      },
    },
  ];
});

const onFieldListUpdate = (fields) => {
  const section = JSON.parse(JSON.stringify(props.section));
  section.fields = fields;
  section.fields = section.fields.map((field) => {
    delete field.order;
    return field;
  });
  emit('update:section', { section, action: 'updated' });
};
</script>

<template>
  <div class="flex flex-col gap-5">
    <div
      class="flex justify-between mt-1"
      :class="{ 'p-5': movingSection }">
      <div class="flex items-center gap-2">
        <div v-if="movingSection">
          <i class="fa fa-fw fa-bars cursor-grab" />
        </div>
        <div class="flex flex-col gap-2">
          <h3 class="text-highlight">
            {{ section.title }}
          </h3>
          <div class="sub-title text-textColor-soft">{{ section.text }}</div>
        </div>
      </div>
      <ActionButtonGroup :actions="actions" />
    </div>

    <template v-if="!movingSection">
      <FieldsList
        class="!p-0 [&>div>div>.cursor-grab]:p-4 [&>div>div>div>div]:!p-[1px] [&>div]:!gap-6"
        :model-value="fieldsWithOrder"
        :is-template="isTemplate"
        :edit-content="!isTemplate && !editMode && canEdit"
        :edit-form="canEdit && editMode"
        :model-id="modelId"
        :model="model"
        emit-document-uploaded-as-own-method
        @update:model-value="onFieldListUpdate"
        @document-uploaded="documentUploaded"
        @assign-value="assignValue"
        @open-edit-modal="[(selectedField = null), $nextTick(() => (selectedField = $event))]" />

      <AddFieldButton
        v-if="editMode"
        :init-field="selectedField"
        :with-button="false"
        :with-description="false"
        @modal-open="selectedField = $event"
        @field-added="fieldAdded"
        @field-deleted="removeField"
        @field-updated="fieldUpdated" />
    </template>
  </div>
</template>
