<script lang="ts" setup>
import HoverContainer from '@/components/calendar/HoverContainer.vue';
import { EventCrewResource } from '@/services/api-event';
import { formatStampAsTime } from '@/util/timeFunctions';
import { EventApi } from '@fullcalendar/core';
import { onMounted, ref } from 'vue';

type Props = {
  event: EventApi;
  allEvents: EventApi[];
};

const props = defineProps<Props>();

type Columns = {
  title: string;
  values: string[];
  icon: string;
};

const firstColumn = ref<Columns[]>([]);

if (props.event.start) {
  firstColumn.value.push({
    title: 'When',
    values: [
      formatStampAsTime(props.event.start) +
        (props.event.end ? ' - ' + formatStampAsTime(props.event.end, props.event.start) : ''),
    ],
    icon: 'clock',
  });
}

const rooms: string[] = [];

props.allEvents.forEach((e) => {
  if (e.extendedProps.invite_id === props.event.extendedProps.invite_id) {
    const room = e.getResources()[0];
    if (room) {
      rooms.push(room.title);
    }
  }
});

if (rooms.length) {
  firstColumn.value.push({
    title: 'Rooms',
    values: rooms,
    icon: 'cube',
  });
}

const secondColumn = ref<Columns[]>([]);
</script>
<template>
  <HoverContainer
    :first-column="firstColumn"
    :second-column="secondColumn">
    <template #header>
      {{ event.title }}
    </template>
  </HoverContainer>
</template>
