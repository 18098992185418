import { useGuestListSettingsStore } from '@/store/guest-list-settings-store';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { formatModel } from '@/util/store-helpers';
import type { ModelType } from '@/types/global';

export const useGuestListSettings = (model: ModelType, modelId: number, shouldProvide = true) => {
  const { list, loading } = storeToRefs(useGuestListSettingsStore());
  const { fetchGuestListSettings } = useGuestListSettingsStore();

  const guestListSettings = computed(() => {
    const item = list.value.get(formatModel(model, modelId));
    if (item) {
      return item[0];
    }
    return null;
  });

  const fetch = async (force = false) => {
    if (force || !list.value.has(formatModel(model, modelId))) {
      await fetchGuestListSettings(model, modelId);
    }
  };

  const flushForModel = () => {
    list.value.delete(formatModel(model, modelId));
  };

  return {
    guestListSettings,
    loading,
    fetch,
    flushForModel,
  };
};
