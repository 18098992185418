<script lang="ts" setup>
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { usePageTabsStore } from '@/store/PageTabsStore';
import { router } from '@inertiajs/vue3';
import { computed, watch } from 'vue';
import { CompletionTrackerNames } from '@/types/completion-tracker';
import { completionTrackerHasItem } from '@/util/completion-tracker-functions';
import { slugify } from '@/util/string-utils';

type Props = {
  itemId: string;
  title: string;
  route: string;
  hash: string;
  icon: string;
  completionTrackerName?: CompletionTrackerNames | null;
  mainMenu?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  mainMenu: false,
  completionTrackerName: null,
});

const emit = defineEmits<{
  (e: 'openList'): void;
}>();

const contextSidebarStore = useContextSidebarStore();

const active = computed(() => props.itemId === contextSidebarStore.currentView);

watch(
  active,
  (newValue) => {
    if (newValue) {
      contextSidebarStore.setCurrentSelectedSidebarItem({
        id: props.itemId,
        title: props.title,
      });
      emit('openList');
    }
  },
  { immediate: true }
);

const onClick = (event: MouseEvent) => {
  const route = window.location.origin + window.location.pathname;
  if (event.ctrlKey || event.metaKey) {
    let newUrl = props.route;
    if (props.hash) {
      newUrl = `${props.route}#${props.hash}`;
    }
    window.open(newUrl);
  } else if (route === props.route) {
    let newUrl = props.route;
    if (props.hash) {
      newUrl = `${props.route}#${props.hash}`;
    }
    if (newUrl === window.location.href) {
      const tabsStore = usePageTabsStore();
      tabsStore.setActiveTab(tabsStore.tabs[0]);
      return;
    } else if (props.route === window.location.origin + window.location.pathname) {
      const tabsStore = usePageTabsStore();
      tabsStore.setActiveTab(tabsStore.tabs[0]);
    }
    contextSidebarStore.currentView = props.itemId;
    window.location.hash = props.hash;
  } else {
    let url = `${props.route}`;
    if (props.hash) {
      url += `#${props.hash}`;
    }
    try {
      router.visit(url);
    } catch (e) {
      console.error(e);
      window.location = url;
    }
  }
};
</script>

<template>
  <div
    :class="(active ? ' ' : '') + (contextSidebarStore.sidebarOpen ? ' ' : ' !pl-0 ')"
    :data-intro="`${itemId}`"
    class="h-7 cursor-pointer truncate px-2 py-[1px]"
    @click="onClick"
    :dusk="`context-sidebar-list-item-${slugify(title)}`">
    <div
      class="flex h-full items-center gap-3 hover:bg-sidebarItemHover rounded-md"
      :class="
        (active
          ? ' !border-highlight bg-sidebarActiveBackground [&_*]:text-sidebarActiveText  hover:bg-sidebarActiveItemHover '
          : 'text-sidebarTextSoft ') +
        ' ' +
        (contextSidebarStore.sidebarOpen ? ' pl-3 ' : ' items-center justify-center ')
      ">
      <i
        :class="icon + (active ? ' text-highlight ' : '')"
        class="fa fa-fw text-sm mt-1" />
      <div
        v-if="contextSidebarStore.sidebarOpen"
        :class="[{ ' w-[0px] ': !contextSidebarStore.sidebarOpen }, { '': !active }]"
        class="flex-1 truncate pr-edge font-headers">
        {{ title }}
      </div>

      <span
        v-if="
          completionTrackerHasItem(contextSidebarStore.completionTracker, completionTrackerName) &&
          contextSidebarStore.sidebarOpen
        "
        :title="
          completionTrackerHasItem(contextSidebarStore.completionTracker, completionTrackerName)?.item.description
        "
        class="pr-3">
        <i class="fa fa-fw fa-exclamation-circle fa-regular text-sm !text-pending"> </i>
      </span>
    </div>
  </div>
</template>
