import type { FestivalMinimalResource } from '@/types/festival';
import { useLocalStorage, useSessionStorage } from '@vueuse/core';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import type { GroupContextSidebarResource } from '@/types/group';
import {
  festivalContextFormat,
  getTitleFromContext,
  getTypeAndIdFromContext,
  groupContextFormat,
  personalContextFormat,
} from '@/util/context-format';
import { getContextSidebarGroup } from '@/services/api-group';
import { CompletionTrackerResource } from '@/types/completion-tracker';

type FillParams = {
  groupId?: number | null;
  festivalId?: number | null;
};

export const useContextSidebarStore = defineStore('context-sidebar', () => {
  const currentContext = useSessionStorage<string | null>('current_context', null);
  const currentView = ref<string | null>('');
  const sidebarLarge = useLocalStorage('context_sidebar_large', true, { listenToStorageChanges: false });
  const sidebarOpen = ref(sidebarLarge.value);
  const currentGroup = ref<GroupContextSidebarResource | null>(null);
  const currentFestival = ref<FestivalMinimalResource | null>(null);
  const personal = ref(false);
  const venueSoftAccessList = ref([]);
  const eventRequestTemplates = ref([]);
  const allGroups = ref<GroupContextSidebarResource[]>([]);
  const personalFestivals = ref<FestivalMinimalResource[]>([]);
  const personalFestivalSections = ref([]);
  const adminOpenGroup = ref([]);
  const currentSelectedSidebarItem = ref<object | null>(null);
  const contextOptions = ref<{ key: string; title: string; slug: string | null }[]>([]);
  const completionTracker = ref<CompletionTrackerResource | null>(null);
  const personalFestivalsLoaded = ref<boolean>(false);

  const currentContextTitle = computed(() => {
    if (currentContext.value) {
      return getTitleFromContext(currentContext.value);
    }
    return null;
  });
  const setCurrentSelectedSidebarItem = (newItem: unknown) => {
    currentSelectedSidebarItem.value = newItem;
  };
  const currentContextType = computed(() => {
    if (currentContext.value) {
      return getTypeAndIdFromContext(currentContext.value).type;
    }
    return null;
  });
  const currentContextId = computed(() => {
    if (currentContext.value) {
      return getTypeAndIdFromContext(currentContext.value)?.id;
    }
    return null;
  });

  const fetchFestivalSections = async () => {
    const { data: festivalSections } = await axios.get('/api/dashboard/festival-sections');
    personalFestivalSections.value = festivalSections;
  };

  const fetchFestivals = async () => {
    if (personalFestivalsLoaded.value) return;
    personalFestivalsLoaded.value = true;
    const { data: festivals } = await axios.get('/api/dashboard/festivals');
    personalFestivals.value = festivals;
  };

  const fill = async ({ groupId, festivalId }: FillParams) => {
    if (!usePage().props.authenticated) return;

    if (groupId) {
      currentView.value = groupContextFormat(groupId);
    } else if (festivalId) {
      currentView.value = festivalContextFormat(festivalId);
    } else {
      currentView.value = personalContextFormat();
    }

    const { data: personalSections } = await axios.get('/api/context-sidebar/sections');

    venueSoftAccessList.value = personalSections.venue_soft_access_lists;
    eventRequestTemplates.value = personalSections.event_request_templates;

    await fetchFestivalSections();
    await fetchFestivals();

    if (groupId) {
      currentContext.value = groupContextFormat(groupId);
    } else if (festivalId) {
      currentContext.value = festivalContextFormat(festivalId);
    } else {
      currentContext.value = personalContextFormat();
    }
  };

  const resetStore = () => {
    currentContext.value = '';
    currentView.value = '';
    sidebarOpen.value = true;
    sidebarLarge.value = true;
    currentGroup.value = null;
    currentFestival.value = null;
    personal.value = false;
    venueSoftAccessList.value = [];
    eventRequestTemplates.value = [];
    allGroups.value = [];
    personalFestivals.value = [];
    personalFestivalSections.value = [];
    completionTracker.value = null;
  };

  const refreshCurrentGroup = async () => {
    if (currentGroup.value) {
      const { data: group } = await getContextSidebarGroup(currentGroup.value.id);
      currentGroup.value = group;
    }
  };

  watch(
    currentContext,
    async (context) => {
      if (!usePage().props.authenticated || context === null) return;
      const { type, id } = getTypeAndIdFromContext(context);
      switch (type) {
        case 'group': {
          personal.value = false;
          currentFestival.value = null;

          const group = allGroups.value.find((g) => g.id === id);
          if (group?.id) {
            currentGroup.value = group;
          } else if (id) {
            const { data: g } = await getContextSidebarGroup(id);
            if (g.has_festivals) {
              const { data: festivals } = await window.axios.get('/api/festivals', {
                params: {
                  model_type: 'App\\Group',
                  model_id: g.id,
                  only_active: true,
                },
              });
              g.festivals = festivals;
            }

            const authGroup = usePage().props.auth.user.groups.find((gr) => gr.id === id);
            if (authGroup) {
              g.read = authGroup.read;
            }

            allGroups.value.push(g);
            currentGroup.value = g;
          }
          break;
        }
        case 'festival': {
          personal.value = false;
          currentGroup.value = null;
          const festival = personalFestivals.value.find((f) => f.id === id);
          if (festival?.id) {
            currentFestival.value = festival;
          } else {
            const { data: f } = await axios.get(`/api/festivals/${id}`);
            const fest = {
              ...f,
              permissions: [],
            };
            currentFestival.value = fest;
          }
          break;
        }
        default: {
          personal.value = true;
          currentGroup.value = null;
          currentFestival.value = null;
        }
      }
    },
    { immediate: false }
  );

  return {
    currentContext,
    currentView,
    currentContextTitle,
    sidebarOpen,
    sidebarLarge,
    currentContextType,
    currentContextId,
    currentGroup,
    currentFestival,
    allGroups,
    venueSoftAccessList,
    eventRequestTemplates,
    personal,
    personalFestivals,
    personalFestivalSections,
    fill,
    resetStore,
    adminOpenGroup,
    contextOptions,
    setCurrentSelectedSidebarItem,
    currentSelectedSidebarItem,
    completionTracker,
    refreshCurrentGroup,
    fetchFestivalSections,
    fetchFestivals,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContextSidebarStore, import.meta.hot));
}
