<script lang="ts" setup>
import { TaskCommentResource } from '@/types/tasks';
import DisplayText from '@/components/Display/DisplayText.vue';
import { usePage } from '@inertiajs/vue3';

type Props = {
  comment: TaskCommentResource;
};

const props = withDefaults(defineProps<Props>(), {});

const getNameOfCommenter = () => {
  if (!props.comment.person_type) return null;
  if (props.comment.person_type === 'App\\User') {
    if (props.comment.person_id === usePage().props.auth.user.id) {
      return 'You';
    }
  }
  return props.comment.person.name;
};
</script>

<template>
  <div
    :id="'task_comment_' + comment.id"
    class="grid grid-cols-[25px_auto] items-start gap-3 px-edge">
    <div>
      <img
        :src="comment.person.avatar_url"
        class="rounded-md mt-1"
        alt="User" />
    </div>
    <div class="flex flex-col">
      <div
        class="font-headers"
        :title="comment.created_at">
        <span class="text-sm">
          {{ getNameOfCommenter() }}
        </span>
        <span class="text-textColor-soft text-xs">
          {{ comment.person ? ' - ' : '' }} {{ comment.created_at_human }}
        </span>
      </div>
      <div>
        <DisplayText
          classes="!mt-0"
          :content="comment.comment" />
      </div>
    </div>
  </div>
</template>
