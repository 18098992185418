<script lang="ts" setup>
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { computed, onMounted } from 'vue';
import { router } from '@inertiajs/vue3';
import ContextSidebarListItem from '@/components/ContextSidebar/ContextSidebarListItem.vue';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import { useAutoAnimate } from '@formkit/auto-animate/vue';

type Props = {
  modelValue?: boolean;
  canOpen?: boolean;
  route?: string;
  title?: string;
  icon?: string;
  sectionId: string;
  isSubMenu?: boolean;
  isSectionTitle?: boolean;
  iconOnSmallScreen?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  canOpen: true,
  route: '',
  title: '',
  icon: '',
  isSubMenu: true,
  isSectionTitle: false,
  iconOnSmallScreen: false,
});

const emit = defineEmits<{
  (e: 'openList'): void;
  (e: 'update:modelValue', arg: boolean): void;
}>();

const contextSidebarStore = useContextSidebarStore();

const active = computed(
  () => (contextSidebarStore.currentView?.includes(props.sectionId) && props.isSubMenu && props.modelValue) ?? false
);

const localCanOpen = computed(() => {
  return props.canOpen;
});

const onClick = () => {
  if (localCanOpen.value) {
    emit('update:modelValue', !props.modelValue);
  }
  if (props.route && props.sectionId) {
    router.visit(props.route);
  }
};

onMounted(() => {
  if (props.route && props.sectionId) {
    if (props.route === window.location.href) {
      emit('openList');
    }
  }
});

const [parent, enable] = useAutoAnimate({ duration: 150 });
enable(props.sectionId !== 'main');
</script>

<template>
  <div v-if="isSubMenu">
    <div
      v-if="title"
      class="flex h-7 cursor-pointer items-center justify-between px-2 py-[1px]"
      :class="active || isSectionTitle ? 'text-sidebarActiveText' : 'text-sidebarTextSoft'"
      @click="onClick">
      <div
        class="flex items-center gap-3 truncate hover:bg-sidebarItemHover w-full h-full rounded-md"
        :class="iconOnSmallScreen && !contextSidebarStore.sidebarOpen ? '' : 'pl-2'"
        :title="title">
        <div
          v-if="contextSidebarStore.sidebarOpen"
          class="flex h-full items-center fa-fw"
          @click.stop="localCanOpen ? $emit('update:modelValue', !modelValue) : null">
          <ChevronToggle
            classes="text-sm"
            :model-value="localCanOpen ? modelValue : true" />
        </div>
        <i
          v-if="iconOnSmallScreen ? !contextSidebarStore.sidebarOpen : icon"
          class="fa fa-fw text-sm"
          :class="[icon, iconOnSmallScreen && !contextSidebarStore.sidebarOpen ? 'mx-auto' : '']" />
        <div
          v-if="contextSidebarStore.sidebarOpen"
          :style="isSectionTitle ? 'color: rgb(var(--color-sidebar-category-text))' : ''"
          :class="{ 'w-[0px]': !contextSidebarStore.sidebarOpen }"
          class="flex-1 truncate font-headers">
          {{ title }}
        </div>
      </div>
    </div>
    <div
      :ref="props.sectionId === 'main' ? null : 'parent'"
      class="flex w-[100%] flex-col">
      <div v-show="modelValue && canOpen">
        <slot />
      </div>
    </div>
  </div>
  <ContextSidebarListItem
    v-else
    :item-id="sectionId"
    :icon="icon"
    hash=""
    :title="title"
    :route="route"
    @open-list="$emit('openList')" />
</template>
