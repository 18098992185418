import { EventInviteResource, EventRoom, GlobalTimelineEvent } from '@/types/event';
import { UserMinimalResource } from '@/types/user';
import { useToast } from 'vue-toastification';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';

export const postEventDaySheet = async (eventId: number, data) => {
  return axios.post(`/api/events/${eventId}/day-sheet`, data);
};

export const doTheEventDelete = async ({ eventId, eventName, global = false }) => {
  const certain = await useCertaintyModal().assertCertain(
    `Delete ${eventName}`,
    `Please confirm the deletion of ${eventName}.`
  );
  if (!certain) return false;
  await axios.delete(`/api/events/${eventId}`, {
    params: {
      is_global: global,
    },
  });
  useToast().success('Event Deleted');
  return true;
};

export const deleteEvent = async ({ eventId, eventName, isRecurring = false, isSimple = false }) => {
  if (isSimple) {
    return doTheEventDelete({
      eventId,
      eventName,
    });
  }
  const certain = await useCertaintyModal().assertCertain(
    `Delete ${eventName}`,
    `Are you sure you want to delete "${eventName}", it can not be restored. All files, assignments, invitations, running orders and others will be deleted. This is the
    final warning.`
  );
  if (!certain) return false;

  const addToAll = isRecurring
    ? false
    : await useRecurringModal().recurringModal(
        null,
        `Delete All Recurrences of ${eventName}`,
        'Do you want to delete all recurrences of this event, or just this one?'
      );
  if (addToAll === 'cancel') return false;
  return doTheEventDelete({
    eventId,
    eventName,
    global: addToAll === 'all',
  });
};

export type EventCrewResource = {
  id: number | string;
  type: string;
  order?: number;
  title: string;
  model: string;
  model_id: number;
  parentId: string;
  children?: {
    id: number;
    title: string;
    model: string;
    model_id: number;
    type: string;
  }[];
};

export const getEventCrew = async (eventId: number) => {
  return axios.get<EventCrewResource[]>(`/api/events/${eventId}/crew`, {
    params: {
      format: 'fullcalendar',
    },
  });
};

export const getEventRooms = async (eventId: number) => {
  const url = `/api/events/${eventId}/rooms`;
  return axios.get<EventRoom[]>(url);
};

export const getEventInvites = async (eventId: number) => {
  return axios.get<EventInviteResource>(`/api/events/${eventId}/invites`);
};

export const getEventMembers = async (eventId: number) => {
  return axios.get<UserMinimalResource[]>(`/api/events/${eventId}/members`);
};

export const getEventGlobalTimeline = async (eventId: number) => {
  return axios.get<GlobalTimelineEvent[]>(`/api/events/${eventId}/global-timeline`);
};

export const patchEventName = async (eventId: number, name: string) => {
  return axios.patch<{ slug: string }>(`/api/events/${eventId}/update-name`, {
    name,
  });
};
