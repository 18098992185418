import { EventResource } from '@/types/event';
import { TopMenuTab } from '@/types/page-tab';
import { getKey } from '@/util/globals';

export const eventShowTabs = (event: EventResource): TopMenuTab[] => {
  return [
    {
      key: '',
      title: 'Event Info',
    },
    {
      key: 'timeline',
      title: 'Timeline',
    },
    getKey(event, 'has_running_orders', true)
      ? {
          key: 'running-order',
          title: 'Running Order',
        }
      : null,
    getKey(event, 'has_boards', true)
      ? {
          key: 'boards',
          title: 'Boards',
        }
      : null,
    getKey(event, 'has_forms', true)
      ? {
          key: 'forms',
          title: 'Forms',
        }
      : null,
    getKey(event, 'has_tasks', false)
      ? {
          key: 'tasks',
          title: 'Tasks',
        }
      : null,
    {
      key: 'documents',
      title: 'Documents',
    },
    {
      key: 'members',
      title: 'Members',
    },
    // {
    //   key: 'old-members',
    //   title: 'Old Members',
    // },
  ].filter((i) => i !== null);
};
