import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId, getKey, reorderArrayByIds } from '@/util/globals';
import { InvoiceRowCategoryResource } from '@/types/invoice-row';
import { getInvoiceRowsCategories, getInvoiceRowsCategory } from '@/services/api-invoice-rows';

export const useInvoiceProductCategoriesStore = defineStore('invoice-product-categories', () => {
  const list = ref<Map<number, InvoiceRowCategoryResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (groupId: number) => {
    Echo.channel(`private-Group.${groupId}`)
      .listen('.invoiceRowCategory.created', async (e) => {
        await addOrUpdateInvoiceProductCategory(groupId, e);
      })
      .listen('.invoiceRowCategory.updated', async (e) => {
        await addOrUpdateInvoiceProductCategory(groupId, e);
      })
      .listen('.invoiceRowCategory.deleted', async (e) => {
        await removeInvoiceProductCategory(groupId, getKey(e, 'id'));
      });
  };

  const fetchInvoiceProductCategories = async (groupId: number) => {
    loading.value = true;
    const { data } = await getInvoiceRowsCategories(groupId);
    if (!list.value.has(groupId)) {
      listenForBroadcast(groupId);
    }
    list.value.set(groupId, data);
    loading.value = false;
  };

  const fetchProductCategory = async (groupId: number, id: number) => {
    const { data } = await getInvoiceRowsCategory(id);
    let currentItems = list.value.get(groupId);
    currentItems = exchangeValuesOfObject(data, currentItems);
    list.value.set(groupId, currentItems);
  };

  const addOrUpdateInvoiceProductCategory = async (
    groupId: number,
    invoiceProductCategory: InvoiceRowCategoryResource
  ) => {
    if (!list.value.has(groupId)) {
      await fetchInvoiceProductCategories(groupId);
      return;
    }
    let data = list.value.get(groupId);
    data = exchangeValuesOfObject(invoiceProductCategory, data);
    list.value.set(groupId, data);
  };

  const reorderProductCategories = async (groupId: number, orderOfIds: []) => {
    if (!list.value.has(groupId)) {
      await fetchInvoiceProductCategories(groupId);
      return;
    }
    let data = list.value.get(groupId);
    data = reorderArrayByIds(data, orderOfIds);
    data = data.map((d, index) => {
      return { ...d, order: index + 1 };
    });
    list.value.set(groupId, data);
  };

  const removeInvoiceProductCategory = async (groupId: number, invoiceProductCategoryId: number) => {
    if (!list.value.has(groupId)) {
      await fetchInvoiceProductCategories(groupId);
      return;
    }
    const data = list.value.get(groupId);
    const index = getIndexFromArrayBasedOnId(invoiceProductCategoryId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(groupId, data);
    } else {
      await fetchInvoiceProductCategories(groupId);
    }
  };

  const changeOutList = (groupId: number, types: InvoiceRowCategoryResource[]) => {
    list.value.set(groupId, types);
  };

  return {
    list,
    loading,
    changeOutList,
    fetchProductCategory,
    fetchInvoiceProductCategories,
    addOrUpdateInvoiceProductCategory,
    removeInvoiceProductCategory,
    reorderProductCategories,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInvoiceProductCategoriesStore, import.meta.hot));
}
