import type { ContextSidebarTab } from '@/types/global';
import { getRoute } from '@/util/route';

export const usePersonalMenu = (): ContextSidebarTab[] =>
  [
    {
      hash: '',
      key: 'personal',
      title: 'Dashboard',
      icon: 'fa-home fa-regular',
      route: getRoute('events'),
    },
    {
      hash: '',
      key: 'personal_tasks',
      title: 'Tasks',
      icon: 'fa-tasks fa-regular',
      route: getRoute('userTasks'),
    },
    {
      hash: '',
      key: 'personal_settings',
      title: 'Settings',
      icon: 'fa-cog fa-regular',
      route: getRoute('profile.settings'),
    },
  ].filter((i) => i !== null);
