export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isNumber = (value: unknown) => typeof value === 'number' && isFinite(value);

export const slugify = (str: string | null | number): string | null | number => {
  if (!str) return null;
  if (typeof str === 'number') return str;
  return str
    ?.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};
