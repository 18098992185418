export const validEmail = (email: string) => {
  const text = email?.trim() ?? '';
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(text).toLowerCase());
};

export const validUrl = (url: string): boolean => {
  const re = /^(http|https):\/\/[^ "]+$/;
  return re.test(String(url).toLowerCase());
};

export const validHexColor = (hex: string): boolean => {
  const re = /^#([0-9A-F]{3}){1,2}$/i;
  return re.test(String(hex).toLowerCase());
};
