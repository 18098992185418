<script setup lang="ts">
import { onMounted, ref } from 'vue';

type Props = {
  statusBar: { number: number; name: string; percent: number }[];
  status: number | null;
  canEdit?: boolean | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:status', arg: number): void;
  (event: 'updateEventType', arg: { key: string; value: number | string }): void;
  (event: 'attributeUpdated', arg: { key: string; value: number | string }): void;
}>();

const pxOfEachElement = ref(100);
const wrapper = ref<HTMLDivElement | null>(null);

onMounted(() => {
  setTimeout(() => {
    if (wrapper.value) {
      pxOfEachElement.value = (wrapper.value.offsetWidth - 160) / props.statusBar.length;
      if (pxOfEachElement.value > 120) {
        pxOfEachElement.value = 120;
      }
    }
  }, 100);
});
</script>

<template>
  <div
    ref="wrapper"
    class="flex items-center justify-between min-w-full px-[80px] pb-7">
    <template
      v-for="step in statusBar"
      :key="step.number">
      <div
        class="flex flex-col items-center relative"
        :title="step.name"
        @click="emit('update:status', step.number)">
        <div
          class="w-6 h-6 border-2 border-borderColor text-xs pt-1 aspect-1 text-center rounded-full transition-all"
          :class="{
            '  hover:border-highlight cursor-pointer ': canEdit,
            ' ': step.number === status,
            'border-success bg-success text-black': step.number <= status,
          }">
          {{ step.number }}
        </div>
        <div
          :style="'max-width: ' + pxOfEachElement + 'px'"
          class="text-sm text-center truncate absolute mt-[25px]"
          :class="{ 'text-textColor-soft ': step.number < status }">
          {{ step.name }}
        </div>
      </div>
      <div
        v-if="step.number < statusBar.length"
        :class="{ 'bg-highlight': step.number < status, 'bg-borderColor': step.number >= status }"
        class="h-1 w-full transition-all" />
    </template>
  </div>
</template>
