<script lang="ts" setup>
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { computed, nextTick, ref, watch } from 'vue';
import { getKey, sortArrayBy } from '@/util/globals';
import { highlightStringBySearch } from '@/util/text-replace-helper';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

type Options = string[] | object[] | number[];

type Props = {
  label?: string;
  labelTitle?: string;
  disabledLabelTitle?: string;
  labelPlacement?: string;
  wrapperClass?: string;
  options: Options;
  canEdit?: boolean;
  optionKey?: string;
  optionLabel?: string;
  modelValue: string[] | number[] | object[] | null;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  groups?: boolean;
  object?: boolean;
  isHidden?: boolean;
  withAddAll?: boolean;
  setFocus?: boolean;
  closeOnSelect?: boolean;
  maxItemPerRow?: number;
  haveMaxWidth?: boolean;
  closeOnScroll?: boolean;
  iconLeft?: string | null;
  withFilter?: boolean;
  sortAlphabetically?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: true,
  label: '',
  labelTitle: null,
  disabledLabelTitle: null,
  labelPlacement: '',
  wrapperClass: '',
  optionKey: 'id',
  inputLabel: '',
  optionLabel: 'name',
  placeholder: 'select',
  required: false,
  disabled: false,
  error: '',
  groups: false,
  isHidden: false,
  withAddAll: false,
  closeOnSelect: false,
  maxItemPerRow: 3,
  object: false,
  setFocus: false,
  haveMaxWidth: true,
  closeOnScroll: true,
  iconLeft: null,
  withFilter: false,
  sortAlphabetically: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: number[] | string[] | null): void;
  (e: 'selectedItem', value: object | string | number | null): void;
  (e: 'removeItem', value: object | string | number | null): void;
  (e: 'dropdownOpened'): void;
  (e: 'dropdownClosed'): void;
}>();

const showDropDown = ref(true);

const onSelectAll = (selectAll: boolean) => {
  if (selectAll) {
    if (props.groups) {
      emit(
        'update:modelValue',
        props.object
          ? props.options.map((o) => o.options)
          : props.options.flatMap((o) => o.options).map((option) => option[props.optionKey])
      );
    } else {
      emit('update:modelValue', props.object ? props.options : props.options.map((option) => option[props.optionKey]));
    }
  } else {
    emit('update:modelValue', []);
  }
};
const onSelect = (value: object | string | number | null) => {
  if (value?.groupTitle) return;

  emit('selectedItem', value);

  showDropDown.value = false;

  nextTick(() => {
    showDropDown.value = true;
  });

  if (props.object) {
    if (props.modelValue?.some((o) => o[props.optionKey] === value[props.optionKey])) {
      const newValue = props.modelValue.filter((o) => o[props.optionKey] !== value[props.optionKey]);
      emit('update:modelValue', newValue);
    } else {
      if (!props.modelValue) {
        emit('update:modelValue', [value]);
      } else {
        emit('update:modelValue', [...props.modelValue, value]);
      }
    }
    return;
  }

  // if value is object add/remove only id to modelvalue
  if (typeof value === 'object' && value !== null) {
    const id = value[props.optionKey];
    if (props.modelValue?.includes(id)) {
      const newValue = props.modelValue.filter((o) => o !== id);
      emit('update:modelValue', newValue);
    } else {
      if (!props.modelValue) {
        emit('update:modelValue', [id]);
      } else {
        const newValue = [...props.modelValue, id];
        emit('update:modelValue', newValue);
      }
    }
    return;
  }

  // if value is string add/remove only value to modelvalue
  if (props.modelValue?.includes(value)) {
    const newValue = props.modelValue.filter((o) => o !== value);
    emit('update:modelValue', newValue);
  } else {
    if (!props.modelValue) {
      emit('update:modelValue', [value]);
    } else {
      const newValue = [...props.modelValue, value];
      emit('update:modelValue', newValue);
    }
  }
};
const optionLabelWithId = (id: string | number | object) => {
  // - check if vmodel array of objects
  if (props.object) {
    return id[props.optionLabel];
  }

  if (typeof props.options[0] === 'object') {
    if (props.groups) {
      let option = null;
      props.options.forEach((o: any) => {
        o.options.forEach((o2: any) => {
          if (o2[props.optionKey] === id) {
            option = o2;
          }
        });
      });
      if (option) {
        return option[props.optionLabel];
      }
    } else {
      const option = props.options.find((o: any) => o[props.optionKey] === id);
      if (option) {
        return option[props.optionLabel];
      }
    }

    return null;
  } else {
    return id.toString();
  }
};

const getOptionLabel = (option: any): string => {
  if (typeof option === 'object') {
    if (searchText.value) {
      return highlightStringBySearch(option[props.optionLabel], searchText.value);
    }
    return option[props.optionLabel];
  } else {
    if (searchText.value) {
      return highlightStringBySearch(option, searchText.value);
    }
    return option;
  }
};

const allOptions = computed(() => {
  const array: any[] = [];
  if (props.groups) {
    props.options.forEach((o) => {
      array.push({
        [props.optionLabel]: o.label,
        [props.optionKey]: o.label,
        groupTitle: true,
      });
      o.options.forEach((oo: any) => {
        array.push(oo);
      });
    });
  } else {
    const options = props.sortAlphabetically
      ? sortArrayBy([...(props.options ?? [])], props.optionLabel)
      : [...(props.options ?? [])];

    options?.forEach((o) => {
      array.push(o);
    });
  }
  return array;
});

const onClick = (item, close: (t) => void) => {
  if (item.disabled) return;
  if (item.groupTitle) return;
  close(item);
};

const isSelected = (option: any): boolean => {
  if (props.object) {
    return props.modelValue?.some((o) => o[props.optionKey] === option[props.optionKey]) ?? false;
  } else if (typeof option === 'object') {
    return props.modelValue?.includes(option[props.optionKey]) ?? false;
  } else {
    return props.modelValue?.includes(option) ?? false;
  }
};

const maxColumns = computed(() => {
  return `grid-cols-${props.maxItemPerRow}`;
});

const removeItem = (item: any) => {
  if (!props.canEdit) return;
  if (props.object) {
    const newValue = props.modelValue.filter((o) => o[props.optionKey] !== item[props.optionKey]);
    emit('update:modelValue', newValue);
  } else {
    const newValue = props.modelValue.filter((o) => o !== item);
    emit('update:modelValue', newValue);
  }
  emit('removeItem', item);
};

const pos = computed(() => {
  switch (props.labelPlacement.toLowerCase()) {
    case 'left': {
      return '';
    }
    default: {
      return 'flex-col';
    }
  }
});

const element = ref<HTMLDivElement>();

const allTimesHasBeenSelected = computed(() => {
  if (!props.modelValue) return false;
  if (props.groups) {
    return props.options.flatMap((o) => o.options).length === props.modelValue.length;
  }
  return props.options.length === props.modelValue.length;
});

const getWidth = computed(() => {
  if (!element.value) return;

  if (element.value.getBoundingClientRect().width > 150) {
    if (allOptions.value.length > 5) {
      return element.value.getBoundingClientRect().width - 12 + 'px';
    }
    return element.value.getBoundingClientRect().width + 'px';
  }

  return '150px';
});

const searchText = ref('');
const isOpen = ref(false);
const selectedIndex = ref<number | null>(null);

const keydown = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    isOpen.value = false;
    return;
  }

  if (props.withFilter) {
    if (e.key === 'Backspace') {
      searchText.value = searchText.value.slice(0, -1);
      return;
    }
  }

  // if key down
  if (e.key === 'ArrowDown') {
    if (selectedIndex.value === null) {
      selectedIndex.value = 0;
    } else {
      selectedIndex.value = Math.min(selectedIndex.value + 1, filterOptions.value.length - 1);
    }
    // scroll to element
    const element = document.querySelector(`[data-index="${selectedIndex.value}"]`);
    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
    return;
  }

  // if key up
  if (e.key === 'ArrowUp') {
    if (selectedIndex.value === null) {
      selectedIndex.value = filterOptions.value.length - 1;
    } else {
      selectedIndex.value = Math.max(selectedIndex.value - 1, 0);
    }
    const element = document.querySelector(`[data-index="${selectedIndex.value}"]`);
    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
    return;
  }

  // if enter
  if (e.key === 'Enter') {
    if (selectedIndex.value !== null) {
      onSelect(filterOptions.value[selectedIndex.value]);
    }
    return;
  }

  // if a-z or 0-9
  if (!/^[a-zA-Z0-9]$/.test(e.key)) return;

  if (props.withFilter) {
    searchText.value += e.key;
  }
};

watch(isOpen, (value) => {
  if (value) {
    window.addEventListener('keydown', keydown);
  } else {
    window.removeEventListener('keydown', keydown);
  }
});

const filterOptions = computed(() => {
  if (!searchText.value) return allOptions.value;

  return allOptions.value.filter((o) => {
    if (typeof o === 'object') {
      return o[props.optionLabel].toLowerCase().includes(searchText.value.toLowerCase());
    } else {
      return o.toString().toLowerCase().includes(searchText.value.toLowerCase());
    }
  });
});
</script>

<template>
  <div
    class="flex min-w-[250px]"
    :class="[pos, wrapperClass]">
    <div class="flex justify-between items-end">
      <InputLabel
        :label="label"
        class="justify-self-start"
        :title="canEdit || !disabledLabelTitle ? labelTitle : disabledLabelTitle"
        :mandatory-text="required ? 'required' : null" />
      <div v-if="!label && withAddAll && options"></div>
      <div
        v-if="withAddAll && options && options.length > 0"
        class="mb-2">
        <VButton
          v-if="allTimesHasBeenSelected"
          type="warning"
          size="extra-small"
          title="Clear"
          icon="fa-minus"
          @click="onSelectAll(false)" />
        <VButton
          v-else
          size="extra-small"
          :disabled="!options || !modelValue || options.length === modelValue.length"
          @click="onSelectAll(true)">
          <i class="fa fa-fw fa-plus" />
          Add All
        </VButton>
      </div>
    </div>
    <VDropdown
      :items="allOptions"
      :item-key="optionKey"
      :item-label="optionLabel"
      :close-on-click="closeOnSelect"
      :set-focus="setFocus"
      :have-max-width="haveMaxWidth"
      :can-open-dropdown="canEdit"
      :close-on-scroll="closeOnScroll"
      @dropdown-opened="[$emit('dropdownOpened'), (isOpen = true)]"
      @dropdown-closed="[$emit('dropdownClosed'), (isOpen = false)]"
      @item-clicked="onSelect">
      <template #click-area="{ isOpen }">
        <div
          ref="element"
          class="flex min-h-[40px] items-center justify-between gap-2 rounded-md p-3 ring-1 hover:border-textColor-soft hover:ring-textColor-soft"
          :class="`${
            isHidden
              ? ' ring-transparent '
              : canEdit
                ? ' bg-inputs-background ring-borderColor '
                : ' bg-inputs-disabledBackground '
          } ${isOpen ? ' !ring-highlight ' : ''}`">
          <div
            v-if="iconLeft"
            class="absolute inline-flex h-full w-5 items-center justify-center">
            <i
              class="fa fa-fw mt-2"
              :class="iconLeft" />
          </div>
          <div>
            <div
              v-if="modelValue?.length"
              class="grid gap-2"
              :class="maxColumns + ' ' + (iconLeft ? 'pl-[25px]' : '')">
              <button
                v-for="item in modelValue"
                :key="String(item)"
                :title="optionLabelWithId(item)"
                class="group rounded-full border px-3 py-0 text-textColor-soft"
                @click.stop="canEdit ? removeItem(item) : null">
                <span class="flex items-center justify-between gap-2 font-headers">
                  <span class="truncate text-sm text-textColor">
                    {{ optionLabelWithId(item) }}
                  </span>
                  <i
                    v-if="canEdit"
                    class="fa fa-fw fa-times fa-xs cursor-pointer group-hover:text-warning" />
                </span>
              </button>
            </div>
            <span
              v-else
              class="pl-1 italic text-textColor-soft">
              {{ placeholder }}
            </span>
          </div>
          <div>
            <i
              v-if="canEdit"
              class="fa fa-fw fa-chevron-down text-textColor-soft transition-transform duration-300"
              :class="{ 'rotate-180': isOpen }" />
          </div>
        </div>
      </template>
      <template #dropdown="{ close }">
        <div
          v-if="showDropDown"
          class="pr-4"
          :style="`min-width: ${getWidth}`">
          <ul class="bg-backgroundColor-content py-3">
            <li
              v-for="(option, index) in filterOptions"
              :key="option[optionKey]"
              :class="[
                { 'opacity-50': option.disabled },
                { 'bg-row-hover': selectedIndex === index },
                { 'cursor-default': option.groupTitle },
                { 'bg-backgroundColor pl-4': option.groupTitle },
                { 'pointer-events-none !bg-textColor-soft !bg-opacity-50': option.disabled },
                option.disabled || option.groupTitle ? '' : ' cursor-pointer  hover:bg-row',
              ]"
              class="group px-4 py-3"
              :data-index="index"
              @click="onClick(option, close)">
              <div
                class="flex items-center gap-2"
                :class="option.groupTitle ? 'font-bold' : ''">
                <span
                  :class="
                    getKey(option, 'classes', '') +
                    (!option.groupTitle && props.groups ? ' pl-6 ' : '') +
                    (option.groupTitle ? 'font-semibold ' : '') +
                    (isSelected(option) || option.disabled ? 'text-textColor-soft' : 'text-textColor')
                  "
                  :title="getOptionLabel(option)">
                  <i
                    v-if="getKey(option, 'icon')"
                    class="fa fa-fw mr-3 fa-regular"
                    :class="getKey(option, 'icon')" />
                  <slot :option="option">
                    <span
                      v-if="searchText"
                      v-html="safeHtmlStringify(getOptionLabel(option))" />
                    <span v-else> {{ getOptionLabel(option) }}</span>
                  </slot>
                </span>
                <span
                  v-if="!option.groupTitle && !option.disabled"
                  class="ml-auto mr-0">
                  <i
                    v-if="selectedIndex === index"
                    class="fa fa-fw fa-arrow-left" />
                  <i
                    v-else-if="isSelected(option)"
                    class="fa fa-fw fa-check text-highlight" />
                  <i
                    v-else
                    class="fa fa-fw fa-check invisible" />
                </span>
                <span
                  v-if="option.disabled"
                  class="ml-auto mr-0 rounded-full bg-textColor-soft px-4 py-0 text-sm text-textColor-disabled">
                  Disabled
                </span>
              </div>
            </li>
            <li
              v-if="!filterOptions.length"
              class="group px-4 py-3">
              No Results for <span class="italic">{{ searchText }}</span>
            </li>
          </ul>
        </div>
      </template>
    </VDropdown>
  </div>
</template>
