<script setup lang="ts">
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  loading?: boolean;
};

withDefaults(defineProps<Props>(), {
  loading: false,
});

defineEmits<{
  (event: 'clicked'): void;
}>();
</script>

<template>
  <VButton
    v-bind="$attrs"
    size="small"
    class="btn-outline ring-[rgb(var(--color-ring-edit-button))]"
    :loading="loading"
    icon="fa-ellipsis-v"
    @click="$emit('clicked')" />
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
