<script setup lang="ts">
import { eventMembersKey } from '@/provide/keys';
import { computed, inject, ref } from 'vue';
import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  modelValue: string | number | null;
  canEdit: boolean;
};

const props = defineProps<Props>();

defineEmits<{
  (e: 'update:modelValue', value: string | number | null): void;
}>();

const edit = ref(false);

const { fetch, allMembers } = inject(eventMembersKey, {
  fetch: () => {},
  allMembers: computed(() => []),
});
fetch(false);

const userName = computed(() => allMembers.value.find((i) => i.id === props.modelValue)?.name ?? '');
</script>
<template>
  <div v-if="canEdit">
    <VSelect
      :model-value="modelValue"
      :options="allMembers"
      nullable-display-text=""
      nullable
      is-hidden
      class="[&_.rounded-md]:rounded-none"
      input-area-class="hover:ring-1 hover:ring-textColor-soft"
      @dropdown-closed="edit = false"
      @update:model-value="$emit('update:modelValue', $event)" />
  </div>
  <div v-else>
    <div v-if="userName">
      {{ userName }}
    </div>
    <slot
      v-else
      name="emptyState" />
  </div>
</template>
