<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { itemsForReminderExchange } from '@/util/reminders';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import ReminderContentSection from '@/components/Models/Reminders/ReminderContentSection.vue';
import { getNewReminder } from '@/util/reminder-helper-functions';
import VButton from '@/components/Inputs/VButton.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { useKeepOpenModal } from '@/composables/modals/use-keep-open-modal';
import { ShiftResource } from '@/types/event';
import DisplayEmail from '@/components/Display/DisplayEmail.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import DisplayPhone from '@/components/Display/DisplayPhone.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import { useToast } from 'vue-toastification';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { getNow } from '@/util/timeFunctions';

type Props = {
  shift: ShiftResource;
  hasSMS?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  hasSMS: false,
});

const emit = defineEmits<{
  (e: 'closed'): void;
}>();

const emailTemplates = ref([]);

const modalOpen = ref(false);

const newMessage = ref(getNewReminder({ email: true }));

const loading = ref(false);

const readyToSendMail = ref(false);

watch(
  newMessage.value,
  () => {
    readyToSendMail.value = false;
  },
  { deep: true }
);

const listOpen = ref(false);
const sendToAssignee = ref(true);
const shiftCrewSlotIds = ref([]);

const sendReminder = async () => {
  if (!readyToSendMail.value) return;
  if (loading.value) return;
  if (!sendToAssignee.value && shiftCrewSlotIds.value.length === 0) {
    useToast().info('No recipients selected.');
    return;
  }

  const certain = await useCertaintyModal().assertCertain(
    'Send ' + (newMessage.value.email ? 'Email' : 'SMS'),
    'Are you sure you are ready to send to ' +
      (shiftCrewSlotIds.value.length + (sendToAssignee.value ? 1 : 0)) +
      ' recipients?'
  );
  if (!certain) return;

  let params = {
    content: newMessage.value.content,
    sms: newMessage.value.sms,
    email: newMessage.value.email,
    send_at: getNow(),
    email_subject: newMessage.value.email_subject,
    reply_to_id: newMessage.value.reply_to_id,
    reply_to_email: newMessage.value.reply_to_email,
    send_to_assignee: sendToAssignee.value,
    shift_crew_slot_ids: shiftCrewSlotIds.value,
    behalf_of_type: props.shift.via_type,
    behalf_of_id: props.shift.via_id,
  };
  loading.value = true;
  await axios.post(`/api/shifts/${props.shift.id}/send-reminder`, params);
  loading.value = false;

  useToast().success('Sent.');
  emit('closed');
};

const openModal = () => {
  modalOpen.value = false;
  nextTick(() => {
    modalOpen.value = true;
  });
};
openModal();

const checkToClose = async () => {
  modalOpen.value = false;
  const keepItOpen = await useKeepOpenModal().keepOpenModal(
    'Close Mail Out',
    'Are you certain you want to close this modal?'
  );
  if (keepItOpen) {
    modalOpen.value = true;
  }
};

const slottedCrewSlots = computed(() => {
  return props.shift.shift_crew_slots.filter((s) => s.slottable_id !== null);
});
const setUp = () => {
  shiftCrewSlotIds.value = slottedCrewSlots.value.map((s) => s.id);
  sendToAssignee.value = props.shift.user_id !== null;
};
setUp();
</script>
<template>
  <CrudSlideout
    v-if="modalOpen"
    small
    :base-z-index="1000"
    :loading="loading"
    title="Communicate with Shift"
    @closed="checkToClose">
    <div class="flex flex-col [&>div:last-child]:border-none [&>div]:border-b [&>div]:p-edge">
      <div class="!p-0">
        <div
          class="grid grid-cols-[auto_90px_90px_90px] items-center p-edge [&_.item]:grid [&_.item]:grid-cols-[100px_1fr]">
          <h3
            class="cursor-pointer"
            @click="listOpen = !listOpen">
            <ChevronToggle
              :loading="loading"
              :model-value="listOpen" />
            Receivers
          </h3>
          <div class="flex items-center gap-3">
            <h4 class="text-highlight">Total</h4>
            {{
              (shift.user_id === null ? 0 : 1) + shift.shift_crew_slots.filter((s) => s.slottable_id !== null).length
            }}
          </div>

          <div class="flex items-center gap-3">
            <h4 class="text-highlight">Selected</h4>
            {{ (shift.user_id !== null && sendToAssignee ? 1 : 0) + shiftCrewSlotIds.length }}
          </div>
          <SettingCheck
            label="Select all"
            :model-value="
              (shift.user_id ? sendToAssignee : true) && shiftCrewSlotIds.length === slottedCrewSlots.length
            "
            @click="
              (shift.user_id ? sendToAssignee : true) && shiftCrewSlotIds.length === slottedCrewSlots.length
                ? [(sendToAssignee = false), (shiftCrewSlotIds = [])]
                : [(sendToAssignee = true), (shiftCrewSlotIds = slottedCrewSlots.map((s) => s.id))]
            " />
        </div>
        <div
          v-if="listOpen"
          class="max-h-[30vh] overflow-auto !p-0">
          <VTable
            edge-to-edge
            sticky-header>
            <VTableRow v-if="shift.user_id">
              <VTableCell
                style="width: 50px"
                :main-cell="sendToAssignee">
                <CheckBox v-model="sendToAssignee" />
              </VTableCell>
              <VTableCell :main-cell="sendToAssignee">
                {{ shift.user?.name }}
              </VTableCell>
              <VTableCell>
                <div class="flex justify-between">
                  <DisplayEmail
                    :email="shift.user.email"
                    :with-icon="false" />
                </div>
              </VTableCell>
              <VTableCell>
                <div class="flex justify-between">
                  <DisplayPhone
                    v-if="shift.user"
                    :with-icon="false"
                    :phone-number="shift.user.phone"
                    :country-code="shift.user.country_code" />
                </div>
              </VTableCell>
            </VTableRow>
            <VTableRow v-for="slot in slottedCrewSlots">
              <VTableCell
                style="width: 50px"
                clickable
                :main-cell="shiftCrewSlotIds.includes(slot.id)"
                @clicked="
                  shiftCrewSlotIds.includes(slot.id)
                    ? shiftCrewSlotIds.splice(shiftCrewSlotIds.indexOf(slot.id), 1)
                    : shiftCrewSlotIds.push(slot.id)
                ">
                <CheckBox :model-value="shiftCrewSlotIds.includes(slot.id)" />
              </VTableCell>
              <VTableCell :main-cell="shiftCrewSlotIds.includes(slot.id)">
                {{ slot.slottable?.name }}
              </VTableCell>
              <VTableCell>
                <div class="flex justify-between">
                  <DisplayEmail
                    v-if="slot.slottable && slot.slottable?.email"
                    :email="slot.slottable?.email"
                    :with-icon="false" />
                </div>
              </VTableCell>
              <VTableCell>
                <div class="flex justify-between">
                  <DisplayPhone
                    v-if="slot.slottable && slot.slottable?.phone"
                    :with-icon="false"
                    :phone-number="slot.slottable.phone"
                    :country-code="slot.slottable.country_code" />
                </div>
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>
      </div>

      <ReminderContentSection
        v-model:reminderSetting="newMessage"
        :email-templates="emailTemplates?.filter((t) => t.type === null)"
        :can-include-link="newMessage.email"
        :already-exists="false"
        can-set-reply-to
        :can-set-title="false"
        :exchange-words="itemsForReminderExchange(true)"
        :can-set-hours-before="false"
        :can-set-receiver="false"
        :has-sms="hasSMS" />
    </div>
    <template #footer>
      <div class="grid grid-cols-2 items-center px-edge py-4">
        <div>
          <SettingCheck
            v-model="readyToSendMail"
            label="Ready to send" />
        </div>

        <div>
          <VButton
            :disabled="!readyToSendMail"
            class="float-right"
            disabled-tool-tip-text="Not Ready to send yet."
            title="Send"
            @click="sendReminder" />
        </div>
      </div>
    </template>
  </CrudSlideout>
</template>
