import FieldDate from '@/components/Fields/Fields/FieldDate.vue';
import FieldDocument from '@/components/Fields/Fields/FieldDocument.vue';
import FieldTime from '@/components/Fields/Fields/FieldTime.vue';
import FieldRichText from '@/components/Fields/Fields/FieldRichText.vue';
import FieldError from '@/components/Fields/Fields/FieldError.vue';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import FieldToggle from '@/components/Fields/Fields/FieldToggle.vue';
import FieldList from '@/components/Fields/Fields/FieldList.vue';
import FieldPublicDocument from '@/components/Fields/Fields/FieldPublicDocument.vue';
import { FieldResource } from '@/types/field';
import { getItemFromArrayBasedOnId } from '@/util/globals';
import { allAvailableFieldTypes } from '@/util/fields';
import FieldTable from '@/components/Fields/Fields/FieldTable.vue';
import FieldMultiSelect from '@/components/Fields/Fields/FieldMultiSelect.vue';

export const getComponent = (component: string): InstanceType<any> => {
  switch (component) {
    case 'field-text': {
      return TextareaInput;
    }
    case 'field-number': {
      return NumberInput;
    }
    case 'field-list': {
      return FieldList;
    }
    case 'field-date': {
      return FieldDate;
    }
    case 'field-time': {
      return FieldTime;
    }
    case 'field-toggle': {
      return FieldToggle;
    }
    case 'field-rich-text': {
      return FieldRichText;
    }
    case 'field-public-document': {
      return FieldPublicDocument;
    }
    case 'field-document': {
      return FieldDocument;
    }
    case 'field-table': {
      return FieldTable;
    }
    case 'field-multi-select': {
      return FieldMultiSelect;
    }
    default: {
      return FieldError;
    }
  }
};
export const getIcon = (component: string): string => {
  return getItemFromArrayBasedOnId(component, [...allAvailableFieldTypes], { icon: null }, 'component').icon;
};

export const getProps = (field: FieldResource, otherProps = {}) => {
  const defaultProps = {
    canEdit: true,
    'model-value': field.value,
    ...otherProps,
  };
  switch (field.component) {
    case 'field-text': {
      return {
        ...defaultProps,
        'min-rows': 1,
        'min-height': 30,
      };
    }
    case 'field-number': {
      return {
        ...defaultProps,
        'with-buttons': false,
        'size': 'block',
      };
    }
    case 'field-list': {
      return {
        ...defaultProps,
        options: field.options,
        nullable: true,
        nullableDisplayText: ' ',
      };
    }
    default: {
      return defaultProps;
    }
  }
};
