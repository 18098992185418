<script setup lang="ts">
import { documentKey, maxUploadSizeKey } from '@/provide/keys';
import { DocumentResource } from '@/types/document';
import { downloadDocument } from '@/helpers/downloadFileFunctions';
import { computed, inject, nextTick, ref } from 'vue';
import ImageViewModal from '@/components/Documents/ImageViewModal.vue';
import VButton from '@/components/Inputs/VButton.vue';
import DocumentUploader from '@/components/Documents/DocumentUploader.vue';
import { getFileTypeIcon } from '@/util/document';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import DocumentCellDocument from '@/components/Config/ColumnConfig/Cells/Files/DocumentCellDocument.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import DocumentCellFolder from '@/components/Config/ColumnConfig/Cells/Files/DocumentCellFolder.vue';
import { sortArrayBy } from '@/util/globals';

type Props = {
  modelValue: string | number | null;
  canEdit: boolean;
  isShowMode: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', arg: any): void;
  (event: 'focus'): void;
}>();

const maxUploadSize = inject(maxUploadSizeKey, 100);

const { fetch, folderDocuments, unconnectedDocuments, allDocuments, model, modelId } = inject(documentKey, {
  fetch: async () => {},
  loading: ref(false),
  folderDocuments: computed(() => []),
  unconnectedDocuments: computed(() => []),
  allDocuments: computed(() => []),
  model: 'Event',
  modelId: 0,
});
fetch(false);

const empty = computed(() => folderDocuments.value.length === 0 && unconnectedDocuments.value.length === 0);

const modalOpen = ref(false);

const document = computed(() => allDocuments.value.find((doc) => doc.id === Number(props.modelValue)));

const removeDocument = async () => {
  const removeIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Document',
    'Are you sure you want to remove this document?'
  );
  if (!removeIt) return;

  emit('update:modelValue', null);
};

const openFolderIds = ref<Set<number>>(new Set());
const getFolderIds = (folder) => {
  return [folder.id].concat(folder.children.map(getFolderIds).flat());
};

const openModal = () => {
  openFolderIds.value = new Set(folderDocuments.value.flatMap((folder) => getFolderIds(folder)));
  modalOpen.value = true;
};

const isImage = computed(() => {
  if (!document.value) return false;
  return document.value.mime_type.startsWith('image');
});
const showImage = ref(false);
const documentAdded = async (doc: DocumentResource, close: () => void, forceFetch = false) => {
  if (forceFetch) {
    await fetch(true);
  }
  emit('update:modelValue', doc.id);
  close();
};
const openDocument = () => {
  if (!document.value) return;
  if (isImage.value) {
    showImage.value = false;
    nextTick(() => {
      showImage.value = true;
    });
    return;
  }
  if (document.value.mime_type.substring(0, 15) === 'application/pdf') {
    window.open(document.value.download_url);
    return;
  }
  downloadDocument(document);
};
const searchTerm = ref('');
</script>
<template>
  <div
    v-if="document"
    class="flex items-center gap-2">
    <div
      class="w-full cursor-pointer truncate rounded-full px-3 py-1 ring-highlight hover:ring-1"
      :title="document.filename"
      @click="openDocument">
      <i
        class="fa fa-fw"
        :class="getFileTypeIcon(document.extension)" />
      {{ document.filename }}
    </div>
    <VButton
      v-if="canEdit"
      size="inTable"
      icon="fa-times"
      @focus="[emit('focus')]"
      @click="removeDocument" />
    <ImageViewModal
      v-if="isImage && showImage"
      :image="document"
      :can-edit="false"
      @close="showImage = false" />
  </div>
  <div
    v-else-if="!isShowMode"
    class="flex h-full items-center justify-center">
    <VButton
      v-if="canEdit"
      size="inTable"
      type="success"
      icon="fa-file"
      tool-tip-text="Select or Upload document to this cell"
      @focus="[emit('focus')]"
      @click="openModal" />
    <slot
      v-else
      name="emptyState" />
    <CrudModal
      v-if="modalOpen"
      title="Select Document"
      @closed="modalOpen = false">
      <template #default="{ close }">
        <div
          v-if="empty"
          class="text-textColor-soft">
          No Documents or Folders has been created yet.
        </div>
        <div v-if="!empty">
          <TextInput
            v-model="searchTerm"
            set-focus
            placeholder="Search"
            text-wrapper-class="[&_*]:ring-0 [&_input:hover]:border-b [&_*]:bg-transparent [&_input:hover]:!border-b  [&_input:hover]:border-textColor [&_input]:!border-solid  [&_input:focus]:!border-textColor [&_input:active]:!border-textColor [&_input]:border-transparent [&_input]:!border-t-0 [&_input]:!border-l-0 [&_input]:!border-r-0 w-[200px] "
            left-icon="fa-search fa-regular !text-textColor" />
        </div>
        <div class="max-h-[50vh] overflow-auto">
          <VTable>
            <DocumentCellFolder
              v-for="folder in folderDocuments"
              :key="folder.id"
              v-model:open-ids="openFolderIds"
              :folder="folder"
              :search-term="searchTerm"
              :selected-document-id="modelValue"
              :document-folder="folder"
              @select-document="documentAdded($event, close)" />

            <DocumentCellDocument
              v-for="doc in sortArrayBy(
                unconnectedDocuments.filter((d) =>
                  searchTerm ? d.filename.toLowerCase().includes(searchTerm.toLowerCase().trim()) : true
                ),
                'filename'
              )"
              :key="doc.id"
              :search-term="searchTerm"
              :document="doc"
              :selected-document-id="modelValue"
              @select-document="documentAdded($event, close)" />
          </VTable>
        </div>
      </template>

      <template #footer="{ close }">
        <DocumentUploader
          class="float-right"
          :can-edit="canEdit"
          :model="model"
          :model-id="modelId"
          :max-m-b-size="maxUploadSize"
          @document-uploaded="documentAdded($event, close, true)" />
      </template>
    </CrudModal>
  </div>
</template>
