import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';

export const useRecurringModal = () => {
  const recurringModal = async (modelName: string | null, title: string, content: string) => {
    let useTitle = title;
    let useContent = content;
    if (modelName !== null) {
      useTitle = `Add ${modelName} To Event`;
      useContent = `Do you want to add this ${modelName} to all recurrences of this event, or just this one?`;
    }

    if (useTitle === null || useContent === null) {
      return 'cancel';
    }

    const { threeButtonModal } = useMultipleButtonsModal();

    const result = await threeButtonModal({
      title: useTitle,
      description: useContent,
      button: {
        text: 'Update',
        type: 'success',
      },
      options: [
        {
          value: 'first',
          label: 'Just This One',
        },
        {
          value: 'second',
          label: 'All Recurrences of this event',
        },
      ],
    });

    switch (result) {
      case 'first': {
        return 'justOne';
      }
      case 'second': {
        return 'all';
      }
      case 'cancel':
      default: {
        return 'cancel';
      }
    }
  };
  return { recurringModal };
};
