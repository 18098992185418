<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import Sortable from 'sortablejs';
import { useToast } from 'vue-toastification';
import VButton from '@/components/Inputs/VButton.vue';
import ContentHeader from '@/components/Content/ContentHeader.vue';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

export type TableHeader = {
  key: string;
  label: string;
  sortable?: boolean;
  html?: string;
  labelPosition?: 'left' | 'right' | 'center';
  class?: string;
};

export type TableCell = {
  id: string | number;
  value: string | number | null;
  component?: any;
  componentProps?: {
    [key: string]: any;
  };
  canSlot?: boolean;
  class?: string;
};

export type TableRow = {
  id: string | number;
  canDrag?: boolean;
  canEdit?: boolean;
  columns: TableCell[];
  canToggle?: boolean;
  extraData?: {
    [key: string]: any;
  };
};

export type BlurData = {
  value: any;
  columnId: string | number;
  rowId: string | number;
};

export type SortEmit = {
  selectedItem: string;
  newOrder: string[];
};

type Props = {
  headers?: TableHeader[] | null;
  rows?: TableRow[] | null;
  canEdit?: boolean;
  withEdit?: boolean;
  draggable?: boolean;
  tinyRows?: boolean;
  toggleable?: boolean;
  pageHeaderTitle?: string;
  stickyPageHeader?: boolean;
  stickyTableHeader?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  headers: () => [],
  rows: () => [],
  withEdit: false,
  canEdit: false,
  draggable: false,
  toggleable: false,
  tinyRows: false,
  stickyPageHeader: false,
  stickyTableHeader: true,
  pageHeaderTitle: undefined,
});

const emit = defineEmits<{
  blur: [data: BlurData];
  edit: [row: TableRow];
  sorted: [data: SortEmit];
}>();

defineSlots<{
  [key: string]: (props: { data: TableHeader | TableCell; save?: () => {}; row?: TableRow }) => any;
  last_row: (props: any) => any;
  after_rows: (props: any) => any;
  extra_row: (props: { data: TableRow }) => any;
}>();

const body = ref<HTMLElement | null>(null);

let sortableInstance: Sortable | null = null;

onMounted(() => {
  if (body.value) {
    sortableInstance = new Sortable(body.value, {
      animation: 150,
      handle: '.fa-up-down',
      disabled: props.rows.length <= 1,
      onEnd(event) {
        const rowId = event.item.dataset.id;

        if (!rowId) {
          useToast().error('Something went wrong, please try again.');
          return;
        }

        const newOrder = sortableInstance?.toArray() || [];

        emit('sorted', { selectedItem: rowId, newOrder: newOrder });
      },
    });
  }
});

watch(
  () => props.rows.length,
  (newValue) => {
    if (sortableInstance) {
      sortableInstance.option('disabled', newValue <= 1);
    }
  }
);

const labelPositionClass = (header: TableHeader) => {
  if (!header?.labelPosition) return 'text-left';

  switch (header.labelPosition) {
    case 'left':
      return 'text-left';
    case 'right':
      return 'text-right';
    case 'center':
      return 'text-center';
    default:
      return 'text-left';
  }
};

const headerById = (id: string | number) => {
  return props.headers.find((header) => header.key === id);
};

const onInputBlur = (data: any, columnId: string | number, rowId: string | number) => {
  emit('blur', { value: data, columnId: columnId, rowId: rowId });
};

const onClickEdit = (row: TableRow) => {
  if (!props.canEdit || !row?.canEdit) return;
  emit('edit', row);
};

const openRowIds = ref(new Set());
</script>

<template>
  <div class="relative">
    <div
      v-if="pageHeaderTitle"
      class="top-0 bg-backgroundColor z-200"
      :class="{ 'sticky': stickyPageHeader }">
      <ContentHeader
        :loading="false"
        :title="'title'"
        :is-page-header="false"
        :actions="[]"
        :actions-as-buttons="true"
        :with-back-button="false">
        <template #afterTitle>
          <slot name="afterTitle" />
        </template>
        <template #underTitle>
          <slot name="underTitle" />
        </template>
      </ContentHeader>
    </div>

    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left h-[1px] relative">
        <thead
          v-if="headers && headers.length > 0"
          class="text-m">
          <tr class="sticky top-0">
            <th
              v-if="toggleable"
              class="py-table-cell-y" />
            <th
              v-if="draggable"
              class="py-table-cell-y">
              <i class="fa fa-fw fa-arrows-alt invisible" />
            </th>
            <th
              v-for="header in headers"
              :key="header.key"
              scope="col"
              :class="[labelPositionClass(header), header.class]"
              class="px-table-cell-x py-table-cell-y text-xs whitespace-nowrap">
              <slot
                :name="`header_${header.key}`"
                :data="header">
                <div
                  v-if="header.html"
                  class="[&>div]:text-xs"
                  v-html="safeHtmlStringify(header.html)" />
                <span v-else>
                  {{ header.label }}
                </span>
              </slot>
            </th>
            <th
              v-if="withEdit"
              class="py-table-cell-y">
              <i class="fa fa-fw fa-edit invisible" />
            </th>
          </tr>
        </thead>
        <tbody ref="body">
          <template
            v-for="row in rows"
            :key="row.id">
            <tr
              :data-id="row.id"
              class="bg-row border-b odd:bg-row-alternate hover:bg-row-hover group">
              <th
                v-if="toggleable && row.canToggle"
                class="py-table-cell-y w-[30px]"
                @click="openRowIds.has(row.id) ? openRowIds.delete(row.id) : openRowIds.add(row.id)">
                <div class="flex justify-center">
                  <i
                    class="fa fa-fw fa-chevron-up cursor-pointer transition transform"
                    :class="{ 'rotate-180': openRowIds.has(row.id) }" />
                </div>
              </th>
              <th
                v-if="draggable && row.canDrag"
                class="py-table-cell-y w-[30px]">
                <div class="flex justify-center">
                  <i
                    class="fa fa-fw fa-up-down cursor-grab invisible"
                    :class="{ 'group-hover:visible': canEdit && row.canDrag && rows.length > 1 }" />
                </div>
              </th>
              <th
                v-for="column in row.columns"
                :key="column.id"
                scope="row"
                :class="[labelPositionClass(headerById(column.id)), column.class]"
                class="px-table-cell-x py-table-cell-y text-base whitespace-nowrap h-[40px]">
                <slot
                  :name="column.canSlot ? `cell_${column.id}` : 'not_in_use'"
                  :save="onInputBlur"
                  :row="row"
                  :data="column">
                  <div
                    v-if="column.component"
                    :class="column.class">
                    <Component
                      :is="column.component"
                      v-bind="column.componentProps"
                      v-model="column.value"
                      :can-edit="canEdit"
                      @blur="onInputBlur($event, column.id, row.id)" />
                  </div>
                  <span v-else>
                    {{ column.value }}
                  </span>
                </slot>
              </th>
              <th
                v-if="withEdit"
                :class="{ 'group-hover:[&_i]:visible': canEdit && row.canEdit }"
                class="py-table-cell-y px-table-cell-x w-[30px]">
                <div class="flex justify-end">
                  <VButton
                    size="inTable"
                    icon="fa-pencil invisible"
                    @click="onClickEdit(row)"></VButton>
                </div>
              </th>
            </tr>
            <tr
              v-if="row.canToggle && openRowIds.has(row.id)"
              class="h-full">
              <slot
                :name="`extra_row`"
                :data="row">
                <td colspan="4">Hallo World</td>
              </slot>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <slot name="after_rows">
            <tr
              v-if="$slots.last_row"
              class="bg-row border-b odd:bg-row-alternate hover:bg-row-hover">
              <slot name="last_row" />
            </tr>
          </slot>
        </tfoot>
      </table>
    </div>
  </div>
</template>
