import { EventRequestResource } from '@/types/event-request';

export const getEventRequests = async (
  model: 'Group',
  modelId: number,
  params: {
    type: string;
    start: string | null;
    end: string | null;
  }
) => {
  const url = `/api/event-requests`;
  return axios.get<
    {
      id: number;
      created_at: string;
      start: string | null;
      end: string | null;
      project_leader_id: number | null;
      event_request_template_id: number | null;
      event_name: string;
      accepted_at: string | null;
      declined_at: string | null;
      responded_by_id: number | null;
    }[]
  >(url, {
    params: {
      model_type: 'App\\' + model,
      model_id: modelId,
      ...params,
    },
  });
};

export const getEventRequest = async (id: number) => {
  const url = `/api/event-requests/${id}`;
  return axios.get<EventRequestResource>(url);
};
