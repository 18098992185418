import { ref } from 'vue';
import { tryOnMounted } from '@vueuse/shared';
import { useEventListener } from '@vueuse/core';

export const useSmallScreen = () => {
  const isSmallScreen = ref(window.innerWidth < 768);

  const update = () => {
    if (window) {
      isSmallScreen.value = window.innerWidth < 768;
    }
  };

  update();
  tryOnMounted(update);
  useEventListener('resize', update, { passive: true });
  useEventListener('orientationchange', update, { passive: true });

  return { isSmallScreen };
};
