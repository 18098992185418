<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { useDebounceFn, useInfiniteScroll } from '@vueuse/core';
import TextInput from '@/components/Inputs/TextInput.vue';
import HoverBox from '@/components/HoverBox.vue';
import { exchangeValuesOfObject, getKey, sortArrayBy } from '@/util/globals';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  modelValue?: string | number | object | null;
  url: string;
  isObject?: boolean;
  searchParam?: string;
  params?: object | null;
  canEdit?: boolean;
  canCreate?: boolean | ((option: any) => boolean);
  minSearchLength?: number;
  label?: string | null;
  optionKey?: string;
  optionLabel?: string;
  debounceTime?: number;
  placeholder?: string;
  additionalSearchString?: string;
  withClear?: boolean;
  autofocus?: boolean;
  emitBeforeSelect?: boolean;
  additionalOptions?: any[];
  assignToSearchObjects?: object | null;
  searchOnOpen?: boolean;
  sortByKey?: string;
  overrideWidth?: number | null;
  createString?: string;
  forceSearchOutside?: number;
  nothingFoundText?: string | null;
  infiniteScroll?: boolean;
  addY?: number;
  clearOnSelect?: boolean;
  alwaysShowClear?: boolean;
  disabledIds?: number[];
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  isObject: false,
  searchParam: 'q',
  params: null,
  canEdit: true,
  canCreate: false,
  minSearchLength: 1,
  label: null,
  optionKey: 'id',
  optionLabel: 'name',
  nothingFoundText: 'No Options found',
  debounceTime: 200,
  placeholder: 'search',
  additionalSearchString: '',
  withClear: true,
  autofocus: false,
  emitBeforeSelect: false,
  additionalOptions: () => [],
  assignToSearchObjects: null,
  searchOnOpen: false,
  infiniteScroll: false,
  alwaysShowClear: false,
  sortByKey: 'name',
  overrideWidth: null,
  createString: 'Create',
  forceSearchOutside: 0,
  addY: 0,
  clearOnSelect: false,
  disabledIds: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', option: any): void;
  (event: 'create', option: any): void;
  (event: 'clear'): void;
}>();

const searchElement = ref<HTMLDivElement | null>(null);
const searchString = ref(props.isObject && props.modelValue ? props.modelValue[props.optionLabel] : '');
const xPos = ref(0);
const yPos = ref(0);
const width = ref(100);
const dropDownOpen = ref(false);
const options = ref([]);
const loading = ref(false);
const hasSearched = ref(false);

const target = ref(null);
const currentPage = ref(1);
const lastPage = ref(1);
const total = ref(0);

const openDropDrown = async () => {
  if (dropDownOpen.value) return;
  const searchBox = searchElement.value?.getBoundingClientRect();
  if (!searchBox) return;

  width.value = searchBox.width;
  xPos.value = searchBox.x;
  yPos.value = searchBox.bottom;
  await nextTick();

  dropDownOpen.value = true;
};

const searchForItems = async (page = 1) => {
  if (!searchString.value && !props.searchOnOpen) {
    options.value = [];
    await openDropDrown();
    return;
  }
  let params = { ...props.params };
  if (props.infiniteScroll) {
    currentPage.value = page;
    params.page = page;
  }
  if (page === 1) {
    options.value = [];
  }
  loading.value = true;
  params[props.searchParam] = `${searchString.value} ${props.additionalSearchString}`;
  const { data } = await axios.get(props.url, { params });
  if (!props.infiniteScroll) options.value = [];

  data.data
    .map((d) => (props.assignToSearchObjects !== null ? Object.assign(d, props.assignToSearchObjects) : d))
    .forEach((d) => {
      options.value = exchangeValuesOfObject(d, options.value);
    });

  loading.value = false;
  hasSearched.value = true;

  lastPage.value = props.infiniteScroll ? getKey(getKey(data, 'meta'), 'last_page', 1) : 1;
  total.value = props.infiniteScroll ? getKey(getKey(data, 'meta'), 'total', 0) : 0;

  // dropDownOpen.value = false;
  await nextTick();
  await openDropDrown();
};

const debouncedFn = useDebounceFn(async () => {
  await searchForItems();
}, props.debounceTime);

const searchForOptions = async () => {
  loading.value = true;
  await debouncedFn();
  loading.value = false;
};

const createElement = () => {
  dropDownOpen.value = false;
  emit('create', searchString.value);
};

const onOptionClick = async (option) => {
  if (props.disabledIds.includes(option[props.optionKey])) return;

  searchString.value = option[props.optionLabel];
  switch (typeof props.modelValue) {
    case 'object': {
      emit('update:modelValue', option);
      break;
    }
    case 'number': {
      emit('update:modelValue', Number(option[props.optionKey]));
      break;
    }
    default: {
      emit('update:modelValue', String(option[props.optionKey]));
    }
  }
  dropDownOpen.value = false;
  if (props.clearOnSelect) {
    await nextTick();
    searchString.value = '';
  }
};

let ticking = false;

const update = () => {
  ticking = false;

  width.value = searchElement.value?.getBoundingClientRect().width;
  xPos.value = searchElement.value?.getBoundingClientRect().x;
  yPos.value = searchElement.value?.getBoundingClientRect().bottom;
};

const requestTick = () => {
  if (!ticking) {
    requestAnimationFrame(update);
  }
  ticking = true;
};

const onScroll = () => {
  requestTick();
};

window.addEventListener('scroll', onScroll, true);

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

const onKeyUp = () => {
  searchForOptions();
  // openDropDrown();
};

const allowEdit = computed(() => {
  if (props.isObject && props.modelValue) {
    return !props.modelValue[props.optionKey] && props.canEdit;
  }
  return props.canEdit;
});

const onClear = (name) => {
  emit('clear');
  emit('update:modelValue', {
    [props.optionKey]: null,
    [props.optionLabel]: null,
  });
};

const concatOptions = computed(() => {
  return sortArrayBy(
    options.value.concat(
      props.additionalOptions.filter((o) => {
        return searchString.value?.length >= 1
          ? o[props.sortByKey].toLowerCase().includes(searchString.value.toLowerCase())
          : false;
      })
    ),
    [props.sortByKey]
  );
});

if (props.searchOnOpen) {
  nextTick(async () => {
    await searchForItems();
    // openDropDrown();
  });
}
watch(
  () => props.forceSearchOutside,
  () => {
    searchForItems();
    // openDropDrown();
  }
);
useInfiniteScroll(
  target,
  async () => {
    if (!props.infiniteScroll) return;
    if (currentPage.value < lastPage.value) {
      await searchForItems(currentPage.value + 1);
    }
  },
  { distance: 10 }
);
</script>

<template>
  <div ref="searchElement">
    <div
      :style="infiniteScroll ? ' grid-template-columns: auto 50px;' : ''"
      :class="{ ' grid items-center ': infiniteScroll }">
      <TextInput
        v-model="searchString"
        :style="overrideWidth ? 'width: ' + overrideWidth + 'px' : ''"
        :with-clear="!!searchString?.length"
        :always-show-clear="canEdit || alwaysShowClear"
        :placeholder="placeholder"
        :can-edit="allowEdit"
        :left-icon="loading ? 'fa-circle-o-notch fa-spin' : 'fa-search'"
        :set-focus="autofocus"
        :label="label"
        @keydown="hasSearched = false"
        @clear="onClear"
        @keyup="onKeyUp" />
      <div
        v-if="infiniteScroll"
        class="w-full text-center text-sm text-textColor-soft">
        {{ options.length }} <br />
        of {{ total }}
      </div>
    </div>
    <HoverBox
      v-if="dropDownOpen"
      :x-pos="xPos"
      :y-pos="yPos"
      :add-y="addY"
      :event-bounds="searchElement?.getBoundingClientRect()"
      @closed="dropDownOpen = false">
      <div
        id="dropdown-search"
        class="flex flex-col divide-y rounded-bl rounded-br border-b border-l border-r bg-backgroundColor-content"
        :style="`width:${width}px;`">
        <div
          ref="target"
          :style="`max-height: ${400}px`"
          class="overflow-auto">
          <div class="flex flex-col divide-y">
            <div
              v-for="option in concatOptions"
              :key="option[optionKey]"
              :class="[
                { 'bg-disabled': disabledIds?.includes(option[optionKey]) },
                disabledIds?.includes(option[optionKey])
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer hover:bg-backgroundColor',
              ]"
              class="h-[40px] p-2"
              @click="onOptionClick(option, $event)">
              <slot :option="option">
                {{ option[optionLabel] }}
              </slot>
            </div>
          </div>
        </div>

        <div
          v-if="!concatOptions.length && searchString?.length < minSearchLength"
          class="h-[40px] p-2">
          Type at least {{ minSearchLength }} to search
        </div>
        <div
          v-if="!concatOptions.length && searchString?.length >= minSearchLength && hasSearched"
          class="h-[40px] p-2">
          {{ nothingFoundText }}
        </div>
        <div
          v-if="canCreate && !loading && searchString && searchString.length > 0 && dropDownOpen && hasSearched"
          class="h-[40px] cursor-pointer p-2 text-center hover:bg-backgroundColor"
          @click="createElement">
          <VButton
            size="extra-small"
            type="success"
            :title="createString + ' ' + searchString"
            @click="createElement" />
        </div>
      </div>
    </HoverBox>
  </div>
</template>
