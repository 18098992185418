import { venueRoomsKey } from '@/provide/keys';
import { useVenueRoomsStore } from '@/store/VenueRoomStore';
import type { VenueRoomResource } from '@/types/venue';
import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import type { VenueRoomsComposable } from '@/provide/keys';

export const useVenueRooms = (venueId: number | null, groupId: number, shouldProvide = true): VenueRoomsComposable => {
  const { list, loading } = storeToRefs(useVenueRoomsStore());
  const { fetchVenueRooms } = useVenueRoomsStore();

  const venueRooms = computed(() => {
    if (venueId == null) return [];
    return list.value.get(venueId) || [];
  });

  const fetch = async (force = false) => {
    if (venueId == null) return;

    if (force || !list.value.has(venueId)) {
      await fetchVenueRooms(venueId, groupId);
    }
  };

  const addItem = (room: VenueRoomResource) => {
    if (venueId == null) {
      throw new Error('You must have a venue to add a room');
    }

    const current = list.value.get(venueId) || [];
    list.value.set(venueId, [...current, room]);
  };

  const removeItem = (roomId: number) => {
    if (venueId == null) {
      throw new Error('You must have a venue to remove a room');
    }

    const current = list.value.get(venueId) || [];
    list.value.set(
      venueId,
      current.filter((item) => item.id !== roomId)
    );
  };

  const updateItem = (room: VenueRoomResource) => {
    if (venueId == null) {
      throw new Error('You must have a venue to update a room');
    }

    const current = list.value.get(venueId) || [];
    list.value.set(
      venueId,
      current.map((item) => (item.id === room.id ? room : item))
    );
  };

  if (venueId !== null && shouldProvide) {
    provide(venueRoomsKey, {
      venueRooms,
      fetch,
      addItem,
      removeItem,
      updateItem,
      loading: readonly(loading),
    });
  }

  return {
    venueRooms,
    loading,
    fetch,
    addItem,
    removeItem,
    updateItem,
  };
};
