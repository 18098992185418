import { ModelType } from '@/types/global';
import { ShiftTypeResource } from '@/types/shifts';
import { formatModel } from '@/util/store-helpers';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useShiftTypeStore = defineStore('shift-type', () => {
  const list = ref<Map<string, ShiftTypeResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: ModelType, modelId: number) => {
    Echo.join(`On.${model}.${modelId}`)
      .listen('.shiftType.created', async () => {
        await fetchShiftTypes(model, modelId);
      })
      .listen('.shiftType.updated', async () => {
        await fetchShiftTypes(model, modelId);
      })
      .listen('.shiftType.deleted', async () => {
        await fetchShiftTypes(model, modelId);
      });
  };

  const fetchShiftTypes = async (model: ModelType, modelId: number) => {
    loading.value = true;

    const { data } = await axios.get('/api/shift-types', {
      params: {
        model_type: `App\\${model}`,
        model_id: modelId,
      },
    });

    if (!list.value.has(formatModel(model, modelId))) {
      listenForBroadcast(model, modelId);
    }

    list.value.set(formatModel(model, modelId), data);
    loading.value = false;
  };

  return {
    list,
    loading,
    fetchShiftTypes,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShiftTypeStore, import.meta.hot));
}
