/* eslint-disable no-use-before-define */
import { ModelType } from '@/types/global';
import { formatModel } from '@/util/store-helpers';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useTimeSlotsStore = defineStore('time-slots', () => {
  const list = ref<Map<string, any[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (modelType: ModelType, modelId: number) => {
    Echo.join(`On.${modelType}.${modelId}`)
      .listen('.timeSlot.created', async () => {
        await fetchTimeSlots(modelType, modelId);
      })
      .listen('.timeSlot.updated', async () => {
        await fetchTimeSlots(modelType, modelId);
      })
      .listen('.timeSlot.deleted', async () => {
        await fetchTimeSlots(modelType, modelId);
      });
  };

  const fetchTimeSlots = async (modelType: ModelType, modelId: number) => {
    if (modelType.includes('App\\')) throw new Error('modelType should not include App\\');

    if (!list.value.has(formatModel(modelType, modelId))) {
      listenForBroadcast(modelType, modelId);
    }

    loading.value = true;
    const { data } = await axios.get('/api/time-slots', {
      params: {
        model_type: `App\\${modelType}`,
        model_id: modelId,
      },
    });
    list.value.set(formatModel(modelType, modelId), data);
    loading.value = false;
  };

  return {
    list,
    loading,
    fetchTimeSlots,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTimeSlotsStore, import.meta.hot));
}
