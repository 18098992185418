import { useLoggedInModal } from '@/composables/modals/use-still-logged-in-modal';

export const checkIfStillLoggedIn = async () => {
  const showModal = () => {
    return useLoggedInModal().assertLoggedIn();
  };

  const time = 1000 * 60 * 10;
  const checkStatus = async () => {
    try {
      await axios.post('/api/stillLoggedIn');
    } catch (e) {
      clearInterval(interval);
      await showModal();
      window.location.reload();
    }
  };
  const interval = setInterval(checkStatus, time);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.data?.message === 'Unauthenticated.') {
        showModal().then(() => {
          window.location.reload();
        });
      }
      return Promise.reject(error);
    }
  );
};
