<script setup lang="ts">
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';

type Props = {
  boardId: number;
  columnDefinitions: any;
  downloading: boolean;
  orderBy: string | null | number;
  orderDirection: string | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'update:downloading', arg: boolean): void;
}>();

const toast = useToast();

const asCsv = ref(false);
const downloadableColumns = ref(props.columnDefinitions.map((column) => column.id));

const downloadBoard = async () => {
  if (props.downloading) return;
  emit('update:downloading', true);
  try {
    const { data } = await axios.post(`/api/boards/${props.boardId}/download`, {
      asCsv: asCsv.value,
      downloadableColumns: downloadableColumns.value,
      orderBy: props.orderBy,
      orderDirection: props.orderDirection,
    });
    await downloadFile(data.url, data.name);
    emit('update:downloading', false);
  } catch (e) {
    console.error(e);
    emit('update:downloading', false);
    toast.warning('Something went wrong, please try again later');
    throw e;
  }
};
</script>

<template>
  <ReportModal
    v-model:as-c-s-v="asCsv"
    can-download
    small
    title="Download Board"
    :working="downloading"
    :with-button="false"
    @download="downloadBoard"
    @close="$emit('closed')">
    <VMultiselect
      v-if="columnDefinitions.length > 0"
      v-model="downloadableColumns"
      label="Select Columns to include"
      :options="columnDefinitions"
      can-edit
      with-add-all
      option-label="title"
      option-key="id" />
  </ReportModal>
</template>
