import { useToast } from 'vue-toastification';

export const addWordToContentOfField = (word: string, currentContent: any, field = null) => {
  let content = currentContent;
  if (content === null) {
    content = '';
  }
  if (field) {
    if (field.selectionStart > 0) {
      const selectionStart = field.selectionStart;
      const pre = content.substring(0, selectionStart);
      const post = content.substring(selectionStart);
      content = `${pre}${word}${post}`;
      content.replace('  ', ' ');
      return content;
    }
  }
  return content + word;
};

export function sanitizeValueForExchangeWords(
  exchangeWords: Array<string>,
  newValue: string | number | null,
  oldValue: string | null
) {
  let theValue = newValue;
  if (theValue && theValue.length > 0) {
    if (exchangeWords && exchangeWords.length > 0) {
      if (oldValue) {
        for (let i = 0; i < exchangeWords.length; i++) {
          if (oldValue.includes(exchangeWords[i])) {
            if (!theValue.includes(exchangeWords[i])) {
              getExchangeWarning(exchangeWords[i]);
              theValue = oldValue.replace(exchangeWords[i], '');
            }
          }
        }
      }
    }
  }
  return theValue;
}

export function getExchangeWarning(word: string | null) {
  if (!word) return;
  useToast().warning(word.replace('{{$', '').replace('}}', '') + ' string was removed, because it was changed.');
}
