<script setup lang="ts">
import { reactive, ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import UrlInput from '@/components/Inputs/UrlInput.vue';

type Props = {
  withButton: boolean;
  newVenueName: string;
  forGroupFestivalGroupId: number | null;
};

const props = withDefaults(defineProps<Props>(), {
  withButton: true,
  newVenueName: '',
  forGroupFestivalGroupId: null,
});

const emit = defineEmits<{
  (event: 'closed', ...args: object[]): void;
  (event: 'created', ...args: object[]): void;
}>();

const showModal = ref(!props.withButton);

const venue = reactive({
  name: props.newVenueName,
  description: null,
  address: null,
  city: null,
  country: null,
  website: null,
  phone: null,
});

const phoneCode = ref('+47');
const phoneNumber = ref('');

const closeModal = () => {
  showModal.value = false;
  emit('closed');
};

const createVenue = async () => {
  try {
    let url = '/api/venues';
    if (props.forGroupFestivalGroupId) {
      url = `/api/groups/${props.forGroupFestivalGroupId}/festival-venues`;
    }
    const phone = `${phoneCode.value}${phoneNumber.value}`;
    const { data } = await axios.post(url, { ...venue, phone });
    emit('created', data);
    useToast().success('Created Venue');
  } catch (e) {
    useToast().error('Something went wrong creating venue');
    throw e;
  }
};
</script>

<template>
  <div>
    <button
      v-if="withButton"
      class="btn-primary">
      Create new Venue
    </button>
    <CrudModal
      v-if="showModal"
      title="Create new venue"
      create-button-text="Create venue"
      @create="createVenue"
      @closed="closeModal">
      <div class="form-layout mb-2">
        <TextInput
          v-model="venue.name"
          label="Name" />

        <TextInput
          v-model="venue.description"
          label="Description" />

        <PhoneInput
          v-model:country-code="phoneCode"
          v-model:phone="phoneNumber"
          size="block" />

        <UrlInput
          v-model="venue.website"
          label="Website" />

        <TextInput
          v-model="venue.address"
          label="Address" />

        <TextInput
          v-model="venue.city"
          label="City" />

        <TextInput
          v-model="venue.postal_code"
          label="Postal code" />

        <TextInput
          v-model="venue.country"
          label="Country" />
      </div>
    </CrudModal>
  </div>
</template>
