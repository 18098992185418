<script setup lang="ts">
import DisplayBadge from '@/components/Display/DisplayBadge.vue';

type Props = {
  firstNum: number;
  secondNum: number;
  title: string;
};

const props = withDefaults(defineProps<Props>(), {
  title: null,
});

const getBackgroundColor = () => {
  if (props.firstNum >= props.secondNum) {
    return 'success';
  }
  if (props.firstNum < props.secondNum) {
    return 'pending';
  }
  return 'warning';
};
</script>

<template>
  <DisplayBadge
    v-bind="$attrs"
    class="w-[75px]"
    :color="getBackgroundColor()"
    :title="title"
    :text="firstNum + '/' + secondNum" />
</template>
