import { PerformanceModelType, PerformanceTypesResource } from '@/types/performance';
import { computed, provide } from 'vue';
import { storeToRefs } from 'pinia';
import { usePerformanceTypesStore } from '@/store/performance/PerformanceTypesStore';
import { performanceTypesKey } from '@/provide/keys';
import { formatModel } from '@/util/store-helpers';

export const usePerformanceTypes = (modelType: PerformanceModelType, modelId: number, shouldProvide = true) => {
  const { list, loading } = storeToRefs(usePerformanceTypesStore());
  const {
    fetchPerformanceTypes: fetch,
    changeOutList: changeListValue,
    addOrUpdatePerformanceTypes: addOrUpdate,
    removePerformanceType: remove,
  } = usePerformanceTypesStore();

  const performanceTypes = computed(() => list.value.get(formatModel(modelType, modelId)) ?? []);

  const fetchPerformanceTypes = async (force = false) => {
    if (force || !list.value.has(formatModel(modelType, modelId))) {
      await fetch(modelType, modelId);
    }
  };

  const addOrUpdatePerformanceTypes = async (performanceType: PerformanceTypesResource) => {
    await addOrUpdate(modelType, modelId, performanceType);
  };

  const removePerformanceType = async (performanceTypeId: number) => {
    await remove(modelType, modelId, performanceTypeId);
  };

  const changeOutList = (types: PerformanceTypesResource[]) => {
    changeListValue(modelType, modelId, types);
  };

  if (shouldProvide) {
    provide(performanceTypesKey, {
      performanceTypes,
      loading,
      changeOutList,
      fetchPerformanceTypes,
      addOrUpdatePerformanceTypes,
      removePerformanceType,
    });
  }

  return {
    performanceTypes,
    changeOutList,
    fetchPerformanceTypes,
    addOrUpdatePerformanceTypes,
    removePerformanceType,
    loading,
  };
};
