import { safeHtmlStringify } from '@/util/safe-html-stringify';

export const highlightStringBySearch = (value, searchQuery: string | null) => {
  if (!value) return value;
  if (!searchQuery) return safeHtmlStringify(value);
  const replaceArray = [];

  const process = (toBeSearched) => {
    const searchLength = searchQuery.length;
    const searchRegex = new RegExp(searchQuery, 'gi');

    const searchIndex = toBeSearched.search(searchRegex);

    if (searchIndex >= 0) {
      const currentSearchString = toBeSearched.slice(0, searchIndex + searchLength);
      const currentSubString = toBeSearched.slice(searchIndex, searchIndex + searchLength);
      replaceArray.push(
        currentSearchString.replace(
          currentSubString,
          `<span class="text-highlight outline outline-1 outline-offset-1 outline-highlight rounded">${currentSubString}</span>`
        )
      );

      process(toBeSearched.slice(searchIndex + searchLength));
    } else {
      replaceArray.push(toBeSearched);
    }
  };

  process(value);

  return safeHtmlStringify(replaceArray.join(''));
};
