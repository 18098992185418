<script setup lang="ts">
import GroupEventSectionWrapper from '@/components/Groups/partials/GroupEventSectionWrapper.vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import VButton from '@/components/Inputs/VButton.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import { nextTick, ref } from 'vue';
import { GroupResource } from '@/types/group';
import { getIndexFromArrayBasedOnId } from '@/util/globals';
import { useToast } from 'vue-toastification';
import PerformanceCreateModal from '@/components/Models/Performances/PerformanceCreateModal.vue';
import { usePerformanceTypes } from '@/composables/performance/use-performance-types';
import { usePartnerTypes } from '@/composables/use-partner-types';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';

type PerformanceInviteResource = {
  id: number | null;
  name: string;
};
type Props = {
  serialNumber?: number | null;
  canEdit?: boolean;
  onHoldEvent?: boolean;
  group: GroupResource;
  event: { name: string };
  performances: PerformanceInviteResource[];
};

const props = withDefaults(defineProps<Props>(), {
  serialNumber: null,
  canEdit: true,
  onHoldEvent: false,
});

const emit = defineEmits<{
  (event: 'update:performances', ...args: PerformanceInviteResource[]): void;
  (event: 'add-performance', ...args: object): void;
  (event: 'remove-performance', ...args: object): void;
}>();

const showPerformanceCreateModal = ref(false);
const initPerformance = ref(null);

const pageX = ref(null);
const pageY = ref(null);
const openPerformanceSearch = (event) => {
  pageX.value = null;
  pageY.value = null;
  // open.value = false;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 235;
    pageY.value = event.target.getBoundingClientRect().y + 35;
  });
};

const openPerformanceSlideOut = async (performanceName: string | null) => {
  showPerformanceCreateModal.value = false;
  initPerformance.value = { name: performanceName };
  await nextTick();
  showPerformanceCreateModal.value = true;
};

const addPerformance = (performance) => {
  const index = getIndexFromArrayBasedOnId(performance.id, props.performances);
  if (index === -1) {
    const newPerformances = [...props.performances];
    newPerformances.push({
      id: performance.id,
      name: performance.name,
    });
    emit('add-performance', performance);
    emit('update:performances', newPerformances);
    return;
  }
  useToast().warning(performance.name + ' already added.');
};
const removePerformanceAtIndex = (index) => {
  if (index > -1) {
    const newPerformances = [...props.performances];
    emit('remove-performance', newPerformances[index]);

    newPerformances.splice(index, 1);
    emit('update:performances', newPerformances);
  }
};
const { performanceTypes, fetchPerformanceTypes } = usePerformanceTypes('Group', props.group.id);
fetchPerformanceTypes();

const { partnerTypes, fetchPartnerTypes } = usePartnerTypes('Group', props.group.id, 'App\\PartnerCompany');
fetchPartnerTypes();
</script>

<template>
  <GroupEventSectionWrapper
    id="add-event-partner-contacts"
    :serial-number="serialNumber"
    :has-content="performances.length > 0"
    title="Performances"
    :actions="[
      {
        title: 'Add',
        icon: 'fa-plus',
        disabled: !canEdit,
        action: ($event) => {
          openPerformanceSearch($event);
        },
      },
    ]"
    :sub-title="onHoldEvent ? null : 'Which performances would you like to add to the Event'">
    <template #content>
      <div>
        <SearchSelectFloatingWindow
          v-if="pageX && pageY"
          url="/api/performances"
          :params="{
            model_type: 'App\\Group',
            model_id: props.group.id,
          }"
          :page-y="pageY"
          :page-x="pageX"
          :can-create="true"
          @create="openPerformanceSlideOut($event)"
          @selected="addPerformance" />
        <VTable
          edge-to-edge
          class="-mx-edge">
          <VTableRow v-for="(performance, index) in performances">
            <VTableCell main-cell>
              {{ performance.name }}
            </VTableCell>
            <VTableCell
              v-if="canEdit"
              style="width: 60px">
              <VButton
                size="inTable"
                icon="fa-trash  fa-regular"
                @click="removePerformanceAtIndex(index)">
              </VButton>
            </VTableCell>
          </VTableRow>
        </VTable>
      </div>
      <EmptyStateFullPage
        v-if="performances.length === 0"
        icon="fa-music fa-regular"
        size="tiny"
        :button-function="null"
        description="No Performances Added" />
      <PerformanceCreateModal
        v-if="showPerformanceCreateModal"
        model="Group"
        :model-id="group.id"
        :init-performance="initPerformance"
        :can-create-more="false"
        :partner-types="partnerTypes"
        :performance-types="performanceTypes"
        @created="addPerformance($event)" />
    </template>
  </GroupEventSectionWrapper>
</template>
