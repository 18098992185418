<script setup lang="ts">
import VToggle from '@/components/Inputs/VToggle.vue';
import InfoIcon from '@/components/Icons/InfoIcon.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: null,
  },
  modelValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: String,
    default: null,
    required: false,
  },
  labelTitle: {
    type: String,
    required: false,
    default: null,
  },
  canEdit: {
    type: Boolean,
    required: false,
    default: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  vertical: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(['clicked', 'update:modelValue']);

const getColStyle = () => {
  let string = 'grid-template-columns: ';
  if (!props.vertical) {
    string += ' 50px ';
  }
  string += ' 1fr ';

  if (props.title) {
    string += ' 20px ';
  }
  return `${string};`;
};
</script>

<template>
  <div
    class="grid cursor-pointer grid-cols-[1fr_50px] items-center rounded p-2 hover:bg-backgroundColor [&_*]:mb-0"
    :class="disabled ? ' opacity-50 ' : ' cursor-pointer '"
    :style="getColStyle()"
    :title="title"
    @click="canEdit && !disabled ? [emits('update:modelValue', !modelValue), emits('clicked')] : null">
    <div
      :title="labelTitle"
      class="flex items-center">
      <InputLabel
        v-if="vertical"
        class="[&_*]:font-headers [&_*]:font-semibold"
        :class="disabled ? ' ' : ' cursor-pointer '"
        :label="label" />
      <VToggle
        :model-value="modelValue"
        :label="null" />
    </div>
    <InputLabel
      v-if="!vertical"
      class="[&_*]:font-headers [&_*]:font-semibold"
      :class="disabled ? ' ' : ' cursor-pointer '"
      :label="label" />
    <InfoIcon :title="title" />
  </div>
</template>
