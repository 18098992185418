<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal';
import { ZIndexPopUpModal } from '@/variables/z-indexes';
import VButton from '@/components/Inputs/VButton.vue';
import { ref } from 'vue';
import { useEventListener } from '@vueuse/core';
import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  title: string;
  description?: string;
  buttonText: string;
  buttonType: string;
  options: {
    value: string | number;
    label: string;
  }[];
  useSelect?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  submit: [value: string | number];
  closed: [];
}>();

const setIndex = ({ index }: { index: number }) => {
  if (index === 0) return ZIndexPopUpModal;
  return ZIndexPopUpModal + 2 * index;
};

const selected = ref(props.options[0].value);

const onSubmit = () => {
  emit('submit', selected.value);
};

const createButton = ref<HTMLButtonElement | null>(null);

useEventListener('keydown', (e) => {
  if (e.key === 'Enter') {
    const focusElements = ['TEXTAREA', 'SELECT'];
    let activeElement = document.activeElement;
    if (focusElements.includes(activeElement.tagName)) return;

    const richTextField = document.getElementsByClassName('ProseMirror-focused');
    if (richTextField.length > 0) return;

    if (createButton.value?.button) {
      createButton.value?.button?.click();
    }
  }
});
</script>

<template>
  <VueFinalModal
    :content-transition="{
      'enter-active-class': 'transition duration-300 ease-in-out transform',
      'enter-from-class': 'scale-0 opacity-0',
      'enter-to-class': '',
      'leave-active-class': 'transition duration-200 ease-in-out transform',
      'leave-to-class': 'opacity-0',
      'leave-from-class': '',
    }"
    overlay-class="bg-[--color-backdrop-modal]"
    class="flex items-center justify-center"
    :z-index-fn="setIndex"
    :content-class="`bg-backgroundColor rounded border w-[400px] text-textColor max-h-full`"
    @closed="$emit('closed')">
    <div class="relative m-2 p-5">
      <div class="grid grid-cols-[auto_30px] items-center">
        <h2 class="flex-1 font-headers">
          {{ title }}
        </h2>

        <button
          class="float-right h-8 w-8 rounded-full hover:bg-backgroundColor-content"
          style="margin-right: -23px; margin-top: -10px"
          @click.stop="$emit('closed')">
          <i class="fa fa-fw fa-times" />
        </button>
      </div>

      <div>
        <div
          v-if="description"
          class="py-5">
          {{ description }}
        </div>
        <ul
          v-if="!useSelect"
          class="space-y-3 pb-5">
          <li
            v-for="option in options"
            :key="option.value"
            class="flex gap-4 items-center cursor-pointer"
            @click="selected = option.value">
            <input
              v-model="selected"
              type="radio"
              style="box-shadow: none"
              class="checked:bg-highlight checked:text-highlight focus:shadow-none focus:outline-0 focus-visible:outline-none checked:focus:ring-0 checked:focus:border-0 focus:ring-0 focus:ring-transparent focus:bg-highlight focus-visible:ring-0 focus-visible:bg-highlight checked:appearance-none"
              :value="option.value" />

            <div>
              {{ option.label }}
            </div>
          </li>
        </ul>

        <div
          v-if="useSelect"
          class="space-y-3 pb-5">
          <VSelect
            v-model="selected"
            :option-key="'value'"
            :option-value="'label'"
            :options="options" />
        </div>
      </div>

      <div class="mt-3 flex justify-between gap-2">
        <VButton
          title="Cancel"
          @click="$emit('closed')" />
        <VButton
          ref="createButton"
          :title="buttonText"
          :type="buttonType"
          @click="onSubmit" />
      </div>
    </div>
  </VueFinalModal>
</template>
