/* eslint-disable @typescript-eslint/no-use-before-define */
import type { EventRequestResponseResource } from '@/types/event-request';
import type { ModelType } from '@/types/global';
import { formatModel } from '@/util/store-helpers';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useEventRequestResponseStore = defineStore('group-event-request-responses', () => {
  const list = ref<Map<string, EventRequestResponseResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: ModelType, modelId: number) => {
    Echo.join(`On.${model}.${modelId}`)
      .listen('.eventRequestResponse.created', async () => {
        await fetchEventRequestResponses(model, modelId);
      })
      .listen('.eventRequestResponse.updated', async () => {
        await fetchEventRequestResponses(model, modelId);
      })
      .listen('.eventRequestResponse.deleted', async () => {
        await fetchEventRequestResponses(model, modelId);
      });
  };

  const fetchEventRequestResponses = async (modelType: ModelType, modelId: number) => {
    if (modelType.includes('App\\')) throw new Error('modelType should not include App\\');

    loading.value = true;
    const { data } = await axios.get('/api/event-request-responses', {
      params: {
        model_type: `App\\${modelType}`,
        model_id: modelId,
      },
    });
    if (!list.value.has(formatModel(modelType, modelId))) {
      listenForBroadcast(modelType, modelId);
    }
    list.value.set(formatModel(modelType, modelId), data);
    loading.value = false;
  };

  return {
    list,
    loading,
    fetchEventRequestResponses,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEventRequestResponseStore, import.meta.hot));
}
