<script setup lang="ts">
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';

type Props = {
  monthPicker?: boolean;
  buttonsClass?: string;
  start?: string;
  highlightDays?: array;
  earliestDate?: string;
  lastDate?: string;
  maxDate?: string | Date | undefined;
};

defineProps<Props>();

const emit = defineEmits<{
  (e: 'newDate', date: string): void;
}>();

const selectDate = (e: string, close: (item) => void) => {
  emit('newDate', e);
  close({});
};
</script>

<template>
  <VDropdown
    close-on-click
    target-classes="!border-none !bg-transparent rounded-xl">
    <template #click-area>
      <slot name="trigger">
        <button
          class="btn"
          :class="buttonsClass">
          <i class="fa fa-calendar fa-regular" />
        </button>
      </slot>
    </template>
    <template #dropdown="{ close }">
      <VDatepicker
        inline
        can-edit
        :highlight-days="highlightDays"
        :earliest-date="earliestDate"
        :start-date="start"
        :month-picker="monthPicker"
        :max-date="maxDate"
        @update:model-value="selectDate($event, close)" />
    </template>
  </VDropdown>
</template>
