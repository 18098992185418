<script setup lang="ts">
import { computed } from 'vue';
import { highlightStringBySearch } from '@/util/text-replace-helper';
import { copyToClipboard } from '@/util/copy';

type Props = {
  phoneNumber?: string | null;
  countryCode?: string | null;
  withIcon?: boolean;
  iconOnly?: boolean;
  indicateMissing?: boolean;
  iconClass?: string;
  textClass?: string;
  textColorClass?: string;
  highlightText?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  phoneNumber: null,
  countryCode: null,
  withIcon: true,
  iconOnly: false,
  indicateMissing: false,
  iconClass: 'mr-2',
  textClass: '',
  textColorClass: 'text-primary',
  highlightText: null,
});

const phone = computed(() => {
  if (!props.phoneNumber) return '';
  let tlf = '';
  if (props.phoneNumber.includes(' ')) {
    return `${props.countryCode ? props.countryCode : ''} ${props.phoneNumber}`;
  }
  const number = props.phoneNumber.split(' ').join('');
  for (let i = 0; i < number.length; i += 1) {
    if (i === 3 || i === 5) {
      tlf += ' ';
    }
    tlf += number[i];
  }
  return `${props.countryCode ? props.countryCode : ''} ${tlf}`;
});
</script>

<template>
  <div
    v-if="phoneNumber"
    :title="phone"
    class="flex items-center cursor-pointer"
    @click="copyToClipboard(phone)">
    <i
      v-if="withIcon"
      class="mt-2"
      :class="['fa fa-fw fa-phone fa-regular text-sm ', iconClass]" />
    <a
      v-if="!iconOnly"
      :href="`tel:${phone}`"
      :class="textClass + ' ' + textColorClass"
      class="cut-overflowing-text truncate no-underline hover:underline"
      @click="$event.stopPropagation()"
      v-html="highlightStringBySearch(phone, highlightText)">
    </a>
  </div>
  <div
    v-if="!phoneNumber && indicateMissing"
    class="display display-phone">
    <i
      title="No Phone Given"
      class="fa fa-phone fa-fw opacity-50" />
  </div>
</template>
