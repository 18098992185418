import type { ModelType } from '@/types/globals';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import {
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getKey,
  reorderArrayByIds,
  sortArrayBy,
} from '@/util/globals';
import { copyObject } from '@/util/object-helpers';
import { TaskFieldResource } from '@/types/tasks';
import { formatAppModel } from '@/util/format-app-model';

export const useTaskFieldsStore = defineStore('task-fields', () => {
  const list = ref(new Map());
  const loading = ref(false);

  const modelsWithFields = ['Group', 'Festival', 'FestivalSection'];

  const getForModel = (modelType: ModelType | null, modelId: number | null, force = false) => {
    if (!modelType) return;
    if (!modelsWithFields.includes(modelType)) return;

    if (!list.value.has(formatModel(modelType, modelId))) {
      listenForBroadcast(modelType, modelId);
    }
    if (force || !list.value.has(formatModel(modelType, modelId))) {
      getData(modelType, modelId);
    }
    return list.value.get(formatModel(modelType, modelId));
  };

  const listenForBroadcast = (modelType: ModelType, modelId: number) => {
    if (modelType === 'FestivalSection') return;
    Echo.join(`On.${modelType}.${modelId}`)
      .listen('.taskField.created', async (field: TaskFieldResource) => {
        await addOrUpdate(modelType, modelId, field);
      })
      .listen('.taskField.updated', async (field: TaskFieldResource) => {
        await addOrUpdate(modelType, modelId, field);
      })
      .listen('.taskField.deleted', async (field: { id: number }) => {
        await removeById(modelType, modelId, getKey(field, 'id', null), false);
      });
  };

  const getData = async (modelType: ModelType, modelId: number) => {
    if (!modelsWithFields.includes(modelType)) return;
    loading.value = true;
    const { data } = await axios.get('/api/task-fields', {
      params: {
        model_type: formatAppModel(modelType),
        model_id: modelId,
      },
    });

    list.value.set(formatModel(modelType, modelId), data);
    loading.value = false;
  };

  const addOrUpdate = async (modelType: ModelType, modelId: number, field: TaskFieldResource) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await getForModel(modelType, modelId);
      return;
    }
    let data = copyObject(list.value.get(formatModel(modelType, modelId)));
    data = exchangeValuesOfObject(field, data);
    data = sortArrayBy(data, 'order');
    list.value.set(formatModel(modelType, modelId), data);
  };

  const removeById = async (modelType: ModelType, modelId: number, fieldId: number, fetchIfCantFind = true) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await getForModel(modelType, modelId);
      return;
    }
    const data = list.value.get(formatModel(modelType, modelId));
    const index = getIndexFromArrayBasedOnId(fieldId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(formatModel(modelType, modelId), data);
    } else if (fetchIfCantFind) {
      await getForModel(modelType, modelId);
    }
  };

  const reorderFieldsByIds = async (modelType: ModelType, modelId: number, arrayOfIds: string | number[]) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await getForModel(modelType, modelId);
      return;
    }
    const data = list.value.get(formatModel(modelType, modelId));
    if (data) {
      const newList = reorderArrayByIds(data, arrayOfIds);
      list.value.set(formatModel(modelType, modelId), newList);
    } else {
      await getForModel(modelType, modelId);
    }
  };

  return {
    list,
    loading,
    getForModel,
    removeById,
    addOrUpdate,
    reorderFieldsByIds,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaskFieldsStore, import.meta.hot));
}
