import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { TimelineTemplateResource } from '@/types/timeline-template';

export const timelineStore = defineStore('timeline-store', () => {
  const list = ref<Map<number, TimelineTemplateResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (groupId: number) => {
    Echo.join(`On.Group.${groupId}`)
      .listen(`.timelineTemplate.created`, async () => {
        await fetchTimelineTemplates(groupId, true);
      })
      .listen(`.timelineTemplate.updated`, async () => {
        await fetchTimelineTemplates(groupId, true);
      })
      .listen(`.timelineTemplate.deleted`, async () => {
        await fetchTimelineTemplates(groupId, true);
      });
  };

  const fetchTimelineTemplates = async (groupId: number, force: boolean = false) => {
    if (!force && list.value.has(groupId)) return list.value.get(groupId);

    loading.value = true;

    const { data } = await axios.get('/api/timeline-templates', {
      params: {
        model_type: `App\\Group`,
        model_id: groupId,
      },
    });

    if (!list.value.has(groupId)) {
      listenForBroadcast(groupId);
    }

    list.value.set(groupId, data);

    loading.value = false;

    return data;
  };

  const cleanUp = () => {
    // list.value.forEach((_, key) => {
    //   if (key.includes('Event_')) {
    //     list.value.delete(key);
    //   }
    // });
  };

  return {
    list,
    loading,
    cleanUp,
    fetchTimelineTemplates,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(timelineStore, import.meta.hot));
}
