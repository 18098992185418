<script setup lang="ts">
import { ref } from 'vue';

import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  modelValue: string | null;
  canEdit: boolean;
  options: string[] | null;
};

defineProps<Props>();

defineEmits<{
  (event: 'update:modelValue', arg: string): void;
}>();

const edit = ref(false);
</script>

<template>
  <div
    v-if="canEdit"
    @click="edit = true">
    <VSelect
      v-if="canEdit"
      :model-value="modelValue"
      :options="options ?? []"
      nullable-display-text=""
      nullable
      is-hidden
      class="[&_.rounded-md]:rounded-none"
      input-area-class="hover:ring-1 hover:ring-textColor-soft"
      @dropdown-closed="edit = false"
      @update:model-value="$emit('update:modelValue', $event)" />
  </div>
  <div v-else>
    <div v-if="modelValue">
      {{ modelValue }}
    </div>
    <slot
      v-else
      name="emptyState" />
  </div>
</template>
