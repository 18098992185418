<script lang="ts" setup>
import { TaskResource } from '@/types/tasks';
import { eventMembersKey, festivalSectionsKey, groupKey, groupResourceKey, rolesKey } from '@/provide/keys';
import { computed, inject, nextTick, ref } from 'vue';
import { getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { patchTask } from '@/services/api-tasks';
import TextInput from '@/components/Inputs/TextInput.vue';
import { formatEventInvitesAsGroupsForEntityOnTask } from '@/util/task-functions';

type Props = {
  task: TaskResource;
  lockedKeys?: string[];
  inList?: boolean;
  isCreate?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  lockedKeys: () => [],
  inList: true,
  isCreate: false,
});
const emit = defineEmits<{
  (e: 'updated', value: TaskResource): void;
  (e: 'change', value: number | null): void;
}>();

const allRoles = inject(rolesKey, null);
const allFestivalSections = inject(festivalSectionsKey, null);
const eventMembers = inject(eventMembersKey, null);

const allAssignableRoles = ref([]);
const allAssignableFestivalSections = ref([]);
const allAssignableGroups = ref([]);
const group = inject(groupKey, null);
const groupResource = inject(groupResourceKey, null);

const selectContext = async (contextType: string | null, contextId: number | null, name: string | null) => {
  if (props.task.context_type === contextType && props.task.context_id === contextId) return;
  const localTask = { ...props.task };
  localTask.context_type = contextType;
  localTask.context_id = contextId;
  localTask.assigned_user_id = null;
  localTask.assigned_user = null;
  localTask.context = contextId && name ? { id: contextId, name: name } : null;
  if (!props.isCreate) {
    await patchTask(props.task.uuid, {
      context_type: localTask.context_type,
      context_id: localTask.context_id,
      assigned_user_id: null,
    });
  }

  emit('updated', localTask);
  await nextTick();
  emit('change', contextId);
};

const loadingContent = ref(false);
const searchTerm = ref('');

const getContextsFromStore = async () => {
  if (!allRoles || !allFestivalSections) return;
  loadingContent.value = true;
  await allRoles.fetchRoles(false);
  allAssignableRoles.value = allRoles.roles.value;
  await allFestivalSections.fetchSections(false);
  allAssignableFestivalSections.value = allFestivalSections.festivalSections.value;
  loadingContent.value = false;
};

const fetchContextsForTask = async () => {
  loadingContent.value = true;
  const { data } = await axios.get(`/api/tasks/${props.task.uuid}/assignable-contexts`);
  allAssignableRoles.value = data.filter((i) => i.type === 'App\\Role');
  allAssignableFestivalSections.value = data.filter((i) => i.type === 'App\\Models\\Festivals\\FestivalSection');
  allAssignableGroups.value = data.filter((i) => i.type === 'App\\Group');
  loadingContent.value = false;
};

const getGroupsFromEventStore = async () => {
  if (!eventMembers) return;
  loadingContent.value = true;
  await eventMembers.fetch(false);
  allAssignableGroups.value = formatEventInvitesAsGroupsForEntityOnTask(eventMembers.invites.value);
  loadingContent.value = false;
};

const getGroupsFromGroupInject = async () => {
  if (group) {
    loadingContent.value = true;
    allAssignableGroups.value = [
      {
        id: group.id,
        name: group.name,
        type: 'App\\Group',
      },
    ].concat(
      group.childGroups.map((child) => {
        return {
          id: child.id,
          name: child.name,
          type: 'App\\Group',
        };
      })
    );
    loadingContent.value = false;
  } else if (groupResource) {
    loadingContent.value = true;
    await groupResource.fetch();
    allAssignableGroups.value = [
      {
        id: groupResource.group.value?.id,
        name: groupResource.group.value?.name,
        type: 'App\\Group',
      },
    ].concat(
      groupResource.group.value?.children.map((child) => {
        return {
          id: child.id,
          name: child.name,
          type: 'App\\Group',
        };
      })
    );
    loadingContent.value = false;
  }
};

const itemClicked = async () => {
  searchTerm.value = '';
  if (props.isCreate) {
    if (eventMembers) {
      getGroupsFromEventStore();
    } else if ((group || groupResource) && props.task.event_id === null) {
      getGroupsFromGroupInject();
    } else if (props.task.event_id === null) {
      getContextsFromStore();
    }
    return;
  }
  if (eventMembers) {
    getGroupsFromEventStore();
  } else if ((group || groupResource) && props.task.event_id === null) {
    getGroupsFromGroupInject();
  } else if (allRoles && allFestivalSections && props.task.event_id === null) {
    getContextsFromStore();
  } else {
    fetchContextsForTask();
  }
  await nextTick();
};

const getOptionsForList = () => {
  let array = [
    {
      color: null,
      postIcon: props.task.context_id === null ? 'fa-check' : null,
      title: '--',
      action: (close) => {
        selectContext(null, null, null);
        close();
      },
    },
  ];
  const filteredGroups = allAssignableGroups.value.filter((f) =>
    searchTerm.value.length === 0 ? true : f.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
  for (let i = 0; i < filteredGroups.length; i++) {
    array.push({
      title: filteredGroups[i].name,
      postIcon:
        props.task.context_id === filteredGroups[i].id && props.task.context_type === filteredGroups[i].type
          ? 'fa-check'
          : null,
      action: (close) => {
        selectContext(filteredGroups[i].type, filteredGroups[i].id, filteredGroups[i].name);
        close();
      },
    });
  }

  const filteredRoles = allAssignableRoles.value.filter((f) =>
    searchTerm.value.length === 0 ? true : f.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
  if (filteredRoles.length > 0) {
    if (!props.task.event_id) {
      array.push({
        type: 'header',
        title: 'Roles',
      });
    }
    for (let i = 0; i < filteredRoles.length; i++) {
      array.push({
        title: filteredRoles[i].name,
        postIcon:
          props.task.context_id === filteredRoles[i].id && props.task.context_type === 'App\\Role' ? 'fa-check' : null,
        action: (close) => {
          selectContext('App\\Role', filteredRoles[i].id, filteredRoles[i].name);
          close();
        },
      });
    }
  }

  const filteredFestivalSection = allAssignableFestivalSections.value.filter((f) =>
    searchTerm.value.length === 0 ? true : f.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
  if (filteredFestivalSection.length > 0) {
    if (!props.task.event_id) {
      array.push({
        type: 'header',
        title: 'Festival Sections',
      });
    }
    for (let i = 0; i < filteredFestivalSection.length; i++) {
      array.push({
        title: filteredFestivalSection[i].name,
        postIcon:
          props.task.context_id === filteredFestivalSection[i].id &&
          props.task.context_type === 'App\\Models\\Festivals\\FestivalSection'
            ? 'fa-check'
            : null,
        action: (close) => {
          selectContext(
            'App\\Models\\Festivals\\FestivalSection',
            filteredFestivalSection[i].id,
            filteredFestivalSection[i].name
          );
          close();
        },
      });
    }
  }

  return array;
};

const open = ref(false);

const getContextName = () => {
  if (props.isCreate) {
    const assignableRole =
      props.task.context_type === 'App\\Role'
        ? getItemFromArrayBasedOnId(props.task.context_id, allAssignableRoles.value)
        : null;
    if (assignableRole) {
      return getKey(assignableRole, 'name', 'No Group');
    }
    const assignableFestivalSection =
      props.task.context_type === 'App\\Models\\Festivals\\FestivalSection'
        ? getItemFromArrayBasedOnId(props.task.context_id, allAssignableFestivalSections.value)
        : null;
    if (assignableFestivalSection) {
      return getKey(assignableFestivalSection, 'name', 'No Group');
    }
    const assignableGroup =
      props.task.context_type === 'App\\Group'
        ? getItemFromArrayBasedOnId(props.task.context_id, allAssignableGroups.value)
        : null;
    if (assignableGroup) {
      return getKey(assignableGroup, 'name', 'No Group');
    }
    return 'Add Group';
  }

  if (props.task.context_id) {
    if (props.task.context) {
      return getKey(props.task.context, 'name', '--');
    }
  }
  return 'No Group';
};
if (props.isCreate) {
  itemClicked();
}

const showCell = computed(() => {
  if (props.task && props.task.id) return true;
  return getOptionsForList().length >= 3;
});
</script>

<template>
  <div v-if="showCell">
    <VDropdown
      v-if="!lockedKeys.includes('group_selector')"
      close-on-click
      :items="getOptionsForList()"
      :have-max-width="false"
      :highlight-text="searchTerm"
      with-arrows-up-and-down
      @dropdown-closed="open = false"
      @dropdown-opened="[itemClicked(), (open = true)]">
      <template #click-area>
        <button
          :class="{
            '!bg-row-hover': open,
            'text-textColor-soft': task.completed_at,
            'bg-transparent hover:!bg-row-alternate min-w-[190px] max-w-[190px] ': inList,
            ' bg-transparent min-w-[250px] max-w-[250px] ': !inList,
            ' text-textColor-soft italic': !task.context_id,
          }"
          class="btn btn-tiny btn-info text-textColor !ring-transparent py-[2px] flex items-center gap-1 justify-between">
          <span class="truncate">
            <i
              v-if="!task.context_id && isCreate"
              class="fa fa-fw fa-group fa-regular mr-2"></i>
            {{ getContextName() }}
          </span>
          <i class="fa fa-fw text-xxs fa-chevron-down" />
        </button>
      </template>
      <template
        v-if="!loadingContent"
        #aboveDropdown>
        <TextInput
          v-model="searchTerm"
          placeholder="Search"
          set-focus
          square />
      </template>
      <template
        v-if="loadingContent"
        #dropdown>
        <div class="bg-backgroundColor w-full h-10 flex justify-center">
          <IconWithLoading loading />
        </div>
      </template>
    </VDropdown>
    <div
      v-else
      class="text-textColor px-edge text-sm font-headers">
      {{ getContextName() }}
    </div>
  </div>
</template>
