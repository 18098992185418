<script lang="ts" setup>
import HoverContainer from '@/components/calendar/HoverContainer.vue';
import { formatStampAsTime } from '@/util/timeFunctions';
import { EventApi } from '@fullcalendar/core';
import { ref } from 'vue';

type Props = {
  event: EventApi;
};

const props = defineProps<Props>();

type Columns = {
  title: string;
  values: string[];
  icon: string;
};

const firstColumn = ref<Columns[]>([]);

if (props.event.start) {
  firstColumn.value.push({
    title: 'When',
    values: [
      formatStampAsTime(props.event.start) +
        (props.event.end ? ' - ' + formatStampAsTime(props.event.end, props.event.start) : ''),
    ],
    icon: 'clock',
  });
}

const members: string[] = [];

const allResources = props.event.getResources();

allResources?.forEach((resource) => {
  if (resource) {
    if (!members.includes(resource.title)) {
      members.push(resource.title);
    }
  }
});

if (members.length) {
  firstColumn.value.push({
    title: 'Members',
    values: members,
    icon: 'fa-user',
    withoutTruncate: true,
  });
}

const secondColumn = ref<Columns[]>([]);
</script>
<template>
  <HoverContainer
    :first-column="firstColumn"
    :second-column="secondColumn">
    <template #header>
      {{ event.title }}
    </template>
  </HoverContainer>
</template>
