<script setup lang="ts">
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { InviteResource } from '@/types/invite';
import moment from 'moment';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import DateHourSelector from '@/components/Inputs/Date/DateHourSelector.vue';
import { dateFormat, dateTimeFormat } from '@/variables/date-format';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import VButton from '@/components/Inputs/VButton.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { NEXT_DAY_START } from '@/util/globals';
import { formatStampAsDate, formatStampAsTime } from '@/util/timeFunctions';

type Props = {
  invite: InviteResource;
  canEdit: boolean;
  withRoomBookings: boolean;
  groupId: number;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'start', arg: string): void;
  (event: 'end', arg: string): void;
  (event: 'editEvent'): void;
}>();

const toast = useToast();
const { threeButtonModal } = useMultipleButtonsModal();
const { assertCertain } = useCertaintyModal();
const { recurringModal } = useRecurringModal();

const editing = ref(false);
const working = ref(false);
const hasAskedIfProdPeriodOrEvent = ref(false);
const start = ref(props.invite.start);
const end = ref(props.invite.end);

const periodBeforeEvent = computed(() => {
  return moment(props.invite.start_date) > moment(start.value) && !editing.value;
});
const periodAfterEvent = computed(() => {
  return moment(props.invite.end_date, dateFormat).endOf('day').add(6, 'hours') < moment(end.value) && !editing.value;
});

const formatDate = (date: string) => moment(date).format('YYYY-MM-DD HH:mm');

const isSameDay = (date1: string, date2: string) => {
  if (moment(date1).isSame(date2, 'day')) return true;

  const time = moment(date2).format('HH:mm');

  if (time < NEXT_DAY_START) {
    if (moment(date1).add(1, 'days').isSame(date2, 'day')) return true;
  }

  return false;
};

const checkIfEditEventDate = async () => {
  if (!props.canEdit) return;
  if (hasAskedIfProdPeriodOrEvent.value) return;

  hasAskedIfProdPeriodOrEvent.value = true;

  const result = await threeButtonModal({
    title: 'Change Date(s) of Event?',
    description:
      'If you are moving the event date(s), this is better done on event level, not production period. ' +
      'Changes to the production period will not be reflected in event dates, show times and such.',
    button: {
      text: 'Update',
      type: 'success',
    },
    options: [
      {
        value: 'first',
        label: 'Update event date',
      },
      {
        value: 'second',
        label: 'Update Production Period only',
      },
    ],
  });

  switch (result) {
    case 'first': {
      if (props.invite.event.owner_type === 'App\\Group' && props.invite.event.owner_id === props.groupId) {
        emit('editEvent');
        editing.value = false;
        // router.visit(getRoute('events.edit', props.invite.event.slug));
      } else {
        toast.warning('You are not the owner of this event.');
      }
      break;
    }
    case 'second': {
      break;
    }
    case 'cancel':
    default: {
      editing.value = false;
      break;
    }
  }
};

const updateProductionPeriod = async () => {
  editing.value = false;
  if (start.value === props.invite.start && end.value === props.invite.end) return;

  const addToAll = props.invite.recurring_original_id
    ? await recurringModal(
        '',
        'Update Recurring Invite',
        'Do you want update all recurrences of this invite, or just this one? <br> ' +
          '<strong>Note</strong>: No other elements in the event will be affected. '
      )
    : false;
  if (addToAll === 'cancel') return;

  const updateRoomBookings = props.withRoomBookings
    ? await assertCertain(
        'Update Room Bookings',
        'Would you like to update all room bookings of this invite, to reflect the changes in start and end?',
        'Update Rooms'
      )
    : false;

  working.value = true;
  await axios
    .patch(`/api/invites/${props.invite.id}/dates`, {
      start: start.value,
      end: end.value,
      is_global: addToAll === 'all',
      update_room_bookings: updateRoomBookings,
    })
    .catch((error) => {
      console.error(error);
    });

  toast.success('Updated');
  emit('start', start.value);
  emit('end', end.value);
  working.value = false;
};

const endDateChanged = (dateTime: string) => {
  if (!moment(end.value).isSame(dateTime, 'day')) {
    checkIfEditEventDate();
  }
  if (dateTime === end.value) return;
  if (start.value >= dateTime) {
    end.value = moment(dateTime).add(1, 'days').format(dateTimeFormat);
    return;
  }
  end.value = dateTime;
};

const startDateChanged = (dateTime: string) => {
  if (!moment(start.value).isSame(dateTime, 'day')) {
    checkIfEditEventDate();
  }
  if (dateTime === start.value) return;
  if (dateTime >= end.value) {
    toast.warning('It cannot start after it ends');
    end.value = moment(dateTime).add(1, 'hours').format(dateTimeFormat);
  }
  start.value = dateTime;
};

watch(editing, () => {
  if (!props.canEdit) {
    editing.value = false;
    return;
  }
  if (editing.value) {
    start.value = props.invite.start;
    end.value = props.invite.end;
  }
});

watch(
  () => props.invite,
  () => {
    start.value = props.invite.start;
    end.value = props.invite.end;
  },
  { deep: true }
);

const editProductionPeriod = () => {
  editing.value = true;
};
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex justify-between gap-1">
      <InputLabel
        label="Production Period"
        super-text />
      <div
        v-if="canEdit"
        class="group">
        <VButton
          type="info"
          class="btn-outline-text text-textColor-soft"
          size="tiny"
          title="Edit"
          icon="fa-pencil"
          @click="editProductionPeriod">
          <!--          <span class="hidden mr-2 group-hover:inline-block"> Edit </span>-->
          <!--          <i class="fa fa-fw fa-pencil" />-->

          <span class="invisible group-hover:visible mr-2"> Edit </span>
          <i class="fa fa-fw fa-pencil group-hover/box:visible invisible" />
        </VButton>
      </div>
    </div>

    <div v-if="working">
      <i class="fa fa-fw fa-circle-o-notch fa-spin" />
    </div>

    <div v-else-if="editing">
      <div>
        <DateHourSelector
          v-if="editing"
          :date-time="start"
          label="Start"
          required
          :min-date="null"
          @update:date-time="startDateChanged" />
      </div>

      <div class="mt-2">
        <DateHourSelector
          v-if="editing"
          :date-time="end"
          required
          label="End"
          :min-date="null"
          @update:date-time="endDateChanged" />
      </div>

      <div class="flex items-center justify-between mt-3">
        <VButton
          size="extra-small"
          type="pending"
          class="btn-outline"
          title="Cancel"
          @click="editing = false" />

        <VButton
          type="success"
          size="extra-small"
          icon="fa-save"
          title="Save"
          @click="updateProductionPeriod" />
      </div>
    </div>

    <div v-else>
      <div v-if="isSameDay(invite.start, invite.end)">
        {{ formatStampAsDate(invite.start) }} {{ formatStampAsTime(invite.start) }} -
        {{ formatStampAsTime(invite.end) }}
      </div>
      <div v-else>
        <div
          class="grid w-full grid-cols-[auto_25px_25px] items-center"
          :class="[canEdit ? 'cursor-pointer' : '']">
          {{ formatDate(invite.start) }} {{}}
          <div>
            <i
              v-if="periodBeforeEvent"
              class="fa fa-fw fa-triangle-exclamation pull-right text-pending"
              title="Production Period is before the date(s) of the event." />
          </div>
        </div>

        <div
          class="grid w-full grid-cols-[auto_25px_25px] items-center"
          :class="[canEdit ? 'cursor-pointer' : '']"
          @click.stop="editing = true">
          <div>
            {{ formatDate(invite.end) }}
          </div>
          <div>
            <i
              v-if="periodAfterEvent"
              class="fa fa-fw fa-triangle-exclamation pull-right hide-hover text-pending"
              title="Production Period is after the date(s) of the event." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
