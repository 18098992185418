<script lang="ts" setup>
import moment from 'moment/moment';
import { formatStampAsDate } from '@/util/timeFunctions';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { nextTick, ref } from 'vue';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { getIndexFromArrayBasedOnId, sortArrayByTime } from '@/util/globals';

type Props = {
  holdDates: [];
  eventDate: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:holdDates', value: []): void;
}>();

const toggleDate = (allDates: string[]) => {
  if (!allDates.length) {
    emit('update:holdDates', []);
    return;
  }
  let newDates: Date[] = [];

  allDates.forEach((date) => {
    const i = _.findIndex(props.holdDates, (d) => moment(d.date).isSame(date, 'day'));
    if (i > -1) {
      newDates.push({ ...props.holdDates[i] });
    } else {
      newDates.push({
        date: date,
      });
    }
  });
  newDates = sortArrayByTime(newDates, 'date');
  emit('update:holdDates', newDates);
};
const removeDate = (date) => {
  const newDates = [...props.holdDates];
  const index = getIndexFromArrayBasedOnId(date.date, newDates, 'date');
  newDates.splice(index, 1);
  emit('update:holdDates', newDates);
};

const pageX = ref(null);
const pageY = ref(null);
const openDateSelector = async (event) => {
  pageX.value = null;
  pageY.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 250;
    pageY.value = event.target.getBoundingClientRect().y + 23;
  });
};
</script>

<template>
  <BoxContainer
    header="Hold Dates"
    class="[&_.box-container]:min-h-[146px] [&_.box-container]:bg-inputs-background"
    :actions="[
      {
        title: 'Add Date',
        icon: 'fa-plus',
        action: (event) => {
          openDateSelector(event);
        },
      },
    ]">
    <template #header><h4>Hold Dates</h4></template>
    <div class="flex flex-wrap gap-x-5 gap-y-2">
      <div
        v-for="date in [{ date: eventDate, disabled: true }].concat(holdDates)"
        :class="{ ' opacity-50 ': date.disabled }"
        class="text-sm border group gap-1 flex h-[27px] items-center min-w-[90px] rounded flex-shrink pl-5 py-2">
        {{ formatStampAsDate(date.date) }}
        <div
          v-if="!date.disabled"
          class="hover:bg-backgroundColor-content cursor-pointer hover:border-borderColor border-transparent rounded-full border flex items-center justify-center h-[17px] w-[17px]"
          @click="removeDate(date)">
          <i class="fa fa-fw fa-times invisible group-hover:visible text-xs"></i>
        </div>
      </div>
    </div>

    <FloatingWindowContainer
      v-if="pageX && pageY"
      :page-x="pageX"
      :page-y="pageY"
      @closed="[(pageX = null), (pageY = null)]">
      <VDatepicker
        inline
        multi-dates
        label="Select Hold Dates"
        :model-value="holdDates.map((d) => d.date)"
        :start-date="eventDate"
        :disabled-dates="[eventDate]"
        @update:model-value="toggleDate" />
    </FloatingWindowContainer>
  </BoxContainer>
</template>
