/* eslint-disable no-use-before-define */
import type { VenueRoomResource } from '@/types/venue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useVenueRoomsStore = defineStore('venue-rooms', () => {
  const list = ref<Map<number, VenueRoomResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (venueId: number, groupId: number) => {
    Echo.join(`On.Group.${groupId}`).listen('.roomStructure.changed', async () => {
      await fetchVenueRooms(venueId, groupId);
    });
  };

  const fetchVenueRooms = async (venueId: number, groupId: number) => {
    loading.value = true;
    const { data } = await axios.get(`/api/venues/${venueId}/rooms`);
    if (!list.value.has(venueId)) {
      listenForBroadcast(venueId, groupId);
    }
    list.value.set(venueId, data);
    loading.value = false;
  };

  return {
    list,
    loading,
    fetchVenueRooms,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVenueRoomsStore, import.meta.hot));
}
