<script lang="ts" setup>
import { computed, ref } from 'vue';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { getKey, sortArrayBy } from '@/util/globals.js';
import { ShowTimeResource } from '@/types/show-time';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { EventResource } from '@/types/event';
import { z } from 'zod';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import { useToast } from 'vue-toastification';
import { changeAndFormatStamp } from '@/util/timeFunctions';
import moment from 'moment/moment';
import { dateTimeFormat } from '@/variables/date-format';

type Props = {
  showTime?: ShowTimeResource | null;
  event: EventResource;
  rooms?: [];
  showTimes?: [];
  withDate?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  rooms: () => [],
  showTimes: () => [],
  showTime: null,
  withDate: true,
});

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'deleted', id: number): void;
  (event: 'created', showTime: ShowTimeResource): void;
  (event: 'updated', showTime: ShowTimeResource): void;
}>();
const showTimeSchema = z.object({
  start: z.string().min(2).max(190),
  end: z.string().min(2).max(190),
  title: z.string().max(190).nullish(),
  room_id: z.number().nullable(),
});
type ShowTimeType = z.infer<typeof showTimeSchema>;

const getStartAndEndOfNextShow = (isStart = true) => {
  if (props.showTimes.length === 0) {
    if (isStart) {
      return changeAndFormatStamp({
        stamp: props.event.start_date,
        startOf: 'day',
        addMinutes: 12 * 60,
      });
    }
    return changeAndFormatStamp({
      stamp: props.event.start_date,
      startOf: 'day',
      addMinutes: 13 * 60,
    });
  }
  const sortedShowTimes = sortArrayBy(props.showTimes, 'start');
  const lastObject = sortedShowTimes[sortedShowTimes.length - 1];
  const start = moment(lastObject.end);
  if (isStart) {
    return start.format(dateTimeFormat);
  }
  const duration = moment.duration(moment(lastObject.end).diff(lastObject.start));
  return start.clone().add(duration.asMinutes(), 'minutes').format(dateTimeFormat);
};

const newShowTime = ref<ShowTimeType>({
  start: getKey(props.showTime, 'start', getStartAndEndOfNextShow(true)),
  end: getKey(props.showTime, 'end', getStartAndEndOfNextShow(false)),
  room_id: getKey(props.showTime, 'room_id'),
  title: getKey(props.showTime, 'title', ''),
});

const modalOpen = ref(true);
const loading = ref(false);

const create = async () => {
  const { data } = await axios.post('/api/show-times', {
    event_id: props.event.id,
    start: newShowTime.value.start,
    end: newShowTime.value.end,
    title: newShowTime.value.title,
    room_id: newShowTime.value.room_id,
  });
  emit('created', data);
  emit('closed');
  useToast().success('Show Created');
  modalOpen.value = false;
};
const update = async () => {
  if (!props.showTime?.id) return;
  loading.value = true;
  await axios.patch('/api/show-times/' + props.showTime.id, {
    event_id: props.event.id,
    start: newShowTime.value.start,
    end: newShowTime.value.end,
    title: newShowTime.value.title,
    room_id: newShowTime.value.room_id,
  });
  emit('updated', {
    ...props.showTime,
    ...newShowTime.value,
  });
  loading.value = false;
  useToast().success('Show Updated');
  emit('closed');
  modalOpen.value = false;
};
const destroy = async () => {
  if (!props.showTime?.id) return;
  const certain = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Delete Meta Data',
    `Are you sure that you want to delete ${props.showTime.title}?`
  );
  if (!certain) return;
  loading.value = true;

  await axios.delete<string>(`/api/show-times/${props.showTime.id}`);
  useToast().success('Show Deleted');
  emit('deleted', props.showTime.id);
  loading.value = false;
  emit('closed');
  modalOpen.value = false;
};

const canSave = computed(() => showTimeSchema.safeParse(newShowTime.value).success);
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    :title="(showTime?.id !== null ? 'Update ' : 'Create new') + ' Show Time'"
    :update="showTime?.id !== null"
    title-highlight="Show Time"
    :disabled="!canSave"
    @closed="$emit('closed')"
    @create="create"
    @update="update"
    @delete="destroy">
    <div class="form-layout grid-cols-2">
      <StartEndPicker
        v-model:start="newShowTime.start"
        v-model:end="newShowTime.end"
        class="col-span-2"
        with-time
        set-focus
        :with-date="withDate"
        vertical
        with-duration
        :allow-no-duration="false"
        required
        :duration-options="[30, 60, 90, 120, 180, 240, 360, 480, 600, 720, 840]" />
      <TextInput
        v-model="newShowTime.title"
        title="Title"
        label="Title" />
      <VSelect
        v-model="newShowTime.room_id"
        label="Room"
        :can-edit="rooms.length > 0"
        nullable
        nullable-display-text="N/A"
        :options="rooms" />
    </div>
  </CrudModal>
</template>
