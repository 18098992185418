type CountryCodeItem = {
  code: string;
  name: string;
  flag: string;
};

type CountryCodeSeparatorItem = {
  code: string;
  name: string;
  flag: string;
};
type CountryCodeOption = CountryCodeItem | CountryCodeSeparatorItem;

export const allCountryCodes = [
  {
    'code': '+47',
    'name': 'Norway',
    'flag': '🇳🇴',
  },
  {
    'code': '+46',
    'name': 'Sweden',
    'flag': '🇸🇪',
  },
  {
    'code': '+45',
    'name': 'Denmark',
    'flag': '🇩🇰',
  },
  {
    'code': '+44',
    'name': 'United Kingdom',
    'flag': '🇬🇧',
  },
  {
    'code': '+1',
    'name': 'United States',
    'flag': '🇺🇸',
  },
  {
    'separator': true,
  },
  {
    'code': '+93',
    'name': 'Afghanistan',
    'flag': '🇦🇫',
  },
  {
    'code': '+355',
    'name': 'Albania',
    'flag': '🇦🇱',
  },
  {
    'code': '+213',
    'name': 'Algeria',
    'flag': '🇩🇿',
  },
  {
    'code': '+376',
    'name': 'Andorra',
    'flag': '🇦🇩',
  },
  {
    'code': '+244',
    'name': 'Angola',
    'flag': '🇦🇴',
  },
  {
    'code': '+1-264',
    'name': 'Anguilla',
    'flag': '🇦🇮',
  },
  {
    'code': '+1-268',
    'name': 'Antigua and Barbuda',
    'flag': '🇦🇬',
  },
  {
    'code': '+54',
    'name': 'Argentina',
    'flag': '🇦🇷',
  },
  {
    'code': '+61',
    'name': 'Australia',
    'flag': '🇦🇺',
  },
  {
    'code': '+43',
    'name': 'Austria',
    'flag': '🇦🇹',
  },
  {
    'code': '+1-242',
    'name': 'Bahamas',
    'flag': '🇧🇸',
  },
  {
    'code': '+973',
    'name': 'Bahrain',
    'flag': '🇧🇭',
  },
  {
    'code': '+880',
    'name': 'Bangladesh',
    'flag': '🇧🇩',
  },
  {
    'code': '+1-246',
    'name': 'Barbados',
    'flag': '🇧🇧',
  },
  {
    'code': '+375',
    'name': 'Belarus',
    'flag': '🇧🇾',
  },
  {
    'code': '+32',
    'name': 'Belgium',
    'flag': '🇧🇪',
  },
  {
    'code': '+229',
    'name': 'Benin',
    'flag': '🇧🇯',
  },
  {
    'code': '+975',
    'name': 'Bhutan',
    'flag': '🇧🇹',
  },
  {
    'code': '+591',
    'name': 'Bolivia',
    'flag': '🇧🇴',
  },
  {
    'code': '+387',
    'name': 'Bosnia and Herzegovina',
    'flag': '🇧🇦',
  },
  {
    'code': '+267',
    'name': 'Botswana',
    'flag': '🇧🇼',
  },
  {
    'code': '+55',
    'name': 'Brazil',
    'flag': '🇧🇷',
  },
  {
    'code': '+1-284',
    'name': 'British Virgin Islands',
    'flag': '🇻🇬',
  },
  {
    'code': '+359',
    'name': 'Bulgaria',
    'flag': '🇧🇬',
  },
  {
    'code': '+226',
    'name': 'Burkina Faso',
    'flag': '🇧🇫',
  },
  {
    'code': '+257',
    'name': 'Burundi',
    'flag': '🇧🇮',
  },
  {
    'code': '+855',
    'name': 'Cambodia',
    'flag': '🇰🇭',
  },
  {
    'code': '+237',
    'name': 'Cameroon',
    'flag': '🇨🇲',
  },
  {
    'code': '+1',
    'name': 'Canada',
    'flag': '🇨🇦',
  },
  {
    'code': '+238',
    'name': 'Cape Verde',
    'flag': '🇨🇻',
  },
  {
    'code': '+1-345',
    'name': 'Cayman Islands',
    'flag': '🇰🇾',
  },
  {
    'code': '+236',
    'name': 'Central African Republic',
    'flag': '🇨🇫',
  },
  {
    'code': '+235',
    'name': 'Chad',
    'flag': '🇹🇩',
  },
  {
    'code': '+56',
    'name': 'Chile',
    'flag': '🇨🇱',
  },
  {
    'code': '+86',
    'name': 'China',
    'flag': '🇨🇳',
  },
  {
    'code': '+57',
    'name': 'Colombia',
    'flag': '🇨🇴',
  },
  {
    'code': '+269',
    'name': 'Comoros',
    'flag': '🇰🇲',
  },
  {
    'code': '+506',
    'name': 'Costa Rica',
    'flag': '🇨🇷',
  },
  {
    'code': '+385',
    'name': 'Croatia',
    'flag': '🇭🇷',
  },
  {
    'code': '+357',
    'name': 'Cyprus',
    'flag': '🇨🇾',
  },
  {
    'code': '+420',
    'name': 'Czech Republic',
    'flag': '🇨🇿',
  },
  {
    'code': '+243',
    'name': 'Democratic Republic of the Congo',
    'flag': '🇨🇩',
  },

  {
    'code': '+253',
    'name': 'Djibouti',
    'flag': '🇩🇯',
  },
  {
    'code': '+1-767',
    'name': 'Dominica',
    'flag': '🇩🇲',
  },
  {
    'code': '+1-809',
    'name': 'Dominican Republic',
    'flag': '🇩🇴',
  },
  {
    'code': '+593',
    'name': 'Ecuador',
    'flag': '🇪🇨',
  },
  {
    'code': '+20',
    'name': 'Egypt',
    'flag': '🇪🇬',
  },
  {
    'code': '+503',
    'name': 'El Salvador',
    'flag': '🇸🇻',
  },
  {
    'code': '+240',
    'name': 'Equatorial Guinea',
    'flag': '🇬🇶',
  },
  {
    'code': '+291',
    'name': 'Eritrea',
    'flag': '🇪🇷',
  },
  {
    'code': '+372',
    'name': 'Estonia',
    'flag': '🇪🇪',
  },
  {
    'code': '+268',
    'name': 'Eswatini',
    'flag': '🇸🇿',
  },
  {
    'code': '+251',
    'name': 'Ethiopia',
    'flag': '🇪🇹',
  },
  {
    'code': '+679',
    'name': 'Fiji',
    'flag': '🇫🇯',
  },
  {
    'code': '+358',
    'name': 'Finland',
    'flag': '🇫🇮',
  },
  {
    'code': '+33',
    'name': 'France',
    'flag': '🇫🇷',
  },
  {
    'code': '+594',
    'name': 'French Guiana',
    'flag': '🇬🇫',
  },
  {
    'code': '+689',
    'name': 'French Polynesia',
    'flag': '🇵🇫',
  },
  {
    'code': '+241',
    'name': 'Gabon',
    'flag': '🇬🇦',
  },
  {
    'code': '+220',
    'name': 'Gambia',
    'flag': '🇬🇲',
  },
  {
    'code': '+995',
    'name': 'Georgia',
    'flag': '🇬🇪',
  },
  {
    'code': '+49',
    'name': 'Germany',
    'flag': '🇩🇪',
  },
  {
    'code': '+233',
    'name': 'Ghana',
    'flag': '🇬🇭',
  },
  {
    'code': '+30',
    'name': 'Greece',
    'flag': '🇬🇷',
  },
  {
    'code': '+1-473',
    'name': 'Grenada',
    'flag': '🇬🇩',
  },
  {
    'code': '+590',
    'name': 'Guadeloupe',
    'flag': '🇬🇵',
  },
  {
    'code': '+1-671',
    'name': 'Guam',
    'flag': '🇬🇺',
  },
  {
    'code': '+502',
    'name': 'Guatemala',
    'flag': '🇬🇹',
  },
  {
    'code': '+224',
    'name': 'Guinea',
    'flag': '🇬🇳',
  },
  {
    'code': '+245',
    'name': 'Guinea-Bissau',
    'flag': '🇬🇼',
  },
  {
    'code': '+592',
    'name': 'Guyana',
    'flag': '🇬🇾',
  },
  {
    'code': '+509',
    'name': 'Haiti',
    'flag': '🇭🇹',
  },
  {
    'code': '+504',
    'name': 'Honduras',
    'flag': '🇭🇳',
  },
  {
    'code': '+36',
    'name': 'Hungary',
    'flag': '🇭🇺',
  },
  {
    'code': '+354',
    'name': 'Iceland',
    'flag': '🇮🇸',
  },
  {
    'code': '+91',
    'name': 'India',
    'flag': '🇮🇳',
  },
  {
    'code': '+62',
    'name': 'Indonesia',
    'flag': '🇮🇩',
  },
  {
    'code': '+98',
    'name': 'Iran',
    'flag': '🇮🇷',
  },
  {
    'code': '+964',
    'name': 'Iraq',
    'flag': '🇮🇶',
  },
  {
    'code': '+353',
    'name': 'Ireland',
    'flag': '🇮🇪',
  },
  {
    'code': '+972',
    'name': 'Israel',
    'flag': '🇮🇱',
  },
  {
    'code': '+39',
    'name': 'Italy',
    'flag': '🇮🇹',
  },
  {
    'code': '+225',
    'name': 'Ivory Coast',
    'flag': '🇨🇮',
  },
  {
    'code': '+1-876',
    'name': 'Jamaica',
    'flag': '🇯🇲',
  },
  {
    'code': '+81',
    'name': 'Japan',
    'flag': '🇯🇵',
  },
  {
    'code': '+962',
    'name': 'Jordan',
    'flag': '🇯🇴',
  },
  {
    'code': '+7',
    'name': 'Kazakhstan',
    'flag': '🇰🇿',
  },
  {
    'code': '+254',
    'name': 'Kenya',
    'flag': '🇰🇪',
  },
  {
    'code': '+383',
    'name': 'Kosovo',
    'flag': '🇽🇰',
  },
  {
    'code': '+965',
    'name': 'Kuwait',
    'flag': '🇰🇼',
  },
  {
    'code': '+996',
    'name': 'Kyrgyzstan',
    'flag': '🇰🇬',
  },
  {
    'code': '+856',
    'name': 'Laos',
    'flag': '🇱🇦',
  },
  {
    'code': '+371',
    'name': 'Latvia',
    'flag': '🇱🇻',
  },
  {
    'code': '+961',
    'name': 'Lebanon',
    'flag': '🇱🇧',
  },
  {
    'code': '+266',
    'name': 'Lesotho',
    'flag': '🇱🇸',
  },
  {
    'code': '+231',
    'name': 'Liberia',
    'flag': '🇱🇷',
  },
  {
    'code': '+218',
    'name': 'Libya',
    'flag': '🇱🇾',
  },
  {
    'code': '+423',
    'name': 'Liechtenstein',
    'flag': '🇱🇮',
  },
  {
    'code': '+370',
    'name': 'Lithuania',
    'flag': '🇱🇹',
  },
  {
    'code': '+352',
    'name': 'Luxembourg',
    'flag': '🇱🇺',
  },
  {
    'code': '+853',
    'name': 'Macau',
    'flag': '🇲🇴',
  },
  {
    'code': '+261',
    'name': 'Madagascar',
    'flag': '🇲🇬',
  },
  {
    'code': '+265',
    'name': 'Malawi',
    'flag': '🇲🇼',
  },
  {
    'code': '+60',
    'name': 'Malaysia',
    'flag': '🇲🇾',
  },
  {
    'code': '+960',
    'name': 'Maldives',
    'flag': '🇲🇻',
  },
  {
    'code': '+223',
    'name': 'Mali',
    'flag': '🇲🇱',
  },
  {
    'code': '+356',
    'name': 'Malta',
    'flag': '🇲🇹',
  },
  {
    'code': '+596',
    'name': 'Martinique',
    'flag': '🇲🇶',
  },
  {
    'code': '+222',
    'name': 'Mauritania',
    'flag': '🇲🇷',
  },
  {
    'code': '+230',
    'name': 'Mauritius',
    'flag': '🇲🇺',
  },
  {
    'code': '+52',
    'name': 'Mexico',
    'flag': '🇲🇽',
  },
  {
    'code': '+373',
    'name': 'Moldova',
    'flag': '🇲🇩',
  },
  {
    'code': '+377',
    'name': 'Monaco',
    'flag': '🇲🇨',
  },
  {
    'code': '+976',
    'name': 'Mongolia',
    'flag': '🇲🇳',
  },
  {
    'code': '+382',
    'name': 'Montenegro',
    'flag': '🇲🇪',
  },
  {
    'code': '+1-264',
    'name': 'Montserrat',
    'flag': '🇲🇸',
  },
  {
    'code': '+212',
    'name': 'Morocco',
    'flag': '🇲🇦',
  },
  {
    'code': '+258',
    'name': 'Mozambique',
    'flag': '🇲🇿',
  },
  {
    'code': '+264',
    'name': 'Namibia',
    'flag': '🇳🇦',
  },
  {
    'code': '+674',
    'name': 'Nauru',
    'flag': '🇳🇷',
  },
  {
    'code': '+977',
    'name': 'Nepal',
    'flag': '🇳🇵',
  },
  {
    'code': '+31',
    'name': 'Netherlands',
    'flag': '🇳🇱',
  },
  {
    'code': '+687',
    'name': 'New Caledonia',
    'flag': '🇳🇨',
  },
  {
    'code': '+64',
    'name': 'New Zealand',
    'flag': '🇳🇿',
  },
  {
    'code': '+505',
    'name': 'Nicaragua',
    'flag': '🇳🇮',
  },
  {
    'code': '+227',
    'name': 'Niger',
    'flag': '🇳🇪',
  },
  {
    'code': '+234',
    'name': 'Nigeria',
    'flag': '🇳🇬',
  },
  {
    'code': '+389',
    'name': 'North Macedonia',
    'flag': '🇲🇰',
  },

  {
    'code': '+968',
    'name': 'Oman',
    'flag': '🇴🇲',
  },
  {
    'code': '+92',
    'name': 'Pakistan',
    'flag': '🇵🇰',
  },
  {
    'code': '+970',
    'name': 'Palestine',
    'flag': '🇵🇸',
  },
  {
    'code': '+507',
    'name': 'Panama',
    'flag': '🇵🇦',
  },
  {
    'code': '+675',
    'name': 'Papua New Guinea',
    'flag': '🇵🇬',
  },
  {
    'code': '+595',
    'name': 'Paraguay',
    'flag': '🇵🇾',
  },
  {
    'code': '+51',
    'name': 'Peru',
    'flag': '🇵🇪',
  },
  {
    'code': '+63',
    'name': 'Philippines',
    'flag': '🇵🇭',
  },
  {
    'code': '+48',
    'name': 'Poland',
    'flag': '🇵🇱',
  },
  {
    'code': '+351',
    'name': 'Portugal',
    'flag': '🇵🇹',
  },
  {
    'code': '+1-787',
    'name': 'Puerto Rico',
    'flag': '🇵🇷',
  },
  {
    'code': '+974',
    'name': 'Qatar',
    'flag': '🇶🇦',
  },
  {
    'code': '+40',
    'name': 'Romania',
    'flag': '🇷🇴',
  },
  {
    'code': '+7',
    'name': 'Russia',
    'flag': '🇷🇺',
  },
  {
    'code': '+250',
    'name': 'Rwanda',
    'flag': '🇷🇼',
  },
  {
    'code': '+685',
    'name': 'Samoa',
    'flag': '🇼🇸',
  },
  {
    'code': '+378',
    'name': 'San Marino',
    'flag': '🇸🇲',
  },
  {
    'code': '+239',
    'name': 'São Tomé and Príncipe',
    'flag': '🇸🇹',
  },
  {
    'code': '+966',
    'name': 'Saudi Arabia',
    'flag': '🇸🇦',
  },
  {
    'code': '+221',
    'name': 'Senegal',
    'flag': '🇸🇳',
  },
  {
    'code': '+381',
    'name': 'Serbia',
    'flag': '🇷🇸',
  },
  {
    'code': '+248',
    'name': 'Seychelles',
    'flag': '🇸🇨',
  },
  {
    'code': '+232',
    'name': 'Sierra Leone',
    'flag': '🇸🇱',
  },
  {
    'code': '+65',
    'name': 'Singapore',
    'flag': '🇸🇬',
  },
  {
    'code': '+421',
    'name': 'Slovakia',
    'flag': '🇸🇰',
  },
  {
    'code': '+386',
    'name': 'Slovenia',
    'flag': '🇸🇮',
  },
  {
    'code': '+677',
    'name': 'Solomon Islands',
    'flag': '🇸🇧',
  },
  {
    'code': '+252',
    'name': 'Somalia',
    'flag': '🇸🇴',
  },
  {
    'code': '+27',
    'name': 'South Africa',
    'flag': '🇿🇦',
  },
  {
    'code': '+82',
    'name': 'South Korea',
    'flag': '🇰🇷',
  },
  {
    'code': '+211',
    'name': 'South Sudan',
    'flag': '🇸🇸',
  },
  {
    'code': '+34',
    'name': 'Spain',
    'flag': '🇪🇸',
  },
  {
    'code': '+94',
    'name': 'Sri Lanka',
    'flag': '🇱🇰',
  },
  {
    'code': '+249',
    'name': 'Sudan',
    'flag': '🇸🇩',
  },
  {
    'code': '+597',
    'name': 'Suriname',
    'flag': '🇸🇷',
  },

  {
    'code': '+41',
    'name': 'Switzerland',
    'flag': '🇨🇭',
  },
  {
    'code': '+886',
    'name': 'Taiwan',
    'flag': '🇹🇼',
  },
  {
    'code': '+992',
    'name': 'Tajikistan',
    'flag': '🇹🇯',
  },
  {
    'code': '+255',
    'name': 'Tanzania',
    'flag': '🇹🇿',
  },
  {
    'code': '+66',
    'name': 'Thailand',
    'flag': '🇹🇭',
  },
  {
    'code': '+228',
    'name': 'Togo',
    'flag': '🇹🇬',
  },
  {
    'code': '+676',
    'name': 'Tonga',
    'flag': '🇹🇴',
  },
  {
    'code': '+1-868',
    'name': 'Trinidad and Tobago',
    'flag': '🇹🇹',
  },
  {
    'code': '+216',
    'name': 'Tunisia',
    'flag': '🇹🇳',
  },
  {
    'code': '+90',
    'name': 'Turkey',
    'flag': '🇹🇷',
  },
  {
    'code': '+993',
    'name': 'Turkmenistan',
    'flag': '🇹🇲',
  },
  {
    'code': '+688',
    'name': 'Tuvalu',
    'flag': '🇹🇻',
  },
  {
    'code': '+1-340',
    'name': 'U.S. Virgin Islands',
    'flag': '🇻🇮',
  },
  {
    'code': '+256',
    'name': 'Uganda',
    'flag': '🇺🇬',
  },
  {
    'code': '+380',
    'name': 'Ukraine',
    'flag': '🇺🇦',
  },
  {
    'code': '+971',
    'name': 'United Arab Emirates',
    'flag': '🇦🇪',
  },

  {
    'code': '+598',
    'name': 'Uruguay',
    'flag': '🇺🇾',
  },
  {
    'code': '+998',
    'name': 'Uzbekistan',
    'flag': '🇺🇿',
  },
  {
    'code': '+678',
    'name': 'Vanuatu',
    'flag': '🇻🇺',
  },
  {
    'code': '+58',
    'name': 'Venezuela',
    'flag': '🇻🇪',
  },
  {
    'code': '+84',
    'name': 'Vietnam',
    'flag': '🇻🇳',
  },
  {
    'code': '+967',
    'name': 'Yemen',
    'flag': '🇾🇪',
  },
  {
    'code': '+260',
    'name': 'Zambia',
    'flag': '🇿🇲',
  },
  {
    'code': '+263',
    'name': 'Zimbabwe',
    'flag': '🇿🇼',
  },
] as ReadonlyArray<CountryCodeOption>;
