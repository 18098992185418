<template>
  <svg
    class="fill-textColor"
    viewBox="0 0 455 56.26">
    <path
      class="cls-1"
      d="M231.23,196.41a27.71,27.71,0,1,0,20.65,46.26l-6.54-4.21c-4.05,3.2-8,5.15-14.11,5.15a19.48,19.48,0,0,1,0-39,21.61,21.61,0,0,1,14.15,5.21l6.51-4.19A27.88,27.88,0,0,0,231.23,196.41Z"
      transform="translate(-203.56 -195.99)" />
    <path
      class="cls-2"
      d="M402.24,237.6c1.5,6.08,8.4,14.23,22.85,14.23,12.37,0,22.82-7.18,22.82-15.67,0-12.33-11.33-14.94-19.69-16.46l-3.15-.41c-4.19-.85-14.91-1.39-14.77-6.9.13-5.22,7.64-9.51,14.08-9.51s11.25,2.13,12.73,4.41h8.49c-1.59-5.51-8.52-10.88-21.22-10.88-11.9,0-21.58,7.15-21.58,15.94,0,6.61,3.52,9.88,16.07,12.72.55.13,1.39.22,2.27.36,5.94.94,19.66,2.43,19.66,10.77,0,5-8.71,9.26-15.71,9.26-8.69,0-12.84-5.08-13.84-7.85Z"
      transform="translate(-203.56 -195.99)" />
    <path
      class="cls-1"
      d="M493,243.6a19.48,19.48,0,0,1,0-39,21.61,21.61,0,0,1,14.15,5.21l6.5-4.19a27.67,27.67,0,1,0,0,37l-6.54-4.21C503,241.65,499.11,243.6,493,243.6Z"
      transform="translate(-203.56 -195.99)" />
    <path
      class="cls-1"
      d="M493,251.83a27.71,27.71,0,1,1,20.66-46.17l-6.5,4.19A21.61,21.61,0,0,0,493,204.64a19.48,19.48,0,0,0,0,39c6.14,0,10.06-2,14.11-5.15l6.54,4.21A27.9,27.9,0,0,1,493,251.83Z"
      transform="translate(-203.56 -195.99)" />
    <path
      class="cls-1"
      d="M582.05,251.83h10.56l-26.52-55.42h-7.84l-27.54,55.42h10.77l3.4-6.88,16.83-36,17,36.06Z"
      transform="translate(-203.56 -195.99)" />
    <path
      class="cls-1"
      d="M626.1,251.83h9.5V204.42h23v-8H603.14v8h23Z"
      transform="translate(-203.56 -195.99)" />
    <path
      class="cls-2"
      d="M307.87,252h9.45l-11.6-16.6,3.55-2c5.76-3.23,8.94-8.9,8.94-16,0-12.27-7.14-21.18-17-21.18H274.17V252h7.78V237.13l16.49,0Zm-25.93-22.28V204.45l18.11.29c6.27,0,10.17,4.86,10.17,12.69,0,7.11-5,12.28-12,12.28Z"
      transform="translate(-203.56 -195.99)" />
    <polygon
      class="cls-1"
      points="148.17 32.14 169.65 32.14 169.65 24.22 148.17 24.22 148.17 8.44 179.13 8.44 179.13 0.42 139.76 0.42 139.76 55.84 179.13 55.84 179.13 47.92 148.17 47.92 148.17 32.14" />
  </svg>
</template>
