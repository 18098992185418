import { sortArrayBy } from '@/util/globals';
import { usePage } from '@inertiajs/vue3';
import { isAdminOfGroup } from '@/util/group-helpers';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';

export const getFlatMappedVenuesForFestival = (venues: []) => {
  let index = 0;
  return venues.flatMap((venue) => {
    let mappedChildren = [];
    const v = {
      order: venue.id === 'withoutResources' ? 9999 : index++,
      id: venue.id,
      title: venue.title,
      model: venue.model,
      model_id: venue.model_id,
    };
    if (venue.children?.length > 0) {
      mappedChildren = venue.children.map(
        (room: { id: unknown; title: unknown; model: unknown; model_id: unknown }) => {
          return {
            order: index++,
            id: room.id,
            title: room.title,
            model: room.model,
            model_id: room.model_id,
            venue_id: venue.model_id,
          };
        }
      );
    }
    const arr = [v].concat(mappedChildren);
    return sortArrayBy(arr, 'order');
  });
};

export const isAdminOfFestival = async (festivalId: number, ownerId?: number | null) => {
  if (!festivalId) return false;
  const user = usePage().props.auth.user;
  if (user.is_admin) return true;

  if (ownerId) {
    const adminOfOwner = isAdminOfGroup(ownerId);
    if (adminOfOwner) return true;
  }
  const contextSidebarStore = useContextSidebarStore();
  await contextSidebarStore.fetchFestivals();

  const festival = contextSidebarStore.personalFestivals.find((festival) => festival.id === festivalId);
  return !!(festival && (festival.admin || festival.permissions.includes('update')));
};
