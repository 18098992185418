export const updateObjectValues = (object: object, anotherObject: object): void => {
  const keys = Object.keys(object);
  keys.forEach((key) => {
    object[key] = anotherObject[key];
  });
};

// create a none reactive copy of an object
export const copyObject = <T>(object: T): T => {
  return JSON.parse(JSON.stringify(object));
};
