<script setup lang="ts">
import { MetaDataResource } from '@/types/meta-data';
import { useLocalStorage } from '@vueuse/core';
import moment from 'moment';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import ReportModal from '@/components/Modals/ReportModal.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { dateFormat } from '@/variables/date-format';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions';

type Props = {
  metaData: MetaDataResource[];
  isVenue: boolean;
};

defineProps<Props>();

const emit = defineEmits<{
  (event: 'downloading', arg: boolean): void;
}>();

const toast = useToast();

const csv = useLocalStorage('meta_data_report_csv', true);
const with_cancelled = useLocalStorage('meta_data_report_with_cancelled', false);
const with_rooms = useLocalStorage('meta_data_report_with_rooms', false);
const start = ref(moment().startOf('month').format(dateFormat));
const end = ref(moment().endOf('month').format(dateFormat));
const meta_data_id = ref(null);
const working = ref(false);
const metaDataFields = ref([]);
const includeMetaDataFields = useLocalStorage('meta_data_report_include_fields', false);
// const includedMetaDataFieldIds = useLocalStorage<number[]>('meta_data_report_selected_field_ids', []);
const includedMetaDataFieldIds = ref([]);

watch(meta_data_id, () => {
  includeMetaDataFields.value = false;
  includedMetaDataFieldIds.value = [];
});

watch(includeMetaDataFields, () => {
  includedMetaDataFieldIds.value = [];
});
watch(working, () => {
  emit('downloading', working.value);
});

const canSave = computed(() => {
  const hasValidDates = moment(start.value).isValid() && moment(end.value).isValid();
  const startEarlier = moment(end.value) > moment(start.value);
  const metaDataId = meta_data_id.value !== null;
  return [hasValidDates, startEarlier, metaDataId].every((v) => v);
});

const downloadReport = async () => {
  if (!canSave.value) {
    return;
  }
  if (working.value) {
    return;
  }
  if (!meta_data_id.value) {
    return;
  }
  working.value = true;
  const response = await axios
    .post(`/api/meta-data/${meta_data_id.value}/report`, {
      csv: csv.value,
      start: start.value,
      end: end.value,
      with_cancelled: with_cancelled.value,
      with_rooms: with_rooms.value,
      meta_data_field_ids: includeMetaDataFields ? includedMetaDataFieldIds.value : null,
    })
    .catch((error) => {
      console.error(error);
      working.value = false;
      toast.warning('Something went wrong, please try again later');
    });
  if (response.status === 204) {
    toast.warning('There are no result for the requested period');
    working.value = false;
    return;
  }
  await downloadFile(response.data.url, response.data.name);
  working.value = false;
};

const fetchMetaDataFields = () => {
  if (!meta_data_id.value) {
    return;
  }
  axios.get(`/api/meta-data/${meta_data_id.value}`).then((response) => {
    metaDataFields.value = response.data.fields;
  });
};

watch(meta_data_id, () => {
  fetchMetaDataFields();
});
</script>

<template>
  <ReportModal
    v-model:start="start"
    v-model:end="end"
    v-model:with-cancelled="with_cancelled"
    v-model:as-c-s-v="csv"
    :with-button="false"
    :can-download="canSave"
    title="Download Meta Data Report"
    :working="working"
    @download="downloadReport">
    <VSelect
      v-model="meta_data_id"
      label="Meta Data"
      :required="true"
      :options="metaData"
      option-value="title" />

    <SettingToggle
      v-if="isVenue"
      v-model="with_rooms"
      label="With Rooms"
      title="If enabled, rooms added to the events will be included in the report." />
    <div v-else />

    <SettingToggle
      v-if="meta_data_id && metaDataFields"
      v-model="includeMetaDataFields"
      :class="meta_data_id && metaDataFields && includeMetaDataFields ? '' : 'col-span-2'"
      label="Filter Fields to include"
      title="If enabled, you can filter which fields to be included in the download." />

    <VMultiselect
      v-if="meta_data_id && metaDataFields && includeMetaDataFields"
      v-model="includedMetaDataFieldIds"
      :close-on-select="false"
      placeholder="If no fields selected, all will be included"
      title="If no fields selected, all will be included"
      option-label="title"
      :options="
        metaDataFields.map((field) => {
          return {
            title: field.title,
            id: field.id,
          };
        })
      "
      label="Select Fields" />
  </ReportModal>
</template>
