import { ProjectLeader } from '@/types/group';
import { UserMinimalResource } from '@/types/user';
import { useToast } from 'vue-toastification';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { ShiftResource } from '@/types/event';

export type GroupPermissions = {
  admin: boolean;
  read: boolean;
  auto_accept: boolean;
  project_leader: boolean;
  superAdmin: boolean;
};

export type GroupInvitedUserInviteResource = {
  id: number;
  email: string;
  group_id: number;
  permissions: GroupPermissions;
};

export type GroupUserPermissions = {
  superadmin: boolean;
  admin: boolean;
  read: boolean;
  auto_accept: boolean;
  project_leader: boolean;
  active: boolean;
};

export type GroupUserNormalResource = {
  id: number;
  name: string;
  country_code: string | null;
  phone: string | null;
  email: string;
  slug: string;
  job_title: string | null;
  avatar_url: string;
  permissions: GroupUserPermissions;
};

export type GroupNormalResource = {
  id: number;
  parent_id: number | null;
  venue_id: number | null;
  name: string;
  slug: string;
  description: string | null;
  using_project_leaders: boolean;
  force_project_leader_on_invite: boolean;
  members: GroupUserNormalResource[];
  children: GroupNormalResource[];
  contact_person_id: number | null;
  contact_person: UserMinimalResource | null;
};

export type GroupUsage = {
  id: number;
  parent_id: number | null;
  unlimited_events: boolean;
  admins_allowed_count: number;
  admins_used_count: number;
  events_allowed_count: number;
  events_used_count: number;
};

export type GroupEvent = {
  resourceIds: string[] | null;
  start: string;
  end: string;
  title?: string;
  slug?: string;
  festival_id?: number;
  color: string;
  classNames?: 'fc-festival-in-scheduler';
  resourceEditable?: boolean;
  shift_type_id?: number | null;
  shift_title?: string | null;
  shift_description?: string | null;
  approved?: boolean;
  for_sale?: boolean;
  in_timeline?: boolean;
  write?: boolean;
  invited_by?: number | null;
  check_in?: string | null;
  check_out?: string | null;
  shift_id?: number;
  events?: {
    id: number;
    name: string;
  }[];
  isCancelled?: boolean;
  isAccepted?: boolean;
  isDeclined?: boolean;
  isAssigned?: boolean;
  recurring_original_id?: number | null;
  roomIds?: number[];
  owner_group_id?: number | null;
  event_id?: number;
  cancelled_at?: string | null;
  is_recurring?: boolean;
  is_festival?: boolean;
  invite_id?: number;
  is_simple?: boolean;
  project_leader_id?: number | null;
  event_type_id?: number | null;
  status_bar_number?: number;
};

export type GroupCrewResource = {
  id?: string;
  order?: number;
  title: string | null;
  job_title?: string | null;
  model?: string;
  model_id?: number;
  parentId?: number | null;
  color?: string;
  background_color?: string;
  status?: string;
  invite_id?: number;
  invited_via_id?: number | null;
  recurring_original_id?: number | null;
  shift_id?: number;
  via_id?: number | null;
  force_project_leader_on_invite?: boolean;
  using_project_leaders?: boolean;
  classes?: string;
};

export const getContextSidebarGroup = async (groupId: number) => {
  const url = `/api/context-sidebar/groups/${groupId}`;
  return axios.get(url);
};

export const getMetaDataForGroup = (groupId: number) => {
  const url = '/api/meta-data';
  const params = {
    model_type: 'App\\Group',
    model_id: groupId,
    with_fields: true,
  };

  return axios.get(url, { params });
};

export const deleteMemberFromGroup = async (
  group: { id: number; name: string },
  member: { id: number; name: string },
  withToast = true,
  withCertainty = true
) => {
  const sure = withCertainty
    ? await useCertaintyModal().assertCertain(
        'Remove User?',
        `Are you sure that you want to remove
      '${member.name}' from '${group.name}'? This
       will delete all invites to the user from this group, and the user will lose access to the group.`
      )
    : true;

  if (!sure) return false;
  const response = await axios.delete(`/api/groups/${group.id}/users/${member.id}`);
  if (response.status === 200 && withToast) {
    useToast().success(`${member.name}' has been removed from ${group.name}`);
  }
  return true;
};

export const getGroupUsage = async (groupId: number) => {
  const url = `/api/groups/${groupId}/usage`;
  return axios.get<GroupUsage>(url);
};

export const getGroup = async (groupId: number) => {
  const url = `/api/groups/${groupId}`;
  return axios.get<GroupNormalResource>(url);
};

export const getGroupInviteUsers = async (groupId: number) => {
  const url = `/api/groups/${groupId}/invitedUsers`;
  return axios.get<GroupInvitedUserInviteResource[]>(url);
};

export const getGroupProjectLeaders = async (groupId: number) => {
  const url = `/api/groups/${groupId}/projectLeaders`;
  return axios.get<ProjectLeader[]>(url);
};

export const getGroupAssignments = async (
  groupId: number,
  params: {
    start: string;
    end: string;
    with_resource_ids?: boolean;
    with_simple?: boolean;
    with_cancelled?: boolean;
    with_colors?: boolean;
    with_festivals?: boolean;
    with_shifts?: boolean;
  }
) => {
  const url = `/api/groups/${groupId}/assignments`;
  return axios.get<GroupEvent[]>(url, {
    params,
  });
};

export const getGroupInvites = async (
  groupId: number,
  params: { type?: string; start: string | null; end: string | null }
) => {
  const url = `/api/groups/${groupId}/invites`;
  return axios.get<
    {
      id: number;
      event_name: string;
      start: string;
      end: string;
      project_leader_id: number | null;
      accepted_at: string | null;
      declined_at: string | null;
      created_at: string;
    }[]
  >(url, {
    params,
  });
};

export const getGroupCrew = async (groupId: number, params?: { dashboard?: boolean; event_id?: number }) => {
  const url = `/api/groups/${groupId}/crew`;
  return axios.get<GroupCrewResource[]>(url, {
    params,
  });
};

export type PaginateResource = {
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number | null;
    last_page: number;
    links: {
      active: boolean;
      label: string;
      url: string | null;
    }[];
    path: string;
    per_page: number;
    to: number | null;
    total: number;
  };
};

// app/Http/Resources/Groups/GroupUserEventListInviteResource.php
export type GroupUserEventListInviteResource = {
  id: number;
  event: {
    id: number;
    slug: string;
    name: string;
    venue_name: string;
    start_date: string;
    end_date: string;
    cancelled_at: string | null;
    owner_type: string;
    owner_id: number;
  };
  project_leader_id: number | null;
  invitable_type: string;
  invitable_id: number;
  isAccepted: string | null;
  isDeclined: string | null;
  accepted_at: string | null;
  declined_at: string | null;
  start: string;
  end: string;
  write: boolean;
  shifts: ShiftResource[];
};

export const getMemberInvitesList = async (groupId: number, currentPage = 1, perPage = 20, past = false) => {
  return axios.get<PaginateResource & { data: GroupUserEventListInviteResource[] }>(
    `/api/groups/${groupId}/member-invites-list`,
    {
      params: {
        per_page: perPage,
        current_page: currentPage,
        is_past: past,
      },
    }
  );
};

type Params = {
  with_simple: boolean;
  with_cancelled: boolean;
  with_festival_events: boolean;
  is_past: boolean;
  project_leader_ids: string[];
  event_type_ids: string[];
  per_page: number;
  page: number;
};

export type GroupEventListInviteResource = {
  id: number;
  event: {
    id: number;
    slug: string;
    name: string;
    venue_name: string | null;
    start_date: string;
    end_date: string;
    cancelled_at: string | null;
    owner_type: string;
    owner_id: number;
  };
  project_leader_id: number | null;
  invitable_type: string;
  invitable_id: number;
  parent_id: number | null;
  isAccepted: boolean;
  isDeclined: boolean;
  accepted_at: string;
  declined_at: string;
  start: string;
  end: string;
  event_type_id: number;
  status_bar_number: number | null;
  write: boolean;
  is_simple_event: boolean;
};

type AxiosResponse = PaginateResource & { data: GroupEventListInviteResource[] };

export const getInviteList = async (groupId: number, params: Params) =>
  axios.get<AxiosResponse>(`/api/groups/${groupId}/invites-list`, {
    params,
  });
