import { getPerformanceTypes } from '@/services/api-performance-types';
import { PerformanceModelType, PerformanceTypesResource } from '@/types/performance';
import { formatModel } from '@/util/store-helpers';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';

export const usePerformanceTypesStore = defineStore('performance-types', () => {
  const list = ref<Map<string, PerformanceTypesResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (modelType: PerformanceModelType, modelId: number) => {
    Echo.join(`On.${modelType}.${modelId}`).listen('.performanceType.changed', async () => {
      await fetchPerformanceTypes(modelType, modelId);
    });
  };

  const fetchPerformanceTypes = async (modelType: PerformanceModelType, modelId: number) => {
    loading.value = true;
    const { data } = await getPerformanceTypes(modelType, modelId);
    if (!list.value.has(formatModel(modelType, modelId))) {
      listenForBroadcast(modelType, modelId);
    }
    list.value.set(formatModel(modelType, modelId), data);
    loading.value = false;
  };

  const addOrUpdatePerformanceTypes = async (
    modelType: PerformanceModelType,
    modelId: number,
    performanceType: PerformanceTypesResource
  ) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await fetchPerformanceTypes(modelType, modelId);
      return;
    }
    let data = list.value.get(formatModel(modelType, modelId));
    data = exchangeValuesOfObject(performanceType, data);
    list.value.set(formatModel(modelType, modelId), data);
  };

  const removePerformanceType = async (modelType: PerformanceModelType, modelId: number, performanceTypeId: number) => {
    if (!list.value.has(formatModel(modelType, modelId))) {
      await fetchPerformanceTypes(modelType, modelId);
      return;
    }
    const data = list.value.get(formatModel(modelType, modelId));
    const index = getIndexFromArrayBasedOnId(performanceTypeId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(formatModel(modelType, modelId), data);
    } else {
      await fetchPerformanceTypes(modelType, modelId);
    }
  };

  const changeOutList = (modelType: PerformanceModelType, modelId: number, types: PerformanceTypesResource[]) => {
    list.value.set(formatModel(modelType, modelId), types);
  };

  return {
    list,
    loading,
    changeOutList,
    fetchPerformanceTypes,
    addOrUpdatePerformanceTypes,
    removePerformanceType,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePerformanceTypesStore, import.meta.hot));
}
