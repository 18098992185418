<script setup lang="ts">
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { computed, nextTick, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import { useToast } from 'vue-toastification';
import { blurActiveElement, createUuId } from '@/util/globals';
import DataList from '@/components/Inputs/Components/DataList.vue';

type Props = {
  modelValue: number | string | null;
  label?: string | null;
  labelTitle?: string | null;
  required?: boolean;
  placeholder?: string;
  withDecimals?: boolean;
  withControlles?: boolean;
  transparentControllers?: boolean;
  onlyControllers?: boolean;
  disabled?: boolean;
  canEdit?: boolean;
  readonly?: boolean;
  min?: number;
  max?: number;
  step?: number;
  rounded?: boolean;
  iconLeft?: string;
  iconRight?: string;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'block';
  isHidden?: boolean;
  title?: string;
  dataListValues?: number[];
  maxDecimals?: number;
  emitNullValue?: boolean;
  tabindex?: number | null;
  dontForceDecimals?: boolean;
  setFocus?: boolean;
  formatDataListOption?: Function;
};

const props = withDefaults(defineProps<Props>(), {
  label: null,
  placeholder: '',
  withDecimals: false,
  withControlles: false,
  transparentControllers: false,
  min: 0,
  max: Infinity,
  step: 1,
  disabled: false,
  canEdit: true,
  rounded: false,
  readonly: false,
  iconLeft: '',
  iconRight: '',
  size: 'medium',
  isHidden: false,
  title: '',
  labelTitle: null,
  required: false,
  dataListValues: () => [],
  formatDataListOption: () => null,
  maxDecimals: 2,
  emitNullValue: true,
  onlyControllers: false,
  tabindex: null,
  dontForceDecimals: true,
  setFocus: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | number | null): void;
  (event: 'update:model-value', value: string | number | null): void;
  (event: 'blur', value: string | number | null): void;
  (event: 'dataListSelected', value: string | number | null): void;
}>();

const data = useVModel(props, 'modelValue', emit);
const currentDataListOption = ref(null);

const input = ref<HTMLInputElement | null>(null);

const decreasable = computed(() => {
  if (data.value === null || data.value === undefined) return true;
  if (props.min < 0) {
    if (data.value === '-') return true;
  }

  if (props.withDecimals && typeof data.value === 'string') {
    return parseFloat(data.value) > props.min;
  }

  const number = Number(data.value);
  return number > props.min;
});

const increasable = computed(() => {
  if (data.value === null || data.value === undefined) return true;

  if (props.min < 0) {
    if (data.value === '-') return true;
  }

  if (props.withDecimals && typeof data.value === 'string') {
    return parseFloat(data.value) < props.max;
  }

  const number = Number(data.value);
  return number < props.max;
});

const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/;
const normalizeDecimalNumber = (value: number, times = 100000000000) =>
  REGEXP_DECIMALS.test(String(value)) ? Math.round(value * times) / times : value;

const setValue = (value: number) => {
  const oldValue = props.modelValue;
  let newValue = typeof value !== 'number' ? parseFloat(value) : value;
  if (!isNaN(newValue)) {
    if (props.min <= props.max) {
      newValue = Math.min(props.max, Math.max(props.min, newValue));
    }
    if (props.rounded) {
      newValue = Math.round(newValue);
    }
  }
  if (newValue === oldValue) {
    (input.value as HTMLInputElement).value = String(newValue);
  }
  data.value = newValue;
};

const decrease = () => {
  if (!props.withDecimals) {
    if (decreasable.value) {
      let value = data.value;
      if (!value || isNaN(value)) {
        value = 0;
      }
      setValue(normalizeDecimalNumber(Number(value) - 1));
    }
  } else {
    if (props.dontForceDecimals) {
      if (data.value === null || data.value === undefined) {
        data.value = props.min.toFixed(0);
      } else {
        if (typeof data.value === 'string' && parseFloat(data.value) <= props.max) {
          if (parseFloat(data.value).toString().includes('.')) {
            const parts = data.value.split('.');
            let multiplier = 0.01;
            if (parts[1]?.length === 1) {
              multiplier = 0.1;
            }

            if (multiplier === 0.1) {
              const arg = Number(parseFloat(data.value) - multiplier).toFixed(1);
              const split = arg.split('.');
              if (split[1] === '0') {
                data.value = Number(parseFloat(data.value) - multiplier).toFixed(0);
              } else {
                data.value = Number(parseFloat(data.value) - multiplier).toFixed(1);
              }
            } else {
              const newNumber = Number(parseFloat(data.value) - 0.01).toString();
              const newNumberParts = newNumber.split('.');

              if (newNumberParts.length > 1 && newNumberParts[1].length > 2) {
                const arg = Number(parseFloat(data.value) - multiplier).toFixed(props.maxDecimals);
                const split = arg.split('.');
                const split2 = split[1].split('');
                if (split2[1] === '0') {
                  data.value = Number(parseFloat(data.value) - 0.01).toFixed(1);
                } else {
                  data.value = Number(parseFloat(data.value) - 0.01).toFixed(props.maxDecimals);
                }
              } else {
                data.value = Number(parseFloat(data.value) - 0.01).toString();
              }
            }
          } else {
            data.value = Number(parseFloat(data.value) - 1).toString();
          }
        }
      }
    } else {
      if (data.value === null || data.value === undefined) {
        data.value = props.min.toFixed(props.maxDecimals);
      } else {
        if (typeof data.value === 'string' && parseFloat(data.value) < props.max) {
          if (parseFloat(data.value).toString().includes('.')) {
            data.value = Number(parseFloat(data.value) - 0.01).toFixed(props.maxDecimals);
          } else {
            data.value = Number(parseFloat(data.value) - 1).toFixed(props.maxDecimals);
          }
        }
      }
    }
  }
};

const increase = () => {
  if (!props.withDecimals) {
    if (increasable.value) {
      let value = data.value;
      if (!value || isNaN(value)) {
        value = 0;
      }
      setValue(normalizeDecimalNumber(Number(value) + 1));
    }
  } else {
    if (props.dontForceDecimals) {
      if (data.value === null || data.value === undefined) {
        data.value = props.min.toFixed(0);
      } else {
        if (typeof data.value === 'string' && parseFloat(data.value) < props.max) {
          if (parseFloat(data.value).toString().includes('.')) {
            const parts = data.value.split('.');
            let multiplier = 0.01;
            if (parts[1]?.length === 1) {
              multiplier = 0.1;
            }

            const num = Number(parseFloat(data.value) + multiplier).toString();
            const numParts = num.split('.');

            if (numParts[1]?.length > 2) {
              if (parts[1]?.length === 1) {
                data.value = Number(parseFloat(data.value) + multiplier).toFixed(1);
              } else {
                data.value = Number(parseFloat(data.value) + multiplier).toFixed(props.maxDecimals);
              }
            } else {
              data.value = Number(parseFloat(data.value) + multiplier).toString();
            }
          } else {
            data.value = Number(parseFloat(data.value) + 1).toString();
          }
        }
      }
    } else {
      if (data.value === null || data.value === undefined) {
        data.value = props.min.toFixed(props.maxDecimals);
      } else {
        if (typeof data.value === 'string' && parseFloat(data.value) < props.max) {
          if (parseFloat(data.value).toString().includes('.')) {
            data.value = Number(parseFloat(data.value) + 0.01).toFixed(props.maxDecimals);
          } else {
            data.value = Number(parseFloat(data.value) + 1).toFixed(props.maxDecimals);
          }
        }
      }
    }
  }
};
const selectDataListOption = (newValue) => {
  emit('update:modelValue', newValue);
  emit('blur', newValue);
  nextTick(() => {
    emit('dataListSelected', newValue);
  });
  inFocus.value = false;
  blurActiveElement();
};

const onEnterClick = (e: KeyboardEvent) => {
  if (currentDataListOption.value !== null) {
    selectDataListOption(currentDataListOption.value);
  }
  blurActiveElement();
};

const onKeyPressed = (e: KeyboardEvent) => {
  if (e.ctrlKey && (e.key === 'v' || e.key === 'c')) return;
  if (e.ctrlKey && e.key === 'a') {
    input.value.setSelectionRange(0, input.value.value.length);
    return;
  }
  if (e.metaKey && (e.key === 'v' || e.key === 'c')) return;

  let stringValue = String(data.value);
  if (e.key === '-' && stringValue.includes('-')) {
    e.preventDefault();
    return;
  }

  const validKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

  if (validKeys.includes(e.key)) return;

  if (e.key === 'ArrowUp' && increasable.value) {
    e.preventDefault();
    increase();
    return;
  }

  if (e.key === 'ArrowDown' && decreasable.value) {
    e.preventDefault();
    decrease();
    return;
  }

  if (props.min < 0) {
    if (e.key === '-') {
      if (
        (e.target as HTMLInputElement).selectionStart === 0 &&
        (e.target as HTMLInputElement).selectionEnd === (e.target as HTMLInputElement).value.length
      ) {
        return;
      }
    }
  }

  if (props.withDecimals) {
    if (e.key === 'ArrowUp' && increasable.value) {
      e.preventDefault();
      if ((e.target as HTMLInputElement).value === '') {
        data.value = props.min.toFixed(props.maxDecimals);
        (e.target as HTMLInputElement).value = props.min.toFixed(props.maxDecimals);
      } else {
        if (parseFloat((e.target as HTMLInputElement).value) < props.max) {
          data.value = Number(parseFloat((e.target as HTMLInputElement).value) + 0.01).toFixed(props.maxDecimals);
          (e.target as HTMLInputElement).value = Number(
            parseFloat((e.target as HTMLInputElement).value) + 0.01
          ).toFixed(props.maxDecimals);
        }
      }
      return;
    }
    if (e.key === 'ArrowDown' && decreasable.value) {
      e.preventDefault();
      if ((e.target as HTMLInputElement).value === '') {
        data.value = props.max.toFixed(props.maxDecimals);
        (e.target as HTMLInputElement).value = props.max.toFixed(props.maxDecimals);
      } else {
        if (parseFloat((e.target as HTMLInputElement).value) > props.min) {
          data.value = Number(parseFloat((e.target as HTMLInputElement).value) - 0.01).toFixed(props.maxDecimals);
          (e.target as HTMLInputElement).value = Number(
            parseFloat((e.target as HTMLInputElement).value) - 0.01
          ).toFixed(props.maxDecimals);
        }
      }
      return;
    }
    if (props.min < 0 && e.key === '-' && (e.target as HTMLInputElement).value === '') {
      return;
    }
    if (e.key === ',') {
      if (!(e.target as HTMLInputElement).value.includes('.')) {
        (e.target as HTMLInputElement).value += '.';
      }
      e.preventDefault();
    }

    if (e.key === '.') {
      if ((e.target as HTMLInputElement).value.includes('.')) {
        e.preventDefault();
      }
      return;
    }

    let regex = props.min >= 0 ? /[0-9]/ : /[0-9]|[-]/;
    if (!regex.test(e.key)) {
      e.preventDefault();
      return;
    }
    if ((e.target as HTMLInputElement).selectionStart !== (e.target as HTMLInputElement).selectionEnd) {
      return;
    }
    const value = (e.target as HTMLInputElement).value;
    const parts = value.split('.');
    const cursorPosition = (e.target as HTMLInputElement).selectionStart;
    if (!isNaN(Number(e.key)) && parts.length === 2 && parts[1].length >= props.maxDecimals) {
      if (cursorPosition !== null && cursorPosition > parts[0].length) {
        e.preventDefault();
        return;
      }
      return;
    }
  } else {
    const validKeys = ['ArrowUp', 'ArrowDown'];
    if (validKeys.includes(e.key)) return;
    if (props.min < 0 && e.key === '-' && (e.target as HTMLInputElement).value === '') {
      return;
    }
    let regex = props.min >= 0 ? /[0-9]/ : /[0-9]|[-]/;
    if (!regex.test(e.key)) {
      e.preventDefault();
      return;
    }
  }
};

const sizes = {
  tiny: 'w-[100px]',
  small: 'w-[140px]',
  medium: 'w-[300px]',
  large: 'w-[400px]',
  block: 'w-full',
};

const onUpdate = (e: number | string) => {
  if (e === '') {
    data.value = null;
  }
};

const inFocus = ref(false);

const onBlur = (e: FocusEvent) => {
  setTimeout(() => {
    inFocus.value = false;
  }, 250);

  const trimNumber = (e.target as HTMLInputElement).value.trim();
  const validNumber = trimNumber.replace(/\s/g, '');
  if (props.withDecimals) {
    let newValue: string | null = validNumber;

    if (newValue === '') {
      if (props.emitNullValue) {
        data.value = null;
        newValue = null;
        emit('blur', newValue);
        return;
      } else {
        if (props.min < 0) {
          if (props.dontForceDecimals) {
            data.value = Number(0).toFixed(0);
            newValue = Number(0).toFixed(0);
            (e.target as HTMLInputElement).value = newValue;
            emit('blur', newValue);
            return;
          } else {
            data.value = Number(0).toFixed(props.maxDecimals);
            newValue = Number(0).toFixed(props.maxDecimals);
            (e.target as HTMLInputElement).value = newValue;
            emit('blur', newValue);
            return;
          }
        }
        data.value = props.min.toFixed(props.maxDecimals);
        newValue = props.min.toFixed(props.maxDecimals);
        (e.target as HTMLInputElement).value = newValue;
        emit('blur', newValue);
        return;
      }
    }
    if (newValue !== null) {
      if (Number(newValue) > props.max) {
        data.value = Number(props.max).toFixed(props.maxDecimals);
        newValue = Number(props.max).toFixed(props.maxDecimals);
        useToast().error('Max value is ' + props.max);
        (e.target as HTMLInputElement).value = newValue;
      }
      if (Number(newValue) < props.min) {
        data.value = Number(props.min).toFixed(props.maxDecimals);
        newValue = Number(props.min).toFixed(props.maxDecimals);
        useToast().error('Min value is ' + props.min);
        (e.target as HTMLInputElement).value = newValue;
      }
    }

    const parts = newValue.split('.');

    if (props.dontForceDecimals) {
      if (parts[1] === '') {
        data.value = parts[0];
        newValue = parts[0];
        (e.target as HTMLInputElement).value = newValue;
      }
    } else {
      if (parts[0] === '-' && parts.length === 1) {
        data.value = `${newValue}0.00`;
        newValue = `${newValue}0.00`;
        (e.target as HTMLInputElement).value = newValue;
      } else if (parts.length === 1) {
        data.value = `${newValue}.00`;
        newValue = `${newValue}.00`;
        (e.target as HTMLInputElement).value = newValue;
      } else if (parts[1].length === 1) {
        data.value = `${newValue}0`;
        newValue = `${newValue}0`;
        (e.target as HTMLInputElement).value = newValue;
      } else if (parts[1].length === 0) {
        data.value = `${newValue}00`;
        newValue = `${newValue}00`;
        (e.target as HTMLInputElement).value = Number(newValue).toFixed(props.maxDecimals);
      }
    }
    emit('blur', newValue);
  } else {
    let newValue: string | null = validNumber;
    if (newValue === '') {
      if (props.emitNullValue) {
        data.value = null;
        newValue = null;
        emit('blur', newValue);
        return;
      } else {
        data.value = props.min;
        (e.target as HTMLInputElement).value = String(props.min);
        emit('blur', props.min);
        return;
      }
    }
    if (newValue !== null) {
      if (Number(newValue) > props.max) {
        data.value = props.max;
        newValue = String(props.max);
        useToast().error('Max value is ' + props.max);
        (e.target as HTMLInputElement).value = newValue;
      }
      if (Number(newValue) < props.min) {
        data.value = props.min;
        newValue = String(props.min);
        useToast().error('Min value is ' + props.min);
        (e.target as HTMLInputElement).value = newValue;
      }
    }
    emit('blur', Number(newValue));
  }
};

const dataListId = createUuId('data-list');

if (props.setFocus) {
  [0, 10, 20, 50].forEach((t) => {
    setTimeout(() => {
      input.value.focus();
    }, t);
  });
}
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :is-hidden="isHidden"
      :title="labelTitle"
      :mandatory-text="required ? 'Mandatory' : null"
      :label="label" />

    <div
      :class="[sizes[size], isHidden ? 'bg-transparent' : 'bg-inputs-background']"
      class="flex h-[40px] overflow-hidden rounded-md bg-inputs-background">
      <button
        v-if="withControlles"
        :class="[
          isHidden
            ? 'border-transparent text-textColor hover:text-black'
            : transparentControllers
              ? ''
              : 'border-highlight bg-highlight text-black hover:border-highlight-hover',
        ]"
        class="h-full rounded-l-[6px] border border-r-0 px-2 hover:bg-highlight-hover disabled:border-disabled disabled:bg-disabled"
        :disabled="disabled || !canEdit || readonly || !decreasable"
        @click.prevent="decrease">
        <i class="fa fa-fw fa-minus" />
      </button>

      <div class="relative inline-flex flex-1">
        <div
          v-if="iconLeft"
          :class="inFocus ? ' text-textColor ' : ' text-textColor-soft '"
          class="absolute inline-flex h-full w-7 items-center justify-center">
          <i
            class="fa fa-fw"
            :class="iconLeft" />
        </div>

        <input
          ref="input"
          v-model="data"
          :tabindex="tabindex"
          :type="withDecimals ? 'text' : 'number'"
          inputmode="numeric"
          :min="min"
          :max="max"
          :list="dataListId"
          :step="withDecimals ? '0.01' : step"
          class="w-full rounded-[6px] text-base bg-transparent text-textColor focus:border-highlight focus:ring-transparent enabled:hover:border-textColor-soft enabled:hover:focus:border-highlight disabled:cursor-not-allowed"
          :class="[
            iconLeft ? 'pl-7' : 'pl-4',
            iconRight ? 'pr-7' : 'pr-4',
            withControlles ? 'rounded-none text-center' : 'rounded-[6px]',
            isHidden ? 'border-transparent ' : 'border-borderColor',
            ['small', 'tiny'].includes(size) ? 'text-center' : '',
          ]"
          :readonly="readonly"
          :disabled="disabled || !canEdit || (!decreasable && !increasable) || (onlyControllers && withControlles)"
          :placeholder="placeholder"
          :title="title"
          autocomplete="off"
          @focus="inFocus = true"
          @blur="onBlur"
          @update:model-value="onUpdate"
          @keydown.enter="onEnterClick"
          @keydown="onKeyPressed" />

        <DataList
          v-if="dataListValues"
          v-model:current-data-list-option="currentDataListOption"
          :with-up-and-down="false"
          :max-items="dataListValues.length"
          :in-focus="inFocus"
          :model-value="modelValue"
          :data-list-options="dataListValues"
          :container="input"
          @update:model-value="selectDataListOption">
          <template #option="{ option }">
            {{ formatDataListOption(option) }}
          </template>
        </DataList>
        <div
          v-if="iconRight"
          class="absolute right-0 top-0 inline-flex h-full w-7 items-center justify-center">
          <i
            class="fa fa-fw"
            :class="iconRight" />
        </div>
      </div>

      <button
        v-if="withControlles"
        :class="
          isHidden
            ? 'border-transparent text-textColor hover:text-black'
            : transparentControllers
              ? ''
              : 'border-highlight bg-highlight text-black hover:border-highlight-hover '
        "
        class="h-full rounded-r-[6px] border border-l-0 px-2 hover:bg-highlight-hover disabled:border-disabled disabled:bg-disabled"
        :disabled="disabled || !canEdit || readonly || !increasable"
        @click="increase">
        <i class="fa fa-fw fa-plus" />
      </button>
    </div>
  </div>
</template>
