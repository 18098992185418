<script setup lang="ts">
import VRichText from '@/components/Inputs/VRichText.vue';
import { ref } from 'vue';
import { WhisperObject } from '@/util/whisper-functions';

type props = {
  modelValue?: string;
  canEdit: boolean;
  whisper?: WhisperObject | null;
  placeholder?: string | null;
  minHeight?: number;
  emitBlurAsModelValue?: boolean;
};

const props = withDefaults(defineProps<props>(), {
  modelValue: '',
  whisper: null,
  placeholder: null,
  minHeight: 50,
  emitBlurAsModelValue: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'blur', value: string): void;
}>();

const focusingMyself = ref(false);
const focusedBySomeone = ref(null);

const emitValue = (value) => {
  if (!props.canEdit) return;
  if (props.emitBlurAsModelValue) return;
  emit('update:modelValue', value);
};
const emitBlur = (value) => {
  if (!props.canEdit) return;
  emit(props.emitBlurAsModelValue ? 'update:modelValue' : 'blur', value);
  focusingMyself.value = false;
};
</script>

<template>
  <div>
    <VRichText
      :model-value="modelValue"
      :can-edit="canEdit"
      :whisper="whisper"
      @update:model-value="emitValue"
      @blur="emitBlur"
      @focus="focusingMyself = true" />

    <!--    <textarea-->
    <!--      ref="elem"-->
    <!--      :placeholder="placeholder"-->
    <!--      :value="value"-->
    <!--      class="form-control"-->
    <!--      :style="'min-height:' + minHeight+ 'px; resize: none; overflow: hidden'"-->
    <!--      :disabled="!canEdit"-->
    <!--      @keyup="setHeight()"-->
    <!--      @focus="focusingMyself = true"-->
    <!--      @change="onChange"-->
    <!--      @blur="onBlur"-->
    <!--    />-->
    <!--    <WhisperHelper-->
    <!--      v-if="whisperChannel && whisperString"-->
    <!--      :focusing-myself="focusingMyself"-->
    <!--      :focused-by-someone="focusedBySomeone"-->
    <!--      :whisper-channel="whisperChannel"-->
    <!--      :whisper-string="whisperString"-->
    <!--      @focused-by-someone="focusedBySomeone = $event" />-->
  </div>
</template>
