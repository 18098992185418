import { CheckListResource, CheckListRowResource } from '@/types/check-list';

export type CheckListModel = 'Group' | 'Invite';

export const getCheckLists = async (model: CheckListModel, modelId: number) => {
  return axios.get<CheckListResource[]>('/api/check-lists', {
    params: {
      model_type: 'App\\' + model,
      model_id: modelId,
    },
  });
};

type CheckListData = {
  title: string;
  description: string | null;
  is_global: boolean;
  template_id: number | null;
  start: string | null;
  rows?: [] | null;
};

export const postCheckLists = async (model: 'Group' | 'Invite', modelId: number, checkList: CheckListData) => {
  return axios.post<CheckListResource>('/api/check-lists', {
    model_type: 'App\\' + model,
    model_id: modelId,
    ...checkList,
  });
};

export const patchCheckLists = async (id: number, data: Omit<CheckListData, 'is_global' | 'template_id'>) => {
  return axios.patch<string>(`/api/check-lists/${id}`, {
    title: data.title,
    description: data.description,
    start: data.start,
  });
};

export const destroyCheckLists = async (id: number) => {
  return axios.delete<string>(`/api/check-lists/${id}`);
};

export const postCheckListsToGroup = async (checkListId: number, groupId: number) => {
  return axios.post<string>(`/api/check-lists/${checkListId}/groups/${groupId}`);
};

export const destroyCheckListsToGroup = async (checkListId: number, groupId: number) => {
  return axios.delete<string>(`/api/check-lists/${checkListId}/groups/${groupId}`);
};

export const getCheckList = async (id: number) => {
  return axios.get<CheckListResource>(`/api/check-lists/${id}`);
};

type CheckListRowData = {
  order?: number;
  title?: string;
  description?: string | null;
};

export const patchCheckListRows = async (rowId: number, data: CheckListRowData) => {
  return axios.patch<string>(`/api/check-list-rows/${rowId}`, {
    ...data,
  });
};

export const postCheckListRows = async (title: string, checkListId: number, order: number) => {
  return axios.post<CheckListRowResource>('/api/check-list-rows', {
    title,
    check_list_id: checkListId,
    order,
  });
};

export const getCheckListRow = async (checkListRowId: number) => {
  return axios.get<CheckListRowResource>(`/api/check-list-rows/${checkListRowId}`);
};

export const destroyCheckListRows = async (checkListRowId: number) => {
  return axios.delete<string>(`/api/check-list-rows/${checkListRowId}`);
};

export const patchCheckListRowInput = async (rowId: number, data: { done: boolean; input: string | null }) => {
  return axios.patch<string>(`/api/check-list-rows/${rowId}/input`, {
    ...data,
  });
};
