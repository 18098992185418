<script setup lang="ts">
import DocumentUploader from '@/components/Documents/DocumentUploader.vue';
import { maxUploadSizeKey } from '@/provide/keys';
import { inject, ref } from 'vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useToast } from 'vue-toastification';
import DisplayLink from '@/components/Display/DisplayLink.vue';
import { getKey } from '@/util/globals';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';

type Props = {
  modelValue:
    | {
        filename: string;
        value: number;
      }
    | number
    | string
    | null;
  canEdit: boolean;
  documents?: object[] | null;
  emitDocumentActionAs?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  documents: () => [],
  emitDocumentActionAs: 'blur',
});

const emit = defineEmits<{
  (e: 'blur', value: string | null): void;
  (e: 'documentUploaded', value: { document: object }): void;
  (e: 'update:modelValue', value: string | null): void;
}>();

const maxMBSize = inject(maxUploadSizeKey, 100);

const name = ref(props.modelValue?.filename || '');
const uuid = ref(props.modelValue?.uuid || '');

const documentAdded = (file: UploadCareResponse) => {
  if (!file) {
    useToast().error('Error uploading document');
    return;
  }
  name.value = file.name;
  uuid.value = file.uuid;
  emit(props.emitDocumentActionAs ?? 'blur', {
    title: name.value,
    uuid: file.uuid,
    mime_type: file.mimeType,
  });
};

const removeDocument = async () => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Document?',
    'Are you sure you want to delete this document? It can not be restored.'
  );
  if (!deleteIt) return;

  try {
    await axios.post('/remove-doc-by-uuid', {
      uploadcare_uuid: uuid.value,
    });
  } catch (e) {}
  useToast().success(`${name.value} has been deleted.`);
  name.value = '';
  uuid.value = '';
  emit('blur', null);
};

const setup = () => {
  if (props.modelValue) {
    const documentId = getKey(props.modelValue, 'value');
    if (documentId) {
      emit('blur', documentId);
    }
  }
};
setTimeout(() => {
  setup();
}, 50);
</script>

<template>
  <div
    v-if="modelValue"
    class="flex h-[38px] items-center gap-1 overflow-hidden rounded">
    <div class="grid w-full grid-cols-[1fr_50px] rounded p-2 hover:bg-backgroundColor">
      <DisplayLink
        :link="`https://ucarecdn.com/${uuid}/`"
        class="truncate"
        :text="name"
        new-tab />
      <VButton
        tool-tip-text="Remove Document"
        icon="fa-trash"
        size="inTable"
        @click="removeDocument" />
    </div>
  </div>
  <div
    v-else
    class="h-full w-full">
    <DocumentUploader
      class="h-full w-full items-center justify-center"
      :max-m-b-size="maxMBSize"
      :can-edit="true"
      @document-uploaded="documentAdded" />
  </div>
</template>
