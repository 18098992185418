import { computed, onMounted, ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';

export type WhisperObject = {
  channel: string | null;
  string: string | null;
};
export type Whisperer = {
  id: number;
  name: string | null;
};

export const whisperForArea = (whisper: WhisperObject | null, inFocus: boolean) => {
  const focusedBySomeone = ref(null);
  const whisperChannel = computed(() => whisper?.channel);
  const whisperString = computed(() => whisper?.string);

  const whisperToOthers = (typing: boolean) => {
    if (!whisperChannel.value || !whisperString.value) return;
    Echo.join(whisperChannel.value).whisper(whisperString.value, {
      typing,
      user: {
        id: usePage().props.auth.user.id,
        name: usePage().props.auth.user.name,
      },
    });
  };
  onMounted(() => {
    if (!whisperChannel.value || !whisperString.value) return;
    Echo.join(whisperChannel.value).listenForWhisper(whisperString.value, (e) => {
      if (e.typing && e.user) {
        focusedBySomeone.value = e.user;
      } else {
        setTimeout(() => {
          focusedBySomeone.value = null;
        }, 2000);
      }
    });
  });

  watch(inFocus, () => {
    whisperToOthers(inFocus.value);
  });

  return computed(() => {
    return focusedBySomeone.value;
  });
};
