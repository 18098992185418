import { DocumentFolderResource, DocumentModelType, DocumentResource } from '@/types/document';

export const addDocumentToFolder = async (
  modelType: DocumentModelType,
  modelId: number,
  folderId: number,
  documentId: number
) => {
  const url = `/api/document-folders/${folderId}/attach/${documentId}`;
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.post<string>(url, params);
};

export const removeDocumentFromFolder = async (
  modelType: DocumentModelType,
  modelId: number,
  folderId: number,
  documentId: number
) => {
  const url = `/api/document-folders/${folderId}/detach/${documentId}`;
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.post<string>(url, params);
};

export const storeDocument = async (modelType: DocumentModelType, modelId: number, uploadcareId: string) => {
  const url = '/api/documents';
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
    uploadcare_uuid: uploadcareId,
  };
  return axios.post<DocumentResource>(url, params);
};

export const getUnconnectedDocuments = async (modelType: DocumentModelType, modelId: number) => {
  return axios.get<DocumentResource[]>('/api/document-folders/notConnected', {
    params: {
      model_type: `App\\${modelType}`,
      model_id: modelId,
    },
  });
};

export const getFolderDocuments = async (modelType: DocumentModelType, modelId: number) => {
  return axios.get<DocumentFolderResource[]>('/api/document-folders/', {
    params: {
      model_type: `App\\${modelType}`,
      model_id: modelId,
    },
  });
};

type NewDocumentFolder = {
  name: string;
  description: string;
  parent_id: number | null;
};

export const postDocumentFolder = async (modelType: DocumentModelType, modelId: number, data: NewDocumentFolder) => {
  const url = '/api/document-folders';
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
    name: data.name,
    description: data.description,
    parent_id: data.parent_id,
  };
  return axios.post<DocumentFolderResource>(url, params);
};

export const patchDocumentFolder = async (
  modelType: DocumentModelType,
  modelId: number,
  folderId: number,
  data: Omit<NewDocumentFolder, 'parent_id'>
) => {
  const url = `/api/document-folders/${folderId}`;
  const params = {
    name: data.name,
    description: data.description,
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.patch<string>(url, params);
};

export const destroyDocumentFolder = async (modelType: DocumentModelType, modelId: number, folderId: number) => {
  const url = `/api/document-folders/${folderId}`;
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.delete(url, { params });
};

export const patchDocument = async (
  modelType: DocumentModelType,
  modelId: number,
  documentId: number,
  fileName: string
) => {
  const url = `/api/documents/${documentId}`;
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
    filename: fileName,
  };
  return axios.patch<string>(url, params);
};

export const destroyDocument = async (documentId: number) => {
  return axios.delete<string>(`/api/documents/${documentId}`);
};

export const getDocuments = async (modelType: DocumentModelType, modelId: number) => {
  const url = '/api/documents';
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.get<DocumentResource[]>(url, { params });
};

export const getDocumentFolderOnModel = async (modelType: 'Event', modelId: number) => {
  const url = '/api/document-folders/on-model';
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.get<{
    document_folder_ids: number[];
  }>(url, { params });
};

export const addFolderToModel = async (modelType: 'Event', modelId: number, folderId: number) => {
  const url = `/api/document-folders/${folderId}/attach`;
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.post<string>(url, params);
};

export const removeFolderToModel = async (modelType: 'Event', modelId: number, folderId: number) => {
  const url = `/api/document-folders/${folderId}/detach`;
  const params = {
    model_type: `App\\${modelType}`,
    model_id: modelId,
  };
  return axios.post<string>(url, params);
};

export const addDocumentToFormPublic = async (formSlug: string, documentUUID: string, fileName: string) => {
  const url = `/forms/${formSlug}/documents`;
  return axios.post<DocumentResource>(url, {
    uploadcare_uuid: documentUUID,
    filename: fileName,
  });
};

export const getDocument = async (documentId: number) => {
  const url = `/api/documents/${documentId}`;
  return axios.get<DocumentResource>(url);
};
