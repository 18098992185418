<script lang="ts" setup>
import ContentHeader from '@/components/Content/ContentHeader.vue';
import VTable from '@/components/Tables/VTable.vue';
import Paginator from '@/components/Tables/Paginator.vue';
import { createUuId, slotEmpty } from '@/util/globals';
import type { Action } from '@/components/Inputs/Components/ActionButtons.vue';
import ActionButtons from '@/components/Inputs/Components/ActionButtons.vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

type PaginatorResource = {
  perPage: number;
  total: number;
  pages: number;
  page: number;
  action: (page: number) => void;
};

type Props = {
  actions?: Action;
  title?: string | null;
  superHeader?: string | null;
  loading?: boolean;
  paginator?: PaginatorResource | null;
  reduceHeight?: number;
  emptyAction?: null;
  emptyMessage?: string | null;
  emptyIcon?: string | null;
  stickyFirstColumn?: boolean;
  bordersOnTable?: boolean;
  snapRows?: boolean;
  firstLoad?: boolean;
  isPageHeader?: boolean;
  tableClasses?: string | null;
  backAction?: (() => void) | null;
  actionsAsButtons?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  actions: () => [],
  title: null,
  superHeader: null,
  loading: false,
  isPageHeader: false,
  snapRows: true,
  paginator: null,
  reduceHeight: 0,
  emptyAction: null,
  emptyMessage: null,
  emptyIcon: null,
  backAction: null,
  tableClasses: null,
  actionsAsButtons: true,
  bordersOnTable: true,
  firstLoad: true,
});

const emit = defineEmits(['backClicked']);
const uuid = createUuId('header_');
const heightOfTable = ref(600);
const widthOfTable = ref<number | null>(null);
const header = ref<HTMLElement | null>(null);

const calcWidthOfTable = () => {
  const elem = document.getElementById(uuid);
  if (elem) {
    widthOfTable.value = elem.clientWidth;
  }
};

const calcHeightOfTable = () => {
  let height = window.innerHeight;
  const style = getComputedStyle(document.body);

  const remToPx = parseFloat(getComputedStyle(document.documentElement).fontSize);

  height -= style.getPropertyValue('--navbar-height').split('rem')[0] * remToPx;
  height -= style.getPropertyValue('--verification-banner').split('px')[0];

  if (props.reduceHeight) {
    height -= props.reduceHeight;
  }
  if (props.paginator) {
    height -= style.getPropertyValue('--context-sidebar-bottom-height').split('rem')[0] * remToPx;
  }

  const elem = document.getElementById(uuid);
  if (elem) {
    height -= elem.clientHeight;
  } else if (header.value) {
    height -= header.value.clientHeight;
  }
  height -= 5;
  heightOfTable.value = height;

  calcWidthOfTable();
};

onMounted(() => {
  calcHeightOfTable();
  setTimeout(() => {
    calcHeightOfTable();
  }, 50);
  window.addEventListener('resize', calcHeightOfTable);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', calcHeightOfTable);
});
</script>

<template>
  <div @change="calcHeightOfTable()">
    <div
      :id="uuid"
      ref="header"
      key="header"
      class="content-table-header">
      <slot name="title" />
      <ContentHeader
        v-if="title"
        :loading="loading"
        :super-header="superHeader"
        :title="title"
        :is-page-header="isPageHeader"
        :actions="actions"
        :actions-as-buttons="actionsAsButtons"
        :with-back-button="backAction !== null"
        @back="[backAction ? backAction($event) : null, emit('backClicked')]">
        <template #afterTitle>
          <slot name="afterTitle" />
        </template>
        <template #underTitle>
          <slot name="underTitle" />
        </template>
      </ContentHeader>

      <div
        v-else-if="actions.length || !slotEmpty($slots, 'searches')"
        class="border-b">
        <div
          :class="{ 'flex justify-between ': actions.length > 0 }"
          class="px-edge py-3"
          style="min-height: 38px">
          <div>
            <slot name="searches" />
          </div>
          <template v-if="actions.length">
            <ActionButtonGroup
              v-if="actionsAsButtons"
              style="margin-right: calc(var(--edge-padding) * -1); margin-top: -0.5rem"
              :actions="actions"
              inline />
            <ActionButtons
              v-else
              style="margin-right: calc(var(--edge-padding) * -1); margin-top: -0.5rem"
              :actions="actions"
              inline />
          </template>
        </div>
      </div>
    </div>
    <div
      class="overflow-auto"
      :class="(paginator ? ' shadow-border ' : '') + ' ' + tableClasses"
      :style="
        'max-height: ' +
        heightOfTable +
        'px;min-height: ' +
        heightOfTable +
        'px;height: ' +
        heightOfTable +
        'px;width: ' +
        widthOfTable +
        'px;'
      ">
      <slot name="overrideContent">
        <VTable
          v-if="!emptyMessage"
          sticky-header
          header-hover
          :snap-rows="snapRows"
          :bordered-table="bordersOnTable"
          :sticky-first-column="stickyFirstColumn"
          row-size="medium"
          edge-to-edge>
          <template #head>
            <slot name="head" />
          </template>
          <slot />
        </VTable>
      </slot>
      <div
        v-if="!firstLoad"
        class="text-5xl text-center text-textColor-soft mt-10">
        <i class="fa fa-fw fa-circle-o-notch fa-spin" /> <br />
        <span class=""> Loading </span>
      </div>
      <EmptyStateFullPage
        v-if="emptyMessage"
        :icon="emptyIcon"
        :description="emptyMessage"
        :button-text="emptyAction ? 'Create New' : null"
        :button-function="emptyAction" />
    </div>

    <Paginator
      v-if="paginator"
      class="h-context-sidebar-bottom-height border-t bg-backgroundColor"
      :loading="loading"
      :per-page="paginator?.perPage"
      :total-count="paginator?.total"
      :total-pages="paginator?.pages"
      :current-page="paginator?.page"
      @change-page="loading ? null : paginator?.action($event)">
      <template #before>
        <slot name="bottomLeft" />
      </template>
    </Paginator>
  </div>
</template>
