import { getNow, isSameOrBefore } from '@/util/timeFunctions';
import { usePage } from '@inertiajs/vue3';
import { getKey } from '@/util/globals';

export const getStatusColorOfReminder = (reminder, type = 'color') => {
  if (reminder.sent_at) {
    switch (type) {
      case 'title':
        return 'Sent';
      case 'color':
      default:
        return 'green';
    }
  }
  if (reminder.fail_reason) {
    switch (type) {
      case 'title':
        return 'Failed';
      case 'color':
      default:
        return 'red';
    }
  }
  if (reminder.sent_at === null && reminder.send_at !== null && isSameOrBefore(reminder.send_at, getNow())) {
    switch (type) {
      case 'title':
        return 'Sending';
      case 'color':
      default:
        return 'green';
    }
  }
  switch (type) {
    case 'title':
      return 'Pending';
    case 'color':
    default:
      return 'yellow';
  }
};

export const reminderIsSending = (reminder) => {
  return (
    reminder.sent_at === null &&
    reminder.send_at !== null &&
    reminder.fail_reason === null &&
    isSameOrBefore(reminder.send_at, getNow())
  );
};

export const getNewReminder = (reminder = null) => {
  return {
    id: getKey(reminder, 'id'),
    sms: getKey(reminder, 'sms', false),
    email: getKey(reminder, 'email', false),
    content: getKey(reminder, 'content'),
    with_link: getKey(reminder, 'with_link', false),
    reply_to_id: getKey(reminder, 'reply_to_id'),
    reply_to_email: getKey(reminder, 'reply_to_email'),
    email_subject: getKey(reminder, 'email_subject'),
    send_at: getKey(reminder, 'send_at', getNow()),
  };
};

export const sendNewMessageToPublicFormResponses = async (
  newMessage: {
    content: string;
    sms: boolean;
    email: boolean;
    send_at: string;
    with_link: boolean;
    email_subject: string;
    reply_to_id: number;
    reply_to_email: string;
  },
  publicFormId: number,
  publicFormResponseIds = [],
  festival_section_id = null
) => {
  await axios.post(`/api/public-forms/${publicFormId}/reminders`, {
    content: newMessage.content,
    sms: newMessage.sms,
    email: newMessage.email,
    send_at: newMessage.send_at,
    with_link: newMessage.with_link,
    email_subject: newMessage.email_subject,
    reply_to_id: newMessage.reply_to_id,
    reply_to_email: newMessage.reply_to_email,
    public_form_response_ids: publicFormResponseIds,
    festival_section_id,
  });
  return true;
};

export const sendNewMessageToCrewMemberOfFestival = async (
  newMessage,
  festivalId,
  userId,
  festival_section_id = null
) => {
  await axios.post('/api/festivals/' + festivalId + '/crew-members/' + userId + '/reminders', {
    content: newMessage.content,
    sms: newMessage.sms,
    email: newMessage.email,
    send_at: newMessage.send_at,
    email_subject: newMessage.email_subject,
    reply_to_id: newMessage.reply_to_id,
    reply_to_email: newMessage.reply_to_email,
    festival_section_id,
    behalf_of_type: festival_section_id ? 'App\\Models\\Festivals\\FestivalSection' : 'App\\Festival',
    behalf_of_id: festival_section_id ? festival_section_id : festivalId,
  });
  return true;
};
