<script setup lang="ts">
import moment from 'moment';
import JsZip from 'jszip';
import JsZipUtils from 'jszip-utils';
import { onMounted, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { dateTimeFormat } from '@/variables/date-format';

const props = defineProps({
  documentFolder: {
    type: Object,
    required: true,
  },
  filenameSave: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['removeLink']);

const fileConfirmation = ref([]);
const zip = ref(null);
const content = ref(null);

const downloadZipIfAllReady = () => {
  if (fileConfirmation.value.every((element) => element)) {
    if (content.value) {
      zip.value.file('Info.txt', content.value);
    }

    zip.value.generateAsync({ type: 'blob' }).then((res) => {
      const a = document.createElement('a');
      a.download = `${props.filenameSave}.zip`;
      a.href = URL.createObjectURL(res);
      document.body.appendChild(a);
      a.click();
      emits('removeLink');
      // $root.$emit('downloadComplete');
    });
  }
};

function zipFile(url, filename, folder, index) {
  JsZipUtils.getBinaryContent(url, (err, data) => {
    if (!err) {
      folder.file(filename, data, { base64: true });
      fileConfirmation.value[index] = true;
      downloadZipIfAllReady();
    }
  });
}

function addDocumentsToZip(documents, folder) {
  documents.forEach((doc) => {
    const index = fileConfirmation.value.push(false);
    zipFile(doc.download_url, doc.filename, folder, index - 1);
  });
}

function addDocumentFoldersToZip(documentFolders, parentFolder) {
  documentFolders.forEach((folder) => {
    const fol = parentFolder.folder(folder.name);
    addDocumentFoldersToZip(folder.children, fol);
    addDocumentsToZip(folder.documents, fol);
  });
}

function getContentNameFromDocument(document, num) {
  let spaces = '';
  for (let i = 0; i < num * 4; i += 1) {
    spaces = `${spaces} `;
  }
  return `\n${spaces} * ${document.filename}`;
}

function getContentNameFromFolder(folder, num) {
  let contentValue = '';
  let spaces = '';
  for (let i = 0; i < num * 4; i += 1) {
    spaces = `${spaces} `;
  }
  folder.documents.forEach((d) => {
    contentValue += getContentNameFromDocument(d, num + 1);
  });
  folder.children.forEach((f) => {
    contentValue += getContentNameFromFolder(f, num + 1);
  });

  return `\n${spaces} - ${folder.name}${contentValue}`;
}

onMounted(() => {
  if (props.documentFolder) {
    zip.value = new JsZip();
    addDocumentFoldersToZip(props.documentFolder.children, zip.value);
    addDocumentsToZip(props.documentFolder.documents, zip.value);

    content.value =
      `${
        usePage().props.authenticated ? `Downloaded by: ${usePage().props.auth.user.name}` : ''
      }\nDownloaded at: ${moment().format(dateTimeFormat)}\nPowered by Crescat ` +
      '\nwww.crescat.no' +
      '\n\nContent:';

    props.documentFolder.children.forEach((folder) => {
      content.value += getContentNameFromFolder(folder, 1);
      content.value = `${content.value}\n`;
    });
    props.documentFolder.documents.forEach((document) => {
      content.value += getContentNameFromDocument(document, 1);
    });
  }
});
</script>

<template>
  <div />
</template>
