<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import TextInput from '@/components/Inputs/TextInput.vue';

type Props = {
  modalTitle: string;
  initTitle: string;
  params: object;
  url: string;
};

const props = defineProps<Props>();

defineEmits<{
  (event: 'closed'): void;
}>();

const toast = useToast();

const title = ref(props.initTitle);
const groupId = ref<number | null>(null);

const addAsTemplate = async (close: () => void) => {
  if (groupId.value === null) return;
  if (title.value.length === 0) return;

  await axios.post(props.url, {
    title: title.value,
    model_type: 'App\\Group',
    model_id: groupId.value,
    ...props.params,
  });

  const index = _.findIndex(usePage().props.auth.user.groups, (g) => g.id === groupId.value);
  toast.success(`A new template (${title.value}) has been added to ${usePage().props.auth.user.groups[index].name}.`);
  close();
};
</script>

<template>
  <CrudModal
    title="Save as Template"
    :disabled="groupId === null || title.length === 0"
    create-button-text="Save"
    small
    @create="addAsTemplate"
    @closed="$emit('closed')">
    <div class="form-layout">
      <div>
        {{ modalTitle }}
      </div>
      <VSelect
        v-model="groupId"
        :options="$page.props.auth.user.groups.filter((g) => g.parent_id === null)"
        option-value="name"
        option-key="id"
        label="Select Group" />
      <TextInput
        v-model="title"
        label="Title"
        placeholder="Template Name" />

      <i class="text-sm">
        Documents, images and users are not stored in the template. It will store all rows and text fields.
      </i>
    </div>
  </CrudModal>
</template>
