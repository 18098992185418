<script setup lang="ts">
import CrudModal from '@/components/Modals/CrudModal.vue';
import { destroyCheckListRows, patchCheckListRowInput, patchCheckListRows } from '@/services/api-check-lists';
import { CheckListRowResource } from '@/types/check-list';
import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTableCell from '@/components/Tables/VTableCell.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import { tooShortOrLong } from '@/util/globals';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import CheckBox from '@/components/Icons/CheckBox.vue';
import VButton from '@/components/Inputs/VButton.vue';
import DisplayText from '@/components/Display/DisplayText.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';

type Props = {
  row: CheckListRowResource;
  isTemplate: boolean;
  canEdit: boolean;
  locked: boolean;
  editMode: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'updated': [];
  'deleted': [];
  'remove': [row: unknown];
}>();

const toast = useToast();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const title = ref('');
const description = ref('');
const input = ref('');
const doneTitle = ref('');
const inputTitle = ref('');
const done = ref(false);
const working = ref(false);
const modalOpen = ref(false);
const activeCell = ref(false);

const initializeRow = () => {
  title.value = props.row.title;
  description.value = props.row.description;
  done.value = props.row.done;
  input.value = props.row.input;

  doneTitle.value = '';

  if (props.row.doneBy) {
    doneTitle.value = `Last Changed by ${props.row.doneBy}`;
  }
  if (props.row.done_date) {
    doneTitle.value = `${doneTitle.value} at ${props.row.done_date}`;
  }
};
initializeRow();

watch(props.row, () => {
  initializeRow();
});
const openModal = () => {
  modalOpen.value = true;
};

const saveRow = async (close: () => void) => {
  if (tooShortOrLong(title.value, 'Title')) return;

  working.value = true;

  await patchCheckListRows(props.row.id, {
    title: title.value,
    description: description.value,
  });

  toast.success('Updated');
  emit('updated');

  close();

  working.value = false;
};

const updateRow = async () => {
  if (done.value !== props.row.done || input.value !== props.row.input) {
    working.value = true;
    await patchCheckListRowInput(props.row.id, {
      done: done.value,
      input: input.value,
    });
    toast.success('Updated');
    emit('updated');
    working.value = false;
  }
};

const removeRow = async (close: () => void) => {
  if (!props.canEdit) return;
  const deleteIt = await assertReadyToDeleteModal(
    'Delete Check List Row',
    'Are you sure that you want to delete this row?'
  );
  if (!deleteIt) return;
  working.value = true;
  await destroyCheckListRows(props.row.id);
  toast.success('Deleted');
  emit('deleted');
  working.value = false;
  close();
};

const updateDoneValue = () => {
  if (!props.isTemplate && !props.locked) {
    done.value = !done.value;
    updateRow();
  }
};
</script>

<template>
  <VTableCell
    v-if="(isTemplate && canEdit) || (canEdit && editMode && !locked)"
    style="width: 30px">
    <i
      v-if="!working"
      :class="{ 'invisible': !editMode }"
      class="show-hover fa fa-fw fa-up-down pointer drag-selector"
      title="Move row" />
    <i
      v-if="working"
      class="fa fa-fw fa-circle-o-notch fa-spin" />
  </VTableCell>

  <VTableCell style="max-width: 250px">
    <div class="text-textColor">
      {{ row.title }}
    </div>
    <DisplayText
      :content="row.description"
      classes="sub-title" />
  </VTableCell>

  <template v-if="!isTemplate && !editMode">
    <VTableCell
      :class="locked ? '' : 'cursor-pointer'"
      main-cell
      :title="doneTitle"
      @click="updateDoneValue">
      <CheckBox
        :model-value="done"
        :can-edit="!locked" />
    </VTableCell>

    <VTableCell
      :title="inputTitle"
      :class="{ '!p-0': !locked }"
      :main-cell="input !== null">
      <p v-if="locked">
        {{ input }}
      </p>
      <TextInput
        v-if="!locked"
        :model-value="input"
        :can-edit="!locked"
        is-hidden
        @focus="activeCell = 'input'"
        @blur="[(activeCell = null), input === $event ? null : [(input = $event), updateRow()]]" />
    </VTableCell>

    <template v-if="!isTemplate">
      <VTableCell
        v-if="!isTemplate"
        :title="doneTitle">
        <IconWithLoading
          v-if="row.done_id || working"
          :loading="working"
          icon="fa-user" />
      </VTableCell>
    </template>
  </template>
  <VTableCell
    v-if="(isTemplate && canEdit && editMode) || (!locked && canEdit && editMode)"
    style="width: 30px">
    <VButton
      :loading="working"
      type="btn-in-table"
      :disabled="working"
      tool-tip-text="Edit Row"
      icon="fa-pencil"
      @click="openModal" />
    <CrudModal
      v-if="modalOpen"
      :update="true"
      title="Change Check List Item"
      @delete="removeRow"
      @update="saveRow"
      @closed="modalOpen = false">
      <div class="grid gap-5">
        <TextInput
          v-model="title"
          label="Title"
          @keydown-enter="saveRow" />
        <TextareaInput
          v-model="description"
          label="Description" />
      </div>
    </CrudModal>
  </VTableCell>
</template>
