type RoomBookingData = {
  room_id: number;
  start: string;
  end: string;
  title: string | null;
  confirmed: boolean;
  is_global: boolean;
};

export const postRoomBooking = async (inviteId: number, data: RoomBookingData) => {
  return axios.post<string>('/api/room-booking', {
    invite_id: inviteId,
    ...data,
  });
};

type PatchBookingData = {
  start: string;
  end: string;
  title: string | null;
  confirmed: boolean;
  is_global: boolean;
};

export const patchRoomBooking = async (bookingId: number, data: PatchBookingData) => {
  return axios.patch<string>(`/api/room-booking/${bookingId}`, {
    ...data,
  });
};

export type OtherUse = {
  id: number;
  invite_id: number;
  start: string;
  end: string;
  event: {
    id: number;
    name: string;
  };
  confirmed: boolean;
};

export type Restriction = {
  room_id: number;
  room_name: string | null;
  message: string;
  start: string;
  end: string;
  eventName: string;
  eventI: number;
  eventSlug: string;
  confirmed: boolean;
};

type Booked = [OtherUse[], Restriction[]];

export const getRoomBooking = async (roomId: number, start: string, end: string, eventId?: number | null) => {
  return axios.get<Booked | string>(`/api/room-booking`, {
    params: {
      room_id: roomId,
      start,
      end,
      event_id: eventId,
    },
  });
};
