import { UserInviteModelType, UserInviteResource } from '@/types/user-invite';
import { useToast } from 'vue-toastification';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';

const BASE_URL = '/api/user-invites';

export const getUserInvites = async (model: UserInviteModelType, modelId: number) => {
  return axios.get<UserInviteResource[]>(BASE_URL, {
    params: {
      model_type: 'App\\' + model,
      model_id: modelId,
    },
  });
};

export const resendEmailToInvite = async (
  userInvite: { id: number; email: string },
  loadingCallback: ((value: boolean) => void) | null = null
) => {
  const certain = await useCertaintyModal().assertCertain(
    'Resend Invite',
    `Are you sure that you want to resend the invite email to '${userInvite.email}'?`
  );
  if (!certain) return false;

  loadingCallback ? loadingCallback(true) : null;
  await axios.post(BASE_URL + `/${userInvite.id}/resend`).catch((error) => {
    useToast().warning('Something went wrong, please try again later');
    return false;
  });
  loadingCallback ? loadingCallback(false) : null;

  useToast().success(`'${userInvite.email}' has been sent a new email.`);
  return true;
};

export const revokeUserInvite = async (userInvite: {
  id: number;
  email: string;
  group_id?: number;
  event_id?: number;
  festival_id?: number;
  festival_section_id?: number;
}) => {
  const certain = await useCertaintyModal().assertCertain(
    'Revoke Invite',
    `Are you sure that you want to revoke the invite email to '${userInvite.email}'?`
  );

  if (!certain) return false;

  try {
    await axios.delete(`${BASE_URL}/${userInvite.id}`);
    if (userInvite.hasOwnProperty('group_id') && userInvite.group_id !== null) {
      useToast().success(`'${userInvite.email}' has been removed from your group.`);
    } else if (userInvite.hasOwnProperty('event_id') && userInvite.event_id !== null) {
      useToast().success(`'${userInvite.email}' has been removed from your event.`);
    } else if (userInvite.hasOwnProperty('festival_id') && userInvite.festival_id !== null) {
      useToast().success(`'${userInvite.email}' has been removed from your festival.`);
    } else if (userInvite.hasOwnProperty('festival_section_id') && userInvite.festival_section_id !== null) {
      useToast().success(`'${userInvite.email}' has been removed from your festival sections.`);
    } else {
      useToast().success(`'${userInvite.email}' has been removed.`);
    }

    return true;
  } catch (e) {
    useToast().warning('Something went wrong, please try again later');
    return false;
  }
};

export const patchUserInvite = async (userInvite, data) => {
  await axios.patch(`${BASE_URL}/${userInvite.id}`, data).catch((error) => {
    useToast().warning('Something went wrong, please try again later');
    return false;
  });
  return true;
};
