import { formatStampAsDate } from '@/util/timeFunctions';

export const groupRecurringDates = (array = [], key = null) => {
  const result = {};
  array.forEach((item) => {
    const monthYear = key ? formatStampAsDate(item[key], 'YYYY-MM') : formatStampAsDate(item, 'YYYY-MM');

    if (!result[monthYear]) {
      result[monthYear] = [];
    }
    result[monthYear].push(item);
  });
  return result;
};
