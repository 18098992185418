import VDialog from '@/components/Modals/VDialog.vue';
import { useModal } from 'vue-final-modal';
import { useEventListener } from '@vueuse/core';

export const useLoggedInModal = () => {
  const assertLoggedIn = async () => {
    return new Promise<boolean>((resolve) => {
      let resolveResponse = false;
      const modelInstance = useModal({
        defaultModelValue: true,
        component: VDialog,
        attrs: {
          title: 'Your session has ended',
          content: 'No worries though! The page will reload, and after logging in you can get back to work!',
          buttons: [
            {
              class: 'btn-success flex-1',
              title: 'Reload',
              emit: 'sure',
            },
          ],
          async onCancel() {
            resolveResponse = false;

            modelInstance.close();
          },
          async onSure() {
            resolveResponse = true;
            modelInstance.close();
          },
          onClosed() {
            resolve(resolveResponse);
            modelInstance.destroy();
          },
        },
      });
      useEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          resolveResponse = true;
          modelInstance.close();
        }
      });
    });
  };

  return { assertLoggedIn };
};
