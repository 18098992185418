import { CompletionTrackerPivotResource, CompletionTrackerResource } from '@/types/completion-tracker';

export const completionTrackerHasItem = (
  completionTracker: CompletionTrackerResource | null,
  item: string | null | []
): CompletionTrackerPivotResource | null => {
  if (!completionTracker || !item) return null;
  const itemIndex = _.findIndex(
    completionTracker.pivots,
    (pivot) =>
      (typeof item === 'string' ? pivot.item.name === item : item.includes(pivot.item.name)) &&
      pivot.completed_at === null
  );
  if (itemIndex === -1) return null;
  return completionTracker.pivots[itemIndex];
};
