import { PerformanceModelType, PerformanceTypesResource } from '@/types/performance';

export const getPerformanceTypes = async (modelType: PerformanceModelType, modelId: number) => {
  const url = '/api/performance-types';
  return axios.get<PerformanceTypesResource[]>(url, {
    params: {
      model_type: `App\\${modelType}`,
      model_id: modelId,
    },
  });
};
