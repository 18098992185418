<script lang="ts" setup>
import TheContextSidebar from '@/components/ContextSidebar/TheContextSidebar.vue';
import TheNavbar from '@/components/Navbar/TheNavbar.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { useUserStore } from '@/store/UserStore';
import type { FestivalResource } from '@/types/festival';
import type { GroupResource } from '@/types/group';
import { removeKeyFromUrl } from '@/util/globals';
import { checkForQueryParameter } from '@/util/query-helpers';
import { getRoute } from '@/util/route';
import { router, usePage } from '@inertiajs/vue3';
import * as Sentry from '@sentry/browser';
import { gsap } from 'gsap';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { onMounted, ref, watch } from 'vue';
import { ModalsContainer } from 'vue-final-modal';
import { checkIfStillLoggedIn } from '@/util/still-logged-in';
import MobileNavbar from '@/components/Navbar/MobileNavbar.vue';
import EmailVerificationBanner from '@/components/Navbar/EmailVerificationBanner.vue';
import posthog from 'posthog-js';

type Props = {
  group?: GroupResource;
  parentGroup?: {
    id: number;
    name: string;
    slug: string;
  } | null;
  festival?: FestivalResource;
  authenticated: boolean;
};

const props = defineProps<Props>();

const { isSmallScreen } = useSmallScreen();

let contextStore = null;

const firstLoad = ref(true);
const pathName = ref(window.location.pathname);

if (props.authenticated) {
  checkIfStillLoggedIn();
  Sentry.setUser({ id: `${usePage().props?.auth?.user?.id}` });
  Sentry.setTags({ 'admin-page': `${getRoute('admin.users.show', usePage().props?.auth?.user?.id)}` });
  useUserStore().fill();

  if (useUserStore().theme === 'light') {
    const html = document.documentElement;
    html.setAttribute('data-theme', 'light');
  }

  contextStore = useContextSidebarStore();
  if (usePage().props.logActivity) {
    router.on('navigate', (e) => {
      if (e.detail.page.props.authenticated) {
        const url = getRoute('session.ended');
        if (firstLoad.value) {
          firstLoad.value = false;
          return;
        }
        try {
          axios.post(url, {
            end_action: 'redirecting',
            path: pathName.value,
          });
        } catch (error: any) {
          if (Math.floor(Math.random() * 100) + 1 === 1) {
            if (error.response.status !== 401 && error.response.status !== 419) {
              throw new Error(error);
            }
          }
        }
      }
      pathName.value = window.location.pathname;
    });
  }
}

const checkForTheme = () => {
  if (window.location.search.includes('theme')) {
    const theme = checkForQueryParameter('theme');
    if (!theme || !['base', 'light'].includes(theme)) return;
    const html = document.documentElement;
    html.setAttribute('data-theme', theme);
    useUserStore().theme = theme;
    removeKeyFromUrl('theme');
  }
};
checkForTheme();

window.addEventListener('beforeunload', () => {
  if (usePage().props.logActivity) {
    const url = getRoute('session.ended');
    if (firstLoad.value) {
      firstLoad.value = false;
      return;
    }
    try {
      axios.post(url, {
        end_action: 'closed',
        path: pathName.value,
      });
    } catch (error: any) {
      if (Math.floor(Math.random() * 100) + 1 === 1) {
        if (error.response.status !== 401 && error.response.status !== 419) {
          throw new Error(error);
        }
      }
    }
  }
});

window.Pusher = Pusher;

const createSectionForSlack = (title: string, text: string) => {
  return `*${title}:*\n${text}\n`;
};

const sendBlockToSlack = (block: string, url: string) => {
  const data = {
    text: 'Websockets Not Connected',
    blocks: [
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: block,
          },
        ],
      },
      {
        type: 'divider',
      },
    ],
  };
  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  xhr.send(JSON.stringify(data));
};

const checkIfEchoIsConnected = () => {
  if (window.Echo.connector.pusher.connection.state !== 'connected') {
    const url = import.meta.env.VITE_SLACK_WEBHOOK_URL;
    let block = '';
    block += createSectionForSlack('Feil med Websockets', `Den skjedde på domenet ${window.location.href}`);
    if (usePage().props.auth && usePage().props.auth.user) {
      block += createSectionForSlack(`Det skjedde for USERID: ${usePage().props.auth?.user?.id}`, '');
    } else {
      block += createSectionForSlack('Skjedde for ikke-innlogget bruker!', '');
    }
    if (window.location.href.includes('app.crescat.io')) {
      sendBlockToSlack(block, url);
    } else {
      console.error('Websockets not connected');
    }
  }
};

if (import.meta.env.VITE_PUSHER_APP_KEY) {
  const key = import.meta.env.VITE_PUSHER_APP_KEY;
  const host = import.meta.env.VITE_PUSHER_APP_HOST;
  const port = import.meta.env.VITE_PUSHER_APP_PORT;
  window.Echo = new Echo({
    broadcaster: 'pusher',
    cluster: 'eu',
    key: key,
    disableStats: true,
    scheme: 'http',
    httpHost: host,
    httpsHost: host,
    wsHost: host,
    wssHost: host,
    wsPort: port,
    wssPort: port,
    statsHost: null,
    withoutInterceptors: true,
    forceTLS: host !== '127.0.0.1',
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel, options) => ({
      authorize: (socketId, callback) => {
        axios
          .post('/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    }),
  });

  [2001, 5000].forEach((time) => {
    setTimeout(() => {
      checkIfEchoIsConnected();
    }, time);
  });

  axios.interceptors.request.use((config) => {
    if (window.Echo.socketId()) {
      if (window.Echo.socketId() !== axios.defaults.headers.common['X-Socket-ID']) {
        axios.defaults.headers.common['X-Socket-ID'] = window.Echo.socketId();
      }
    }
    return config;
  });

  [500, 1000, 2000, 3000, 10000].forEach((time) => {
    setTimeout(() => {
      if (window.Echo.socketId() !== axios.defaults.headers.common['X-Socket-ID']) {
        axios.defaults.headers.common['X-Socket-ID'] = window.Echo.socketId();
      }
    }, time);
  });
}

const sidebarWrapper = ref(null);

if (props.authenticated) {
  watch(
    () => useContextSidebarStore().sidebarLarge,
    (open) => {
      if (open) {
        gsap.fromTo(
          sidebarWrapper.value,
          {
            width: getComputedStyle(document.documentElement).getPropertyValue('--context-sidebar-width-closed'),
          },
          {
            width: getComputedStyle(document.documentElement).getPropertyValue('--context-sidebar-width-open'),
            duration: 0.2,
            onComplete: () => {
              window.dispatchEvent(new Event('resize'));
            },
          }
        );
      } else {
        gsap.fromTo(
          sidebarWrapper.value,
          {
            width: getComputedStyle(document.documentElement).getPropertyValue('--context-sidebar-width-open'),
          },
          {
            width: getComputedStyle(document.documentElement).getPropertyValue('--context-sidebar-width-closed'),
            duration: 0.2,
            onComplete: () => {
              window.dispatchEvent(new Event('resize'));
            },
          }
        );
      }
    },
    { immediate: false }
  );
}

const clearSessionStorage = ref(false);
const resizeFunction = () => {
  window.dispatchEvent(new Event('resize'));
  setTimeout(() => {
    resizeFunction();
  }, 2000);
};
resizeFunction();
window.addEventListener('hashchange', () => {
  if (clearSessionStorage.value) {
    window.location.reload();
  }
});

router.on('before', () => {
  if (clearSessionStorage.value) {
    window.location.reload();
    return false;
  }
  return true;
});

const listenForBroadcast = () => {
  if (usePage().props.authenticated) {
    window.Echo.private(`App.User.${usePage().props.auth.user.id}`).listen('.sessionStorage.clear', () => {
      clearSessionStorage.value = true;
    });
  }
};
onMounted(() => {
  listenForBroadcast();
});

if (import.meta.env.MODE === 'production' && props.authenticated && usePage()?.props?.auth?.user?.is_self_created) {
  const plugin = posthog.init('phc_QNnPrcP4nuBiedAgaUVLsR7WzBxUy9gDcDZ6RyKPLMR', {
    api_host: 'https://eu.i.posthog.com',
  });

  if (plugin) {
    plugin.capture('$set', {
      $set: {
        user_id: usePage().props.auth.user.id,
        'admin-page': `${getRoute('admin.users.show', usePage().props?.auth?.user?.id)}`,
      },
    });

    router.on('navigate', (e) => {
      const path = e?.detail?.page?.props?.asset_url?.slice(0, -1);
      let url: string;
      if (e?.detail?.page?.url?.includes('http')) {
        url = e.detail.page.url;
      } else {
        url = path + e.detail.page.url;
      }

      plugin?.capture('$pageview', { path: url });
    });

    window.addEventListener('hashchange', (e) => {
      plugin?.capture('$pageview', { path: e.newURL });
    });
  }
}
</script>

<template>
  <div :class="{ 'flex h-dvh w-screen overflow-hidden ': !isSmallScreen && authenticated }">
    <div
      v-if="authenticated"
      v-show="!isSmallScreen"
      ref="sidebarWrapper"
      :style="
        contextStore.sidebarLarge
          ? 'width: var(--context-sidebar-width-open)'
          : 'width: var(--context-sidebar-width-closed)'
      "
      class="sticky top-0 z-50 h-dynamic-screen">
      <TheContextSidebar
        :festival="festival"
        :group="group" />
    </div>
    <div
      :class="{
        'flex flex-col h-dvh overflow-hidden gap-1': isSmallScreen && authenticated,
        'flex-1 overflow-hidden': authenticated,
      }">
      <template v-if="authenticated">
        <EmailVerificationBanner v-if="$page.props?.auth?.user?.verified_at === null" />

        <MobileNavbar v-if="isSmallScreen" />

        <TheNavbar
          v-if="!isSmallScreen"
          class="z-40" />
      </template>

      <main
        :key="$page.url"
        :class="{ 'flex-1': authenticated && isSmallScreen }">
        <slot />
      </main>
    </div>

    <div id="right-side" />
    <ModalsContainer />
  </div>
</template>
