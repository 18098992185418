<script setup lang="ts">
import { RunningOrderModelType } from '@/types/global';
import {
  RunningOrderCellResource,
  RunningOrderColumnResource,
  RunningorderResource,
  RunningOrderRowResource,
  RunningOrderShowResource,
} from '@/types/runningorder';
import { secondToHourMinuteAndSecond } from '@/util/date';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import moment from 'moment';
import TextInput from '@/components/Inputs/TextInput.vue';
import { secondFormat } from '@/variables/date-format';
import {
  changeAndFormatStamp,
  formatHoursAndMinutesAndSecondsAsSeconds,
  formatMinutesAsHoursAndMinutes,
  formatSecondsAsHoursAndMinutesAndSeconds,
  formatStampAsDate,
  formatStampAsHumanReadableDate,
  formatStampAsTime,
  getDiffInInterval,
  timeStampsAreSame,
} from '@/util/timeFunctions';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import { useLocalStorage } from '@vueuse/core';
import { useEmitStore } from '@/store/EmitStore';
import VTable, { SortEmit } from '@/components/Tables/VTable.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import ColumnHeader from '@/components/Config/ColumnConfig/ColumnHeader.vue';
import ColumnCRUDModal from '@/components/Config/ColumnConfig/ColumnCRUDModal.vue';
import { columnTypes, getStartOfRow } from '@/util/running-order-functions';
import VTableRow from '@/components/Tables/VTableRow.vue';
import RunningOrderRow from '@/components/Models/RunningOrder/RunningOrderRow.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { getRoute, openRoute } from '@/util/route';
import { usePage } from '@inertiajs/vue3';
import {
  arrayMove,
  createUuId,
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  getKey,
  removeItemFromArrayBasedOnId,
  reorderArrayByIds,
  scrollIntoViewById,
  uniqueValuesFromArray,
} from '@/util/globals';
import {
  createRunningOrderCell,
  createRunningOrderColumn,
  createRunningOrderRow,
  deleteRunningOrderColumn,
  patchRunningOrder,
  reOrderRunningOrderColumns,
  reOrderRunningOrderRows,
  updateRunningOrderColumn,
} from '@/services/api-running-order';
import RunningOrderSetRowStart from '@/components/Models/RunningOrder/RunningOrderSetRowStart.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import RunningOrderDownloadModal from '@/components/Models/RunningOrder/RunningOrderDownloadModal.vue';
import SaveAsTemplateModal from '@/components/Modals/SaveAsTemplateModal.vue';
import RunningOrderAuditSidebar from '@/components/Models/RunningOrder/RunningOrderAuditSidebar.vue';
import { useUserStore } from '@/store/UserStore';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import { eventTypesKey } from '@/provide/keys';
import CrudModal from '@/components/Modals/CrudModal.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  runningOrder: RunningorderResource;
  isDisplay?: boolean;
  isSingleRunningOrder?: boolean;
  isTemplate?: boolean | null;
  canEdit?: boolean;
  model?: RunningOrderModelType | null;
  modelId?: number | null;
  newlyCreatedRunningOrderId?: number | null;
  users?: object[] | null;
};

const props = withDefaults(defineProps<Props>(), {
  isDisplay: false,
  isSingleRunningOrder: false,
  isTemplate: false,
  modelId: null,
  model: null,
  newlyCreatedRunningOrderId: null,
  users: () => [],
});

const emit = defineEmits<{
  'updated': [];
  'updateRunningOrder': [value: object];
  'update:runningOrder': [value: object];
  'edit': [];
}>();

const { rootEmit } = useEmitStore();

const userStore = useUserStore();

const maxNumberRows = 150;
const maxNumberColumns = 15;

const toast = useToast();

const runningOrderEndTime = (format = 'HH:mm:ss') => {
  if (props.isTemplate) {
    const hoursAndMinutesAsSeconds = props.runningOrder.template_start
      ? formatHoursAndMinutesAndSecondsAsSeconds(props.runningOrder.template_start)
      : 0;
    const totalDuration = getTotalDurationInSecond(runningOrderRows.value);
    return formatSecondsAsHoursAndMinutesAndSeconds(hoursAndMinutesAsSeconds + totalDuration);
  }
  return changeAndFormatStamp({
    stamp: props.runningOrder.start,
    addSeconds: runningOrderRows.value.reduce((partialSum, row) => partialSum + row.duration, 0),
    format: format,
  });
};

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

const loading = ref(false);
const editMode = ref(props.canEdit || !props.isTemplate);
const open = ref(false);

const runningOrderShows = ref<RunningOrderShowResource>(props.runningOrder.running_order_shows);

const runningOrderRows = ref(getKey(props.runningOrder, 'running_order_rows', []));
const loadingRows = ref([]);

watch(
  open,
  () => {
    if (!open.value) return;
    if (props.isDisplay) return;
    loadingRows.value = props.runningOrder.running_order_rows.map((r) => r.id);
    removeRowFromLoadingRows();
  },
  { immediate: true }
);

const removeRowFromLoadingRows = () => {
  if (loadingRows.value.length <= 0) return;
  loadingRows.value.splice(0, 1);
  setTimeout(() => {
    removeRowFromLoadingRows();
  }, 40);
};

const runningOrderColumns = ref(getKey(props.runningOrder, 'running_order_columns', []));
const runningOrderCells = ref(getKey(props.runningOrder, 'running_order_cells', []));

watch(
  () => props.newlyCreatedRunningOrderId,
  (newVal, oldValue) => {
    if (!newVal && oldValue === props.runningOrder.id) {
      runningOrderRows.value = props.runningOrder.running_order_rows;
      runningOrderColumns.value = props.runningOrder.running_order_columns;
      runningOrderCells.value = props.runningOrder.running_order_cells;
    }
  }
);

const debounceTimer = ref(null);
const checkToUpdateEndOfRunningOrder = () => {
  clearTimeout(debounceTimer.value);

  debounceTimer.value = setTimeout(() => {
    const duration =
      runningOrderRows.value
        .map((r: RunningOrderRowResource) => {
          return r.duration;
        })
        .reduce((partialSum, a) => partialSum + a, 0) ?? 0;
    const newEnd = changeAndFormatStamp({
      stamp: props.runningOrder.start,
      addSeconds: duration,
    });
    if (newEnd !== props.runningOrder.end) {
      patchRunningOrder(props.runningOrder.id, {
        end: newEnd,
      });
      emit('update:runningOrder', {
        id: props.runningOrder.id,
        end: newEnd,
      });
    }
  }, 500);
};
watch(
  () => runningOrderRows.value,
  () => {
    if (loadingRows.value.length > 0) return;
    checkToUpdateEndOfRunningOrder();
  },
  { deep: true }
);

const getTotalDurationInSecond = (rows: RunningOrderRowResource[]) => {
  let seconds = 0;
  rows.forEach((r) => {
    seconds = r.duration + seconds;
  });
  return seconds;
};

const runningOrderDuration = computed(() =>
  secondToHourMinuteAndSecond(getTotalDurationInSecond(runningOrderRows.value))
);

const selectedRowIds = ref<Set<number>>(new Set());
const clearRowIds = () => {
  if (!props.canEdit) return;
  selectedRowIds.value = new Set();
};
const addAllRowIds = () => {
  if (!props.canEdit) return;
  selectedRowIds.value = new Set(runningOrderRows.value.map((r) => r.id));
};

const hiddenColumnIds = useLocalStorage('hidden_columns_for_running_order_' + props.runningOrder.id, []);
const activeColumns = computed(() => runningOrderColumns.value.filter((c) => !hiddenColumnIds.value.includes(c.id)));

const newRowTitle = ref('');

const creatingNewRow = ref(false);

const addRow = async (index = null, withToast = true) => {
  if (maxNumberRows) {
    if (maxNumberRows <= runningOrderRows.value.length) {
      useToast().warning('Maximum number of rows is ' + maxNumberRows);
      return;
    }
  }
  if (!newRowTitle.value) return;

  creatingNewRow.value = true;

  const idOfRow = createUuId('new_row_');
  const newRow = {
    id: idOfRow,
    title: newRowTitle.value,
    duration: 0,
    color: 'none',
    order: null,
  };
  if (index) {
    runningOrderRows.value.splice(index, 0, newRow);
  } else {
    newRow.order = Math.max(...runningOrderRows.value.map((r) => r.order), 0) + 1;
    runningOrderRows.value = [...runningOrderRows.value, newRow];
  }
  newRowTitle.value = '';
  if (withToast) {
    toast.success('new row added');
  }
  creatingNewRow.value = false;

  const data = await createRunningOrderRow(props.runningOrder.id, newRow);
  const indexOfTemp = getIndexFromArrayBasedOnId(idOfRow, runningOrderRows.value);
  if (indexOfTemp > -1) {
    runningOrderRows.value[indexOfTemp] = data.data;
  } else {
    runningOrderRows.value = [...runningOrderRows.value, data.data];
  }
};

const pasteInNewRowTitle = async (pasteEvent: ClipboardEvent) => {
  const pastedData = pasteEvent.clipboardData.getData('text');
  let rows = [];
  if (pastedData.includes('\n')) {
    rows = pastedData.split('\n');
  } else if (pastedData.includes('\t')) {
    rows = pastedData.split('\t');
  }
  if (rows.length > 0) {
    let rowsAdded = 0;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].trim().length === 0) continue;
      newRowTitle.value = rows[i];
      await addRow(null, false);
      rowsAdded++;
    }
    if (rowsAdded > 0) {
      toast.success(rowsAdded + ' new rows added');
    }
  }
};

const newOrderOfRows = async (e: SortEmit) => {
  const { selectedItem: idOfMovedRow, newOrder: arrayOfIds } = e;
  await reOrderRunningOrderRows(props.runningOrder.id, arrayOfIds, idOfMovedRow);
  runningOrderRows.value = reorderArrayByIds(runningOrderRows.value, arrayOfIds);
};

const duplicateRow = async (rowToDuplicate: RunningOrderRowResource) => {
  if (maxNumberRows) {
    if (maxNumberRows <= runningOrderRows.value.length) {
      useToast().warning('Maximum number of rows is ' + maxNumberRows);
      return;
    }
  }

  const indexOfRow = runningOrderRows.value.indexOf(rowToDuplicate) + 1;
  const idOfRow = createUuId('new_row_');
  const newRow = { ...rowToDuplicate, id: idOfRow, order: rowToDuplicate.order + 1 };
  runningOrderRows.value.splice(indexOfRow, 0, newRow);

  const data = await createRunningOrderRow(props.runningOrder.id, newRow);

  const indexOfTemp = getIndexFromArrayBasedOnId(idOfRow, runningOrderRows.value);
  if (indexOfTemp > -1) {
    runningOrderRows.value[indexOfTemp] = data.data;
  } else {
    runningOrderRows.value.splice(indexOfRow, 0, data.data);
  }

  await reOrderRunningOrderRows(
    props.runningOrder.id,
    runningOrderRows.value.map((r) => r.id)
  );

  for (let j = 0; j < runningOrderCells.value.length; j++) {
    if (runningOrderCells.value[j].running_order_row_id === rowToDuplicate.id) {
      const { data: newCell } = await createRunningOrderCell(props.runningOrder.id, {
        running_order_row_id: data.data.id,
        running_order_column_id: runningOrderCells.value[j].running_order_column_id,
        value: runningOrderCells.value[j].value,
      });
      runningOrderCells.value = exchangeValuesOfObject(newCell, runningOrderCells.value);
    }
  }

  useToast().info('Duplicated');
};

const insertRowRelative = async (relativeTo: RunningOrderRowResource, before = true) => {
  if (maxNumberRows) {
    if (maxNumberRows <= runningOrderRows.value.length) {
      useToast().warning('Maximum number of rows is ' + maxNumberRows);
      return;
    }
  }

  const indexOfRow = runningOrderRows.value.indexOf(relativeTo);
  const idOfRow = createUuId('new_row_');
  const newRow = {
    id: idOfRow,
    title: 'New Row',
    duration: 0,
    color: null,
    order: relativeTo.order + (before ? 0 : 1),
  };
  runningOrderRows.value.splice(indexOfRow + (before ? 0 : 1), 0, newRow);

  const data = await createRunningOrderRow(props.runningOrder.id, newRow);

  const indexOfTemp = getIndexFromArrayBasedOnId(idOfRow, runningOrderRows.value);
  if (indexOfTemp > -1) {
    runningOrderRows.value[indexOfTemp] = data.data;
  } else {
    runningOrderRows.value.splice(indexOfRow, 0, data.data);
  }

  await reOrderRunningOrderRows(
    props.runningOrder.id,
    runningOrderRows.value.map((r) => r.id)
  );
  useToast().info('Added');
};

const setRowStartRow = ref(null);

const setRowStart = async (row: RunningOrderRowResource) => {
  if (getIndexFromArrayBasedOnId(row.id, runningOrderRows.value) <= 0) {
    emit('edit');
    return;
  }
  rootEmit('close-all-drop-downs');
  setRowStartRow.value = null;
  await nextTick();
  setRowStartRow.value = row;
};

const toggleRow = async (id: string) => {
  selectedRowIds.value.has(id) ? selectedRowIds.value.delete(id) : selectedRowIds.value.add(id);
};

const selectedColumn = ref<RunningOrderColumnResource | null>(null);

const createColumn = async (newColumn: RunningOrderColumnResource) => {
  const { data: column } = await createRunningOrderColumn(props.runningOrder.id, newColumn);
  runningOrderColumns.value = [...runningOrderColumns.value, column];
  toast.info('Created');
  await nextTick();
  scrollIntoViewById('running_order_column_' + column.id);
};

const updateColumn = async (updateColumn: RunningOrderColumnResource) => {
  await updateRunningOrderColumn(props.runningOrder.id, updateColumn);
  runningOrderColumns.value = exchangeValuesOfObject(
    updateColumn,
    runningOrderColumns.value,
    ['id', 'component'],
    'id',
    false
  );
  toast.info('Updated');
};

const deleteColumn = async (columnId: string) => {
  await deleteRunningOrderColumn(props.runningOrder.id, { id: columnId });
  runningOrderColumns.value = removeItemFromArrayBasedOnId(columnId, runningOrderColumns.value);
  toast.info('Deleted');
};

const editColumn = async (column: RunningOrderColumnResource) => {
  selectedColumn.value = null;
  await nextTick();
  selectedColumn.value = column;
};

const moveColumnLeft = async (columnId: string) => {
  const index = getIndexFromArrayBasedOnId(columnId, runningOrderColumns.value);
  if (index === 0) {
    toast.warning('Cannot move the column further to the left.');
    return;
  }
  runningOrderColumns.value = arrayMove([...runningOrderColumns.value], index, index - 1);
  await reOrderRunningOrderColumns(
    props.runningOrder.id,
    runningOrderColumns.value.map((c) => c.id),
    columnId
  );
};

const moveColumnRight = async (columnId: string) => {
  const index = getIndexFromArrayBasedOnId(columnId, runningOrderColumns.value);
  if (index === runningOrderColumns.value.length - 1) {
    toast.warning('Cannot move the column further to the right.');
    return;
  }
  runningOrderColumns.value = arrayMove([...runningOrderColumns.value], index, index + 1);
  await reOrderRunningOrderColumns(
    props.runningOrder.id,
    runningOrderColumns.value.map((c) => c.id),
    columnId
  );
};

const listenForBroadcast = () => {
  if (!props.model || !props.modelId) return;
  if (props.model === 'GlobalModel') return;

  Echo.join(`On.RunningOrder.${props.runningOrder.id}`)
    .listen(`.runningOrderCell.created`, (e: RunningOrderCellResource) => {
      runningOrderCells.value = exchangeValuesOfObject(e, runningOrderCells.value);
    })
    .listen(`.runningOrderCell.updated`, (e: RunningOrderCellResource) => {
      runningOrderCells.value = exchangeValuesOfObject(e, runningOrderCells.value);
    })
    .listen(`.runningOrderColumn.created`, (e: RunningOrderColumnResource) => {
      runningOrderColumns.value = exchangeValuesOfObject(e, runningOrderColumns.value);
    })
    .listen(`.runningOrderColumn.updated`, (e: RunningOrderColumnResource) => {
      let copy = exchangeValuesOfObject(e, runningOrderColumns.value);
      copy.sort((a, b) => a.order - b.order);

      runningOrderColumns.value = copy;
    })
    .listen(`.runningOrderColumn.deleted`, (e: { id: number }) => {
      runningOrderColumns.value = removeItemFromArrayBasedOnId(e.id, runningOrderColumns.value);
    })
    .listen(`.runningOrderRow.created`, (e: RunningOrderRowResource) => {
      runningOrderRows.value = exchangeValuesOfObject(e, runningOrderRows.value);
    })
    .listen(`.runningOrderRow.updated`, (e: RunningOrderRowResource) => {
      runningOrderRows.value = exchangeValuesOfObject(e, runningOrderRows.value);
    })
    .listen(`.runningOrderRow.deleted`, (e: { id: number }) => {
      runningOrderRows.value = removeItemFromArrayBasedOnId(e.id, runningOrderRows.value);
    });
  Echo.join(`On.${props.model}.${props.modelId}`).listen(
    `.runningOrder.${props.runningOrder.id}.updated`,
    (e: { id: number; title: string; row_title: string }) => {
      emit('updated', e);
    }
  );
};

onMounted(() => {
  listenForBroadcast();
});

const showSaveAsTemplateModal = ref(false);
const showDownloadRunningOrderModal = ref(false);

const canDragAndDrop = ref(!isTouchDevice());

const actions = computed(() => {
  const array = [];

  if (isTouchDevice()) {
    array.push({
      title: canDragAndDrop.value ? 'Stop dragging rows' : 'Drag Rows',
      icon: 'fa-arrows-alt',
      action: () => {
        canDragAndDrop.value = !canDragAndDrop.value;
      },
    });
  }

  if (!props.isTemplate) {
    array.push({
      title: 'Show Mode',
      icon: 'fa-play',
      primary: true,
      action: () => {
        openRoute(getRoute('runningorder.showmode', props.runningOrder.id));
      },
    });
  }
  const dropdown = [
    !props.isTemplate && usePage().props.auth.user.groups.length > 0 && props.canEdit
      ? {
          title: 'Save as Template',
          preIcon: 'fa-save',
          action: () => {
            showSaveAsTemplateModal.value = true;
            rootEmit('close-all-drop-downs');
          },
        }
      : null,
    props.isTemplate
      ? null
      : {
          title: 'Download',
          preIcon: 'fa-download',
          action: async () => {
            showDownloadRunningOrderModal.value = false;
            await nextTick();
            showDownloadRunningOrderModal.value = true;
            rootEmit('close-all-drop-downs');
          },
        },

    props.canEdit
      ? {
          title: 'Running Order audit',
          preIcon: 'fa-history',
          action: () => {
            openAudits(null, null);
          },
        }
      : null,
    props.canEdit
      ? {
          preIcon: 'fa-history',
          title: showAuditsButton.value ? 'Hide Cell audit' : 'Cell Audit',
          action: () => {
            showAuditsButton.value = !showAuditsButton.value;
          },
        }
      : null,

    {
      title: 'Visible Columns',
      type: 'header',
    },
  ]
    .concat(
      runningOrderColumns.value.map((c) => {
        return {
          title: c.title,
          value: c.id,
          selected: !hiddenColumnIds.value.includes(c.id),
          action: () => {
            const index = hiddenColumnIds.value.indexOf(c.id);
            if (index === -1) {
              hiddenColumnIds.value.push(c.id);
            } else {
              hiddenColumnIds.value.splice(index, 1);
            }
          },
        };
      })
    )
    .filter((i) => i !== null);
  array.push({
    icon: 'fa-chevron-down',
    loading: loading.value,
    closeOnClick: false,
    dropdown: dropdown,
  });
  return array;
});

const getRowClasses = (row: RunningOrderRowResource) => {
  let textColor =
    ' text-black [&_*]:!text-black [&_.btn-in-table:enabled:hover_i]:!text-black [&_.fa-file]:!text-black  [&_.fa-times]:!text-black  [&_.fa-image]:!text-black !z-[9]';
  if (row.color === 'color-black') {
    textColor = ' !text-white [&_*]:!text-white ';
  }
  if ((row.color === 'none' || !row.color) && userStore.theme === 'base') {
    textColor = ' !text-white [&_*]:!text-white ';
  }
  return [
    selectedRowIds.value.has(row.id) ? ' row-has-been-selected ' : '',
    row.color && row.color !== 'none' ? ' running-order-row-' + row.color + ' ' : 'running-order-row-default',
    row.header ? 'running-order-row-header ' : '',
    textColor,
    !row.color || row.color === 'none' ? ' hover:!bg-row-hover ' : '',
  ].join(' ');
};

const showAuditsButton = ref(false);

const showAudits = ref(false);

const auditsRow = ref<RunningOrderRowResource | null>(null);

const auditsColumn = ref<RunningOrderColumnResource | null>(null);

const auditsCell = ref<RunningOrderCellResource | null>(null);

const openAudits = async (
  runningOrderRow: null | RunningOrderRowResource,
  runningOrderColumn: null | RunningOrderColumnResource
) => {
  rootEmit('close-all-drop-downs');
  showAudits.value = false;
  auditsRow.value = runningOrderRow;
  auditsColumn.value = runningOrderColumn;
  auditsCell.value = null;
  if (runningOrderRow && runningOrderColumn) {
    const cell = runningOrderCells.value.find(
      (c) => c.running_order_row_id === runningOrderRow.id && c.running_order_column_id === runningOrderColumn.id
    );
    if (cell) {
      auditsCell.value = cell;
    }
  }

  await nextTick();
  showAudits.value = true;
};

const formatDuration = (dur: string) => {
  const duration = moment.duration(dur);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = '';

  if (hours > 0) {
    result += `${hours}${hours === 1 ? 'h' : 'h'}`;
  }

  if (minutes > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${minutes}${minutes === 1 ? 'm' : 'm'}`;
  }

  if (seconds > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${seconds}${seconds === 1 ? 's' : 's'}`;
  }

  return result;
};

const runningOrderDeleted = (ids: number[]) => {
  runningOrderRows.value = runningOrderRows.value.filter((r) => !ids.includes(r.id));
};

const { eventTypes, fetch: fetchEventTypes } = inject(eventTypesKey, {
  eventTypes: computed(() => []),
  fetch: (force?: boolean = false) => {},
});

fetchEventTypes();

const connectetedEventTypes = computed(() => {
  if (!props.runningOrder) return [];

  return eventTypes.value.filter((ev) => ev.pivot.runningorders.some((cl) => cl.id === props.runningOrder.id));
});

const concatAllEvenTypeNames = computed(() => {
  if (!connectetedEventTypes.value) return '';

  return connectetedEventTypes.value.map((ev) => ev.name).join(', ');
});

const activeRunningOrderShowRows = ref([]);
const activeRunningOrderShow = ref<RunningOrderShowResource | null>(null);
watch(activeRunningOrderShow, () => {
  if (!activeRunningOrderShow.value) return;
  activeRunningOrderShowRows.value = [];
  selectedRowIds.value = new Set([]);
  // if (activeRunningOrderShow.value.running_order_show_row_pivots.length === 0) return;
  const indexOfFirstRow = getIndexFromArrayBasedOnId(
    activeRunningOrderShow.value.running_order_show_row_pivots[0].running_order_row_id,
    runningOrderRows.value
  );
  for (let i = 0; i < indexOfFirstRow; i++) {
    activeRunningOrderShowRows.value.push({ ...runningOrderRows.value[i], actualDuration: 0 });
  }
  activeRunningOrderShow.value.running_order_show_row_pivots.forEach(function (pivot) {
    const row = getItemFromArrayBasedOnId(pivot.running_order_row_id, runningOrderRows.value);
    if (row) {
      activeRunningOrderShowRows.value.push({ ...row, actualDuration: pivot.duration });
    }
  });
  const indexOfLastRow =
    getIndexFromArrayBasedOnId(
      activeRunningOrderShow.value.running_order_show_row_pivots[
        activeRunningOrderShow.value.running_order_show_row_pivots.length - 1
      ].running_order_row_id,
      runningOrderRows.value
    ) + 1;
  for (let j = indexOfLastRow; j < runningOrderRows.value.length; j++) {
    activeRunningOrderShowRows.value.push({ ...runningOrderRows.value[j], actualDuration: 0 });
  }
});
const runningOrderShowModalOpen = ref(false);
const openShows = ref(new Set([]));
const openRunningOrderShowModal = async () => {
  runningOrderShowModalOpen.value = false;
  await nextTick();
  runningOrderShowModalOpen.value = true;
};
const deleteRunningOrderShow = async (show: RunningOrderShowResource) => {
  const certain = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Delete Show',
    'Are you sure you want to delete this show from ' + formatStampAsHumanReadableDate(show.start)
  );
  if (!certain) return;
  await axios.delete('/api/running-order-shows/' + show.id);
  activeRunningOrderShow.value = null;
  runningOrderShows.value = runningOrderShows.value.filter((i) => i.id !== show.id);
};

const activeField = ref(null);
watch(
  activeField,
  () => {
    if (activeField.value) {
      const activeElement = document.activeElement;
      if (activeElement) {
        console.log(activeElement);
        activeElement.scrollIntoView({
          behavior: 'smooth', // Smooth scrolling
          block: 'nearest', // Vertical alignment (optional)
          inline: 'start', // Horizontal alignment (center element horizontally)
        });

        const scrollableContainer = activeElement.closest('.running-order-scroll-content-class'); // or any specific container
        console.log(scrollableContainer);
        if (scrollableContainer) {
          scrollableContainer.scrollLeft -= 500;
        }
      }
    }
  },
  { immediate: true, deep: true }
);
</script>
<template>
  <ContentContainer
    :class="{ 'isolate': open }"
    :loading="loading || loadingRows.length > 0"
    :can-edit="canEdit && loadingRows.length === 0"
    :edit-mode="isTemplate ? true : canEdit"
    :title="runningOrder.title"
    :start-open="isSingleRunningOrder"
    :just-content-without-header="isDisplay"
    pre-icon="fa-table-list"
    actions-as-buttons
    content-class="running-order-scroll-content-class"
    :actions="actions"
    :with-second-border="!(!isTemplate && open)"
    :align-center="!isTemplate && open"
    :content-class="!isTemplate && open ? 'mx-edge' : undefined"
    @open="open = $event"
    @edit="$emit('edit')">
    <!--    <template-->
    <!--      v-if="isTemplate && open && connectetedEventTypes.length > 0"-->
    <!--      #afterTitle>-->
    <!--      <div-->
    <!--        class="flex gap-5 ml-5"-->
    <!--        :title="concatAllEvenTypeNames">-->
    <!--        Part of {{ connectetedEventTypes.length }} event types-->
    <!--        &lt;!&ndash;        <DisplayBadge&ndash;&gt;-->
    <!--        &lt;!&ndash;          v-for="item in connectetedEventTypes"&ndash;&gt;-->
    <!--        &lt;!&ndash;          :key="item.id"&ndash;&gt;-->
    <!--        &lt;!&ndash;          :text="item.name" />&ndash;&gt;-->
    <!--      </div>-->
    <!--    </template>-->
    <template #underHeader>
      <div
        v-if="loadingRows.length > 0 && open"
        class="flex h-[9px] items-center justify-center mt-[20px]">
        <div class="h-[6px] w-full bg-backgroundColor-content rounded">
          <div
            class="h-[5px] rounded transition-[width] bg-success"
            :style="
              'width: ' + Number((runningOrderRows.length - loadingRows.length) / runningOrderRows.length) * 100 + '%;'
            " />
        </div>
      </div>
      <div
        v-else
        :class="open ? ' pl-[42px] ' : ' [&_*]:cursor-pointer '"
        class="flex gap-5">
        <div
          v-if="!isTemplate"
          class="text-textColor-soft pb-3 flex gap-5 items-center">
          <div
            :title="canEdit && open ? 'Click to change date' : ''"
            :class="canEdit && open ? 'hover:underline cursor-pointer' : ''"
            @click="canEdit && open ? $emit('edit') : null">
            <i class="fa fa-fw fa-calendar-o fa-regular" />
            {{ moment(runningOrder.start).format('ddd D MMM') }}
          </div>
          <div
            :title="canEdit && open ? 'Click to change start' : ''"
            :class="canEdit && open ? 'hover:underline cursor-pointer' : ''"
            @click="canEdit && open ? $emit('edit') : null">
            <i class="fa fa-fw fa-clock-o fa-regular" />
            {{ moment(runningOrder.start).format('HH:mm') }}-{{ runningOrderEndTime('HH:mm') }}
            <span v-if="formatDuration(runningOrderDuration)">({{ formatDuration(runningOrderDuration) }})</span>
          </div>

          <div
            v-if="runningOrder && runningOrderShows.length && runningOrderShows.filter((s) => !s.end).length && !open"
            class="text-sm underline cursor-pointer text-textColor"
            @click.stop="openRoute(getRoute('runningorder.showmode', runningOrder.id))">
            View Ongoing Show <i class="fa fa-fw fa-regular fa-external-link"></i>
          </div>

          <div
            v-if="runningOrder && runningOrderShows.length && canEdit && open"
            class="flex gap-5">
            <div
              v-if="runningOrderShows.filter((s) => !s.end).length"
              class="text-sm underline cursor-pointer text-textColor"
              @click="openRoute(getRoute('runningorder.showmode', runningOrder.id))">
              View Ongoing Show <i class="fa fa-fw fa-regular fa-external-link"></i>
            </div>
            <div
              v-else
              class="text-sm underline cursor-pointer hover:text-textColor"
              @click="openRunningOrderShowModal">
              {{
                activeRunningOrderShow
                  ? 'Seeing One Show'
                  : runningOrderShows.length + ' Show' + (runningOrderShows.length > 1 ? 's' : '') + ' Run'
              }}
              <i
                v-if="!activeRunningOrderShow"
                class="fa fa-fw fa-regular fa-eye"></i>
            </div>
            <VButton
              v-if="activeRunningOrderShow"
              type="warning"
              icon="fa-times"
              title="Clear"
              class="btn-outline"
              size="extra-small"
              @click="activeRunningOrderShow = null" />
          </div>
        </div>
        <div
          v-if="isTemplate"
          class="text-textColor-soft pb-3 flex gap-5">
          <div>
            <i class="fa fa-fw fa-clock-o fa-regular" />
            {{ runningOrder.template_start }} - {{ runningOrderEndTime() }}
            <span v-if="formatDuration(runningOrderDuration)">({{ formatDuration(runningOrderDuration) }})</span>
          </div>
        </div>
        <div
          v-if="runningOrder.show_caller_id"
          class="pt-2">
          <DisplayBadge
            size="tiny"
            color="blue"
            :text="
              'Show Caller: ' + getItemFromArrayBasedOnId(runningOrder.show_caller_id, users, { name: 'N/A' }).name
            " />
        </div>
        <div
          v-if="runningOrder?.placable?.name"
          class="pt-2">
          <DisplayBadge
            size="tiny"
            :text="'Where: ' + runningOrder.placable.name" />
        </div>
        <div
          v-if="maxNumberRows && maxNumberRows - 50 <= runningOrderRows.length"
          class="pt-2">
          <DisplayBadge
            size="tiny"
            :color="maxNumberRows - 20 <= runningOrderRows.length ? 'warning' : ''"
            :text="'Using: ' + runningOrderRows.length + ' of ' + maxNumberRows + ' rows.'" />
        </div>
      </div>
    </template>
    <template #content>
      <VTable
        v-if="newlyCreatedRunningOrderId !== runningOrder.id"
        :model-value="
          loadingRows.length
            ? runningOrderRows.filter((r) => !loadingRows.includes(r.id))
            : activeRunningOrderShow
              ? activeRunningOrderShowRows
              : runningOrderRows
        "
        row-size="small"
        class="running-order [&_:z-10]:"
        :can-drag="
          editMode && canEdit && canDragAndDrop && !creatingNewRow && !activeRunningOrderShow && activeField === null
        "
        sticky-header
        un-striped
        :vertically-bordered-table="true"
        :split-rows-vertically="false"
        :bordered-table="true"
        rounded-pill-rows
        row-classes="group/item outline-none h-[40px]"
        :add-background-classes-to-rows="false"
        handle-outside
        snap-rows
        :scroll-padding-top="20"
        :dont-use-scroll-snap="true"
        :sticky-last-column="editMode"
        :set-row-classes="getRowClasses"
        header-classes="z-[10]"
        @update:model-value="runningOrderRows = $event"
        @sorted="newOrderOfRows">
        <template #head>
          <VTableRow
            head
            class="z-[12]"
            sticky>
            <VTableCell
              style="min-width: 40px; max-width: 40px; width: 40px; background-color: rgb(var(--color-background-box))"
              classes=" group/header-multiselect  text-center left-0 h-[30px] z-[12] !pt-0 sticky border-t border-l">
              <div
                class="flex justify-center text-center"
                @click="selectedRowIds.size === 0 ? addAllRowIds() : clearRowIds()">
                <div
                  v-if="selectedRowIds.size === 0"
                  class="block text-sm"
                  :class="{ 'group-hover/header-multiselect:hidden': editMode && canEdit }">
                  #
                </div>
                <div
                  v-if="(canEdit && editMode) || selectedRowIds.size > 0"
                  :class="{ ' hidden group-hover/header-multiselect:block ': selectedRowIds.size === 0 }">
                  <CheckBox
                    :model-value="selectedRowIds.size > 0"
                    size="sm">
                  </CheckBox>
                </div>
              </div>
            </VTableCell>

            <VTableCell
              style="min-width: 70px; max-width: 70px; width: 70px; background-color: rgb(var(--color-background-box))"
              classes="left-[40px] z-[11] text-center sticky  border-t">
              Start
            </VTableCell>

            <VTableCell
              style="min-width: 69px; max-width: 69px; width: 69px; background-color: rgb(var(--color-background-box))"
              class="sticky left-[110px] z-[11] text-center border-t">
              Duration
            </VTableCell>

            <VTableCell
              class="sticky left-[179px] z-[11] !pl-edge border-t !border-r-4"
              style="min-width: 350px; background-color: rgb(var(--color-background-box))">
              Description
            </VTableCell>

            <ColumnHeader
              v-for="(columnDefinition, index) in activeColumns"
              :id="'running_order_column_' + columnDefinition.id"
              :key="columnDefinition.id"
              add-style="background-color: rgb(var(--color-background-box))"
              classes="!pl-edge  border-t"
              :column="columnDefinition"
              :can-edit="editMode && canEdit"
              :show-audits="showAuditsButton && canEdit"
              :is-hidden="hiddenColumnIds.includes(columnDefinition.id)"
              :can-move-left="index > 0"
              :can-move-right="index < activeColumns.length - 1"
              :running-order="runningOrder"
              @edit="editColumn(columnDefinition)"
              @toggle-hide="hiddenColumnIds.push(columnDefinition.id)"
              @move-left="moveColumnLeft(columnDefinition.id)"
              @move-right="moveColumnRight(columnDefinition.id)" />

            <ColumnCRUDModal
              v-if="editMode && canEdit && !activeRunningOrderShow"
              :max-number-columns="maxNumberColumns"
              :columns="runningOrderColumns"
              :column-types="columnTypes"
              :init-column="selectedColumn"
              class="!border-l-4"
              style="background-color: rgb(var(--color-background-box))"
              :with-restrict-edit="true"
              classes="border-t border-r"
              @closed="selectedColumn = null"
              @created="createColumn"
              @updated="updateColumn"
              @deleted="deleteColumn" />

            <VTableCell
              v-if="editMode && canEdit && activeRunningOrderShow"
              class="!border-l-4 sticky z-[11] border-t"
              style="background-color: rgb(var(--color-background-box))">
            </VTableCell>
          </VTableRow>
        </template>
        <template #row="{ item: row, index }: { item: RunningOrderRowResource }">
          <RunningOrderRow
            v-model:running-order-cells="runningOrderCells"
            v-model:selected-row-ids="selectedRowIds"
            v-model:active-field="activeField"
            :running-order-rows="runningOrderRows"
            :row="row"
            :can-edit="canEdit && loadingRows.length === 0 && activeRunningOrderShow === null"
            :is-template="isTemplate"
            :is-display="isDisplay"
            :edit-mode="editMode && activeRunningOrderShow === null"
            :in-show-mode="activeRunningOrderShow !== null"
            :active-columns="activeColumns"
            :row-selected="selectedRowIds.has(row.id)"
            :running-order="runningOrder"
            :with-audits-button="showAuditsButton"
            :index="index"
            :loading="loadingRows.includes(row.id)"
            @open-audits="openAudits(row, columnDefinition)"
            @update-row="runningOrderRows = exchangeValuesOfObject($event, runningOrderRows)"
            @deleted="runningOrderDeleted"
            @toggle-row="[activeRunningOrderShow ? null : toggleRow(row.id)]"
            @set-row-start="setRowStart(row)"
            @insert-row-after="insertRowRelative(row, false)"
            @insert-row-before="insertRowRelative(row, true)"
            @duplicate="duplicateRow(row)">
            <template
              v-if="activeRunningOrderShow"
              #showModeDuration>
              <div class="flex flex-col px-2 text-center">
                <div
                  v-if="row.actualDuration !== row.duration"
                  class="line-through text-sm">
                  {{ formatSecondsAsHoursAndMinutesAndSeconds(row.duration) }}
                </div>
                {{ formatSecondsAsHoursAndMinutesAndSeconds(row.actualDuration) }}
              </div>
            </template>
            <template
              v-if="activeRunningOrderShow"
              #showModeStart>
              <div class="flex flex-col px-2 text-center">
                <div class="line-through text-sm">
                  {{
                    formatStampAsTime(getStartOfRow(row, runningOrderRows, activeRunningOrderShow), null, secondFormat)
                  }}
                </div>
                {{
                  formatStampAsTime(
                    getStartOfRow(
                      row,
                      runningOrderRows.map((r) => {
                        return {
                          ...r,
                          duration: getItemFromArrayBasedOnId(
                            r.id,
                            activeRunningOrderShow.running_order_show_row_pivots,
                            { duration: 0 },
                            'running_order_row_id'
                          ).duration,
                        };
                      }),
                      activeRunningOrderShow
                    ),
                    null,
                    secondFormat
                  )
                }}
              </div>
            </template>
          </RunningOrderRow>
        </template>
      </VTable>
      <div
        v-if="editMode && canEdit && newlyCreatedRunningOrderId !== runningOrder.id && !activeRunningOrderShow"
        class="sticky left-0 px-[11px] py-5 [&>div>div>div]:flex-1">
        <TextInput
          v-model="newRowTitle"
          :can-edit="maxNumberRows ? maxNumberRows > runningOrderRows.length : true"
          placeholder="Description of new Row"
          action-buttons
          :disabled="loading || loadingRows.length > 0 || creatingNewRow"
          save-action-title="Add"
          with-save-action
          :tabindex="0"
          :with-clear-action="false"
          @paste="pasteInNewRowTitle"
          @keyup.enter="addRow(null)"
          @clear="newRowTitle = ''"
          @save="addRow(null)" />
      </div>
      <div v-if="newlyCreatedRunningOrderId === runningOrder.id">
        <EmptyStateFullPage
          icon="fa-circle-o-notch fa-spin"
          :description="'Building ' + runningOrder.title" />
      </div>
    </template>
  </ContentContainer>

  <RunningOrderAuditSidebar
    v-if="showAudits"
    :running-order="runningOrder"
    :running-order-cell="auditsCell"
    :running-order-column="auditsColumn"
    :running-order-row="auditsRow"
    @closed="showAudits = false" />

  <RunningOrderSetRowStart
    v-if="setRowStartRow"
    :show-mode="false"
    :running-order-rows="runningOrderRows"
    :runningorder="runningOrder"
    :selected-running-order-row="setRowStartRow"
    @update-row="runningOrderRows = exchangeValuesOfObject($event, runningOrderRows)"
    @update-rows="runningOrderRows = $event" />

  <RunningOrderDownloadModal
    v-if="showDownloadRunningOrderModal"
    :running-order="runningOrder"
    :hidden-columns="runningOrderColumns.filter((column) => hiddenColumnIds.includes(column.id))"
    :column-definitions="runningOrderColumns"
    @closed="showDownloadRunningOrderModal = false" />

  <SaveAsTemplateModal
    v-if="showSaveAsTemplateModal"
    :init-title="runningOrder.title"
    :modal-title="'Add ' + runningOrder.title + ' as a template'"
    url="/api/runningorders"
    :params="{
      model_type: 'App\\Group',
      template_id: runningOrder.id,
    }"
    @closed="showSaveAsTemplateModal = false" />

  <CrudModal
    v-if="runningOrderShowModalOpen"
    title="Select Show to display"
    medium
    only-close-button
    @closed="[(runningOrderShowModalOpen = false)]">
    <div class="flex flex-col gap-edge">
      <h4>This Running Order Has been run {{ runningOrderShows.length }} times</h4>
      <BoxContainer
        v-for="show in runningOrderShows"
        :header="'Show on ' + formatStampAsDate(show.start)"
        :default-open="activeRunningOrderShow !== null && activeRunningOrderShow.id === show.id"
        :actions="
          openShows.has(show.id)
            ? [
                {
                  icon:
                    activeRunningOrderShow && activeRunningOrderShow.id === show.id
                      ? 'fa-times fa-regular'
                      : 'fa-eye fa-regular',
                  title: activeRunningOrderShow && activeRunningOrderShow.id === show.id ? 'Clear Show' : 'View Show',
                  action: () => {
                    [
                      activeRunningOrderShow && activeRunningOrderShow.id === show.id
                        ? (activeRunningOrderShow = null)
                        : (activeRunningOrderShow = show),
                      (runningOrderShowModalOpen = activeRunningOrderShow === null),
                    ];
                  },
                },

                {
                  icon: 'fa-trash fa-regular',
                  type: 'warning',
                  action: () => {
                    deleteRunningOrderShow(show);
                  },
                },
              ]
            : []
        "
        togglable
        @open="$event ? openShows.add(show.id) : openShows.delete(show.id)">
        <template
          v-if="!openShows.has(show.id)"
          #header>
          <div class="w-full flex justify-between items-center">
            <h3>Show on {{ formatStampAsDate(show.start) }}</h3>
            <div class="text-sm text-textColor-soft">
              {{ formatStampAsTime(show.start) }} -
              {{ show.end ? formatStampAsTime(show.end) : 'Ongoing' }}
            </div>
          </div>
        </template>
        <div
          v-if="show"
          class="flex flex-col gap-edge">
          <div class="grid grid-cols-3 gap-y-edge">
            <div>
              <InputLabel
                label="Start"
                super-text></InputLabel>
              {{ formatStampAsTime(show.start) }}
            </div>
            <div>
              <InputLabel
                label="end"
                super-text></InputLabel>
              {{ show.end ? formatStampAsTime(show.end) : 'Ongoing' }}
              {{
                show.end && !timeStampsAreSame(show.start, show.end, 'day') ? ' on ' + formatStampAsDate(show.end) : ''
              }}
            </div>
            <div v-if="show.show_caller">
              <InputLabel
                label="Show Caller"
                super-text></InputLabel>
              {{ show.show_caller.name }}
            </div>

            <div>
              <InputLabel
                label="Expected Run Time"
                super-text></InputLabel>
              {{ formatMinutesAsHoursAndMinutes(getDiffInInterval(runningOrder.start, runningOrder.end, 'minutes')) }}
            </div>
            <div>
              <InputLabel
                label="Actual Run Time"
                super-text></InputLabel>
              {{ formatMinutesAsHoursAndMinutes(getDiffInInterval(show.start, show.end, 'minutes')) }}
            </div>
            <div v-if="show">
              <InputLabel
                label="#Rows Run"
                super-text></InputLabel>
              {{ uniqueValuesFromArray(show.running_order_show_row_pivots, 'running_order_row_id').length }} /
              {{ runningOrderRows.length }}
            </div>
          </div>
          <div>
            <h4>Users ({{ show.running_order_show_user_pivots.length }})</h4>
            <VTable>
              <VTableRow v-for="pivot in show.running_order_show_user_pivots">
                <VTableCell>
                  <div class="flex gap-3 items-center">
                    <img
                      :src="pivot.user.avatar_url"
                      class="rounded size-7 object-cover"
                      alt="user profile picture" />
                    {{ pivot.user.name }}
                  </div>
                </VTableCell>
                <VTableCell>
                  <div class="flex flex-col [&_*]:text-xs text-right">
                    <div>From: {{ pivot.start }}</div>
                    <div>To: {{ pivot.end }}</div>
                  </div>
                </VTableCell>
              </VTableRow>
            </VTable>
          </div>
        </div>
      </BoxContainer>
    </div>
  </CrudModal>
</template>
