import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import type { CheckListResource } from '@/types/check-list';
import { CheckListModel, getCheckLists } from '@/services/api-check-lists';

export const checkListStore = defineStore('check-list-store', () => {
  const list = ref<Map<string, CheckListResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (model: CheckListModel, modelId: number) => {
    Echo.channel(`private-${model}.${modelId}`)
      .listen('.checkList.created', async () => {
        await fetchCheckLists(model, modelId, true);
      })
      .listen('.checkList.updated', async () => {
        await fetchCheckLists(model, modelId, true);
      })
      .listen('.row.created', async () => {
        await fetchCheckLists(model, modelId, true);
      })
      .listen('.row.updated', async () => {
        await fetchCheckLists(model, modelId, true);
      })
      .listen('.row.deleted', async () => {
        await fetchCheckLists(model, modelId, true);
      })
      .listen('.checkList.deleted', async () => {
        await fetchCheckLists(model, modelId, true);
      });
  };

  const fetchCheckLists = async (model: CheckListModel, modelId: number, force: boolean = false) => {
    if (!force && list.value.has(formatModel(model, modelId))) return list.value.get(formatModel(model, modelId));

    loading.value = true;

    const { data } = await getCheckLists(model, modelId);

    if (!list.value.has(formatModel(model, modelId))) {
      listenForBroadcast(model, modelId);
    }

    list.value.set(formatModel(model, modelId), data);

    loading.value = false;

    return data;
  };

  return {
    list,
    loading,
    fetchCheckLists,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(checkListStore, import.meta.hot));
}
