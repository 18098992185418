import { EntityTaskPivotResource, TaskCommentResource, TaskResource, TaskTaskFieldPivotResource } from '@/types/tasks';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import { getNow } from '@/util/timeFunctions';
import { usePage } from '@inertiajs/vue3';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';

type TaskData = {
  title?: string | null;
  description?: string | null;
  owner_type: string;
  owner_id: number;
  taskable_type: string | null;
  taskable_id: number | null;
  assigned_user_id: number | null;
};

export const getTask = async (taskUuid: string, loadOwnerFields = false) => {
  return axios.get<TaskResource>('/api/tasks/' + taskUuid, {
    params: {
      load_owner_fields: loadOwnerFields,
    },
  });
};

export const postTask = async (data: TaskData) => {
  return axios.post<TaskResource>('/api/tasks', {
    ...data,
  });
};

export const patchTask = async (taskUuid: string, data: Partial<TaskData>) => {
  return axios.patch<TaskResource>(`/api/tasks/${taskUuid}`, data);
};

export const destroyTask = async (taskUuid: string, alreadyCertain = false) => {
  if (!alreadyCertain) {
    const certain = await useDeleteObjectModal().assertReadyToDeleteModal(
      'Delete Task',
      'Are you sure you want to delete this task?'
    );
    if (!certain) return false;
  }
  await axios.delete<string>(`/api/tasks/${taskUuid}`);
  return true;
};

export const changeCompleteStatusOfTask = async (task: TaskResource, alreadyCertain = false): Promise<TaskResource> => {
  if (!alreadyCertain && task.completed_at) {
    const certain = await useCertaintyModal().assertCertain(
      'Un-complete Task',
      'Are you sure you want to un-complete this task?'
    );
    if (!certain) return task;
  }
  if (task.completed_at) {
    await axios.post(`/api/tasks/${task.uuid}/un-complete`);
    return { ...task, completed_at: null, completeable_type: null, completeable_id: null };
  } else {
    await axios.post(`/api/tasks/${task.uuid}/complete`);
    return {
      ...task,
      completed_at: getNow(),
      completeable_type: 'App\\User',
      completeable_id: usePage().props.auth.user.id,
    };
  }
};

export const getTaskComment = async (taskUuid: string, commentId: number) => {
  return axios.get<TaskCommentResource>('/api/tasks/' + taskUuid + '/comments/' + commentId);
};

export const postTaskComment = async (taskUuid: string, data: { comment: string }) => {
  return axios.post<TaskResource>('/api/tasks/' + taskUuid + '/comments', {
    ...data,
  });
};

export const patchTaskComment = async (taskUuid: string, comment: TaskCommentResource) => {
  return axios.patch<TaskResource>(`/api/tasks/${taskUuid}/comments/${comment.id}`, {
    comment: comment.comment,
  });
};

export const destroyComment = async (taskUuid: string, comment: TaskCommentResource, alreadyCertain = false) => {
  if (!alreadyCertain) {
    const certain = await useDeleteObjectModal().assertReadyToDeleteModal(
      'Delete Comment',
      'Are you sure you want to delete this comment?'
    );
    if (!certain) return false;
  }
  await axios.delete<string>(`/api/tasks/${taskUuid}/comments/${comment.id}`);
  return true;
};

export const getTaskEntityPivots = async (taskUuid: string) => {
  return axios.get<EntityTaskPivotResource[]>('/api/tasks/' + taskUuid + '/entity-task-pivots');
};
export const getTaskEntityPivot = async (taskUuid: string, pivotId: number) => {
  return axios.get<EntityTaskPivotResource>('/api/tasks/' + taskUuid + '/entity-task-pivots/' + pivotId);
};

export const postTaskEntityPivot = async (taskUuid: string, model: string, modelId: number) => {
  return axios.post<EntityTaskPivotResource>(`/api/tasks/${taskUuid}/entity-task-pivots`, {
    'model': model,
    'model_id': modelId,
  });
};

export const patchTaskEntityPivot = async (taskUuid: string, pivotId: number, data: object) => {
  return axios.patch(`/api/tasks/${taskUuid}/entity-task-pivots/${pivotId}`, data);
};

export const destroyEntityPivot = async (taskUuid: string, pivotId: number, alreadyCertain = false) => {
  if (!alreadyCertain) {
    const certain = await useDeleteObjectModal().assertReadyToDeleteModal(
      'Remove',
      'Are you sure you want to delete this?'
    );
    if (!certain) return false;
  }
  await axios.delete(`/api/tasks/${taskUuid}/entity-task-pivots/${pivotId}`);
  return true;
};

export const postTaskTaskFieldPivot = async (
  taskUuid: string,
  taskFieldId: number,
  value: string | number | null,
  taskFieldOptionId: number | null
) => {
  return axios.post<TaskTaskFieldPivotResource>(`/api/tasks/${taskUuid}/task-fields/${taskFieldId}`, {
    'value': value,
    'task_field_option_id': taskFieldOptionId,
  });
};
