<script setup lang="ts">
import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  modelValue?: string | number | null | boolean;
  canEdit?: boolean;
  isHidden?: boolean;
  options?: string[] | object[] | number[] | null;
  iconLeft?: string | null;
};

withDefaults(defineProps<Props>(), {
  modelValue: null,
  canEdit: false,
  isHidden: false,
  options: null,
  iconLeft: null,
});

const emit = defineEmits<{
  (e: 'blur', arg: string | number | null): void;
}>();

const emitValue = (value: string | number | null) => {
  emit('blur', value);
};
</script>

<template>
  <VSelect
    :model-value="modelValue"
    :can-edit="canEdit"
    :options="options"
    :icon-left="iconLeft"
    :is-hidden="isHidden"
    nullable
    nullable-display-text=""
    @update:model-value="emitValue" />
</template>
