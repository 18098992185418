import type { ModelType, PartnerTypesResource } from '@/types/globals';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { formatModel } from '@/util/store-helpers';
import { copyObject } from '@/util/object-helpers';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId, getKey } from '@/util/globals';

export const usePartnerTypesStore = defineStore('partner-types', () => {
  const list = ref(new Map());
  const loading = ref(false);

  const fetchPartnerTypes = async (modelType: ModelType, modelId: number, type: string, force = false) => {
    if (!list.value.has(formatModel(modelType, modelId, type))) {
      listenForBroadcast(modelType, modelId, type);
    }
    if (force || !list.value.has(formatModel(modelType, modelId, type))) {
      getData(modelType, modelId, type);
    }
    return list.value.get(formatModel(modelType, modelId, type));
  };

  const listenForBroadcast = (modelType: ModelType, modelId: number, type: string) => {
    Echo.join(`On.${modelType}.${modelId}`)
      .listen('.partnerType.created', async (partnerType: PartnerTypesResource) => {
        if (partnerType.type === type) {
          await addOrUpdate(modelType, modelId, type, partnerType);
        }
      })
      .listen('.partnerType.updated', async (partnerType: PartnerTypesResource) => {
        if (partnerType.type === type) {
          await addOrUpdate(modelType, modelId, type, partnerType);
        }
      })
      .listen('.partnerType.deleted', async (partnerType: { id: number }) => {
        await removeById(modelType, modelId, type, getKey(partnerType, 'id', null), false);
      });
  };

  const getData = async (modelType: ModelType, modelId: number, type: string) => {
    loading.value = true;
    switch (modelType) {
      case 'Festival': {
        const { data } = await axios.get(`/api/festivals/${modelId}/partners/types`);
        list.value.set(formatModel(modelType, modelId, type), data);
        break;
      }
      case 'Group': {
        const { data } = await axios.get('/api/partners/types', {
          params: {
            model_type: `App\\${modelType}`,
            model_id: modelId,
            type: type,
          },
        });
        list.value.set(formatModel(modelType, modelId, type), data);

        break;
      }
      default: {
        throw new Error('Invalid model type');
      }
    }
    loading.value = false;
  };

  const addOrUpdate = async (
    modelType: ModelType,
    modelId: number,
    type: string,
    partnerType: PartnerTypesResource
  ) => {
    if (!list.value.has(formatModel(modelType, modelId, type))) {
      await fetchPartnerTypes(modelType, modelId, type);
      return;
    }
    let data = copyObject(list.value.get(formatModel(modelType, modelId, type)));
    data = exchangeValuesOfObject(partnerType, data);
    list.value.set(formatModel(modelType, modelId, type), data);
  };

  const removeById = async (
    modelType: ModelType,
    modelId: number,
    type: string,
    partnerTypeId: number,
    fetchIfCantFind = true
  ) => {
    if (!list.value.has(formatModel(modelType, modelId, type))) {
      await fetchPartnerTypes(modelType, modelId, type);
      return;
    }
    const data = list.value.get(formatModel(modelType, modelId, type));
    const index = getIndexFromArrayBasedOnId(partnerTypeId, data);
    if (index > -1) {
      data.splice(index, 1);
      list.value.set(formatModel(modelType, modelId, type), data);
    } else if (fetchIfCantFind) {
      await fetchPartnerTypes(modelType, modelId, type);
    }
  };

  return {
    list,
    loading,
    fetchPartnerTypes,
    removeById,
    addOrUpdate,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePartnerTypesStore, import.meta.hot));
}
