import { usePage } from '@inertiajs/vue3';
import { useLocalStorage } from '@vueuse/core';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const initTheme: 'light' | 'base' = localStorage.getItem('theme') ?? 'base';

  const userId = ref<number | null>(null);
  const name = ref('');
  const admin = ref(false);
  const email = ref('');
  const customHomeId = ref<number | null>(null);
  const customHomeType = ref<string | null>(null);
  const notifications = ref([]);
  const intro = ref<(() => void) | null>(null);
  const theme = useLocalStorage<'base' | 'light'>('theme', initTheme ?? 'base');

  const notificationCount = computed(() => notifications.value.length);

  const fill = (user: any = null) => {
    if (usePage().props.authenticated) {
      if (user?.id) {
        userId.value = user.id;
        name.value = user.name;
        admin.value = user.is_admin;
        email.value = user.email;
        customHomeId.value = user.custome_home_id;
        customHomeType.value = user.custome_home_typ;
      } else {
        userId.value = usePage().props.auth.user.id;
        name.value = usePage().props.auth.user.name;
        admin.value = usePage().props.auth.user.is_admin;
        email.value = usePage().props.auth.user.email;
        customHomeId.value = usePage().props.auth.user.custom_home_id;
        customHomeType.value = usePage().props.auth.user.custom_home_type;
      }
    }
  };

  return {
    userId,
    name,
    admin,
    email,
    customHomeId,
    customHomeType,
    fill,
    notificationCount,
    theme,
    intro,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
